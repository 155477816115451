import { useIsAccountingFirm } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';

import { useSubscriptionOrganization } from '../api';
import { useIsSubscriptionsEnabled } from './useIsSubscriptionsEnabled';
import { useSubscription } from './useSubscription';

export const useSubscriptionOrganizationFailureState = () => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const [isSubscriptionsPaymentFeeFailedGracePeriodEnabled] = useFeature(
    FeatureFlags.SubscriptionsPaymentFeeFailedGracePeriod,
    false
  );
  const subscription = useSubscription();
  const {
    settings: { subscriptions: subscriptionsConfig },
  } = useConfig();
  const isAccountingFirm = useIsAccountingFirm();

  const isEnabled =
    isSubscriptionsEnabled &&
    isSubscriptionsPaymentFeeFailedGracePeriodEnabled &&
    !!subscription &&
    subscriptionsConfig?.isPaymentFeeFailedGracePeriodEnabled &&
    !isAccountingFirm;

  const { data: subscriptionOrganization, isFetching } = useSubscriptionOrganization({
    enabled: isEnabled,
  });

  return {
    isFetching,
    failureState: isEnabled ? subscriptionOrganization?.failureState : undefined,
  };
};
