import { Box, Flex, Image } from '@chakra-ui/react';
import { Icon, Link, Text, useBreakpoint, useTheme } from '@melio/penny';
import { useEffect, useState } from 'react';

import { useBrazeNavigate } from '../hooks/useBrazeNavigate';

type DashboardContentCardProps = {
  title: string;
  description: string;
  mode: 'light' | 'dark';
  imageUrl?: string;
  icon: 'true' | 'false';
  url: string;
  urlText: string;
  newTab?: 'true' | 'false';
  onCardView: VoidFunction;
  onClick: VoidFunction;
  onClose: VoidFunction;
};

export const DashboardContentCard = ({
  title,
  description,
  mode,
  icon,
  imageUrl,
  url,
  urlText,
  newTab,
  onCardView,
  onClick,
  onClose,
}: DashboardContentCardProps) => {
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const { colors } = useTheme();
  const { isExtraSmallScreen } = useBreakpoint();
  const { route } = useBrazeNavigate();

  const isIcon = icon === 'true';

  useEffect(() => {
    onCardView();
  }, [onCardView]);

  const dimensions = imageUrl && isIcon ? '24px' : isExtraSmallScreen ? '52px' : '64px';
  const image = imageUrl && (
    <Flex direction="column" height="full" alignItems="flex-end">
      <Image src={imageUrl} height={dimensions} width={dimensions} minWidth={dimensions} minHeight={dimensions} />
    </Flex>
  );

  const backgroundColor = mode === 'light' ? colors.global.brand['100'] : colors.global.neutral['300'];

  return showBanner ? (
    <Flex
      background={backgroundColor}
      minHeight={isExtraSmallScreen ? '116px' : '96px'}
      width="100%"
      alignItems="center"
      top="s-m"
      left="s-m"
      border="1px"
      borderColor={backgroundColor}
      padding="s"
      borderRadius="12px"
      justifyContent="space-between"
    >
      <Flex height="full" gap={isExtraSmallScreen ? 'xs-s' : isIcon ? 's' : 'm'}>
        {image}
        <Flex direction="column" width="100%" height="full" gap={isExtraSmallScreen ? 'xs' : 'xxs'}>
          <Flex direction="column" width="100%" height="full" gap={isExtraSmallScreen ? 'xxs' : 'none'}>
            <Text textStyle={isExtraSmallScreen ? 'body4Semi' : 'body3Semi'}>{title}</Text>
            {isExtraSmallScreen ? (
              <Text textStyle="caption1">{description}</Text>
            ) : (
              <Text textStyle="body3">{description}</Text>
            )}
          </Flex>
          <Text textStyle={isExtraSmallScreen ? 'body4Semi' : 'body3Semi'}>
            <b>
              <Link
                href={url}
                label={urlText}
                color="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  onClick();
                  route(url, newTab === 'true');
                }}
              />
            </b>
          </Text>
        </Flex>
      </Flex>
      <Box
        width="s"
        height="s"
        onClick={() => {
          setShowBanner(false);
          onClose();
        }}
        cursor="pointer"
        display="flex"
        justifyContent="right"
        justifySelf="stretch"
        alignSelf="self-start"
        margin={0}
      >
        <Icon type="close-mini" size="small" />
      </Box>
    </Flex>
  ) : null;
};
