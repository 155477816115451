import { useMissingCompanyLegalInfoFields } from '@melio/ap-domain';
import { CompleteLegalInfoFormWidgetFields } from '@melio/ap-widgets';
import { useToast } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useAccount, useOrganizationPreferences } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import React from 'react';

import { CompleteRequiredDetailsActivity, useCompleteComplianceInformation } from '../../complete-required-details';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { getDefaultValuesFromAccount } from '../BusinessLegalInfo.utils';
import { buildLegalCompanyData } from './CompleteLegalInfo.activity.utils';
import { CompleteLegalInfoLoadingScreen, CompleteLegalInfoScreen } from './screens';

export type CompleteLegalInfoActivityProps = {
  onBack: VoidFunction;
  onClose: VoidFunction;
  onDone: VoidFunction;
  onError?: ErrorFunction;
  isLoading?: boolean;
};

type CompleteLegalInfoProps = Omit<CompleteLegalInfoActivityProps, 'missingKycComplianceInformation'>;

export const CompleteLegalInfoActivity = withAnalyticsContext<CompleteLegalInfoActivityProps>((props) => {
  const { isLoading, missingFields } = useCompleteComplianceInformation({ isInitiator: true });

  if (isLoading) {
    return <NewSinglePaymentStepLayout isLoading />;
  }

  return missingFields.length ? (
    <CompleteLegalInfoActivityExistingUsers {...props} />
  ) : (
    <CompleteLegalInfoActivityNewUsers {...props} />
  );
});
CompleteLegalInfoActivity.displayName = 'CompleteLegalInfoActivity';

const CompleteLegalInfoActivityExistingUsers = withAnalyticsContext<CompleteLegalInfoProps>(
  ({ setAnalyticsProperties, onDone, ...props }) => {
    const { formatMessage } = useMelioIntl();

    setAnalyticsProperties({
      Flow: 'payment',
      Intent: 'pay',
    });

    return (
      <CompleteRequiredDetailsActivity
        {...props}
        submitButtonLabel={formatMessage('widgets.completeRequiredDetailsModal.button.primary.label.last-step')}
        onDone={onDone}
        onSkip={onDone}
        placement="one-last-thing"
      />
    );
  }
);

const CompleteLegalInfoActivityNewUsers = withAnalyticsContext<CompleteLegalInfoProps>(
  ({ onDone, onBack, onClose, onError, setAnalyticsProperties, ...props }) => {
    const { isLoading, data: currentAccount, update, isMutating } = useAccount({ id: 'me' });
    const { missingCompanyLegalInfoFields } = useMissingCompanyLegalInfoFields() || {};
    const { toast } = useToast();
    const { formatMessage } = useMelioIntl();
    const [kycUpliftEnabled] = useFeature<boolean>(FeatureFlags.MtlKycUplift, false);
    const { create: createOrganizationPreference } = useOrganizationPreferences({ enabled: false });

    const { track } = useAnalytics();

    const handleDone = async ({
      legalCompanyName,
      legalAddress,
      taxInfoIdentifier,
      taxInfoType,
      phoneNumber,
      industry,
      businessType,
      existingTaxIdIsEin,
      taxIdEinOverride,
    }: CompleteLegalInfoFormWidgetFields) => {
      const shouldOverrideTaxId = kycUpliftEnabled && existingTaxIdIsEin === 'No' && !!taxIdEinOverride;

      try {
        await update({
          company: buildLegalCompanyData(currentAccount, {
            legalCompanyName,
            legalAddress,
            phoneNumber,
            industry,
            businessType,
            taxInfoType,
            taxInfoIdentifier,
            ...(shouldOverrideTaxId && { taxIdEinOverride }),
          }),
        });

        if (shouldOverrideTaxId) {
          await createOrganizationPreference({
            key: 'taxIdEinOverride',
            value: 'true',
          });
        }

        track('VerifyTaxDetails', 'Submitted');
        onDone();
      } catch (error) {
        // Until we have a infra solution.
        toast({ type: 'error', title: formatMessage('activities.completeLegalInfo.updateError') });
        onError?.({ message: formatMessage('activities.completeLegalInfo.updateError') });
      }
    };

    useAnalyticsContext({
      globalProperties: {
        BusinessFieldsShow: missingCompanyLegalInfoFields,
      },
    });

    if (!missingCompanyLegalInfoFields) {
      return <CompleteLegalInfoLoadingScreen onBack={onBack} onClose={onClose} />;
    }

    // this shouldn't actually happen, but it prevents the option of seeing the form without any fields
    if (missingCompanyLegalInfoFields.length === 0) {
      return null;
    }

    const defaultValues = getDefaultValuesFromAccount(currentAccount);

    return (
      <CompleteLegalInfoScreen
        {...props}
        companyName={currentAccount?.company.name || ''}
        missingLegalInfoFields={missingCompanyLegalInfoFields}
        isLoading={isLoading}
        isSaving={isMutating}
        defaultValues={defaultValues}
        onBack={onBack}
        onClose={onClose}
        onDone={handleDone}
      />
    );
  }
);
