/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useRecoilValue } from 'recoil';

import { GroupedLists } from '@/cl/components/GroupedLists/GroupedLists.component';
import { NoResultsFound } from '@/cl/components/NoResultsFound/NoResultsFound.component';
import { BillsInboxMainPagePromo } from '@/components/bills-inbox/BillsInboxMainPagePromo';
import { usePartnerConfig } from '@/hooks/partners';
import { useSelectFirstCardFromGroup } from '@/hooks/payDashboard.hooks';
import { payDashboardQuerySearchSelector } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import {
  GroupItem,
  isBillGroupItem,
  isPaymentGroupItem,
  isPaymentRequestGroupItem,
  isScannedInvoiceGroupItem,
  PayDashboardInboxTabGroup,
  PayDashboardSectionEnum,
  PaymentGroupItem,
} from '@/types/payDashboard.types';
import { sortDates } from '@/utils/dates.util';
import { EmptyPayList } from '../../empty-pay-list/EmptyPayList.widget';

const getGroupItemDueDate = (groupItem: GroupItem): string | Date | null => {
  // We return null and not undefined to reserve undefined as switch exhaustiveness check
  if (isScannedInvoiceGroupItem(groupItem)) {
    return groupItem.scannedInvoice.dueDate || null;
  } else if (isPaymentGroupItem(groupItem)) {
    return groupItem.payment.bills?.[0].dueDate || null;
  } else if (isBillGroupItem(groupItem)) {
    return groupItem.bill.dueDate || null;
  } else if (isPaymentRequestGroupItem(groupItem)) {
    return groupItem.paymentRequest.dueDate || null;
  }
  return null;
};

const getSortedGroupItemsBySectionName = (group: PayDashboardInboxTabGroup): GroupItem[] => {
  switch (group.section) {
    case PayDashboardSectionEnum.error:
      return [...(group.items as PaymentGroupItem[])].sort((cardA, cardB) =>
        sortDates(cardA.payment?.scheduledDate, cardB.payment?.scheduledDate, 'desc'),
      );
    case PayDashboardSectionEnum.overdue:
    case PayDashboardSectionEnum.thisWeek:
    case PayDashboardSectionEnum.later:
    default:
      return [...group.items].sort((cardA, cardB) =>
        sortDates(getGroupItemDueDate(cardA) || undefined, getGroupItemDueDate(cardB) || undefined, 'asc'),
      );
  }
};

export const UnpaidCardList = ({ groups }: { groups: PayDashboardInboxTabGroup[] }) => {
  const { formatMessage } = usePlatformIntl();
  const queryString = useRecoilValue(payDashboardQuerySearchSelector);
  const { partnerConfig } = usePartnerConfig();
  const { NoBillsFoundIcon } = partnerConfig.icons;

  const getTitleBySectionName = (section: PayDashboardSectionEnum) => {
    switch (section) {
      case PayDashboardSectionEnum.error:
        return formatMessage(`app.payDashboard.payListSection.sections.failed`);
      case PayDashboardSectionEnum.overdue:
        return formatMessage(`app.payDashboard.payListSection.sections.overdue`);
      case PayDashboardSectionEnum.thisWeek:
        return formatMessage(`app.payDashboard.payListSection.sections.thisWeek`);
      case PayDashboardSectionEnum.later:
      default:
        return formatMessage(`app.payDashboard.payListSection.sections.later`);
    }
  };

  const sortedGroupsWithTitles = React.useMemo(
    () =>
      groups.map((group) => ({
        ...group,
        title: getTitleBySectionName(group.section),
        items: getSortedGroupItemsBySectionName(group),
      })),
    [groups],
  );

  useSelectFirstCardFromGroup(sortedGroupsWithTitles);

  return sortedGroupsWithTitles.length === 0 ? (
    queryString ? (
      <NoResultsFound text={formatMessage('widgets.emptySearchResult.billDescription')} icon={NoBillsFoundIcon.small} />
    ) : (
      <EmptyPayList />
    )
  ) : (
    <>
      <BillsInboxMainPagePromo />
      <GroupedLists groups={sortedGroupsWithTitles} size="small" listType="payCardList" />
    </>
  );
};
