/* eslint-disable max-lines */
import { datadogRum } from '@datadog/browser-rum';
import {
  fxMemoRegex,
  getFastDeliveryPreference,
  isFxDM,
  isFxPaymentIntent as _isFxPaymentIntent,
  useNonPositiveAmountsEffect,
  usePaymentSchedulingPreference,
} from '@melio/ap-domain';
import { useToast } from '@melio/penny';
import { Traits, useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import {
  DeliveryMethod,
  DeliveryPreference,
  FinancingProvider,
  Payment,
  PaymentApprovalDecisionStatusEnum,
  SelectedRepaymentOption,
  useAccountingPlatforms,
  useDeliveryMethods,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { getDollarsFromCents, useDebounce } from '@melio/platform-utils';
import Big from 'big.js';
import { useEffect, useMemo, useRef, useState } from 'react';

import { getDefaultPaymentPurposeFormFields } from '../../../utils/pay-flow/paymentPurpose';
import { ExtendedPaymentIntent, useExtendedPaymentIntent } from '../../../utils/pay-flow/useExtendedPaymentIntent';
import { useVendorUnMaskedAccountNumber } from '../../../utils/pay-flow/useVendorAccountNumber';
import { CompleteLegalInfoActivity } from '../../business-legal-info';
import { useShouldCollectLegalInfoOnPaymentFlow } from '../../complete-required-details';
import { DeliveryMethodSelectionActivity } from '../../delivery-methods';
import { FundingSourceSelectionActivity } from '../../funding-sources';
import { FundingSourceSelectionOnDoneFn } from '../../funding-sources/FundingSourceSelection/types';
import { InternationalPaymentPurposeActivity } from '../../international-payment/payment-purpose';
import { PaymentPurposeUpdateData } from '../../international-payment/payment-purpose/PaymentPurpose.activity';
import { MemoToVendorActivity } from '../../MemoToVendor';
import { NewSinglePaymentStepLayout } from '../../NewSinglePaymentStepLayout';
import { PaymentScheduledActivity } from '../../PaymentScheduled';
import { PaymentFlowDoneAction } from '../../types';
import { DeliveryDateActivity } from '../DeliveryDate';
import { RepaymentTermsActivity } from '../RepaymentTerms/RepaymentTerms.activity';
import { ReviewAndConfirmActivity } from '../ReviewAndConfirm';
import { getAdditionalAnalytics } from './getAdditionalAnalytics';
import { getDefaultMemo } from './SinglePaymentFlow.activity.utils';
import { SinglePaymentFlowActivityProps } from './types';
import { usePreFetch } from './usePreFetch';
import { useSinglePaymentFlowActivityStep } from './useSinglePaymentFlowActivityStep';

function useReadyEventWhenLoaded(isLoading: boolean) {
  const hasReportedLoadSuccess = useRef(false);

  useEffect(() => {
    if (!isLoading && !hasReportedLoadSuccess.current) {
      datadogRum.addTiming('single_payment_flow_ready');
      hasReportedLoadSuccess.current = true;
    }
  }, [isLoading]);
}

// TODO: this component should be deleted and the relevant logic moved into ScheduleSinglePayment + EditSinglePayment - https://linear.app/meliopayments/issue/PLA-803/refactor-singlepaymentflow-editsinglepayment-and-schedulesinglepayment
export const SinglePaymentFlowActivity = withAnalyticsContext<SinglePaymentFlowActivityProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({
    paymentIntentId,
    onBack: onFirstStepBack,
    onClose,
    onError,
    onDone,
    actionToPerform = 'schedule',
    onConfirm,
    isLoading,
    paymentIntentModel,
    deliveryMethodId: deliveryMethodIdFromState,
    analyticsProperties,
    externalOrigin,
  }) => {
    const { setTraits, track } = useAnalytics();
    const { formatMessage } = useMelioIntl();
    const { toast } = useToast();
    const { settings } = useConfig();
    const [isFinancingEnabled] = useFeature<boolean>(FeatureFlags.PlatformFinancingPaymentFlow, false);

    const isOriginalPaymentIntentSaved = useRef<boolean>(false);
    const [originalPaymentIntent, setOriginalPaymentIntent] = useState<ExtendedPaymentIntent>();
    const { data: basicPaymentIntent, update: updatePaymentIntent } = paymentIntentModel;
    const paymentIntent = useExtendedPaymentIntent(basicPaymentIntent);
    const { isLoadingShouldCollectLegalInfoOnPaymentFlow, shouldCollectLegalInfoOnPaymentFlow } =
      useShouldCollectLegalInfoOnPaymentFlow({
        billIds: paymentIntent && [paymentIntent.billInfo.id],
      });
    const isFx = paymentIntent?.deliveryMethod && isFxDM(paymentIntent?.deliveryMethod);
    const isFxPaymentIntent = !!paymentIntent && _isFxPaymentIntent(paymentIntent);
    const isFinancingPaymentFlow = Boolean(isFinancingEnabled && paymentIntent?.financingEligibilityToken);
    const [selectedRepaymentOption, setSelectedRepaymentOption] = useState<SelectedRepaymentOption>();
    const hasInvoice = !!paymentIntent?.fileId;
    const { currentStep, getConditionalStepsCallback, goToStep, goToPreviousStep, calculateStepNumber } =
      useSinglePaymentFlowActivityStep({
        onFirstStepBack,
        actionToPerform,
        deliveryMethodIdFromState,
        selectedDeliveryMethod: paymentIntent?.deliveryMethod,
        isFinancingSelected: isFinancingPaymentFlow,
        vendorId: paymentIntent?.vendorId,
        hasInvoice,
      });

    const [isLoadingReviewAndConfirmButton, setIsLoadingReviewAndConfirmButton] = useState<boolean>(false);

    const [confirmedPayment, setConfirmedPayment] = useState<Payment>();
    const { activeAccountingPlatform } = useAccountingPlatforms();
    const { isByDeliveryDate } = usePaymentSchedulingPreference();
    const { isLoading: isUnmaskAccountNumberLoading, unmaskedAccountNumber } = useVendorUnMaskedAccountNumber({
      vendorId: paymentIntent?.vendorId,
    });

    const fastDeliveryPreference =
      paymentIntent?.deliveryPreferenceOptions && getFastDeliveryPreference(paymentIntent.deliveryPreferenceOptions);

    const defaultPaymentPurposeValues = useMemo(
      () => getDefaultPaymentPurposeFormFields(paymentIntent?.paymentPurpose),
      [paymentIntent?.paymentPurpose]
    );

    const { data: deliveryMethods, confirm: confirmDeliveryMethod } = useDeliveryMethods({
      vendorId: paymentIntent?.vendorId,
    });

    const goToDeliveryDateSelectionStepOrSkip = () => {
      if (isFxPaymentIntent) {
        moveOrSkipVendorToMemoStep();
        return;
      }
      goToStep('DELIVERY_DATE_SELECTION');
    };

    const setPaymentTraits = () => {
      const traits: Traits = { first_time_pay: true };

      setTraits(traits);
    };

    const confirmRequiredDeliveryMethods = () => {
      if (deliveryMethods) {
        const dmRequireConfirmation = deliveryMethods.filter((dm) => dm.requireConfirmationForPayment);
        Promise.all(dmRequireConfirmation.map((dm) => confirmDeliveryMethod(dm.id)));
      }
    };

    const fsType =
      paymentIntent?.fundingSource?.type === 'card'
        ? paymentIntent.fundingSource.details.type
        : paymentIntent?.fundingSource?.type;

    const analyticsData = {
      PaymentIntentId: paymentIntentId,
      DeliveryMethodId: paymentIntent?.deliveryMethodId,
      FundingSourceId: paymentIntent?.fundingSourceId,
      PaymentMethodId: paymentIntent?.fundingSourceId,
      FundingSourceType: fsType,
      PaymentMethodType: fsType,
      DeliveryMethod: paymentIntent?.deliveryMethod?.type,
      DueDate: paymentIntent?.billInfo.dueDate,
      VendorEmail: paymentIntent?.billInfo.vendor.contact.email,
      MoneyFlow: 'ap',
      AccountingSoftwareType: activeAccountingPlatform?.accountingSlug || null,
      FastDeliveryExposure: fastDeliveryPreference?.type || null,
      FundingSource: fsType,
      IsAccountingSoftwareSync: !!activeAccountingPlatform,
      PaymentFrequency: 'One Time',
      RecurringPaymentEndBy: null,
      RecurringPaymentEndValue: null,
      Flow: 'single-payment',
      VendorId: paymentIntent?.vendorId,
      EBillerStatus: paymentIntent?.billInfo.vendor.eBillStatus,
    };

    useAnalyticsContext({
      globalProperties: {
        ...analyticsData,
        ...getAdditionalAnalytics(actionToPerform, currentStep, analyticsProperties),
      },
    });

    useNonPositiveAmountsEffect(onFirstStepBack, [paymentIntent?.amountToPay]);
    useEffect(() => {
      if (paymentIntent && !isOriginalPaymentIntentSaved.current) {
        setOriginalPaymentIntent(paymentIntent);
        isOriginalPaymentIntentSaved.current = true;
      }
    }, [paymentIntent]);

    // pre-fetch queries
    usePreFetch(paymentIntent);

    const debouncedIsLoading = useDebounce(isLoading, 10); // stay on loading state until the next step is set
    const isFetchingData =
      debouncedIsLoading ||
      isUnmaskAccountNumberLoading ||
      isLoadingShouldCollectLegalInfoOnPaymentFlow ||
      !paymentIntent ||
      !originalPaymentIntent ||
      !paymentIntentId;
    useReadyEventWhenLoaded(isFetchingData);

    const isActionIsSchedule = actionToPerform === 'schedule';
    const isEditAmountDisabledForPaymentIntent = (basicPaymentIntent?.disabledActions || []).some(
      ({ name }) => name === 'edit-amount'
    );
    const isEditPaymentFlow = actionToPerform === 'edit';
    const isFinancingDisabledForPaymentIntent = (basicPaymentIntent?.disabledActions || []).some(
      ({ name }) => name === 'financing'
    );

    const shouldAllowFinancingOption = isActionIsSchedule && isFinancingEnabled && !isFinancingDisabledForPaymentIntent;
    const shouldAllowEditAmount = isActionIsSchedule && !isFxPaymentIntent && !isEditAmountDisabledForPaymentIntent;

    if (isFetchingData) {
      return <NewSinglePaymentStepLayout isLoading />;
    }

    const expectedBalance =
      paymentIntent.billPayments?.[0]?.balance &&
      paymentIntent.amountToPay &&
      (actionToPerform === 'edit'
        ? paymentIntent.billPayments?.[0]?.balance
        : Big(paymentIntent.billPayments?.[0]?.balance).minus(paymentIntent.amountToPay).toNumber());

    const handleFail = (error: PlatformError) => {
      const errorsToIgnore = {
        unsupportedCardNetworkForInternational:
          'Card funding source network is not supported for international payments',
      };
      // do not show toast for ignored errors
      if (!Object.values(errorsToIgnore).includes(error.message)) {
        toast({ type: 'error', title: error.message });
      }
      onError?.(error);
    };

    const handleSetFundingSource: FundingSourceSelectionOnDoneFn = ({
      fundingSourceId,
      paymentAmount,
      financingEligibilityToken,
      options,
    }) => {
      const shouldUpdateAmount = paymentAmount !== paymentIntent.amountToPay;
      const updatedBillPayments = paymentIntent.billPayments?.map((bp) => ({
        ...bp,
        amount: paymentAmount,
      }));

      updatePaymentIntent({
        fundingSourceId,
        financingEligibilityToken,
        ...(shouldUpdateAmount && { amountToPay: paymentAmount }),
        ...(shouldUpdateAmount && { billPayments: updatedBillPayments }),
        ...(options?.shouldCleanFinancing && {
          financingEligibilityToken: null,
          financingNumberOfInstallments: null,
          financingProvider: null,
        }),
      })
        .then(({ deliveryMethod, financingEligibilityToken }) => {
          const isFinancingSelected = Boolean(isFinancingEnabled && financingEligibilityToken);
          const { hasDeliveryMethodStep, hasPaymentPurposeStep, hasRepaymentTermsStep } = getConditionalStepsCallback({
            deliveryMethod,
            isFinancingSelected,
          });
          if (hasDeliveryMethodStep) {
            goToStep('DELIVERY_METHOD_SELECTION');
          } else if (hasRepaymentTermsStep) {
            goToStep('REPAYMENT_TERMS');
          } else if (hasPaymentPurposeStep) {
            goToStep('PAYMENT_PURPOSE');
          } else {
            goToDeliveryDateSelectionStepOrSkip();
          }
        })
        .catch(handleFail);
    };

    const moveOrSkipVendorToMemoStep = (deliveryMethod?: DeliveryMethod | null) => {
      const { hasMemoToVendorStep } = getConditionalStepsCallback({
        deliveryMethod,
      });
      goToStep(hasMemoToVendorStep ? 'MEMO_TO_VENDOR' : 'REVIEW_AND_CONFIRM');
    };
    const handleSetRepaymentOption = (
      financingEligibilityToken: string,
      financingNumberOfInstallments: number,
      financingProvider: FinancingProvider,
      fundingSourceId: string,
      scheduledDate: Date
    ) => {
      updatePaymentIntent({
        financingEligibilityToken,
        financingNumberOfInstallments,
        financingProvider,
        fundingSourceId,
        scheduledDate,
      })
        .then(({ deliveryMethod }) => {
          moveOrSkipVendorToMemoStep(deliveryMethod);
        })
        .catch(handleFail);
    };

    const handleSetDeliveryMethodId = (deliveryMethod: DeliveryMethod) => {
      updatePaymentIntent({ deliveryMethodId: deliveryMethod.id })
        .then(({ deliveryMethod, financingEligibilityToken }) => {
          const isFinancingSelected = Boolean(isFinancingEnabled && financingEligibilityToken);
          const { hasPaymentPurposeStep, hasRepaymentTermsStep } = getConditionalStepsCallback({
            deliveryMethod,
            isFinancingSelected,
          });

          if (hasRepaymentTermsStep) {
            goToStep('REPAYMENT_TERMS');
          } else if (hasPaymentPurposeStep) {
            goToStep('PAYMENT_PURPOSE');
          } else {
            goToDeliveryDateSelectionStepOrSkip();
          }
        })
        .catch(handleFail);
    };

    const handleSetPaymentPurpose = (data: PaymentPurposeUpdateData) => {
      updatePaymentIntent(data)
        .then(() => {
          goToDeliveryDateSelectionStepOrSkip();
        })
        .catch(handleFail);
    };

    const handleSetSchedulingDate = (
      selectedDeliveryPreferenceType: DeliveryPreference['type'],
      schedulingDate: Date
    ) => {
      const paymentIntentUpdateData = {
        selectedDeliveryPreferenceType,
        ...(isByDeliveryDate ? { deliveryDate: schedulingDate } : { scheduledDate: schedulingDate }),
      };
      return updatePaymentIntent(paymentIntentUpdateData)
        .then(({ deliveryMethod }) => {
          moveOrSkipVendorToMemoStep(deliveryMethod);
        })
        .catch(handleFail);
    };

    const handleSetMemoToVendor = (noteToVendor?: string) => {
      updatePaymentIntent({ noteToVendor })
        .then(() => goToStep('REVIEW_AND_CONFIRM'))
        .catch(handleFail);
    };

    const handleConfirmSinglePayment = (fxQuoteToken?: string) => {
      setIsLoadingReviewAndConfirmButton(true);
      onConfirm(fxQuoteToken)
        .then((payment) => {
          setConfirmedPayment(payment);
          track('PaymentCreatedSuccssfully', 'Submitted', {
            PaymentId: payment.id,
            DeductionDate: payment.scheduledDate,
            PaymentMethodId: payment.fundingSourceId,
            DeliveryMethodId: payment.deliveryMethodId,
            VendorId: payment.vendorId,
            Amount: getDollarsFromCents(payment.foreignAmount ?? payment.amount, payment.currency),
            Currency: payment.currency,
            UsdAmount: getDollarsFromCents(payment.amount),
          });
          setPaymentTraits();
          if (settings.achDeliveryMethodPromotion) {
            confirmRequiredDeliveryMethods();
          }
          goToStep('PAYMENT_SCHEDULED');
        })
        .catch(handleFail)
        .finally(() => setIsLoadingReviewAndConfirmButton(false));
    };

    const getRecurringPaymentDetails = () => {
      const occurrence = paymentIntent?.payments?.[0]?.subscriptionOccurrence;
      if (occurrence?.billSubscription) {
        return {
          paymentFrequency: occurrence.billSubscription.intervalType,
          numOfOccurrences: occurrence.billSubscription.numOfOccurrences,
          occurrenceNumber: occurrence.occurrenceNumber,
          managedBy: occurrence.billSubscription.managedBy,
        };
      }

      return;
    };

    const isPendingApproval = confirmedPayment?.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Pending;
    const getInternationalDmDetails = () => {
      if (paymentIntent.deliveryMethod?.type === 'international-account') {
        return {
          currency: paymentIntent.deliveryMethod?.details.currency,
          internationalCountryCode: paymentIntent.deliveryMethod?.details.identifierDetails.bankCountryCode,
        };
      }
      return { currency: undefined, internationalCountryCode: undefined };
    };

    const totalSteps = calculateStepNumber('REVIEW_AND_CONFIRM');

    const memoValidation = isFx
      ? {
          memoRegex: fxMemoRegex,
          memoErrorMsg: formatMessage('widgets.memoToVendorForm.fields.memo.validation.fx.pattern'),
        }
      : void 0;

    switch (currentStep) {
      case 'FUNDING_SOURCE_SELECTION':
      default:
        return (
          <FundingSourceSelectionActivity
            step={calculateStepNumber('FUNDING_SOURCE_SELECTION')}
            totalSteps={totalSteps}
            vendorId={paymentIntent.vendorId}
            selectedId={paymentIntent.fundingSourceId || void 0}
            paymentAmount={paymentIntent.amountToPay || 0}
            recurringPaymentDetails={getRecurringPaymentDetails()}
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={onError}
            onDone={handleSetFundingSource}
            billId={paymentIntent.billPayments?.[0]?.billId}
            shouldAllowEditAmount={shouldAllowEditAmount}
            shouldAllowFinancingOption={shouldAllowFinancingOption}
            fundingSourceTypesOptions={paymentIntent.fundingSourceTypesOptions}
          />
        );
      case 'REPAYMENT_TERMS':
        return (
          <RepaymentTermsActivity
            step={calculateStepNumber('REPAYMENT_TERMS')}
            totalSteps={totalSteps}
            paymentIntentId={paymentIntentId}
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={onError}
            onDone={handleSetRepaymentOption}
            setSelectedRepaymentOption={setSelectedRepaymentOption}
            selectedRepaymentOption={selectedRepaymentOption}
          />
        );
      case 'DELIVERY_METHOD_SELECTION':
        return (
          <DeliveryMethodSelectionActivity
            step={calculateStepNumber('DELIVERY_METHOD_SELECTION')}
            totalSteps={totalSteps}
            vendorId={paymentIntent.vendorId}
            billCurrency={paymentIntent.billInfo.currency}
            selectedId={paymentIntent.deliveryMethodId || void 0}
            fundingSourceId={paymentIntent.fundingSourceId || void 0}
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={onError}
            onDone={handleSetDeliveryMethodId}
            deliveryMethodTypeOptions={paymentIntent.deliveryMethodTypeOptions}
            fileId={paymentIntent.billInfo.invoice.fileId || paymentIntent.fileId}
          />
        );
      case 'PAYMENT_PURPOSE': {
        const { currency, internationalCountryCode } = getInternationalDmDetails();
        return (
          <InternationalPaymentPurposeActivity
            step={calculateStepNumber('PAYMENT_PURPOSE')}
            totalSteps={totalSteps}
            onClose={onClose}
            onBack={goToPreviousStep}
            onDone={handleSetPaymentPurpose}
            defaultValues={defaultPaymentPurposeValues}
            vendorId={paymentIntent.vendorId}
            hasInvoice={!!paymentIntent.fileId}
            currency={currency}
            internationalCountryCode={internationalCountryCode}
            variation="screen"
          />
        );
      }

      case 'DELIVERY_DATE_SELECTION': {
        return (
          <DeliveryDateActivity
            step={calculateStepNumber('DELIVERY_DATE_SELECTION')}
            totalSteps={totalSteps}
            paymentIntentId={paymentIntentId}
            onBack={goToPreviousStep}
            onClose={onClose}
            // XXX sending `handleFail` to the sub activity since its and exception
            // where it handles updating the payment intent
            onError={handleFail}
            onDone={handleSetSchedulingDate}
          />
        );
      }

      case 'MEMO_TO_VENDOR':
        return (
          <MemoToVendorActivity
            step={calculateStepNumber('MEMO_TO_VENDOR')}
            totalSteps={totalSteps}
            memo={paymentIntent.noteToVendor || void 0}
            defaultMemo={getDefaultMemo(paymentIntent, unmaskedAccountNumber)}
            vendorId={paymentIntent.vendorId}
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={onError}
            onDone={handleSetMemoToVendor}
            memoValidation={memoValidation}
          />
        );

      case 'COMPLETE_LEGAL_INFO':
        return (
          <CompleteLegalInfoActivity
            onBack={goToPreviousStep}
            onClose={onClose}
            onError={onError}
            onDone={handleConfirmSinglePayment}
            isLoading={isLoadingReviewAndConfirmButton}
          />
        );

      case 'REVIEW_AND_CONFIRM':
        return (
          <ReviewAndConfirmActivity
            step={calculateStepNumber('REVIEW_AND_CONFIRM')}
            totalSteps={totalSteps}
            isEditPaymentFlow={actionToPerform === 'edit'}
            paymentIntentId={paymentIntentId}
            onEditAmount={isEditAmountDisabledForPaymentIntent ? undefined : () => goToStep('FUNDING_SOURCE_SELECTION')}
            onEditFundingSource={() => goToStep('FUNDING_SOURCE_SELECTION')}
            onEditRepaymentTerm={() => goToStep('REPAYMENT_TERMS')}
            onEditDate={isFxPaymentIntent ? undefined : () => goToStep('DELIVERY_DATE_SELECTION')}
            onEditDeliveryMethod={() => goToStep('DELIVERY_METHOD_SELECTION')}
            onEditMemoToVendor={() => goToStep('MEMO_TO_VENDOR')}
            onBack={goToPreviousStep}
            onClose={onClose}
            onDone={(fxQuoteToken?: string) => {
              if (
                paymentIntent?.deliveryMethod?.type !== 'international-account' &&
                shouldCollectLegalInfoOnPaymentFlow
              ) {
                goToStep('COMPLETE_LEGAL_INFO');
              } else {
                handleConfirmSinglePayment(fxQuoteToken);
              }
            }}
            onError={handleFail}
            isLoadingButton={isLoadingReviewAndConfirmButton}
            enableLateTag={false}
            remainingAmount={expectedBalance || void 0}
            isFinancingPaymentFlow={isFinancingPaymentFlow}
            selectedRepaymentOption={selectedRepaymentOption}
            hideFeesSection={isFinancingPaymentFlow}
          />
        );

      case 'PAYMENT_SCHEDULED':
        return (
          <PaymentScheduledActivity
            paymentsIds={[(confirmedPayment as Payment).id]}
            onClose={onClose}
            onError={onError}
            onDone={(action: PaymentFlowDoneAction) =>
              confirmedPayment && onDone(confirmedPayment.id, action, isPendingApproval)
            }
            externalOrigin={externalOrigin}
            isEditPaymentFlow={isEditPaymentFlow}
            // TODO: https://meliorisk.atlassian.net/browse/ME-57383
            // updatedFields={
            //   isEditPaymentFlow ? getDifferenceKeysByValue(originalPaymentIntent, paymentIntent) : undefined
            // }
          />
        );
    }
  }
);
