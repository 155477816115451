import {
  addWildcardToRoutes,
  GuestPayorUserTypes,
  useFlowRouting,
  useGuestPayorPaymentRequestDetails,
  useGuestPayorUserDetails,
  useMelioIntl,
  useSystemMessage,
} from '@melio/ar-domain';
import { SectionBannerProps as _SectionBannerProps } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { PartnerName } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AddCardFundingSourceActivity } from '../activities';
import { useGetPaymentErrorNotification } from '../hooks';
import { GuestPaymentLayout } from '../layout';
import { BankPaymentFulfillmentFlow } from './BankPaymentFulfillment.flow';
import { PaymentsLayoutWrapper } from './PaymentsLayoutWrapper';

type SectionBannerProps = _SectionBannerProps & { type: 'error' | 'invoice-updated' };

export type PaymentFulfillmentFlowProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
  partnerName: PartnerName;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  isNavigateApUsersToDashboardEnabled?: boolean;
  onDone: (paymentId: string) => unknown;
};

export const PaymentFulfillmentFlow = withAnalyticsContext<PaymentFulfillmentFlowProps>(
  forwardRef(
    ({
      onLoggedIn,
      partnerName,
      paymentRequestLink,
      onViewInvoice,
      isNavigateApUsersToDashboardEnabled,
      onDone,
      setAnalyticsProperties,
    }) => {
      const { formatMessage, formatDate } = useMelioIntl();
      const { track } = useAnalytics();

      const { Paths, goToBank, goToCard } = useGuestPaymentRouterFlow();

      const [isPaymentProcessing, setIsPaymentProcessing] = useState<boolean>(false);
      const [isCreatePaymentLoading, setIsCreatePaymentLoading] = useState<boolean>(false);

      const [notificationInfo, setNotificationInfo] = useState<SectionBannerProps>();
      const getPaymentErrorNotification = useGetPaymentErrorNotification();

      const { data, isLoading } = useGuestPayorPaymentRequestDetails({ paymentRequestLink });
      const { data: userDetails, isLoading: isUserDetailsLoading } = useGuestPayorUserDetails({
        id: paymentRequestLink,
      });

      setAnalyticsProperties({
        ProductName: 'ar',
        Flow: 'payment-request',
        PaymentRequestLink: paymentRequestLink,
        AlertShown: notificationInfo?.type,
      });

      const config = useConfig();
      const generateDeepLinkToPaymentRequestPayDashboard =
        config.settings.guestPaymentFlow?.generateDeepLinkToPaymentRequestPayDashboard;
      const redirectToPayDashboardUrl =
        generateDeepLinkToPaymentRequestPayDashboard && data
          ? generateDeepLinkToPaymentRequestPayDashboard(data.paymentRequestId, {
              paymentRequestDetails: { ref: paymentRequestLink },
              vendorDetails: { name: data.payeeDetails.companyName },
            })
          : undefined;

      useEffect(() => {
        if (data?.invoice.editedAt) {
          setNotificationInfo({
            title: formatMessage('ar.guestPayment.notifications.updatedAt.title.text'),
            description: formatMessage('ar.guestPayment.notifications.updatedAt.description.text', {
              updatedAtDate: formatDate(data.invoice.editedAt),
              companyName: data.payeeDetails.companyName,
            }),
            showCloseIcon: true,
            type: 'invoice-updated',
          });
        }
      }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

      const { triggerMessage } = useSystemMessage();

      if (isLoading || isUserDetailsLoading) {
        return <GuestPaymentLayout isLoading />;
      }

      const trackPaymentError = () => {
        track('PaymentRequest', 'Status', {
          ErrorType: 'payment-failed',
        });
      };

      const showErrorNotification = ({ title, description }: { title: string; description: string }) => {
        triggerMessage({ type: 'critical', title: description });
        setNotificationInfo({ title, description, variant: 'critical', type: 'error' });
      };

      const handleCardPaymentError = () => {
        trackPaymentError();
        showErrorNotification(getPaymentErrorNotification('card'));
      };

      const handlePaymentFormLoading = (isLoading: boolean) => {
        setIsCreatePaymentLoading(isLoading);
      };

      const shouldRedirectToPayDashboard = () =>
        redirectToPayDashboardUrl &&
        isNavigateApUsersToDashboardEnabled &&
        userDetails?.userType === GuestPayorUserTypes.REGISTERED_AND_MATCHED;

      if (shouldRedirectToPayDashboard()) {
        window.location.href = redirectToPayDashboardUrl as string;
        return null;
      }

      return (
        <Routes>
          <Route
            path={Paths.Bank}
            element={
              <BankPaymentFulfillmentFlow
                partnerName={partnerName}
                paymentRequestLink={paymentRequestLink}
                isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
                onDone={onDone}
                onLoggedIn={onLoggedIn}
                onViewInvoice={onViewInvoice}
                onSelectFundingSource={(type) => type == 'card' && goToCard()}
              />
            }
          />
          <Route
            path="*"
            element={
              <PaymentsLayoutWrapper
                notificationInfo={notificationInfo}
                goToBank={goToBank}
                goToCard={goToCard}
                onViewInvoice={onViewInvoice}
                isPaymentFormLoading={isCreatePaymentLoading}
                paymentRequestLink={paymentRequestLink}
                isPaymentProcessing={isPaymentProcessing}
              />
            }
          >
            <Route
              path={Paths.Card}
              element={
                <AddCardFundingSourceActivity
                  onError={handleCardPaymentError}
                  onLoggedIn={onLoggedIn}
                  partnerName={partnerName}
                  paymentRequestLink={paymentRequestLink}
                  onFormLoading={handlePaymentFormLoading}
                  onCreatePayment={onDone}
                  setIsPaymentProcessing={setIsPaymentProcessing}
                  redirectToPayDashboardUrl={redirectToPayDashboardUrl}
                  isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
                />
              }
            />
          </Route>
        </Routes>
      );
    }
  )
);
PaymentFulfillmentFlow.displayName = 'PaymentFulfillmentFlow';

const useGuestPaymentRouterFlow = () => {
  enum Paths {
    Bank = 'bank',
    Card = 'card',
  }
  const { createCallback } = useFlowRouting<Paths>();

  return {
    Paths: addWildcardToRoutes(Paths),
    goToBank: createCallback(Paths.Bank, { replace: true }),
    goToCard: createCallback(Paths.Card, { replace: true }),
  };
};
