import { Box } from '@chakra-ui/react';
import { isPayorPaymentFee } from '@melio/ap-domain';
import { BillDetailsWidgetAccessibleNew, Carousel } from '@melio/ap-widgets';
import { Group, Loader, Text } from '@melio/penny';
import {
  Bill,
  BillsApiGetBillsRequest,
  PaymentFullyExpanded,
  PaymentStatusEnum,
  useAccountingPlatforms,
  useBills,
  usePaymentApprovalDecisions,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { SystemMessageDisplay } from '@melio/platform-utils';
import { noop } from 'lodash';
import { RefObject, useEffect } from 'react';

import { useMonitorTiming } from '../../../utils';
import { MicroDepositModalRefHandlers } from '../../funding-sources';
import { usePaymentDrawerData, useShowBillDetailsInPaymentDrawer } from '../paymentDrawer.utils';
import { PaymentDetails } from './payment-details/PaymentDetails';
import { PaymentFees } from './payment-fees/PaymentFees';

export const Body = ({
  paymentId,
  billDetailsRef,
  microDepositModalRef,
}: {
  paymentId: string;
  billDetailsRef: RefObject<HTMLDivElement>;
  microDepositModalRef: RefObject<MicroDepositModalRefHandlers>;
}) => {
  const [isNPEPaymentFeesEnabled] = useFeature(FeatureFlags.NPEPaymentFees, false);
  const { formatMessage } = useMelioIntl();

  const { data: payment, refetch: refetchPayment, isFetching: isPaymentLoading } = usePaymentDrawerData(paymentId);
  const { fundingSource, markedAsPaid, bills: billsFromPayment } = payment ?? {};
  const { showBillDetailsInPaymentDrawer } = useShowBillDetailsInPaymentDrawer(payment?.createMethod);

  useEffect(() => {
    if (fundingSource && payment && payment?.fundingSource?.isVerified !== fundingSource?.isVerified) {
      void refetchPayment();
    }
  }, [refetchPayment, payment, fundingSource]);

  const billsFromPaymentIds = billsFromPayment?.map((bill) => bill.id) || [];
  const billsSearchParams: BillsApiGetBillsRequest = markedAsPaid
    ? {
        search: {
          'bill.id': billsFromPaymentIds,
          'bill.status': {
            $in: ['unpaid', 'paid', 'partially_paid', 'scheduled', 'cancelled'],
          },
        },
        expand: ['vendor', 'payments', 'file'],
      }
    : {
        search: {
          'payment.id': payment?.id,
          'bill.status': {
            $in: ['unpaid', 'paid', 'partially_paid', 'scheduled', 'cancelled'],
          },
        },
        expand: ['vendor', 'payments', 'file'],
      };

  const {
    isMutating: isBillUpdating,
    data: bills,
    isFetching: isFetchingBills,
    isLoading: isLoadingBills,
  } = useBills({
    enabled: markedAsPaid ? !!billsFromPaymentIds : !!payment?.id,
    params: billsSearchParams,
  });
  const { activeAccountingPlatform, isFetching: isAccountingPlatformLoading } = useAccountingPlatforms();
  const { data: approvalDecisions, isLoading: isApprovalDecisionsLoading } = usePaymentApprovalDecisions({
    paymentId,
    refetchOnMount: 'always',
  });

  const isLoading = isPaymentLoading || isBillUpdating || isApprovalDecisionsLoading;

  const isLoadingBillsSection = isLoadingBills || isFetchingBills || isAccountingPlatformLoading;

  const legacyRouteReady = useMonitorTiming('payment_drawer_ready');
  const { routeReady } = useMonitoring();

  const triggerMonitoring = (el: HTMLDivElement) => {
    legacyRouteReady(el);
    routeReady(el);
  };

  if (isLoading) {
    return <Loader aria-live="polite" />;
  }
  if (!payment || (!!payment?.fundingSource && !fundingSource)) {
    return (
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        data-testid="payment-details-empty-state"
      >
        <Text textStyle="body4" color="global.neutral.800">
          {formatMessage('activities.payDashboard.drawer.body.payment.empty.label')}
        </Text>
      </Box>
    );
  }

  const mapBills = (bill: Bill, _index: number, bills: Bill[]) => (
    <BillDetailsWidgetAccessibleNew
      ref={billDetailsRef}
      key={bill.id}
      bill={bill}
      hasBorder={bills.length > 1}
      showOpenBalance
      activeAccountingPlatform={activeAccountingPlatform}
      onSubmit={noop}
      onSubmissionStateChange={noop}
      onInvoiceChange={noop}
      paymentVendor={payment?.vendor?.history?.deletedAt ? payment?.vendor : undefined}
      hideRemainingAmountSection={
        payment?.status === PaymentStatusEnum.Canceled || payment?.status === PaymentStatusEnum.Failed
      }
    />
  );

  const elements = bills?.map(mapBills);

  const renderBillsSection = () => {
    if (isLoadingBillsSection) {
      return <Loader />;
    }
    if (!elements || elements.length === 0) {
      return null;
    }
    if (elements.length > 1) {
      const prevButtonTooltipLabel = formatMessage(
        'activities.payDashboard.drawer.body.payment.billDetails.carousel.prev.tooltip'
      );
      const nextButtonTooltipLabel = formatMessage(
        'activities.payDashboard.drawer.body.payment.billDetails.carousel.next.tooltip'
      );
      return (
        <Group variant="vertical" spacing="m">
          <Text textStyle="body2Semi">
            {formatMessage('activities.payDashboard.drawer.body.payment.billDetails.label', {
              amount: elements.length,
            })}
          </Text>

          <Carousel
            elements={elements}
            initialElementIndex={0}
            ref={billDetailsRef}
            tooltipLeftButtonLabel={prevButtonTooltipLabel}
            tooltipRightButtonLabel={nextButtonTooltipLabel}
            arrowSpacing="m"
          />
        </Group>
      );
    }
    return elements[0];
  };

  const payorFees = payment.fees?.filter(isPayorPaymentFee) ?? [];

  return (
    <Group hasDivider ref={triggerMonitoring} data-testid="pay-dashboard-payment" variant="vertical" spacing="l">
      <Group variant="vertical" spacing="l">
        <SystemMessageDisplay data-testid="payment-drawer-notification" />
        <PaymentDetails
          payment={payment as PaymentFullyExpanded}
          approvalDecisions={approvalDecisions}
          billDetailsRef={billDetailsRef}
          microDepositModalRef={microDepositModalRef}
        />
      </Group>

      {isNPEPaymentFeesEnabled && !payment.isFinanced && payorFees.length > 0 ? <PaymentFees fees={payorFees} /> : null}

      {showBillDetailsInPaymentDrawer ? renderBillsSection() : null}
    </Group>
  );
};
