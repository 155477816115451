import { withAnalyticsContext } from '@melio/platform-analytics';
import { getAvailableToConnectAccountingPlatforms, useAccountingPlatforms } from '@melio/platform-api';
import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AddBillV2Activity } from '../AddBillV2/AddBillV2.activity';
import { AddOcrBillActivity } from '../AddOcrBill';
import { AddBillScreen } from './screens';
import { AddBillActivityProps } from './types';
import { useAddBillActivityStep } from './useAddBillActivityStep';

export const AddBillActivity = withAnalyticsContext<AddBillActivityProps>(
  ({
    setAnalyticsProperties,
    onConnectAccountingPlatform,
    onBack: onFirstStepBack,
    onClose,
    onDone,
    onError,
    isAccountingPlatformConnecting,
    onUploadMultipleInvoicesSuccess,
    onUploadMultipleInvoicesNavigate,
    files: filesList = [],
  }) => {
    setAnalyticsProperties({
      Flow: 'bill',
    });

    const { currentStep, goToStep, goToPreviousStep } = useAddBillActivityStep({
      onFirstStepBack,
    });
    const { data, hasAccountingPlatform, isFetching } = useAccountingPlatforms();

    const [files, setFiles] = useState<File[]>([]);
    const onAddManualBill = () => {
      goToStep('ADD_BILL_MANUALLY');
    };

    const accountingPlatforms = getAvailableToConnectAccountingPlatforms(data);

    const getIndexRoute = () => {
      switch (currentStep) {
        case 'ADD_BILL_METHOD_SELECTION':
        default: {
          return (
            <AddBillScreen
              isLoading={isFetching}
              isAccountingPlatformConnecting={isAccountingPlatformConnecting}
              onAddManualBill={onAddManualBill}
              onUploadABill={(selectedFiles: File[]) => {
                setFiles(selectedFiles);
                goToStep('UPLOAD_A_BILL');
              }}
              includeConnectAccountingPlatformOption={!hasAccountingPlatform}
              availablePlatformsToConnect={accountingPlatforms}
              onConnectAccountingPlatform={onConnectAccountingPlatform}
              onBack={goToPreviousStep}
              onClose={onClose}
            />
          );
        }
        case 'ADD_BILL_MANUALLY': {
          return <AddBillV2Activity onDone={onDone} onClose={onClose} onBack={goToPreviousStep} />;
        }
        case 'UPLOAD_A_BILL': {
          return (
            <AddOcrBillActivity
              filesList={files}
              onBack={goToPreviousStep}
              onClose={onClose}
              onDone={onDone}
              onError={onError}
              onUploadMultipleInvoicesSuccess={onUploadMultipleInvoicesSuccess}
              onUploadMultipleInvoicesNavigate={onUploadMultipleInvoicesNavigate}
            />
          );
        }
      }
    };

    return (
      <Routes>
        <Route index path="/" element={getIndexRoute()} />
        <Route
          path="manual"
          element={<AddBillV2Activity onDone={onDone} onClose={onClose} onBack={onFirstStepBack} />}
        />
        <Route
          path="upload"
          element={
            <AddOcrBillActivity
              filesList={filesList}
              onBack={onFirstStepBack}
              onClose={onClose}
              onDone={onDone}
              onError={onError}
              onUploadMultipleInvoicesSuccess={onUploadMultipleInvoicesSuccess}
              onUploadMultipleInvoicesNavigate={onUploadMultipleInvoicesNavigate}
            />
          }
        />
      </Routes>
    );
  }
);

AddBillActivity.displayName = 'AddBillActivity';
