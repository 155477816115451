import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';

export const useIsSubscriptionsEnabled = () => {
  const [isPlatformSubscriptionsEnabled] = useFeature(FeatureFlags.PlatformSubscriptions, false);
  const {
    settings: { subscriptions },
  } = useConfig();

  return isPlatformSubscriptionsEnabled && !!subscriptions;
};
