import { useEffect, useMemo, useState } from 'react';
import {
  AddressSearchWidget,
  AddressSearchWidgetProps,
  useStateSelect,
  useUnmanagedVendorFormExtendedSchema,
  useVendorDirectoryInfoComplete,
  useVendorForm,
  ZipCodeField,
} from '@melio/ap-widgets';
import {
  Container,
  Form,
  GridItem,
  Group,
  LoadingContainer,
  SimpleGrid,
  Text,
  useBreakpoint,
  useToast,
  VisuallyHidden,
} from '@melio/penny';
import { Address, US_STATES, useDeliveryMethod, useVendor, useVendorEditableFields } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum } from '@/types/vendors.types';
import { ConfirmVendorUpdateModal, useConfirmVendorUpdate } from '../../ConfirmVendorUpdateModal.widget';
import { VendorDetailsFormInnerProps } from '../types';
import { preventFormSubmissionOnEnter } from '../utils';
import { VendorDetailsExtendedUnmanagedFields } from './types';
import { getUpdateVendorPayload } from './utils';

const maskAccountNumber = (accountNumber?: string) =>
  accountNumber ? `****${accountNumber.slice(-4)}` : accountNumber;

export type VendorDetailsExtendedUnmanagedFormProps = VendorDetailsFormInnerProps<VendorDetailsExtendedUnmanagedFields>;

export const VendorDetailsExtendedUnmanagedForm = forwardRef<VendorDetailsExtendedUnmanagedFormProps, 'form'>(
  (
    {
      vendorId,
      defaultValues,
      isSaving,
      onSubmit,
      isEditable,
      onSubmissionStateChange,
      inlineApiErrorCodes,
      loadingContainerId,
      ...props
    },
    ref,
  ) => {
    const { closeToast } = useToast();
    const { formatMessage } = usePlatformIntl();
    const { isExtraSmallScreen } = useBreakpoint();
    const defaultEditableFields = [
      'name',
      'nickname',
      'accountNumber',
      'contactPhoneNumber',
      'emailAddress',
      'bankAccount',
      'address',
    ];

    const confirmVendorUpdate = useConfirmVendorUpdate({
      vendorId,
      initialFormData: defaultValues,
    });
    const [isPlatformMultipleVendorsWithTheSameNameEnabled] = usePartnerFeature(
      'PlatformMultipleVendorsWithTheSameName',
      false,
    );
    const [isLoadingUnmaskedValues, setIsLoadingUnmaskedValues] = useState(false);
    const [unmaskedValues, setUnmaskedValues] = useState<{
      vendorUnmaskedAccountNumber?: string;
      unmaskedBankAccountNumber?: string;
    }>();
    const { getVendorUnmaskedAccountNumber, data: vendor } = useVendor({ id: vendorId });
    const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(vendor);
    const { data: editableFieldsFromApi, isLoading: isLoadingEditableFields } = useVendorEditableFields({
      disabled: !isEditable || !isVendorDirectoryInfoCompleted,
      vendorId,
    });

    const editableFields = isVendorDirectoryInfoCompleted ? editableFieldsFromApi : defaultEditableFields;

    const bankAccount = useMemo(
      () => vendor?.deliveryMethods.find((method) => method.type === 'bank-account'),
      [vendor],
    );
    const isBankAccountDefaultValuesPresented = defaultValues?.bankAccountNumber && defaultValues.bankRoutingNumber;
    const nonRequiredFieldsVisibility = {
      nickname: isEditable || !!defaultValues?.nickname,
      email: isEditable || !!defaultValues?.email,
      bankAccount: isEditable || isBankAccountDefaultValuesPresented,
      line2: isEditable || !!defaultValues?.line2,
      uniqueName: !!defaultValues?.uniqueName && isPlatformMultipleVendorsWithTheSameNameEnabled,
    };
    const { getDeliveryMethodUnmaskedAccountNumber } = useDeliveryMethod({
      id: bankAccount?.id,
      enabled: false,
    });

    const handleSubmit = async () => {
      const data = getValues();
      await onSubmit(getUpdateVendorPayload(data, defaultValues));
      confirmVendorUpdate.onClose();
    };

    const {
      formProps,
      registerField,
      reset,
      setValue,
      trigger,
      formState: { isSubmitted },
      getValues,
      onChangeWithValidation,
    } = useVendorForm<VendorDetailsExtendedUnmanagedFields>({
      onSubmit: (formData) => {
        if (confirmVendorUpdate.shouldShowConfirm(formData)) {
          confirmVendorUpdate.onOpen();
        } else {
          handleSubmit();
        }
      },

      schema: useUnmanagedVendorFormExtendedSchema({
        showCompanyField: true,
        showUniqueNameField: !!defaultValues?.uniqueName && isPlatformMultipleVendorsWithTheSameNameEnabled,
        isEmailRequired: !!defaultValues?.email,
      }),
      isSaving,
      defaultValues,
      onSubmissionStateChange,
      inlineApiErrorCodes,
      ...(!isVendorDirectoryInfoCompleted ? { mode: 'onChange' } : {}),
    });

    useEffect(() => {
      if (!isVendorDirectoryInfoCompleted && isEditable && vendor) {
        trigger();
      }
    }, [isVendorDirectoryInfoCompleted, trigger, isEditable, vendor]);

    useEffect(() => {
      const unmaskValues = async () => {
        setIsLoadingUnmaskedValues(true);

        try {
          const [vendorUnmaskedAccountNumber, unmaskedBankAccountNumber] = await Promise.all([
            getVendorUnmaskedAccountNumber(),
            getDeliveryMethodUnmaskedAccountNumber(),
          ]);

          setUnmaskedValues({
            vendorUnmaskedAccountNumber: vendorUnmaskedAccountNumber.accountNumber,
            unmaskedBankAccountNumber: unmaskedBankAccountNumber?.accountNumber,
          });
        } finally {
          setIsLoadingUnmaskedValues(false);
        }
      };

      if (isVendorDirectoryInfoCompleted) {
        unmaskValues();
      }
    }, [
      getVendorUnmaskedAccountNumber,
      getDeliveryMethodUnmaskedAccountNumber,
      defaultValues?.accountNumber,
      defaultValues?.bankAccountNumber,
      isVendorDirectoryInfoCompleted,
    ]);

    useEffect(() => {
      const accountNumber = isEditable
        ? unmaskedValues?.vendorUnmaskedAccountNumber
        : maskAccountNumber(unmaskedValues?.vendorUnmaskedAccountNumber);
      const bankAccountNumber = isEditable
        ? unmaskedValues?.unmaskedBankAccountNumber
        : maskAccountNumber(unmaskedValues?.unmaskedBankAccountNumber);

      reset({
        ...defaultValues,
        accountNumber: accountNumber ?? defaultValues?.accountNumber,
        confirmAccountNumber: accountNumber ?? defaultValues?.accountNumber,
        bankAccountNumber: bankAccountNumber ?? defaultValues?.bankAccountNumber,
      });
    }, [defaultValues, isEditable, unmaskedValues, reset]);

    const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
      const address = event.target.value as unknown as Address;
      const isFormValueCleared = address === null;

      if (isFormValueCleared) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - if we pass '' search works properly only after 2nd search input
        setValue('line1', null);
      }

      const setFormFieldValue = (field: keyof Omit<Address, 'countryCode'>) => {
        if (address?.[field]) {
          setValue(field, address[field], {
            shouldValidate: true,
          });
        }
      };
      setFormFieldValue('line1');
      setFormFieldValue('line2');
      setFormFieldValue('city');
      setFormFieldValue('state');
      setFormFieldValue('postalCode');
    };

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      setIsLoading(isLoadingUnmaskedValues || isLoadingEditableFields); // because of screen reader
    }, [isLoadingEditableFields, isLoadingUnmaskedValues]);

    const stateOptions = useMemo(
      () =>
        US_STATES.map((state) => ({
          value: state,
          label: formatMessage(`local.USA.states.${state}`),
        })),
      [formatMessage],
    );
    const { filteredStateOptions, handleChange: handleStateSearch } = useStateSelect(stateOptions);

    return (
      <>
        <LoadingContainer aria-live="polite" isLoading={isLoading} id={loadingContainerId}>
          {!isLoading && (
            <Form
              data-component={DataComponentEnum.VENDOR_DETAILS_EXTENDED_UNMANAGED_FORM}
              {...props}
              {...formProps}
              ref={ref}
              isViewMode={!isEditable}
              columns={2}
              aria-live="off"
              {...preventFormSubmissionOnEnter()}
            >
              {/* @ts-ignore Form.ContentBox should be changed to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */}
              <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
                <SimpleGrid
                  columns={isExtraSmallScreen ? 1 : 2}
                  spacing="m"
                  aria-labeledby="widgets-vendorDetails-form-business-title"
                  role="group"
                >
                  <Group variant="vertical" spacing="xs">
                    {isEditable && (
                      <Text color="global.neutral.900" textStyle="body4">
                        {formatMessage('activities.addBillV2.billForm.requiredFields')}
                      </Text>
                    )}
                    <Text as="h3" textStyle="heading3Semi" id="widgets-vendorDetails-form-business-title">
                      {formatMessage('widgets.vendorDetails.form.business.title')}
                    </Text>
                  </Group>
                  <Form.TextField
                    colSpan={2}
                    {...registerField('companyName')}
                    labelProps={{ label: formatMessage('widgets.vendorDetails.form.companyName.label') }}
                    viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
                    autoFocus={isEditable}
                    isTruncated={!isEditable}
                    isDisabled={isEditable && !editableFields?.includes('name')}
                  />
                  {nonRequiredFieldsVisibility.nickname ? (
                    <Form.TextField
                      colSpan={2}
                      {...registerField('nickname')}
                      labelProps={{ label: formatMessage('widgets.vendorDetails.form.nickname.label') }}
                      viewModePlaceholder={formatMessage('widgets.vendorDetails.form.nickname.viewModePlaceholder')}
                      placeholder={formatMessage('widgets.vendorDetails.form.nickname.placeholder')}
                      isTruncated={!isEditable}
                      hidden={!isEditable && !defaultValues?.nickname}
                      isDisabled={isEditable && !editableFields?.includes('nickname')}
                    />
                  ) : null}
                  <Form.TextField
                    colSpan={isEditable ? 1 : 2}
                    {...registerField('accountNumber')}
                    data-private
                    labelProps={{
                      tooltipProps: isEditable
                        ? {
                            content: (
                              <>
                                <VisuallyHidden>
                                  {formatMessage('widgets.vendorDetails.form.accountNumber.labelTooltip')} tooltip
                                </VisuallyHidden>
                                {formatMessage('widgets.vendorDetails.form.accountNumber.labelTooltip')}
                              </>
                            ),
                            triggerAriaLabel: formatMessage(
                              'widgets.vendorDetails.form.accountNumber.ariaLabelTooltip',
                            ),
                          }
                        : undefined,
                      label: formatMessage('widgets.vendorDetails.form.accountNumber.label'),
                    }}
                    viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
                    isTruncated={!isEditable}
                    isDisabled={isEditable && !editableFields?.includes('accountNumber')}
                    helperTextProps={
                      isEditable
                        ? {
                            label: formatMessage('widgets.vendorDetails.form.accountNumber.helperText'),
                          }
                        : undefined
                    }
                  />
                  {isEditable && (
                    <Form.TextField
                      data-private
                      colSpan={1}
                      {...registerField('confirmAccountNumber')}
                      labelProps={{
                        label: formatMessage('widgets.vendorDetails.form.confirmAccountNumber.label'),
                      }}
                      isDisabled={isEditable && !editableFields?.includes('accountNumber')}
                    />
                  )}
                  <Form.PhoneField
                    colSpan={2}
                    {...registerField('phone')}
                    labelProps={{ label: formatMessage('widgets.vendorDetails.form.phone.label') }}
                    viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
                    placeholder={formatMessage('widgets.vendorDetails.form.phone.placeholder')}
                    isDisabled={isEditable && !editableFields?.includes('contactPhoneNumber')}
                    autoComplete="on"
                  />
                  {nonRequiredFieldsVisibility.email ? (
                    <Form.TextField
                      colSpan={2}
                      {...registerField('email')}
                      labelProps={{ label: formatMessage('widgets.vendorDetails.form.email.label') }}
                      viewModePlaceholder={formatMessage('widgets.vendorDetails.form.email.viewModePlaceholder')}
                      placeholder={formatMessage('widgets.vendorDetails.form.email.placeholder')}
                      isTruncated={!isEditable}
                      isHidden={!isEditable && !defaultValues?.email}
                      isDisabled={isEditable && !editableFields?.includes('emailAddress')}
                      autoComplete="on"
                    />
                  ) : null}
                </SimpleGrid>
              </Form.ContentBox>
              {nonRequiredFieldsVisibility.bankAccount ? (
                /* @ts-ignore Form.ContentBox should be change to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */
                <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
                  <SimpleGrid
                    columns={isExtraSmallScreen ? 1 : 2}
                    spacing="m"
                    aria-labeledby="widgets-vendorDetails-form-electronic-title"
                    role="group"
                  >
                    <GridItem colSpan={isExtraSmallScreen ? 1 : 2} paddingTop="m">
                      <Text as="h3" textStyle="heading3Semi" id="widgets-vendorDetails-form-electronic-title">
                        {formatMessage(
                          isEditable
                            ? 'widgets.vendorDetails.form.electronic.title.editMode'
                            : 'widgets.vendorDetails.form.electronic.title.viewMode',
                        )}
                      </Text>
                    </GridItem>
                    <Form.TextField
                      colSpan={isEditable ? 1 : 2}
                      labelProps={{
                        label: formatMessage('widgets.vendorDetails.form.bankRoutingNumber.label'),
                      }}
                      {...registerField('bankRoutingNumber')}
                      onChange={(e) => {
                        setValue('bankRoutingNumber', e.target.value, {
                          shouldValidate: isSubmitted,
                        });

                        if (isSubmitted) {
                          void trigger('bankAccountNumber');
                        }
                      }}
                      isTruncated={!isEditable}
                      viewModePlaceholder={formatMessage(
                        'widgets.vendorDetails.form.bankRoutingNumber.viewModePlaceholder',
                      )}
                      isDisabled={isEditable && !editableFields?.includes('bankAccount')}
                    />
                    <Form.TextField
                      colSpan={isEditable ? 1 : 2}
                      labelProps={{
                        label: formatMessage('widgets.vendorDetails.form.bankAccountNumber.label'),
                      }}
                      {...registerField('bankAccountNumber')}
                      onChange={(e) => {
                        setValue('bankAccountNumber', e.target.value, {
                          shouldValidate: isSubmitted,
                        });

                        if (isSubmitted) {
                          void trigger('bankRoutingNumber');
                        }
                      }}
                      isTruncated={!isEditable}
                      viewModePlaceholder={formatMessage(
                        'widgets.vendorDetails.form.bankAccountNumber.viewModePlaceholder',
                      )}
                      isDisabled={isEditable && !editableFields?.includes('bankAccount')}
                    />
                  </SimpleGrid>
                </Form.ContentBox>
              ) : null}
              {nonRequiredFieldsVisibility.uniqueName ? (
                /* @ts-ignore Form.ContentBox should be change to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */
                <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
                  <SimpleGrid
                    columns={isExtraSmallScreen ? 1 : 2}
                    spacing="m"
                    aria-labeledby="widgets-vendorDetails-form-accounting-software-title"
                    role="group"
                  >
                    <GridItem colSpan={isExtraSmallScreen ? 1 : 2} paddingTop="m">
                      <Text as="h3" textStyle="heading3Semi" id="widgets-vendorDetails-form-accounting-software-title">
                        {formatMessage('widgets.vendorDetails.form.accountingSoftware.title')}
                      </Text>
                    </GridItem>
                    <Form.TextField
                      colSpan={2}
                      {...registerField('uniqueName')}
                      labelProps={{ label: formatMessage('widgets.vendorDetails.form.uniqueName.label') }}
                      viewModePlaceholder={formatMessage('widgets.vendorDetails.form.uniqueName.viewModePlaceholder')}
                      isTruncated={!isEditable}
                      helperTextProps={{
                        label: formatMessage('widgets.vendorDetails.form.uniqueName.unmanagedVendorHelperText'),
                      }}
                    />
                  </SimpleGrid>
                </Form.ContentBox>
              ) : null}
              {/* @ts-ignore Form.ContentBox should be change to FormField once this ticket is done: https://meliorisk.atlassian.net/browse/ME-60665 */}
              <Form.ContentBox sx={{ listStyle: 'none' }} as="li" colSpan={isExtraSmallScreen ? 1 : 2}>
                <SimpleGrid
                  columns={isExtraSmallScreen ? 1 : 2}
                  spacing="m"
                  aria-labeledby="widgets-vendorDetails-form-address-title"
                  role="group"
                >
                  <Container paddingTop="m">
                    <Text as="h3" textStyle="heading3Semi" id="widgets-vendorDetails-form-address-title">
                      {formatMessage('widgets.vendorDetails.form.address.title')}
                    </Text>
                  </Container>
                  <AddressSearchWidget
                    colSpan={2}
                    {...registerField('line1')}
                    onChange={handleAddressChange}
                    // The 'OR' is a workaround for formatting the defaultValue we're getting for this field.
                    formatSelectedValue={(option) =>
                      (option.value as unknown as Address).line1 || (option.value as unknown as string)
                    }
                    labelProps={{
                      label: formatMessage('widgets.vendorDetails.form.addressLine1.label'),
                    }}
                    placeholder={formatMessage('widgets.vendorDetails.form.addressLine1.placeholder')}
                    viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
                    clearButtonAriaLabel={formatMessage('widgets.vendors.addressLine1.clearButtonAriaLabel')}
                    isDisabled={isEditable && !editableFields?.includes('address')}
                    autoComplete="on"
                  />
                  {nonRequiredFieldsVisibility.line2 ? (
                    <Form.TextField
                      colSpan={2}
                      labelProps={{
                        label: formatMessage('widgets.vendorDetails.form.addressLine2.label'),
                      }}
                      placeholder={formatMessage('widgets.vendorDetails.form.addressLine2.placeholder')}
                      viewModePlaceholder={formatMessage('widgets.vendorDetails.form.addressLine2.viewModePlaceholder')}
                      {...registerField('line2')}
                      onChange={(e) => {
                        closeToast();
                        onChangeWithValidation('line2', e.target.value, isSubmitted);
                      }}
                      isTruncated={!isEditable}
                      isDisabled={isEditable && !editableFields?.includes('address')}
                      autoComplete="on"
                    />
                  ) : null}
                  <Form.TextField
                    colSpan={isEditable ? 1 : 2}
                    labelProps={{
                      label: formatMessage('widgets.vendorDetails.form.city.label'),
                    }}
                    viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
                    {...registerField('city')}
                    onChange={(e) => {
                      closeToast();
                      onChangeWithValidation('city', e.target.value, isSubmitted);
                    }}
                    isTruncated={!isEditable}
                    isDisabled={isEditable && !editableFields?.includes('address')}
                  />
                  <Form.SelectNew
                    colSpan={isEditable ? 1 : 2}
                    labelProps={{
                      label: formatMessage('widgets.vendorDetails.form.state.label'),
                    }}
                    placeholder={formatMessage('widgets.vendorDetails.form.state.placeholder')}
                    {...registerField('state')}
                    onChange={(e) => {
                      closeToast();
                      onChangeWithValidation('state', e.target.value, isSubmitted);
                    }}
                    options={US_STATES.map((state) => ({
                      value: state,
                      label: formatMessage(`local.USA.states.${state}`),
                    }))}
                    viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
                    isDisabled={isEditable && !editableFields?.includes('address')}
                    searchBarProps={{
                      placeholder: formatMessage('widgets.vendors.state.placeholder'),
                      options: filteredStateOptions,
                      onChange: handleStateSearch,
                    }}
                    shouldHideClearButton
                  />
                  <ZipCodeField
                    colSpan={2}
                    labelProps={{
                      label: formatMessage('widgets.vendorDetails.form.postalCode.label'),
                    }}
                    placeholder={formatMessage('widgets.vendorDetails.form.postalCode.placeholder')}
                    viewModePlaceholder={formatMessage('widgets.vendorDetails.form.viewModePlaceholder')}
                    {...registerField('postalCode')}
                    onChange={(e) => {
                      closeToast();
                      onChangeWithValidation('postalCode', e.target.value, isSubmitted);
                    }}
                    isTruncated={!isEditable}
                    isDisabled={isEditable && !editableFields?.includes('address')}
                    helperTextProps={{
                      label: formatMessage('widgets.vendors.postalCode.description'),
                    }}
                  />
                </SimpleGrid>
              </Form.ContentBox>
            </Form>
          )}
        </LoadingContainer>
        <ConfirmVendorUpdateModal
          isSubmitting={!!isSaving}
          submitForm={handleSubmit}
          onClose={confirmVendorUpdate.onClose}
          isOpen={confirmVendorUpdate.isOpen}
        />
      </>
    );
  },
);
