import { InvoiceExpandedSummary, useMelioIntl } from '@melio/ar-domain';
import { Group, Link, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

type InvoiceAttachmentsProps = {
  attachments: InvoiceExpandedSummary['attachments'];
};

export const InvoiceAttachments = forwardRef<InvoiceAttachmentsProps>(({ attachments }, ref) => {
  const { formatMessage } = useMelioIntl();

  if (!attachments?.length) return null;

  return (
    <Group variant="vertical" spacing="m" ref={ref}>
      {attachments.map((attachment, index) => (
        <Group
          key={attachment.id}
          variant="vertical"
          spacing="xxs"
          data-testid={`attachment-group-${attachment.id ?? ''}`}
        >
          <Text
            textStyle="body4Semi"
            color="global.neutral.800"
            data-testid={`attachment-label-${attachment.id ?? ''}`}
          >
            {index === 0
              ? formatMessage('ar.dashboard.activities.InvoiceDrawer.body.summary.attachments.withoutNumberSign.label')
              : formatMessage('ar.dashboard.activities.InvoiceDrawer.body.summary.attachments.withNumberSign.label', {
                  index: index + 1,
                })}
          </Text>
          <Link
            data-testid={`attachment-link-${attachment.id ?? ''}`}
            href="#"
            newTab
            color="secondary"
            label={attachment.fileName || ''}
            onClick={(e) => {
              e.preventDefault();
              window.open(attachment.previewUrls[0], '_blank');
            }}
          />
        </Group>
      ))}
    </Group>
  );
});
