import { useMelioIntl } from '@melio/ar-domain';
import { Button, Container, Group, SearchBar } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type ServiceChargesTableToolbarProps = {
  onAdd: VoidFunction;
  searchTerm?: string;
  setSearchTerm: (value: string) => unknown;
};

export const ServiceChargesTableToolbar = forwardRef<ServiceChargesTableToolbarProps>(
  ({ searchTerm, setSearchTerm, onAdd, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    return (
      <Container paddingX="xxs" paddingY="xxs" data-component={ServiceChargesTableToolbar.displayName}>
        <Group variant="horizontal" justifyContent="space-between" {...props} ref={ref}>
          <SearchBar
            label={formatMessage(
              'ar.settings.activities.serviceChagresTable.components.serviceChagresTableToolbar.searchBar.label'
            )}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            data-testid="search-bar"
          />
          <Button
            variant="tertiary"
            label={formatMessage(
              'ar.settings.activities.serviceChagresTable.components.serviceChagresTableToolbar.addButton.label'
            )}
            onClick={onAdd}
            data-testid="add-service-charge-button"
          />
        </Group>
      </Container>
    );
  }
);
ServiceChargesTableToolbar.displayName = 'ServiceChargesTableToolbar';
