import { PropsWithChildren } from 'react';
import { Modal, ModalBody, ModalContent, ModalOverlay, ModalProps } from '@chakra-ui/react';

type Props = PropsWithChildren<{
  isOpen: boolean;
  onClose: () => void;
}> &
  Pick<ModalProps, 'size'>;

export const NakedModal = ({ children, isOpen, onClose, size = 'md' }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={size} isCentered>
      <ModalOverlay />
      <ModalContent backgroundColor={'transparent'} padding={0} boxShadow={0}>
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
