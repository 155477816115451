import { InvoiceDiscount, InvoiceTaxRate, useInvoice } from '@melio/ar-domain';

import { ServiceChargeParams } from '../../../types';
import { EditInvoiceSubmitTarget } from '../types';
import { useGetUpdatePayload } from './line-item-utils';
import { InvoiceFormValues } from './useInvoiceForm';
import { useUpdateCatalogItems } from './useUpdateCatalogItems';

type EditInvoiceActionsProps = {
  invoiceId: string;
  discount?: InvoiceDiscount;
  taxRate?: InvoiceTaxRate;
  serviceCharges?: ServiceChargeParams[];
  onError?: ARErrorFunction;
  isInvoiceAttachmentsEnabled?: boolean;
};

export const useEditInvoiceActions = ({
  invoiceId,
  discount,
  serviceCharges,
  taxRate,
  onError,
  isInvoiceAttachmentsEnabled,
}: EditInvoiceActionsProps) => {
  const { updateCatalogItems, isSaving: isSavingCatalogItems } = useUpdateCatalogItems();
  const { update, isMutating: isSaving } = useInvoice({ id: invoiceId, enabled: false, onUpdateError: onError });
  const getPayloadFromFormData = useGetUpdatePayload({ onError, isInvoiceAttachmentsEnabled });
  const updateInvoice = async (formData: InvoiceFormValues, target?: EditInvoiceSubmitTarget) => {
    await updateCatalogItems(formData.lineItems);
    const payload = getPayloadFromFormData({
      data: formData,
      discount,
      serviceCharges,
      taxRate,
      isDraft: target === 'save-and-continue' ? false : undefined,
    });
    return update(payload);
  };
  return {
    updateInvoice,
    isSaving: isSaving || isSavingCatalogItems,
  };
};
