import { CompleteMandatoryDetailsWarningBanner, ComplianceVerificationFailedBanner } from '@melio/ap-widgets';

import { PayDashboardActivityBanner, PayDashboardActivityProps } from '../payDashboardActivityPropType';

export const usePayDashboardBanner = ({
  bannerToDisplay,
  onCloseCompleteDetailsDialog,
}: Pick<PayDashboardActivityProps, 'bannerToDisplay' | 'onCloseCompleteDetailsDialog'>) => {
  if (!bannerToDisplay) {
    return;
  }
  const bannerPropsMap: Record<
    PayDashboardActivityBanner,
    { Banner: React.FC; analyticsProps: { BannerCta: string | undefined } }
  > = {
    'kyc-complete-details': {
      Banner: () => (
        <CompleteMandatoryDetailsWarningBanner onOpenCompleteRequiredDetailsDialog={onCloseCompleteDetailsDialog} />
      ),
      analyticsProps: {
        BannerCta: 'complete-details',
      },
    },
    'kyc-verification-failed': {
      Banner: () => <ComplianceVerificationFailedBanner />,
      analyticsProps: {
        BannerCta: undefined,
      },
    },
  };

  return bannerPropsMap[bannerToDisplay];
};
