import { useMelioIntl } from '@melio/ar-domain';
import { Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { MAX_FILE_ATTACHMENTS } from '../../../types';
import { createDefaultFormAttachment, useInvoiceFormContext } from '../../../utils';
import { InvoiceFileAttachmentsForm } from './InvoiceFileAttachmentsForm';

export const InvoiceFileAttachmentsFormSection = forwardRef<{ isSaving: boolean }>(({ isSaving }, ref) => {
  const { filesFieldArray, getValues, setError, clearErrors } = useInvoiceFormContext();
  const { formatMessage } = useMelioIntl();

  return (
    <Group variant="vertical" spacing="m" ref={ref}>
      {filesFieldArray.fields.map((field, index) => (
        <InvoiceFileAttachmentsForm
          isSaving={isSaving}
          key={field.id}
          index={index}
          onError={(type) => {
            if (type === 'sizeExceeded') {
              setError(`attachments.${index}`, {
                message: formatMessage(
                  'ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.error.size.text'
                ),
              });
            } else {
              setError(`attachments.${index}`, {
                message: formatMessage(
                  'ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.error.general.text'
                ),
              });
            }
          }}
          onCreate={() => {
            clearErrors(`attachments.${index}`);

            if (filesFieldArray.fields.length < MAX_FILE_ATTACHMENTS) {
              filesFieldArray.append(createDefaultFormAttachment());
            }
          }}
          onDelete={() => {
            const { attachments } = getValues();
            const isLastFileEmpty = attachments?.[attachments.length - 1]?.fileName == '';
            const isEmptyFileDeleted = filesFieldArray.fields[index]?.fileName == '';

            filesFieldArray.remove(index);
            if (
              (filesFieldArray.fields.length === MAX_FILE_ATTACHMENTS && !isLastFileEmpty) ||
              filesFieldArray.fields.length === 1 ||
              isEmptyFileDeleted
            ) {
              filesFieldArray.append(createDefaultFormAttachment());
            }
          }}
        />
      ))}
    </Group>
  );
});
InvoiceFileAttachmentsFormSection.displayName = 'InvoiceAttachmentsFormSection';
