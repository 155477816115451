import { Container, Group, IconButton, Layout, Text, useMelioForm, useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  Account,
  ApplyForFinancingStatus,
  useAccount,
  useFinancingApplication,
  useFinancingOrgEligibility,
  useOrganizationBeneficialOwners,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { useNavigate } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import { BrandColoredLink } from '../BrandColoredLink/BrandColoredLink';
import { TermsAndConditions } from '../single-payment/RepaymentTerms/components/TermsAndConditions';
import { FinancingApplicationFlowTitle } from './components/ApplicationFlowTitle';
import { ApplicationProcessingLoader } from './components/ApplicationProcessingLoader';
import { BusinessDetailsForm } from './components/BusinessDetailsForm';
import { OwnershipDetailsForm } from './components/OwnershipDetailsForm';
import { useBusinessDetailsFormValidation } from './hooks/useBusinessDetailsFormValidation';
import { useFinancingApplicationForbiddenRedirect } from './hooks/useFinancingApplicationForbiddenRedirect';
import { useOwnershipDetailsFormDefaultValues } from './hooks/useOwnershipDetailsFormDefaultValues';
import { useOwnershipDetailsSchema } from './hooks/useOwnershipDetailsSchema';
import { FinancingApplicationBusinessDetailsForm, FinancingApplicationOwnerDetailsForm } from './types';
import { applicationFormValuesToApplicationCreateParams } from './utils';

export const FinancingApplicationFlowActivity = () => {
  const [isApplicationFlowUboEnabled] = useFeature(FeatureFlags.PlatformFinancingApplicationFlowUBO, false);
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const { toast } = useToast();
  const { data: account, isLoading: isLoadingAccount } = useAccount({ id: 'me' });
  const { data: beneficialOwners, isLoading: isLoadingBeneficialOwners } = useOrganizationBeneficialOwners({
    enabled: isApplicationFlowUboEnabled,
  });

  const { isLoading: isLoadingOrgFinancingEligibilityData, data: orgEligibilityData } = useFinancingOrgEligibility();
  useFinancingApplicationForbiddenRedirect(orgEligibilityData);

  const [isEditBusinessDetails, setIsEditBusinessDetails] = useState(false);
  const [isSubmittedBusinessDetailsForm, setIsSubmittedBusinessDetailsForm] = useState(false);
  const [isBusinessDetailsCardExpanded, setIsBusinessDetailsCardExpanded] = useState(true);
  const [isOwnershipDetailsCardExpanded, setIsOwnershipDetailsCardExpanded] = useState(false);
  const { links: linksConfig } = useConfig();

  const { applyForFinancing, isMutating } = useFinancingApplication({
    options: {
      onCreateError: () => {
        track('PaymentMethod', 'Status', {
          PageName: 'processing-application',
          Flow: 'financing',
          Intent: 'processing-application',
          FinancingExperience: 'application',
          StatusType: 'Failure',
        });

        toast({
          type: 'error',
          title: formatMessage('activities.financingApplication.applicationFormScreen.error.toast.title'),
          action: {
            text: formatMessage('activities.financingApplication.applicationFormScreen.error.toast.action'),
            onAction: handleOwnershipDetailsSubmit,
            type: 'button',
          },
          duration: 7000,
        });
      },
      onCreateSuccess: (res) => {
        track('PaymentMethod', 'Status', {
          PageName: 'processing-application',
          Flow: 'financing',
          Intent: 'processing-application',
          FinancingExperience: 'application',
          StatusType: res.status,
        });

        switch (res.status) {
          case ApplyForFinancingStatus.Eligible:
            return navigate('./approved', {
              state: {
                amount: res.limit,
              },
            });
          case ApplyForFinancingStatus.Ineligible:
            return navigate('./declined');
          case ApplyForFinancingStatus.Pending:
            return navigate('./pending');
        }
      },
    },
  });

  useEffect(() => {
    track('PaymentMethod', 'View', {
      PageName: 'business-details',
      Flow: 'financing',
      Intent: 'add-business-details',
      FinancingExperience: 'application',
    });
  }, [track]);

  const { company } = account ?? ({} as Account);

  const ownerShipDetailsDefaultFormValues = useOwnershipDetailsFormDefaultValues({
    beneficialOwners: isApplicationFlowUboEnabled ? beneficialOwners : [],
  });
  const businessDetailsFormSchema = useBusinessDetailsFormValidation();
  const ownershipDetailsFormSchema = useOwnershipDetailsSchema();
  const businessDetailsForm = useMelioForm<FinancingApplicationBusinessDetailsForm>({
    defaultValues: {
      companyName: company?.name ?? '',
      legalBusinessName: company?.legalName ?? '',
      legalBusinessAddress: company?.legalAddress,
      ownerPhone: company?.phoneNumber?.replaceAll(/\W/g, '') ?? '',
      annualRevenue: '',
      businessIndustry: {
        naicsCode: company?.industry?.naicsCode ?? undefined,
        name: company?.industry?.name ?? '',
      },
      businessType: undefined,
      employerId: '',
    },
    subscribeToDefaultValuesChanges: true,
    onSubmit: () => {
      track('PaymentMethod', 'Click', {
        PageName: 'business-details',
        Flow: 'financing',
        Intent: isSubmittedBusinessDetailsForm ? 'edit' : 'save-and-continue',
        FinancingExperience: 'application',
        applicationDetails: businessDetailsForm.getValues(),
      });

      onBusinessDetailsFormSubmit();
    },
    schema: businessDetailsFormSchema,
  });

  const ownershipDetailsForm = useMelioForm<FinancingApplicationOwnerDetailsForm>({
    schema: ownershipDetailsFormSchema,
    defaultValues: ownerShipDetailsDefaultFormValues as FinancingApplicationOwnerDetailsForm,
    subscribeToDefaultValuesChanges: true,
    onSubmit: (values) => {
      track('PaymentMethod', 'Click', {
        PageName: 'ownership-details',
        Flow: 'financing',
        Cta: 'apply',
        Intent: 'complete-application',
        FinancingExperience: 'application',
        applicationDetails: {
          ...businessDetailsForm.getValues(),
          ...ownershipDetailsForm.getValues(),
        },
      });

      const request = applicationFormValuesToApplicationCreateParams({
        ownershipDetails: values,
        businessDetails: businessDetailsForm.getValues(),
      });

      applyForFinancing(request);

      track('PaymentMethod', 'View', {
        PageName: 'processing-application',
        Flow: 'financing',
        Intent: 'processing-application',
        FinancingExperience: 'application',
      });
    },
  });

  const handleBusinessDetailsSubmit = businessDetailsForm.handleSubmit();
  const handleOwnershipDetailsSubmit = ownershipDetailsForm.handleSubmit();
  const onBusinessDetailsFormSubmit = () => {
    setIsSubmittedBusinessDetailsForm(true);
    setIsBusinessDetailsCardExpanded(false);
    setIsOwnershipDetailsCardExpanded(true);
    setIsEditBusinessDetails(false);

    track('PaymentMethod', 'View', {
      PageName: 'ownership-details',
      Flow: 'financing',
      Intent: 'ownership-details',
      FinancingExperience: 'application',
    });
  };

  if (isMutating) {
    return <ApplicationProcessingLoader />;
  }

  const onClose = () => {
    track('PaymentMethod', 'Click', {
      PageName: 'financing-application',
      Flow: 'financing',
      Intent: 'financing-application',
      FinancingExperience: 'application',
      Cta: 'exit',
    });

    navigate(-1);
  };

  const isLoading = isLoadingAccount || isLoadingBeneficialOwners || isLoadingOrgFinancingEligibilityData;
  return (
    <Layout
      maxWidth="600px"
      data-testid="financing-application-flow-page"
      isLoading={isLoading}
      header={{
        content: (
          <Container paddingRight="xl" paddingTop="xl">
            <Group justifyContent="flex-end" width="full">
              <IconButton icon="close" onClick={onClose} variant="naked" size="medium" aria-label="Go back" />
            </Group>
          </Container>
        ),
      }}
    >
      <Group variant="vertical" spacing="xl">
        <FinancingApplicationFlowTitle />
        <Group variant="vertical">
          <BusinessDetailsForm
            isEditMode={isEditBusinessDetails}
            onClickEdit={() => setIsEditBusinessDetails(true)}
            form={businessDetailsForm}
            onSubmit={() => {
              handleBusinessDetailsSubmit();
            }}
            isSubmitted={isSubmittedBusinessDetailsForm}
            isExpanded={isBusinessDetailsCardExpanded}
            onCollapse={() => setIsBusinessDetailsCardExpanded(false)}
            onOpen={() => setIsBusinessDetailsCardExpanded(true)}
          />
          <OwnershipDetailsForm
            isExpanded={isOwnershipDetailsCardExpanded}
            isDisabled={!isSubmittedBusinessDetailsForm}
            onExpandChange={(isExpanded) => setIsOwnershipDetailsCardExpanded(isExpanded)}
            form={ownershipDetailsForm}
            onSubmit={() => {
              handleOwnershipDetailsSubmit();
            }}
          />
        </Group>
        <Group width="full" variant="vertical">
          <Text textStyle="caption1">
            {formatMessage('activities.financingApplication.applicationFormScreen.footer.termsOfService', {
              termsOfServiceLink: (
                <BrandColoredLink
                  label={formatMessage(
                    'activities.financingApplication.applicationFormScreen.footer.termsOfService.link'
                  )}
                  href={linksConfig['creditKey.legal.termsOfService']}
                  textStyle="caption1"
                />
              ),
              privacyPolicyLink: (
                <BrandColoredLink
                  label={formatMessage(
                    'activities.financingApplication.applicationFormScreen.footer.privacyPolicy.link'
                  )}
                  href={linksConfig['creditKey.legal.privacyPolicy']}
                  textStyle="caption1"
                />
              ),
            })}
          </Text>
          <Text textStyle="caption1">
            {formatMessage('activities.financingApplication.applicationFormScreen.footer.patriotAct')}
          </Text>
          <TermsAndConditions />
        </Group>
      </Group>
    </Layout>
  );
};
