/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { compact } from 'lodash';
import { usePaymentProcessingInfo } from '@melio/ap-domain';
import { PaymentReviewLineItem } from '@melio/ap-widgets';
import { ActionsDropdownMenu, ActionsDropdownMenuItemProps, Divider, StatusBar, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { Payment, useAccountingPlatforms, useInboxItems, usePayment } from '@melio/platform-api';
import { usePermissions } from '@melio/platform-permissions';
import { usePartnerFeature } from '@melio/platform-provider';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { useRouter } from '@/hooks/router.hooks';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePlatformIntl } from '@/translations/Intl';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { DeleteBillDialog } from '@/widgets/pay-dashboard/bill-details/components/DeleteBillDialog';
import { MarkAsPaidDialog } from '@/widgets/pay-dashboard/bill-details/components/MarkAsPaidDialog';
import { PayFromSectionPaymentFailed } from '@/widgets/pay-dashboard/payment-details/components/failed/PayFromSectionPaymentFailed';
import { PaymentDetailsProps } from '@/widgets/pay-dashboard/payment-details/PaymentDetails.types';
import { usePaymentScheduledByProps } from '../../hooks/usePaymentScheduledByProps';
import { PaymentDetailsSection } from '../general/PaymentDetailsSection';
import { PaymentDetailsTop } from '../general/PaymentDetailsTop';
import { PaymentScheduledBySection } from '../general/PaymentScheduledBySection';
import { PaymentFailedSectionBanner } from './PaymentFailedSectionBanner';

const getPageName = (paymentActions?: Payment['paymentActions']) => {
  if (paymentActions?.retryCollect?.eligible) return 'payment-failed-to-collect';
  if (paymentActions?.retryDeliver?.eligible) return 'payment-failed-to-deliver';
  return 'payment-failed';
};

const PaymentFailedInner = ({ payment, isActionsEnabled }: PaymentDetailsProps) => {
  const [isMarkAsPaidEnabled] = usePartnerFeature('MarkAsPaid', false);
  const { isFetching: accountingPlatformIsLoading, hasAccountingPlatform } = useAccountingPlatforms();
  const { formatMessage, formatDate } = usePlatformIntl();
  const { replaceToPayDashboardTab } = useRouter();
  const { isOpen: isDeleteDialogOpen, onClose: closeDeleteDialog, onOpen: openDeleteDialog } = useDisclosure();
  const {
    isOpen: isMarkAsPaidPaymentDialogOpen,
    onClose: onMarkAsPaidPaymentModalClose,
    onOpen: onMarkAsPaidPaymentModalOpen,
  } = useDisclosure();
  const { track } = useAnalytics();
  const { showPaymentScheduledBySection, ...props } = usePaymentScheduledByProps(payment);
  const { refetch: refetchInboxItems } = useInboxItems({ enabled: false });
  const { markAsPaid, isMutating } = usePayment({ id: payment.id, enabled: false });
  const { can } = usePermissions();
  const hasMarkPaymentAsPaidPermission = can({
    subject: 'payment:markAsPaid',
    action: 'update',
    subjectData: {
      createdById: payment.createdById,
      fundingSourceId: payment.fundingSourceId,
      vendor: {
        createdById: payment.vendor?.createdById,
        managedBy: payment.vendor?.managedBy,
      },
      payment: {
        origin: payment.origin,
      },
    },
  });
  const hasDeletePaymentPermission = can({
    subject: 'payment',
    action: 'delete',
    subjectData: {
      createdById: payment.createdById,
      fundingSourceId: payment.fundingSourceId,
      vendor: {
        createdById: payment.vendor?.createdById,
        managedBy: payment.vendor?.managedBy,
      },
      payment: {
        origin: payment.origin,
      },
    },
  });
  const hasPaymentBillsDeletePermissions = payment.bills?.length
    ? payment.bills.every((bill) =>
        can({
          subject: 'bill',
          action: 'delete',
          subjectData: {
            createdById: bill.createdById,
            vendor: {
              createdById: bill.vendor?.createdById,
              managedBy: bill.vendor?.managedBy,
            },
          },
        }),
      )
    : true;

  const { isPaymentProcessedByCapitalOne } = usePaymentProcessingInfo();
  const shouldDisplayMemoToVendor = !isPaymentProcessedByCapitalOne(payment.deliveryMethod?.type);

  useAnalyticsContext({ globalProperties: { PaymentId: payment.id } });

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (payment) {
      track('Dashboard', 'View', { PageName: getPageName(payment.paymentActions), intent: 'payment-details' });
    }
  }, [payment]);

  const handleDeleteSuccess = () => {
    refetchInboxItems();
    replaceToPayDashboardTab(PayDashboardTabs.Inbox);
  };

  const onSubmitMarkAsPaid = async (accountingPlatformPaymentAccountId?: string) => {
    await markAsPaid({ accountingPlatformPaymentAccountId });
  };

  const isDeletable = payment.paymentActions.delete.eligible;

  const actionMenuItems = compact([
    isMarkAsPaidEnabled &&
      isDeletable &&
      hasMarkPaymentAsPaidPermission &&
      hasDeletePaymentPermission && {
        label: formatMessage('widgets.paymentDetails.failed.menu.items.markAsPaid'),
        onClick: onMarkAsPaidPaymentModalOpen,
        dataTestId: 'payment-failed-details-menu-mark-as-paid',
      },
    isDeletable &&
      hasDeletePaymentPermission &&
      hasPaymentBillsDeletePermissions && {
        label: formatMessage('widgets.paymentDetails.failed.menu.items.delete'),
        onClick: openDeleteDialog,
        variant: 'critical' as ActionsDropdownMenuItemProps['variant'],
        dataTestId: 'failed-payment-details-menu-delete-bill',
      },
  ]);

  if (accountingPlatformIsLoading) return <Loader isAbsoluteCenter />;

  return (
    <>
      <StatusBar variant={'critical'} content={formatMessage('widgets.paymentDetails.failed.title')} />

      <Box py={'32px'} data-testid="pay-dashboard-payment-failed">
        <PaymentDetailsSection gridGap={'24px'}>
          <PaymentFailedSectionBanner payment={payment} />
          <PaymentDetailsTop
            payment={payment}
            isActionsEnabled={isActionsEnabled}
            menu={
              payment.bills?.[0] && actionMenuItems.length ? (
                <ActionsDropdownMenu
                  data-testid="failed-payment-details-menu"
                  label={formatMessage('widgets.paymentDetails.failed.menu.button')}
                  items={actionMenuItems}
                  isOpen={isMenuOpen}
                  onOpenChange={setIsMenuOpen}
                />
              ) : null
            }
          />
        </PaymentDetailsSection>
        {showPaymentScheduledBySection && (
          <PaymentDetailsSection gridGap={'16px'}>
            <PaymentScheduledBySection {...props} />
          </PaymentDetailsSection>
        )}
        <PaymentDetailsSection gridGap={'16px'}>
          <PayFromSectionPaymentFailed fundingSource={payment.fundingSource} />
          <Divider />
          <Text textStyle="body4Semi" color="global.critical.700">
            {formatMessage('widgets.paymentDetails.failed.failedDate', {
              date: formatDate(payment.history.updatedAt, { dateStyle: 'medium' }),
            })}
          </Text>
          {shouldDisplayMemoToVendor && (
            <PaymentReviewLineItem
              labelProps={{ label: formatMessage('widgets.paymentDetails.vendorReceives.memoToVendor.label') }}
              mainLabelProps={{
                label: payment.note
                  ? payment.note
                  : formatMessage('widgets.paymentDetails.vendorReceives.memoToVendor.empty'),
              }}
            />
          )}
        </PaymentDetailsSection>
      </Box>
      {isMarkAsPaidEnabled && (
        <MarkAsPaidDialog
          isOpen={isMarkAsPaidPaymentDialogOpen}
          onClose={onMarkAsPaidPaymentModalClose}
          onMarkAsPaidSubmit={onSubmitMarkAsPaid}
          isLoading={isMutating}
          hasAccountingPlatform={hasAccountingPlatform}
          amount={payment.amount}
        />
      )}
      {payment.bills?.[0] ? (
        <DeleteBillDialog
          isOpen={isDeleteDialogOpen}
          onClose={closeDeleteDialog}
          billId={payment.bills[0].id}
          onSuccess={handleDeleteSuccess}
        />
      ) : null}
    </>
  );
};

export const PaymentFailed = withAnalyticsContext(PaymentFailedInner);
