/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box } from '@chakra-ui/react';
import { useRecoilState } from 'recoil';
import { Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';

import { EmptyStateList } from '@/cl/components/EmptyStateList/EmptyStateList.component';
import { FormSearch } from '@/cl/components/FormSearch/FormSearch.component';
import { NoResultsFound } from '@/cl/components/NoResultsFound/NoResultsFound.component';
import { usePartnerConfig } from '@/hooks/partners';
import { useRouter } from '@/hooks/router.hooks';
import { vendorsDashboardSearchQuerySelector } from '@/store/Vendors/Vendors.model';
import { usePlatformIntl } from '@/translations/Intl';

export const EmptyVendorExplanation = () => {
  const { formatMessage } = usePlatformIntl();
  const { goToAddNewVendor } = useRouter();
  const { partnerConfig } = usePartnerConfig();
  const { track } = useAnalytics();
  const { EmptyVendorsIcon, NoVendorsFoundIcon } = partnerConfig.icons;
  const [searchQuery, setSearchQuery] = useRecoilState(vendorsDashboardSearchQuerySelector);

  const onSearch = (searchString: string) => {
    setSearchQuery(searchString);
  };

  const onClickHandler = React.useCallback(() => {
    goToAddNewVendor();
    track('VendorDashboardAddVendor', 'Chose');
  }, [goToAddNewVendor]);

  return (
    <>
      <Text as="h2" textStyle="heading1Semi">
        {formatMessage('widgets.noVendors.title')}
      </Text>
      <Box paddingTop={'40px'}>
        {searchQuery ? (
          <>
            <FormSearch onSearch={onSearch} placeholder={'Search...'} defaultValue={searchQuery} />
            <NoResultsFound text={formatMessage('widgets.NoResultsFound.text')} icon={NoVendorsFoundIcon.small} />
          </>
        ) : (
          <EmptyStateList
            title={formatMessage('widgets.noVendors.subtitle')}
            description={formatMessage('widgets.noVendors.text')}
            icon={EmptyVendorsIcon.small}
            button={{
              text: formatMessage('widgets.noVendors.button'),
              onClick: onClickHandler,
            }}
          />
        )}
      </Box>
    </>
  );
};
