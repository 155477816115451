/* eslint-disable max-lines */
import { MissingVendorInfoBannerWidget } from '@melio/ap-widgets/src/components/Vendors/MissingVendorInfoBanner/MissingVendorInfoBanner.widget';
import {
  Button,
  Card,
  Container,
  Divider,
  Group,
  IconButton,
  Layout,
  SectionBanner,
  Text,
  useBreakpoint,
} from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useConfig } from '@melio/platform-provider';
import { forwardRef, PageTitle, SystemMessageDisplay } from '@melio/platform-utils';
import { useState } from 'react';

import { AmountHeader } from './components/AmountHeader/AmountHeader';
import { PaymentIntentsTable } from './components/PaymentIntentsTable/PaymentIntentsTable';
import { UsHolidayChecksBanner } from './components/UsHolidayChecksBanner';
import {
  doesPaymentIntentsContainAnEbill,
  getNumberOfBills,
  useUnreadyPaymentIntents,
} from './PaymentIntentsTable.screen.utils';
import { PaymentIntentsTableScreenProps } from './types';
import { useEventHandlers } from './useEventHandlers';

export const PaymentIntentsTableScreen = forwardRef<PaymentIntentsTableScreenProps, 'div'>(
  (
    {
      isToggling,
      paymentIntentsWithDerivatives,
      immediatelyShowStatus,
      fundingSources,
      orgBillingFeeSettings,
      isByDueDate,
      selectedDeliveryDateHeaderCellOption,
      missingVendorDirectoryDetailsBannerProps,
      arePaymentsCombined,
      onToggleCombinedPayments,
      onOpenReconciliationModal,
      onOpenPaymentPurposeModal,
      onOpenVendorDetailsModal,
      onOpenInvoiceAttachmentModal,
      onViewBillDetailsClick,
      onAddMemoToVendorClick,
      onAddPaymentNoteClick,
      onSetInvoiceNumberClick,
      onRemoveBillsClick,
      onAddFundingSourceClick,
      onAddDeliveryMethodClick,
      onUpdateSingleDeliveryPreferenceType,
      onUpdateSingleFundingSource,
      onUpdateSingleDeliveryMethod,
      onUpdateSingleScheduledDate,
      onUpdateSingleDeliveryDate,
      onDone,
      onBack,
      loadingRowIds,
      onUpdateAllFundingSources,
      onUpdateAllDeductionDatesToTheSameDate,
      onUpdateAllDeductionDatesToTheEarliestDate,
      onUpdateAllDeductionDatesToArriveByDueDate,
      onUpdateAllDeliveryDatesToTheDueDate,
      isUpdating,
      shouldDisplayVendorDetailsFailureBanner,
      onEditAmountClick,
      ...props
    },
    ref
  ) => {
    const { isExtraSmallScreen } = useBreakpoint();
    const {
      settings: { batchPayments },
    } = useConfig();

    const [isEoyChecksEnabled] = useFeature(FeatureFlags.USHolidaysChecks, false);
    const [showMissingDetailsIndicationAsCritical] = useFeature(
      FeatureFlags.BatchPaymentsShowPaymentIntentsMissingDetailsIndicationAsCritical,
      false
    );

    const numberOfBills = getNumberOfBills(paymentIntentsWithDerivatives);
    const hasCombinedPayments = numberOfBills > paymentIntentsWithDerivatives.length;
    const hasEbills = doesPaymentIntentsContainAnEbill(paymentIntentsWithDerivatives);

    const { formatMessage } = useMelioIntl();
    const { startAction } = useMonitoring();

    const [shouldDisplayStatus, setShouldDisplayStatus] = useState(false);

    const unreadyToConfirmPaymentIntentsIds = useUnreadyPaymentIntents(
      paymentIntentsWithDerivatives,
      fundingSources
    ).map(({ paymentIntent }) => paymentIntent.id);
    const numberOfUnreadyPaymentIntents = unreadyToConfirmPaymentIntentsIds.length;

    const {
      onConfirmClick,
      handleAddMemoToVendorClick,
      handleSetInvoiceNumberClick,
      handleRemoveBillsClick,
      handleUpdateAllDeductionDatesToTheSameDate,
      handleUpdateAllDeductionDatesToTheEarliestDate,
      handleUpdateAllDeductionDatesToArriveByDueDate,
      handleUpdateAllDeliveryDatesToArriveByDueDate,
      handleUpdateSingleFundingSource,
      handleViewBillDetailsClick,
      handleCancel,
      handleUpdateSingleDeliveryMethod,
      handleUpdateSingleScheduledDate,
      handleUpdateSingleDeliveryDate,
      handleAddDeliveryMethodClick,
      handleAddFundingSourceClick,
      handleUpdateAllFundingSources,
      handleOpenReconciliationModal,
      handleOpenPaymentPurposeModal,
      handleOpenInvoiceAttachmentModal,
      handleUpdateSingleDeliveryPreferenceType,
      handleRowExpand,
      handleEditAmountClick,
    } = useEventHandlers({
      arePaymentsCombined,
      paymentIntentsWithDerivatives,
      setShouldDisplayStatus,
      isByDueDate,
      selectedDeliveryDateHeaderCellOption,
      onDone: () => {
        startAction('batch_payment_confirm');
        onDone();
      },
      onBack,
      onOpenReconciliationModal,
      onOpenPaymentPurposeModal,
      onOpenInvoiceAttachmentModal,
      onRemoveBillsClick,
      numberOfUnreadyPaymentIntents,
      fundingSources,
      onAddMemoToVendorClick,
      onSetInvoiceNumberClick,
      onAddDeliveryMethodClick,
      onViewBillDetailsClick,
      onAddFundingSourceClick,
      onUpdateAllDeductionDatesToTheSameDate,
      onUpdateAllDeductionDatesToTheEarliestDate,
      onUpdateAllDeductionDatesToArriveByDueDate,
      onUpdateAllDeliveryDatesToTheDueDate,
      onUpdateAllFundingSources,
      onUpdateSingleDeliveryMethod,
      onUpdateSingleDeliveryPreferenceType,
      onUpdateSingleFundingSource,
      onUpdateSingleScheduledDate,
      onUpdateSingleDeliveryDate,
      onEditAmountClick,
      missingVendorDirectoryDetailsBannerProps,
    });

    const shouldDisplayStatusBanner = (shouldDisplayStatus || immediatelyShowStatus) && !!numberOfUnreadyPaymentIntents;

    const shouldShowBannersSection =
      isEoyChecksEnabled ||
      shouldDisplayStatusBanner ||
      shouldDisplayVendorDetailsFailureBanner ||
      !!missingVendorDirectoryDetailsBannerProps;
    return (
      <Layout
        {...(batchPayments.useMinimalHeaderDesign ? { paddingContent: 'l' } : {})}
        backgroundColor={batchPayments.backgroundColor}
        data-component="BatchPaymentsActivity.PaymentIntentsTableScreen"
        data-testid="batch-payments-activity-payment-intents-table-screen"
        {...props}
        ref={ref}
        footer={{
          isSticky: true,
          content: (
            <Group variant="vertical" spacing="none">
              {batchPayments.closeButtonOnLeft && <Divider variant="horizontal" />}
              <Container
                backgroundColor="white"
                {...(batchPayments.closeButtonOnLeft
                  ? { width: 'full', paddingX: 's', paddingY: 's', border: 'none' }
                  : { justifyContent: 'flex-end', paddingY: 'm', paddingRight: 'm', border: 'regular' })}
              >
                <Group spacing="l" {...(batchPayments.closeButtonOnLeft && { justifyContent: 'space-between' })}>
                  <Button
                    variant="tertiary"
                    data-testid="layout-cancel-button"
                    onClick={handleCancel}
                    label={formatMessage('activities.batchPayments.screens.paymentIntentsTable.cancel')}
                  />
                  <Button
                    onClick={onConfirmClick}
                    data-testid="layout-next-button"
                    isLoading={isUpdating}
                    label={formatMessage('activities.batchPayments.screens.paymentIntentsTable.schedulePayments')}
                  />
                </Group>
              </Container>
            </Group>
          ),
        }}
      >
        <Group variant="vertical" spacing="xl">
          {!batchPayments.useMinimalHeaderDesign && (
            <Container>
              <IconButton
                data-testid="layout-back-button"
                onClick={onBack}
                aria-label="back"
                icon="chevron-left"
                variant="naked"
                size="medium"
              />
            </Container>
          )}
          <Group
            variant={isExtraSmallScreen ? 'vertical' : 'horizontal'}
            alignItems="flex-start"
            justifyContent="space-between"
            data-testid="layout-title"
          >
            <Group variant="vertical" spacing="xs">
              <PageTitle textStyle="heading1Semi" aria-live="polite">
                {formatMessage('activities.batchPayments.screens.paymentIntentsTable.title', {
                  numberOfPaymentIntents: paymentIntentsWithDerivatives.length,
                })}
              </PageTitle>
              {hasCombinedPayments && (
                <Text textStyle="body4" color="global.neutral.900">
                  {formatMessage('activities.batchPayments.screens.paymentIntentsTable.subtitle.combined', {
                    numberOfBills,
                  })}
                </Text>
              )}
            </Group>
            <AmountHeader
              orgBillingFeeSettings={orgBillingFeeSettings}
              paymentIntentsWithDerivatives={paymentIntentsWithDerivatives}
            />
          </Group>
          {shouldShowBannersSection && (
            <Container>
              <Group variant="vertical" spacing="m">
                {isEoyChecksEnabled && <UsHolidayChecksBanner />}
                {shouldDisplayStatusBanner && (
                  <SectionBanner
                    variant={showMissingDetailsIndicationAsCritical ? 'critical' : 'warning'}
                    title={formatMessage(
                      'activities.batchPayments.screens.paymentIntentsTable.unreadyPaymentsBanner.title',
                      {
                        numberOfPaymentIntents: numberOfUnreadyPaymentIntents,
                      }
                    )}
                    description={formatMessage(
                      'activities.batchPayments.screens.paymentIntentsTable.unreadyPaymentsBanner.description',
                      { numberOfPaymentIntents: numberOfUnreadyPaymentIntents }
                    )}
                    data-testid="confirm-warning-banner"
                  />
                )}
                {shouldDisplayVendorDetailsFailureBanner && (
                  <SectionBanner
                    data-testid="mcc-selection-modal-section-banner-error"
                    variant="critical"
                    title={formatMessage(
                      'activities.fundingSourceSelection.screens.fundingSourceSelection.mccSelectionFailedLoading.title'
                    )}
                    description={formatMessage(
                      'activities.fundingSourceSelection.screens.fundingSourceSelection.mccSelectionFailedLoading.description'
                    )}
                  />
                )}
                {!!missingVendorDirectoryDetailsBannerProps && (
                  <MissingVendorInfoBannerWidget
                    onEditClick={missingVendorDirectoryDetailsBannerProps.onEditClick}
                    vendorName={missingVendorDirectoryDetailsBannerProps.vendorName}
                    variant={shouldDisplayStatus ? 'critical' : 'warning'}
                  />
                )}
              </Group>
            </Container>
          )}
          <SystemMessageDisplay data-testid="payment-intents-table-notification" />
          <Container>
            <Group variant="vertical" data-testid="layout-content" spacing="l">
              {hasEbills && (
                <SectionBanner
                  data-testid="payment-intents-table-screen-ebills-section-banner"
                  description={formatMessage(
                    'activities.batchPayments.screens.paymentIntentsTable.ebills.section.description'
                  )}
                  variant="informative"
                />
              )}
              <Card paddingX="none" paddingY="none">
                <PaymentIntentsTable
                  isToggling={isToggling}
                  paymentIntentsWithDerivatives={paymentIntentsWithDerivatives}
                  fundingSources={fundingSources}
                  orgBillingFeeSettings={orgBillingFeeSettings}
                  arePaymentsCombined={arePaymentsCombined}
                  onToggleCombinedPayments={onToggleCombinedPayments}
                  onOpenVendorDetailsModal={onOpenVendorDetailsModal}
                  onOpenReconciliationModal={handleOpenReconciliationModal}
                  onOpenPaymentPurposeModal={handleOpenPaymentPurposeModal}
                  onOpenInvoiceAttachmentModal={handleOpenInvoiceAttachmentModal}
                  onViewBillDetailsClick={handleViewBillDetailsClick}
                  onAddMemoToVendorClick={handleAddMemoToVendorClick}
                  onAddPaymentNoteClick={onAddPaymentNoteClick}
                  onEditAmountClick={handleEditAmountClick}
                  onSetInvoiceNumberClick={handleSetInvoiceNumberClick}
                  onRemoveBillsClick={handleRemoveBillsClick}
                  onAddFundingSourceClick={handleAddFundingSourceClick}
                  onAddDeliveryMethodClick={handleAddDeliveryMethodClick}
                  onUpdateSingleDeliveryPreferenceType={handleUpdateSingleDeliveryPreferenceType}
                  onUpdateSingleFundingSource={handleUpdateSingleFundingSource}
                  onUpdateSingleDeliveryMethod={handleUpdateSingleDeliveryMethod}
                  onUpdateSingleScheduledDate={handleUpdateSingleScheduledDate}
                  onUpdateSingleDeliveryDate={handleUpdateSingleDeliveryDate}
                  loadingRowIds={loadingRowIds}
                  shouldDisplayStatus={shouldDisplayStatus}
                  onUpdateAllFundingSources={handleUpdateAllFundingSources}
                  onUpdateAllDeductionDatesToTheSameDate={handleUpdateAllDeductionDatesToTheSameDate}
                  onUpdateAllDeductionDatesToTheEarliestDate={handleUpdateAllDeductionDatesToTheEarliestDate}
                  onUpdateAllDeductionDatesToArriveByDueDate={handleUpdateAllDeductionDatesToArriveByDueDate}
                  onUpdateAllDeliveryDatesToTheDueDate={handleUpdateAllDeliveryDatesToArriveByDueDate}
                  isByDueDate={isByDueDate}
                  onRowExpand={handleRowExpand}
                  selectedDeliveryDateHeaderCellOption={selectedDeliveryDateHeaderCellOption}
                />
              </Card>
            </Group>
          </Container>
        </Group>
      </Layout>
    );
  }
);

PaymentIntentsTableScreen.displayName = 'BatchPaymentsActivity.PaymentIntentsTableScreen';
