import { useRecoilValue } from 'recoil';
import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useSystemMessage } from '@melio/platform-utils';
import { AddDeliveryMethodByPayeeActivity, AddDeliveryMethodByUnilateralActivity } from '@melio/vex-activities';

import { WithLoading } from '@/hoc/withLoading.hoc';
import {
  vendorActionAccountEmailSelector,
  vendorActionAccountNameSelector,
  vendorActionPaymentIdSelector,
  vendorActionUnilateralRequestIdSelector,
  vendorActionVendorIdSelector,
} from '@/store/VendorActions/VendorAction.model';
import { usePlatformIntl } from '@/translations/Intl';

export const UnilateralScreen = withAnalyticsContext(() => {
  const { formatMessage } = usePlatformIntl();
  const errorMsg = formatMessage('app.accept.error');
  const paymentId = useRecoilValue(vendorActionPaymentIdSelector);
  const vendorId = useRecoilValue(vendorActionVendorIdSelector);
  const accountName = useRecoilValue(vendorActionAccountNameSelector);
  const accountEmail = useRecoilValue(vendorActionAccountEmailSelector);
  const unilateralRequestId = useRecoilValue(vendorActionUnilateralRequestIdSelector);
  const { showMessage } = useSystemMessage();
  useAnalyticsContext({
    globalProperties: {
      paymentId,
      vendorId,
      flow: 'Unilateral',
    },
  });

  const renderActivity = () => {
    if (paymentId) {
      return (
        <AddDeliveryMethodByPayeeActivity
          paymentId={paymentId}
          accountName={accountName}
          accountEmail={accountEmail}
          onDone={() => {}}
          onError={() =>
            showMessage({
              type: 'error',
              title: errorMsg,
            })
          }
        />
      );
    }

    return (
      <AddDeliveryMethodByUnilateralActivity
        vendorId={vendorId}
        accountName={accountName}
        accountEmail={accountEmail}
        unilateralRequestId={unilateralRequestId}
        onDone={() => {}}
        onError={() =>
          showMessage({
            type: 'error',
            title: errorMsg,
          })
        }
      />
    );
  };

  return (
    <WithLoading isLoading={!accountName} isAbsoluteCenter={true}>
      {renderActivity()}
    </WithLoading>
  );
});

UnilateralScreen.displayName = 'UnilateralScreen';
