import React from 'react';
import { MicroDepositModalRefHandlers, MicroDepositModalWrapper } from '@melio/ap-activities';
import { useFundingSourcePermissions } from '@melio/ap-domain';
import { NakedButton, SectionBanner } from '@melio/penny';
import {
  BankAccount,
  DeliveryMethodType,
  FundingSourceType,
  useIsPlaidLinkVerificationTokenFetching,
} from '@melio/platform-api';

import { PaymentFullyExpanded } from '@/api/payments/payments.api.types';
import { usePlatformIntl } from '@/translations/Intl';

export const PaymentScheduledActionAlert = ({ payment }: { payment: PaymentFullyExpanded }) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const { fundingSource, deliveryMethod, vendor } = payment;
  const microDepositRef = React.useRef<MicroDepositModalRefHandlers>(null);
  const isVerifyingBankAccount = useIsPlaidLinkVerificationTokenFetching(payment.fundingSource?.id);

  const onClick = () => microDepositRef.current?.openModal();

  let alertDescription = '';
  let alertLink;
  if (deliveryMethod.type === DeliveryMethodType.VirtualAccount) {
    alertDescription = formatMessage('widgets.paymentDetails.scheduled.actionRequired.unilateral', {
      vendorName: vendor.name,
      date: formatDate(new Date(payment.scheduledDate), {
        dateStyle: 'medium',
      }),
    });
  }

  const { canUpdate } = useFundingSourcePermissions({ fundingSource });

  // payment can't have virtual deliveryMethod and unverified paymentMethod at once (risk wise)
  if (canUpdate && fundingSource.type === FundingSourceType.BankAccount && !fundingSource.isVerified) {
    alertDescription = formatMessage('widgets.paymentDetails.scheduled.actionRequired.verifyBankAccount.text', {
      '4digits': (fundingSource.details as BankAccount).accountNumber.slice(-4),
      date: formatDate(new Date(payment.scheduledDate), {
        month: 'short',
        day: 'numeric',
      }),
    });
    alertLink = (
      <NakedButton
        variant="secondary"
        onClick={onClick}
        label={formatMessage('widgets.paymentDetails.scheduled.actionRequired.verifyBankAccount.link')}
        isDisabled={isVerifyingBankAccount}
      />
    );
  }

  return alertDescription ? (
    <>
      <SectionBanner
        variant="warning"
        description={alertDescription}
        title={formatMessage('widgets.paymentDetails.scheduled.actionRequired.title')}
        action={alertLink}
      />
      {fundingSource.type === FundingSourceType.BankAccount && (
        <MicroDepositModalWrapper ref={microDepositRef} fundingSourceId={payment.fundingSourceId} />
      )}
    </>
  ) : null;
};
