import { useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import { throttle } from 'lodash';
import { useRecoilState } from 'recoil';
import { Container, IconButton, Text } from '@melio/penny';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { appAccountUrlSelector } from '@/store/app/app.model';
import { DemoLoginAccountUrlTheme } from './DemoLoginAccountUrl.component.theme';

export const DemoLoginAccountUrl = () => {
  const styles = useAppTheme(DemoLoginAccountUrlTheme, {});

  const [accountUrl, setAccountUrl] = useRecoilState(appAccountUrlSelector);

  const copyToClipboard = throttle(async () => accountUrl && navigator.clipboard.writeText(accountUrl), 2000);

  const onClose = useCallback(() => {
    setAccountUrl(undefined);
  }, [setAccountUrl]);

  return accountUrl ? (
    <Box sx={styles['container']} boxShadow="300">
      <Box sx={styles['closeContainer']}>
        <IconButton aria-label="close" size="small" icon="close" variant="naked" onClick={onClose} />
      </Box>
      <Box>
        <Text textStyle="body3Semi">Copy your account login URL and bookmark it for next time!</Text>
      </Box>
      <Box mt={2} display={'flex'} alignItems="center">
        <Container paddingRight="xxs">
          <Text textStyle="body3Semi" color="global.neutral.900">
            {accountUrl}
          </Text>
        </Container>
        <IconButton aria-label={'copy'} size={'small'} variant={'naked'} icon="duplicate" onClick={copyToClipboard} />
      </Box>
    </Box>
  ) : null;
};
