import {
  isPayorPaymentFee,
  sumPaymentFees,
  useGuestPayorPayment,
  useGuestPayorPaymentRequestDetails,
} from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { GuestPaymentLayout } from '../../layout';
import { GuestPaymentConfirmationScreen } from './screens';

export type GuestPaymentConfirmationActivityProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
  paymentId: string;
  onError?: ARErrorFunction;
};

export const GuestPaymentConfirmationActivity = withAnalyticsContext<GuestPaymentConfirmationActivityProps>(
  forwardRef(({ setAnalyticsProperties, paymentRequestLink, onViewInvoice, paymentId, onError }, ref) => {
    const {
      data: paymentRequestDetails,
      isLoading: isLoadingPaymentRequestDetails,
      error: paymentRequestDetailsError,
    } = useGuestPayorPaymentRequestDetails({
      paymentRequestLink,
    });
    const {
      data: paymentData,
      isLoading: isLoadingPayment,
      error: paymentError,
    } = useGuestPayorPayment({ id: paymentId });

    const { track } = useAnalytics();

    const handleOnViewInvoice = () => {
      track('PaymentRequest', 'Click', {
        PageName: 'invoice-paid',
        Intent: 'view-invoice-file',
        Cta: 'view',
      });
      onViewInvoice();
    };

    setAnalyticsProperties({
      ProductName: 'ar',
      Flow: 'payment-request',
      PaymentRequestLink: paymentRequestLink,
      PaymentId: paymentId,
    });

    if (
      isLoadingPaymentRequestDetails ||
      isLoadingPayment ||
      !paymentRequestDetails ||
      !paymentRequestDetails.receivablePaymentDetails
    ) {
      return <GuestPaymentLayout isLoading data-component={GuestPaymentConfirmationActivity.displayName} ref={ref} />;
    }

    if (paymentRequestDetailsError || !paymentData || paymentError) {
      onError?.(paymentRequestDetailsError ?? paymentError ?? { code: 'UNKNOWN_ERROR', message: 'Unknown error' });
      return null;
    }

    const payorFees = paymentData.fees?.filter(isPayorPaymentFee) ?? [];
    const totalFeesAmount = sumPaymentFees(payorFees);

    return (
      <GuestPaymentConfirmationScreen
        ref={ref}
        amount={paymentRequestDetails.invoice.totalAmount}
        invoicePreviewUrl={paymentRequestDetails.invoice.fileInfo?.previewUrls[0]}
        onViewInvoice={handleOnViewInvoice}
        debitDate={paymentData.scheduledDate}
        deliveryDate={paymentData.estimatedDelivery}
        totalFeeAmount={totalFeesAmount}
        invoiceNumber={paymentRequestDetails.invoice.invoiceNumber}
        vendorName={paymentRequestDetails.payeeDetails.companyName}
        fundingSourceDisplayData={paymentData.fundingSource?.displayName as string}
      />
    );
  })
);

GuestPaymentConfirmationActivity.displayName = 'GuestPaymentRequestConfirmationActivity';
