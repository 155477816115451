import React, { useCallback } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { LocalStorageKeys } from '@melio/local-storage';

import { usePartnerLocalStorage } from '@/hooks/partners/usePartnerLocalStorage';
import { useRouter } from '@/hooks/router.hooks';
import { useIsMissingKycComplianceInformation } from '@/hooks/useIsMissingKycComplianceInformation';
import { CompleteMtlDetailsScreen } from '@/screens/CompleteMtlDetails.screen';

export const CompleteDetailsRoute = () => {
  const localStorage = usePartnerLocalStorage();
  const accessToken = localStorage.getItem(LocalStorageKeys.accessToken);

  const { goHome, generateGetPaidDashboardLink } = useRouter();
  const { search } = useLocation();
  const redirect = new URLSearchParams(search).get('redirect');

  const { onBlockerScreenUserInteraction } = useIsMissingKycComplianceInformation();

  const handleClose = useCallback(() => {
    onBlockerScreenUserInteraction();
    try {
      if (!accessToken) {
        return goHome();
      }

      if (redirect === 'get-paid/dashboard') {
        window.location.href = generateGetPaidDashboardLink(accessToken)!;
        return;
      }
    } catch (e) {
      console.error('Failed to redirect user to requested URL', e);
    }

    return goHome();
  }, [accessToken, generateGetPaidDashboardLink, goHome, onBlockerScreenUserInteraction, redirect]);

  const isTriggerManually = !redirect;

  return (
    <Routes>
      <Route
        path="*"
        element={<CompleteMtlDetailsScreen onDone={handleClose} isTriggerManually={isTriggerManually} />}
      />
    </Routes>
  );
};
