/* eslint-disable no-restricted-syntax */
import { Modal, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { usePaymentsReport } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { saveAs } from 'file-saver';
import { useEffect } from 'react';

import { DAY, ExportFileFormWidgetFields, ExportFormat } from './createReport.types';
import { CreateReportFormatWidget } from './CreateReportFormat.widget';

export type CreateReportModalProps = {
  isOpen: boolean;
  onClose: VoidFunction;
  analytics?: { name: string; properties: Record<string, string> };
};

export const CreateReportModal = withAnalyticsContext<CreateReportModalProps>(
  ({ isOpen, onClose, analytics, setAnalyticsProperties }) => {
    const [includeMultipleFormats] = useFeature(FeatureFlags.ExportPaymentsMultipleFormats, false);
    const { formatMessage } = useMelioIntl();

    const title = formatMessage('widgets.createReportModal.title');
    const today = new Date();
    const defaultFromDate = new Date(today.getTime() - 7 * DAY);
    const { createReport: createPaymentsReport } = usePaymentsReport();
    const { onSubmissionStateChange, submitButtonProps } = useFormSubmissionController<ExportFileFormWidgetFields>();
    const { track } = useAnalytics();
    setAnalyticsProperties({
      PageName: 'download-payment-report',
      Intent: 'download-report',
      ...analytics?.properties,
    });

    useEffect(() => {
      if (isOpen && analytics?.name) {
        track(analytics.name, 'View');
      }
    }, [isOpen, analytics, track]);

    // str of format: "attachment; filename="...""
    const parseContentDisposition = (str: string, suffix: string) => {
      if (str.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*\2?|[^;\n]*)/;
        const matches = filenameRegex.exec(str);
        if (matches != null && matches.length > 1 && matches[1]) {
          return matches[1].replace(/['"]/g, '');
        }
      }

      return `paymentsReport.${suffix}`;
    };

    const convertDateToStartDay = (date: Date) => new Date(date.setHours(0, 0, 0, 0));

    const convertDateToEndDay = (date: Date) => new Date(date.setHours(24, 0, 0, 0));

    //TODO: ME-28144-add-test-to-check-the-payments-report-content
    const onClickButton = async ({
      fileFormatRadioValue,
      fromDate,
      toDate,
      includePayments,
    }: ExportFileFormWidgetFields) => {
      if (analytics?.name) {
        track(analytics?.name, 'Click', {
          IncludeSchedulePayments: includePayments,
          Cta: 'create-report',
        });
      }
      const data = {
        startDate: convertDateToStartDay(fromDate),
        endDate: convertDateToEndDay(toDate),
        includeScheduledPayments: includePayments,
        ...(includeMultipleFormats && { fileFormat: fileFormatRadioValue }),
      };
      onClose();
      const res = await createPaymentsReport(data);
      const blob = new Blob([res.data], { type: 'text/csv' });
      const filename = parseContentDisposition(
        res.headers['content-disposition'] || '',
        fileFormatRadioValue.toLocaleLowerCase()
      );
      saveAs(blob, filename);
    };

    const handleClose = (analyticsProps?: Record<string, string>) => {
      if (analytics?.name) {
        track(analytics.name, 'Click', analyticsProps);
      }
      onClose();
    };

    return (
      <Modal
        header={title}
        isOpen={isOpen}
        onClose={() => handleClose({ Cta: 'exit' })}
        primaryButton={{
          label: formatMessage('widgets.createReportModal.form.submitText'),
          variant: 'primary',
          ...submitButtonProps,
        }}
        secondaryButton={{
          label: formatMessage('widgets.createReportModal.form.cancelText'),
          variant: 'tertiary',
          onClick: () => handleClose({ Cta: 'cancel' }),
        }}
        data-testid="create-report-modal"
      >
        <CreateReportFormatWidget
          onSubmit={onClickButton}
          onSubmissionStateChange={onSubmissionStateChange}
          defaultValues={{
            fileFormatRadioValue: ExportFormat.CSV,
            fromDate: defaultFromDate,
            toDate: today,
            includePayments: false,
          }}
          includeMultipleFormats={includeMultipleFormats}
        />
      </Modal>
    );
  }
);
