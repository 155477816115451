import { useSupportedFXData } from '@melio/ap-domain';
import { CountryCode, Currency } from '@melio/international-payments-utils';
import { Form, Group, Text, Tooltip, UseMelioFormResults, useWatch } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

import { useRequireFields } from '../useRequireFields';
import { getRequireFieldsForBankDetails, useCurrenciesOptions, useGetCountryOption } from './fxBankDetailsForm.utils';
import { RequiredFieldsComponent } from './RequiredFieldsComponent';
import { FxVendorAccountFieldsFormFields } from './types';

export type InternationalRequireFieldsFormProps = {
  form: UseMelioFormResults<FxVendorAccountFieldsFormFields>;
  defaultValues?: Partial<FxVendorAccountFieldsFormFields>;
};

export const FxBankDetailsForm = ({ form, defaultValues }: InternationalRequireFieldsFormProps) => {
  const { formatMessage } = useMelioIntl();

  const { formProps, registerField, control, setValue } = form;

  const [foreignCurrency, fxCountrySelection] = useWatch({ control, name: ['foreignCurrency', 'fxCountrySelection'] });
  const { requiredFields } = useRequireFields({ currency: foreignCurrency });
  const { track } = useAnalytics();
  const { countries, currencies, isLoading } = useSupportedFXData({
    currency: foreignCurrency,
    countryCode: fxCountrySelection,
  });
  const currenciesOptions = useCurrenciesOptions(currencies);
  const countriesOptions = useGetCountryOption(countries);
  const getKeysList = (list: { label: string }[]) => list.map((x) => x.label);

  const isCountryDisabled = !!defaultValues?.fxCountrySelection;
  const isCurrencyDisabled = !!defaultValues?.foreignCurrency;

  const onFxCountrySelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    track('DeliveryMethod', 'Click', {
      PageName: 'add-international-account-details',
      Intent: 'set-vendor-country',
      Cta: e.target.value,
      Flow: 'add-vendor',
      CountriesShown: getKeysList(countriesOptions),
    });
    setValue('fxCountrySelection', e.target.value as CountryCode);
  };

  const onForeignCurrencySelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    track('DeliveryMethod', 'Click', {
      PageName: 'add-international-account-details',
      Intent: 'set-vendor-currency',
      Cta: e.target.value,
      Flow: 'add-vendor',
      CurrenciesShown: getKeysList(currenciesOptions),
    });
    // Reset previous currency required fields
    getRequireFieldsForBankDetails(requiredFields)?.fields.map((field) => setValue(field.key, ''));
    setValue('foreignCurrency', e.target.value as Currency);
  };

  return (
    <Group variant="vertical" width="full" spacing="m">
      <Text textStyle="body4Semi" color="global.neutral.900">
        {formatMessage('activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.title')}
      </Text>
      <Form {...formProps}>
        <Tooltip
          placement="top-end"
          isEnabled={isCountryDisabled}
          content={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCountrySelection.tooltip'
          )}
        >
          <Form.SelectNew
            options={countriesOptions}
            isLoading={isLoading}
            {...registerField('fxCountrySelection')}
            labelProps={{
              label: formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCountrySelection.label'
              ),
            }}
            isDisabled={isCountryDisabled}
            placeholder={formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCountrySelection.placeHolder'
            )}
            onClick={() => {
              track('DeliveryMethod', 'View', {
                PageName: 'add-international-account-details',
                Intent: 'set-vendor-currency',
                Flow: 'add-vendor',
                CountriesShown: getKeysList(countriesOptions),
              });
            }}
            onChange={onFxCountrySelectionChange}
          />
        </Tooltip>
        <Tooltip
          placement="top-end"
          isEnabled={isCurrencyDisabled}
          content={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.fxCurrencySelection.tooltip'
          )}
        >
          <Form.SelectNew
            options={currenciesOptions}
            isLoading={isLoading}
            {...registerField('foreignCurrency')}
            onChange={onForeignCurrencySelectionChange}
            labelProps={{
              label: formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.foreignCurrency.label'
              ),
            }}
            isDisabled={isCurrencyDisabled}
            placeholder={formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBankDetailsForm.foreignCurrency.placeHolder'
            )}
            onClick={() => {
              track('DeliveryMethod', 'View', {
                PageName: 'add-international-account-details',
                Intent: 'set-vendor-currency',
                Flow: 'add-vendor',
                CurrenciesShown: getKeysList(currenciesOptions),
              });
            }}
          />
        </Tooltip>
        <RequiredFieldsComponent requiredFields={requiredFields} form={form} />
      </Form>
    </Group>
  );
};
