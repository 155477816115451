import { useUserPreference, useUserPreferences } from '@melio/platform-api';
import { usePartnerFeature } from '@melio/platform-provider';
import { useDateUtils } from '@melio/platform-utils';
import { createContext, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { PayDashboardSortingType } from './types';

const Context = createContext<PayDashboardSortingType | null>(null);
export const usePayDashboardSorting = () => useContext(Context);
const userPreferencePrefix = 'PayDashboardTableSort-';

export const PayDashboardSortingProvider = ({
  children,
  defaultSort,
  tableId,
}: {
  children: React.ReactNode;
  defaultSort?: PayDashboardSortingType['sortingState'];
  tableId?: string;
}) => {
  const [sortedColumn, sortColumn] = useState<PayDashboardSortingType['sortingState']>(defaultSort);
  const userPreferenceKey = useMemo(() => (tableId ? `${userPreferencePrefix}${tableId}` : undefined), [tableId]);
  const { create } = useUserPreferences({ enabled: false });
  const { data, isLoading } = useUserPreference({ id: userPreferenceKey });
  const { createDate } = useDateUtils();
  const [isPayDashboardSaveSortEnabled] = usePartnerFeature('PayDashboardSaveSort', false);

  const isValidSort = useCallback(
    (sort: PayDashboardSortingType['sortingState'] & { lastSorted: string }) => {
      const now = createDate();
      const time_3_month_ago = now.setMonth(now.getMonth() - 3);

      return (
        sort.id &&
        sort.sortDirection &&
        sort.lastSorted &&
        createDate(sort.lastSorted).getTime() > time_3_month_ago &&
        (sort.sortDirection === 'asc' || sort.sortDirection === 'desc')
      );
    },
    [createDate]
  );

  useEffect(() => {
    if (isPayDashboardSaveSortEnabled && !isLoading && data?.value) {
      try {
        const sort = JSON.parse(data.value) as PayDashboardSortingType['sortingState'] & { lastSorted: string };
        if (isValidSort(sort)) {
          sortColumn(sort);
        }
      } catch (e) {
        //todo add analytics?
      }
    }
  }, [data, isLoading, isValidSort, tableId, isPayDashboardSaveSortEnabled]);

  const setSortState = (sort: SetStateAction<PayDashboardSortingType['sortingState'] | undefined>) => {
    sortColumn(sort);
    if (
      isPayDashboardSaveSortEnabled &&
      tableId &&
      sort &&
      userPreferenceKey &&
      !(sort as PayDashboardSortingType['sortingState'])?.disableSaveforNextTime
    ) {
      create({
        userPreference: {
          key: userPreferenceKey,
          value: JSON.stringify({ ...sort, lastSorted: createDate().toISOString() }),
        },
      });
    }
  };

  return <Context.Provider value={{ setSortState, sortingState: sortedColumn }}>{children}</Context.Provider>;
};
