import { useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { useEffect } from 'react';

import { GuestPaymentLayout } from '../../layout';
import { GuestPayorPaymentOptions } from '../../types';

type PaymentRoutingActivityProps = {
  paymentRequestLink: string;
  onDone: (next: GuestPayorPaymentOptions) => unknown;
  onError?: ARErrorFunction;
};

export const PaymentRoutingActivity = ({ paymentRequestLink, onDone, onError }: PaymentRoutingActivityProps) => {
  const { data, error, isLoading } = useGuestPayorPaymentRequestDetails({ paymentRequestLink });

  useEffect(() => {
    if (data) {
      if (data.invoice.paymentOptions.isCardAllowed) {
        onDone('card');
      } else if (data.invoice.paymentOptions.isAchAllowed) {
        onDone('bank');
      } else {
        onDone('custom');
      }
    } else if (error) {
      onError?.(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);

  return <GuestPaymentLayout isLoading={isLoading} />;
};
