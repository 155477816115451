import { ServiceChargeCatalog } from '@melio/ar-domain';
import { Group } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { ServiceChargesEmptyState, ServiceChargesTable, ServiceChargesTableToolbar } from '../components';

export type ServiceChargesTableScreenProps = {
  isLoading: boolean;
  onAdd: VoidFunction;
  serviceCharges: ServiceChargeCatalog[];
  onEdit: (serviceChargeId: string) => unknown;
  onDelete: (serviceChargeId: string) => unknown;
  searchTerm?: string;
  setSearchTerm: (value: string) => unknown;
};

export const ServiceChargesTableScreen = forwardRef<ServiceChargesTableScreenProps>(
  ({ searchTerm, setSearchTerm, serviceCharges, onEdit, onDelete, isLoading, onAdd, ...props }, ref) => (
    <Group variant="vertical" {...props} ref={ref}>
      <ServiceChargesTableToolbar onAdd={onAdd} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <ServiceChargesTable onEdit={onEdit} onDelete={onDelete} serviceCharges={serviceCharges} isLoading={isLoading} />
      {!isLoading && serviceCharges.length === 0 && <ServiceChargesEmptyState setSearchTerm={setSearchTerm} />}
    </Group>
  )
);
