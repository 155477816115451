import { useMelioIntl } from '@melio/platform-i18n';

import { DeliveryMethodDetailsDiff } from '../types';

export const useHeaderText = (diff?: DeliveryMethodDetailsDiff, vendorName?: string) => {
  const { formatMessage } = useMelioIntl();

  if (!diff || !vendorName) {
    return '';
  }

  const { deliveryMethod } = diff;

  if (deliveryMethod.old.details.deliveryType === 'check' && deliveryMethod.new.details.deliveryType === 'ach') {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.checkToAch', {
      vendorName,
    });
  }

  if (deliveryMethod.old.details.deliveryType === 'ach' && deliveryMethod.new.details.deliveryType === 'check') {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.achToCheck', {
      vendorName,
    });
  }

  return '';
};
