import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

export const CrownIcon = (props: IconProps) => (
  <Icon width="12" height="12" viewBox="0 0 48 48" fill="none" {...props}>
    <svg
      width="48px"
      height="48px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-component="Box"
      className="css-0"
    >
      <path
        d="m10.227 11.088 1.203-3.64c.197-.597.943-.597 1.14 0l1.203 3.64 2.279-1.724c.474-.359 1.085.154.92.774l-1.42 5.37c-.076.292-.313.492-.582.492H9.03c-.269 0-.506-.2-.583-.492l-1.42-5.37c-.164-.62.447-1.133.921-.774l2.279 1.724Z"
        fill="#7849ff"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Zm11-9a9 9 0 1 0 0 18 9 9 0 0 0 0-18Z"
        fill="#7849ff"
      ></path>
    </svg>
  </Icon>
);
