// eslint-disable-next-line import/no-deprecated
import { useToast } from '@melio/penny';
import {
  NavigateOptions as _NavigateOptions,
  useMatch,
  useNavigate as reactRouterUseNavigate,
  useResolvedPath,
  // eslint-disable-next-line no-restricted-imports
  useSearchParams as reactRouterUseSearchParams,
} from 'react-router-dom';

import { useSystemMessage } from './system-message';
import { compileUrlTemplate } from './url-templte';

/**
 * extends react-router-dom's useNavigate hook to support query params, and callback function return
 * @returns a navigation function or a callback navigation function
 */
export const useNavigate = <TRoute extends string = string, TState = never>({
  withSearchparams,
  closeToastOnNavigate = true,
  __useNavigate = reactRouterUseNavigate,
  __useSearchParams = reactRouterUseSearchParams,
}: UseNavigateOptions<TRoute, TState> = {}) => {
  const _navigate = __useNavigate();

  const [globalSearchParams] = __useSearchParams();

  // eslint-disable-next-line import/no-deprecated
  const { closeToast } = useToast();
  const { cleanMessage } = useSystemMessage();

  /**
   * Navigate to `path`
   * @param path Path to navigate to
   * @param options NavigateOptions
   * @param options.withSearchparams If true, appends search params to the path (default: false)
   */
  function navigate<T extends TRoute>(path: T, _options?: NavigateOptions<T, TState>): void {
    const {
      historyBack,
      pathParams,
      withSearchparams: localWithSearchparams,
      queryParams: localQueryParams,
      pathPrefix = [],
      ...options
    } = _options ?? {};
    if (historyBack && history.length > 1) {
      return history.back();
    }

    const [_pathname = '', pathQueryString = ''] = path.split('?');

    const pathname = compileUrlTemplate(_pathname)(pathParams);

    const target = new URLSearchParams();

    if (localWithSearchparams ?? withSearchparams) {
      for (const [key, value] of globalSearchParams.entries()) {
        target.set(key, value);
      }
    }

    for (const [key, value] of new URLSearchParams(pathQueryString).entries()) {
      target.set(key, value);
    }

    if (localQueryParams) {
      Object.entries(localQueryParams).forEach(([key, value]) => {
        target.set(key, value);
      });
    }

    for (const [key, value] of target.entries()) {
      if (!value) {
        target.delete(key);
      }
    }

    if (!options.keepSystemMessage) {
      cleanMessage();
    }

    if (options.closeToast ?? closeToastOnNavigate) {
      closeToast();
    }

    const prefix = Array.isArray(pathPrefix) ? pathPrefix : [pathPrefix];
    return _navigate({ pathname: [...prefix, pathname].join('/'), search: target.toString() }, options);
  }

  return navigate;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export type NavigateOptions<TRoute extends string = string, TState = never> = Override<
  _NavigateOptions,
  {
    withSearchparams?: boolean;
    historyBack?: boolean;
    pathParams?: { [K in RouteTokens<TRoute>]: unknown };
    queryParams?: Record<string, string>;
    state?: TState;
    closeToast?: boolean;
    keepSystemMessage?: boolean;
    pathPrefix?: TRoute | TRoute[];
  }
>;

export type UseNavigateOptions<TRoute extends string = string, TState = never> = Pick<
  NavigateOptions<TRoute, TState>,
  'withSearchparams' | 'state'
> & {
  __useNavigate?: typeof reactRouterUseNavigate;
  __useSearchParams?: typeof reactRouterUseSearchParams;
  closeToastOnNavigate?: boolean;
  keepSystemMessageOnNavigate?: boolean;
};

export function useFlowRouting<TRoute extends string = string, TState = never>(
  props?: UseNavigateOptions<TRoute, TState>
) {
  const navigate = useNavigate<TRoute, TState>(props);

  type Options = NavigateOptions<TRoute, TState>;

  const createCallback = (path: TRoute, defaultOptions?: Options) => (options?: Options) =>
    navigate(path, { ...defaultOptions, ...options });

  const createCallbackWithParam =
    <T extends TRoute>(path: T, paramName: RouteTokens<T>, defaultOptions?: Options) =>
    (value: string, options?: Options) =>
      navigate(path, { ...defaultOptions, ...options, pathParams: { [paramName]: value } as never });

  return {
    navigate,
    createCallback,
    createCallbackWithParam,
  };
}

export function useCurrentRoute<TRoute extends Record<string, string>>(paths: TRoute) {
  const resolvedPathUrl = useResolvedPath('');
  const _currentRoute = useMatch({ path: `${resolvedPathUrl.pathname}/*`, end: true })?.params['*'];

  const currentRoute = Object.entries(paths).reduce((curr, [key, path]) => {
    if (!!_currentRoute && _currentRoute.endsWith(path)) return key as keyof typeof paths;
    return curr;
  }, 'Form' as keyof typeof paths);

  const isOnRoute = (route: keyof TRoute) => currentRoute === route;

  return {
    currentRoute,
    isOnRoute,
  };
}
type RouteTokens<TRoute extends string> = {
  [K in TRoute]: string extends K
    ? never
    : K extends `${string}:${infer Param}?/${infer Rest}`
    ? Param | RouteTokens<Rest>
    : K extends `${string}:${infer Param}/${infer Rest}`
    ? Param | RouteTokens<Rest>
    : K extends `${string}:${infer Param}?`
    ? Param
    : K extends `${string}:${infer Param}`
    ? Param
    : never;
}[TRoute];
