import React, { ComponentProps } from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { Group, Image, Link, Text } from '@melio/penny';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { usePartnerConfig } from '@/hooks/partners';
import { usePlatformIntl } from '@/translations/Intl';
import { FooterLayoutTheme } from './footerLayout.theme';

export const FooterLayout = () => {
  const style = useAppTheme(FooterLayoutTheme, {});
  const {
    partnerConfig: {
      icons,
      config: { links },
      partnerProductName,
    },
  } = usePartnerConfig();
  const { formatMessage } = usePlatformIntl();
  const footerDirection = useBreakpointValue<ComponentProps<typeof Group>['variant']>(
    { xs: 'vertical', s: 'horizontal' },
    {
      ssr: false,
    } as never,
  );
  const footerGap = useBreakpointValue<ComponentProps<typeof Group>['spacing']>({ xs: 'none', s: 'xs' }, {
    ssr: false,
  } as never);

  const getLink = ({ href, text }: { href: string; text: string }) => (
    <Box css={style['link']}>
      <Link href={href} label={text} newTab />
    </Box>
  );
  return (
    <Box data-testid="footer-container" __css={style['footer']}>
      <Image width="auto" height="auto" src={icons.MelioIcon.small} alt="icon" css={style['melioLogo']} />
      <Group variant={footerDirection} spacing={footerGap} alignItems={{ xs: 'flex-start', s: 'center' } as never}>
        <Text as="h2" textStyle="caption1Semi" color="global.neutral.900">
          {formatMessage('widgets.footer.info', { partnerProductName })}
        </Text>
        <Group>
          {getLink({
            href: links['widgets.footer.termsOfServiceLink.href'],
            text: formatMessage('widgets.footer.termsOfServiceLink.text'),
          })}
          {getLink({
            href: links['widgets.footer.privacyPolicy.href'],
            text: formatMessage('widgets.footer.privacyPolicy.text'),
          })}
        </Group>
      </Group>
    </Box>
  );
};
