import { useCallback } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { Icon, IconButton, Text, useTheme } from '@melio/penny';
import { useAccount } from '@melio/platform-api';

import { LabelCopy } from '@/cl/components/LabelCopy/LabelCopy.component';
import { useCopyEmailAnalytics } from '@/hooks/analytics/copyEmailAnalytics';
import { usePreferences } from '@/hooks/preferences.hooks';
import { usePlatformIntl } from '@/translations/Intl';

export const BillsInboxMainPagePromo = () => {
  const theme = useTheme();
  const { data } = useAccount({ id: 'me' });
  const { inboxEmailAddress } = data?.company || {};
  const { orgPreferences, setOrgPreference } = usePreferences();
  const { formatMessage } = usePlatformIntl();
  const { trackCopyEmailCopy, trackCopyEmailClose, trackCopyEmailLink } = useCopyEmailAnalytics('PayDashboardInbox');
  const onCloseHandler = useCallback(() => {
    trackCopyEmailClose();
    return setOrgPreference({ key: 'billsInboxDismissedPromo', value: 'true' });
  }, [trackCopyEmailClose, setOrgPreference]);
  // Show if the feature is active and the org hasn't received an email or dismissed yet
  if (
    inboxEmailAddress &&
    orgPreferences &&
    orgPreferences.billsInboxReceivedMail !== 'true' &&
    orgPreferences.billsInboxDismissedPromo !== 'true'
  ) {
    return (
      <Stack
        flexDirection="column"
        p={'15px'}
        mb={'16px'}
        border="basic.lighter"
        background={theme.colors.global.neutral['100']}
        borderRadius={'l'}
        gridGap={'8px'}
        spacing={'0px'}
      >
        <Box display={'flex'} gridGap={'12px'} alignItems="center">
          <Icon type="email" size="small" color="inherit" />
          <Box flexGrow={1}>
            <Text textStyle="body2Semi">{formatMessage('widgets.billsInbox.mainPagePromo.title')}</Text>
          </Box>
          <IconButton
            icon="close"
            data-testid={'close-button'}
            aria-label={'close'}
            variant={'naked'}
            size={'small'}
            onClick={onCloseHandler}
          />
        </Box>
        <Text textStyle="body3" color="global.neutral.900">
          {formatMessage('widgets.billsInbox.mainPagePromo.description')}
        </Text>
        <LabelCopy
          testId="inbox-email-link"
          label={inboxEmailAddress}
          link={`mailto:${inboxEmailAddress}`}
          successMessage={formatMessage('widgets.billsInbox.successCopyToast')}
          errorMessage={formatMessage('widgets.billsInbox.errorCopyToast')}
          onCopy={trackCopyEmailCopy}
          onLinkClick={trackCopyEmailLink}
        />
      </Stack>
    );
  }
  return null;
};

BillsInboxMainPagePromo.displayName = 'BillsInboxMainPagePromo';
