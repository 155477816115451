import { getVendorDirectoryMissingFields } from '@melio/ap-domain';
import { Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { useMemo } from 'react';

export const useVendorDirectoryMissingFields = (vendor?: Vendor) => {
  const {
    settings: {
      vendor: { requiredDirectoryFields },
    },
  } = useConfig();

  const [isCollectMissingVendorInfoEnabled] = useFeature<boolean>(FeatureFlags.CollectMissingVendorInfoEnabled, false);

  return useMemo(
    () =>
      getVendorDirectoryMissingFields({
        vendor,
        isCollectMissingVendorInfoEnabled,
        requiredDirectoryFields,
      }),
    [isCollectMissingVendorInfoEnabled, requiredDirectoryFields, vendor]
  );
};
