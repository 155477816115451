import { ReceivablePaymentDetailsSourceEnum, useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { GuestPaymentLayout } from '../../layout';
import { InvoicePaidScreen } from './screens';

export type InvoicePaidActivityProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
  onError?: ARErrorFunction;
};

export const InvoicePaidActivity = withAnalyticsContext<InvoicePaidActivityProps>(
  forwardRef(({ setAnalyticsProperties, paymentRequestLink, onViewInvoice, onError }, ref) => {
    const { data: paymentRequestDetails, isLoading } = useGuestPayorPaymentRequestDetails({
      paymentRequestLink,
      onError,
    });
    setAnalyticsProperties({
      ProductName: 'ar',
      Flow: 'payment-request',
      PaymentRequestLink: paymentRequestLink,
      Intent: 'pay-invoice',
    });

    if (isLoading || !paymentRequestDetails || !paymentRequestDetails.receivablePaymentDetails) {
      return <GuestPaymentLayout isLoading data-component={InvoicePaidActivity.displayName} ref={ref} />;
    }

    const isInvoiceMarkedAsPaid =
      paymentRequestDetails.receivablePaymentDetails.source === ReceivablePaymentDetailsSourceEnum.Manual;

    return (
      <InvoicePaidScreen
        ref={ref}
        amount={paymentRequestDetails.invoice.totalAmount}
        invoicePreviewUrl={paymentRequestDetails.invoice.fileInfo?.previewUrls[0]}
        isMarkedAsPaid={isInvoiceMarkedAsPaid}
        onViewInvoice={onViewInvoice}
      />
    );
  })
);

InvoicePaidActivity.displayName = 'InvoicePaidActivity';
