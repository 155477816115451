import { useBreakpointValue } from '@chakra-ui/react';
import { Container, Group, GroupProps, Illustration } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { FormattedMessage } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { useCallback, useEffect } from 'react';

import { MonitoredAction } from '../../../../monitoring';
import { NewSinglePaymentStepLayout } from '../../../NewSinglePaymentStepLayout';
import { PaymentScheduledPromotions } from '../../../PaymentScheduled/screens/PaymentsScheduled/components/PaymentScheduledPromotions';
import { PaymentScheduledRiskDisclaimer } from '../../../PaymentScheduled/screens/PaymentsScheduled/components/RiskDisclaimer';
import { PaymentScheduledUpsells } from '../../../PaymentScheduled/screens/PaymentsScheduled/components/Upsells';
import { usePromotionsData } from '../../../promotions';
import { RecurringPaymentScheduledScreenProps } from './RecurringPaymentScheduled.types';
import {
  getBillSubscriptionSecondaryDescription,
  useBillSubscriptionDescription,
} from './RecurringPaymentScheduled.utils';
import { RecurringPaymentScheduledActionButtons } from './RecurringPaymentScheduledActionButtons';

export const RecurringPaymentScheduledScreen = withAnalyticsContext<RecurringPaymentScheduledScreenProps>(
  ({
    notifyVendor,
    subscriptionDetails,
    onClose,
    onDone,
    onNotifyVendors,
    isLoading,
    setAnalyticsProperties,
    ...props
  }) => {
    const description = useBillSubscriptionDescription(subscriptionDetails);
    const paymentsIds = subscriptionDetails.paymentIds || [];

    const {
      isLoading: isPromotionsLoading,
      analyticsData: promotionsAnalyticsData,
      activePromotion,
    } = usePromotionsData();

    const { trackMarketing } = useAnalytics();

    setAnalyticsProperties({
      BillSubscriptionId: subscriptionDetails.billSubscriptionId,
    });

    useAnalyticsView('PaymentScheduledSucceeded', !isPromotionsLoading, false, {
      PaymentId: paymentsIds,
      Feature: promotionsAnalyticsData,
    });

    const { endAction } = useMonitoring<MonitoredAction>();
    const endActionMonitoring = useCallback(() => {
      endAction('review_and_confirm');
      endAction('complete_legal_info');
    }, [endAction]);

    useEffect(() => {
      trackMarketing('pay-bill_recurring-confirm-success');
    }, [trackMarketing]);

    const footerGap = useBreakpointValue<GroupProps['spacing']>({ xs: 'm', s: 'm', m: 'm' });
    const actionSectionWidth = useBreakpointValue<GroupProps['width']>({ xs: 'full', s: 'full', m: 'full' });
    const secondaryDescription = getBillSubscriptionSecondaryDescription(subscriptionDetails);

    return (
      <NewSinglePaymentStepLayout
        data-component="RecurringPaymentScheduledActivity.RecurringPaymentScheduledScreen"
        data-testid="recurring-payment-scheduled-activity-recurring-payment-scheduled-screen"
        isLoading={isLoading}
        ref={endActionMonitoring}
        {...props}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <NewSinglePaymentStepLayout.Actions>
            <Group variant="vertical" spacing={footerGap} width={actionSectionWidth}>
              <RecurringPaymentScheduledActionButtons
                onDone={onDone}
                onNotifyVendors={onNotifyVendors}
                notifyVendor={notifyVendor}
                isNewSuccessScreen={false}
              />
            </Group>
          </NewSinglePaymentStepLayout.Actions>
        }
        bottomContent={
          <NewSinglePaymentStepLayout.Actions>
            <PaymentScheduledRiskDisclaimer vendors={[subscriptionDetails.vendor]} />
          </NewSinglePaymentStepLayout.Actions>
        }
      >
        <Group variant="vertical" alignItems="center">
          <Illustration type="success" size="large" />
        </Group>
        <NewSinglePaymentStepLayout.Title textAlign="center">
          <FormattedMessage id="activities.recurringPaymentScheduled.screens.recurringPaymentScheduled.title" />
        </NewSinglePaymentStepLayout.Title>
        <NewSinglePaymentStepLayout.Description textAlign="center">
          {description}
        </NewSinglePaymentStepLayout.Description>
        {secondaryDescription ? (
          <NewSinglePaymentStepLayout.Content>
            <Container textAlign="center">{secondaryDescription}</Container>
          </NewSinglePaymentStepLayout.Content>
        ) : null}
        <PaymentScheduledUpsells vendorId={subscriptionDetails.vendor.id} />
        <PaymentScheduledPromotions
          flow="recurring-payment"
          promotionName={activePromotion}
          paymentIds={paymentsIds}
          onDone={onDone}
        />
      </NewSinglePaymentStepLayout>
    );
  }
);

RecurringPaymentScheduledScreen.displayName = 'RecurringPaymentScheduledActivity.RecurringPaymentScheduledScreen';
