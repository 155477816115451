/* eslint-disable react-hooks/exhaustive-deps */
import { FormEvent, RefObject, useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { DeprecatedSearchBar } from '@melio/penny';

type FormSearchProps = {
  onSearch: (query: string) => void;
  onSearchInput?: (query: string) => void;
  placeholder?: string;
  defaultValue?: string;
  searchBarRef?: RefObject<HTMLInputElement>;
};

export const FormSearch = ({ onSearch, onSearchInput, placeholder, defaultValue, searchBarRef }: FormSearchProps) => {
  const queryRef = useRef(defaultValue || '');

  const handleFormSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSearch(queryRef.current);
  };
  const handleInputChange = (query: string, options: { clear: boolean }) => {
    onSearchInput && onSearchInput(query);
    queryRef.current = query;
    if (options.clear) {
      onSearch('');
    }
  };

  useEffect(() => {
    onSearch(defaultValue || '');
  }, []);

  return (
    <Box as={'form'} onSubmit={handleFormSubmit}>
      <DeprecatedSearchBar
        onChange={handleInputChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        ref={searchBarRef}
      />
    </Box>
  );
};
