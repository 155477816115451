import { matchRoutes } from 'react-router-dom';
export function mapTargetToTargetAction(target: string) {
  const matches = matchRoutes(
    [
      '/schedule-payment/:id',
      '/schedule-batch-payments',
      '/view-payments',
      'view-payment/:id',
      '/view-bills',
      '/view-bill/:id',
      '/view-vendors',
      'view-vendor/:id',
      '/view-settings',
    ].map((path) => ({ path })),
    target,
  );
  if (!matches || matches.length <= 0 || !matches[0].pathname) {
    return {};
  }
  const match = matches[0];
  const searchString = target.split('?')[1] || null;
  const searchParams = searchString ? new URLSearchParams(searchString) : null;

  switch (match.route.path) {
    case '/schedule-payment/:id':
      return { targetAction: 'schedulePayment', targetId: match.params.id };
    case '/schedule-batch-payments':
      return {
        targetAction: 'scheduleBatchPayments',
        targetId: searchParams!.get('bill_ids') || undefined,
      };
    case '/view-payments':
      return { targetAction: 'viewPayment' };
    case 'view-payment/:id':
      return { targetAction: 'viewPaidPayment', targetId: match.params.id };
    case '/view-bills':
      return { targetAction: 'viewBill' };
    case '/view-bill/:id':
      return { targetAction: 'viewBill', targetId: match.params.id };
    case '/view-vendors':
      return { targetAction: 'viewVendors' };
    case 'view-vendor/:id':
      return { targetAction: 'viewVendors', targetId: match.params.id };
    case '/view-settings':
      return { targetAction: 'viewSettings' };
  }
  return {};
}
