import { useRecoilValue } from 'recoil';
import { usePaymentProcessingInfo } from '@melio/ap-domain';
import { PaymentStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { GroupItemCard, PayCardTagStatus } from '@/cl/components/PayCard/PayCard.component';
import { payDashboardQuerySearchSelector } from '@/store/PayDashboard/PayDashboards.model';
import { usePlatformIntl } from '@/translations/Intl';
import { PaymentType } from '@/types/accounting.types';
import { GroupItemType } from '@/types/payDashboard.types';
import { getFinalPaymentTag, getPaymentCardRecurringText, isLastPayment } from '@/utils/billSubscription.utils';
import { BasePaymentCardProps } from '@/widgets/pay-dashboard/cards/types/PaymentCard.types';

export const PaidCard = ({ vendorName, payment }: BasePaymentCardProps) => {
  const { formatMessage, formatDate } = usePlatformIntl();
  const [isRecurringPaymentsEnabled] = useFeature(FeatureFlags.RecurringPayments, false);
  const { isCompletedPaymentStillProcessing } = usePaymentProcessingInfo();

  const highlightedText = useRecoilValue(payDashboardQuerySearchSelector);
  if (!payment) {
    return <></>;
  }

  const { amount, scheduledDate, subscriptionOccurrence, status } = payment;
  const recurringText = getPaymentCardRecurringText(isRecurringPaymentsEnabled, formatMessage, subscriptionOccurrence);
  const shouldShowCompletedBadge =
    status === PaymentStatusEnum.Completed && !isCompletedPaymentStillProcessing(payment);

  const getTagByType = (): { tagText: string; status: PayCardTagStatus } | null => {
    if (shouldShowCompletedBadge) {
      return {
        tagText: formatMessage('widgets.paidCard.badgeByType.completed'),
        status: 'success',
      };
    }
    if (recurringText && isLastPayment(subscriptionOccurrence)) {
      return getFinalPaymentTag(formatMessage);
    }

    return null;
  };

  const scheduledText = `${formatMessage('widgets.paidCard.paidDatePrefix')} ${formatDate(scheduledDate, {
    dateStyle: 'medium',
  })}`;

  return (
    <GroupItemCard
      itemType={GroupItemType.PAID}
      vendorName={vendorName}
      topTag={getTagByType()}
      amount={amount}
      recurringText={recurringText}
      dateText={scheduledText}
      paymentType={PaymentType.AP}
      highlightedText={highlightedText}
      bills={payment.bills}
    />
  );
};

PaidCard.displayName = 'PaidCard';
