import { FormattedMessage, useIsMobile, useMelioIntl, useMonitoring } from '@melio/ar-domain';
import { Button, Form, Group, Icon, Text, useMelioForm } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';
import { object, SchemaOf, string } from 'yup';

import { SignInBanner } from '../../../../components';
import { PaymentRequestDetailsLayout, PaymentRequestDetailsLayoutProps } from '../../../../layout';
import { AddBankFundingSourceFormFields } from './types';

export const useAddBankAccountDetailsSchema = (): SchemaOf<AddBankFundingSourceFormFields> => {
  const { formatMessage } = useMelioIntl();
  return object().shape({
    email: string()
      .email(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.email.valid.text'))
      .required(formatMessage('ar.guestPayment.activities.cardHolder.form.fields.email.required.text')),
  });
};

export type AddBankAccountDetailsScreenProps = Pick<
  PaymentRequestDetailsLayoutProps,
  'onViewInvoice' | 'onSelectFundingSource' | 'paymentRequestDetails' | 'isLoading' | 'isDisabled'
> & {
  isSaving?: boolean;
  isCheckingEmail?: boolean;
  onEmailChanged: (email: string) => void;
  onSubmit: (data: AddBankFundingSourceFormFields) => void;
  signInUrl?: string;
};

export const AddBankAccountDetailsScreen = forwardRef<AddBankAccountDetailsScreenProps>(
  (
    {
      onSubmit,
      isSaving,
      isDisabled,
      isLoading,
      isCheckingEmail,
      onEmailChanged,
      onViewInvoice,
      onSelectFundingSource,
      paymentRequestDetails,
      signInUrl,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const { routeReady } = useMonitoring();
    const { track } = useAnalytics();
    const isMobile = useIsMobile();

    const { formProps, registerField, submitButtonProps, formState, watch } =
      useMelioForm<AddBankFundingSourceFormFields>({
        onSubmit,
        schema: useAddBankAccountDetailsSchema(),
        isSaving,
      });

    const email = watch('email');
    useEffect(() => onEmailChanged(email), [email, onEmailChanged]);

    useAnalyticsView('PaymentRequest', !isLoading, true, {
      PaymentMethodType: 'ach',
    });

    useEffect(() => {
      if (formState.isSubmitted && !formState.isValid) {
        track('PaymentRequest', 'Status', {
          ErrorType: 'invalid-email-address',
        });
      }
    }, [formState.isSubmitted, formState.isValid, track]);

    return (
      <PaymentRequestDetailsLayout
        onViewInvoice={onViewInvoice}
        onSelectFundingSource={onSelectFundingSource}
        selectedFundingSource="bank"
        paymentRequestDetails={paymentRequestDetails}
        isDisabled={isSaving || isDisabled}
        data-loading={!!isLoading}
        data-testid="add-bank-account-details-screen"
        data-component={AddBankAccountDetailsScreen.displayName}
      >
        <Group variant="vertical" spacing="l" {...props} ref={ref}>
          <Group variant="vertical" spacing="m">
            <Group variant="vertical" spacing="xxs">
              <Text textStyle="body2Semi">
                <FormattedMessage id="ar.guestPayment.payByBank.form.title.text" />
              </Text>
              <Text textStyle="body3">
                <FormattedMessage id="ar.guestPayment.payByBank.form.description.text" />
              </Text>
            </Group>
            <Group variant="vertical" spacing="xs">
              {signInUrl && <SignInBanner onSignInClick={() => window.location.assign(signInUrl)} />}
              <Form
                data-testid="add-bank-account-details-form"
                size={isMobile ? 'small' : 'large'}
                columns={2}
                {...formProps}
              >
                <Form.TextField
                  colSpan={2}
                  labelProps={{ label: formatMessage('ar.guestPayment.activities.cardHolder.form.fields.email.label') }}
                  isLoading={isCheckingEmail}
                  {...registerField('email')}
                />
              </Form>
            </Group>
          </Group>
          <Button
            ref={routeReady}
            data-testid="add-bank-submit-button"
            size="large"
            label={formatMessage('ar.guestPayment.payByBank.form.button.submit.text')}
            leftElement={<Icon type="lock" color="inherit" />}
            {...submitButtonProps}
            isDisabled={(isDisabled || submitButtonProps.isDisabled) && !submitButtonProps.isLoading}
          />
        </Group>
      </PaymentRequestDetailsLayout>
    );
  }
);
AddBankAccountDetailsScreen.displayName = 'AddBankAccountDetailsScreen';
