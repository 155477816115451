import { StatusIconSolid, Tooltip, Tracker } from '@melio/penny';

import { PaymentDetailsProps } from '@/widgets/pay-dashboard/payment-details/PaymentDetails.types';
import { usePaymentTimelineTracker } from './usePaymentTimelineTracker';

export const PaymentTimelineTrackerSection = ({
  payment,
  children,
}: {
  payment: PaymentDetailsProps['payment'];
  children?: React.ReactNode;
}) => {
  const { shouldShowPaymentTimeLineTracker, steps, currentStepIndex } = usePaymentTimelineTracker(payment);

  if (!shouldShowPaymentTimeLineTracker) return null;

  const isActive = (index: number) => Boolean(currentStepIndex && index === currentStepIndex);

  const isCompleted = (index: number) => Boolean(currentStepIndex && index < currentStepIndex);

  return (
    <>
      <Tracker data-testid="payment-timeline-tracker">
        {steps?.map((step, index) => (
          <Tracker.Step
            key={`tracker-step-${index}`}
            data-testid={`tracker-step-${index}`}
            isActive={isActive(index)}
            isCompleted={isCompleted(index)}
          >
            <Tracker.StepTitle label={step.title} />
            {step.description && <Tracker.StepDescription label={step.description} />}
            {step.invalid && (
              <Tooltip content={step.invalid}>
                <StatusIconSolid variant="warning" size="small" data-testid="icon-indicator" />
              </Tooltip>
            )}
          </Tracker.Step>
        ))}
      </Tracker>{' '}
      {children}
    </>
  );
};
