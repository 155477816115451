// eslint-disable-next-line no-restricted-imports
import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useMelioIntl } from '@melio/i18n-tools/src/useMelioIntl';
import { Button } from '@melio/penny';
import { ErrorResponse, PartnerName } from '@melio/platform-api';
import { useOneTimeEffect } from '@melio/platform-utils';

import { createMockLink } from '@/api/auth-demo/auth-demo.api';
import { AbsoluteCenter } from '@/cl/components/AbsoluteCenter/AbsoluteCenter.component';
import { WithLoading } from '@/hoc/withLoading.hoc';
import { useGoogleSSO } from '@/hooks/useGoogleSSO';
import { EmbeddedExperienceDemoScreen } from '@/screens/embedded-experience-demo/EmbeddedExperienceDemo.screen';
import { EmbeddedDemoLayout } from '@/screens/embedded-experience-demo/layouts/types';
import { ErrorPage } from '@/widgets/error-page/ErrorPage.widget';

const useCreateMockLink = ({
  providerName,
  partnerName,
  payload,
}: {
  providerName: string;
  partnerName: PartnerName;
  payload?: Record<string, unknown>;
}) => {
  const mutation = useMutation<{ link: string }, AxiosError<ErrorResponse>>(async () => {
    const response = await createMockLink({ partnerName, providerName, payload });
    return response.data;
  });

  useOneTimeEffect(
    () => {
      mutation.mutate();
    },
    () => true,
    [mutation.mutate],
  );

  return mutation;
};

const getLocalUrl = (url: string, themeOverride: string | null): string => {
  const newUrl = new URL(url);
  if (themeOverride) {
    newUrl.searchParams.set('theme', themeOverride);
  }
  newUrl.protocol = window.location.protocol;
  newUrl.host = window.location.host;
  return newUrl.toString();
};

const EmbeddedExperienceDemoRoute = () => {
  const [searchParams] = useSearchParams();
  const showFakeBankFrame = !!searchParams.get('fakeBank');
  const forceIframe = !!searchParams.get('forceIframe');
  const themeOverride = searchParams.get('theme');

  const { data, error, isLoading } = useCreateMockLink({
    partnerName: PartnerName.FiservUsBank,
    providerName: 'fiserv',
    payload: {
      externalUserId: searchParams.get('user')?.toUpperCase() || 'PEONY_LEVEL1',
      externalOrgId: searchParams.get('org') || '202405161054',
      userRole: {
        userId: '',
        userRole: `level${searchParams.get('level') || 1}`,
      },
      accountInfo: {
        user: {
          email: 'meliotest@melio.com',
          phone: '4785372615',
          firstName: 'FirstNameTest',
          lastName: 'LastNameTest',
        },
        company: {
          name: 'MyCompany2',
          legalName: 'MyCompany22',
          phoneNumber: '5555666677',
          address: {
            state: 'WI',
            line1: '1330 Commonwealth Dr',
            city: 'Fort Atkinson',
            postalCode: '535381366',
          },
          legalAddress: {
            state: 'WI',
            line1: '1330 Commonwealth Dr',
            city: 'Fort Atkinson',
            postalCode: '535381366',
          },
          taxInfo: {
            identifier: searchParams.get('tax') || '123321568',
            type: '',
          },
          industry: {
            naicsCode: '722511',
          },
        },
      },
    },
  });
  const { formatMessage } = useMelioIntl();

  const shouldRedirect = !showFakeBankFrame && !forceIframe && !!data?.link;

  useEffect(() => {
    if (shouldRedirect) {
      window.location.href = getLocalUrl(data.link, themeOverride);
    }
  }, [data?.link, shouldRedirect, themeOverride]);

  return (
    <WithLoading isLoading={isLoading || shouldRedirect}>
      {error || !data ? (
        <ErrorPage title={error?.message || formatMessage('screens.generalError.title')} />
      ) : (
        <EmbeddedExperienceDemoScreen
          embeddedUrl={data.link}
          layout={showFakeBankFrame ? EmbeddedDemoLayout.US_BANK : EmbeddedDemoLayout.CLEAN_BANK}
        />
      )}
    </WithLoading>
  );
};

export const AuthEmbeddedExperienceDemoRoute = () => {
  const { isLoggedIn, signIn, isLoading } = useGoogleSSO();

  return (
    <WithLoading isLoading={isLoading}>
      {isLoggedIn ? (
        <EmbeddedExperienceDemoRoute />
      ) : (
        <AbsoluteCenter>
          <Button onClick={() => signIn()} label={'Sign in with Google'} />
        </AbsoluteCenter>
      )}
    </WithLoading>
  );
};
