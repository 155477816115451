import { Outlet } from 'react-router-dom';
import { SyncNowWidget } from '@melio/ap-widgets';
import { AccountingPlatformConnectionStatus, useAccountingPlatforms } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { usePermissions } from '@melio/platform-permissions';

import { AppLayout } from '@/cl/layouts/app-layout/appLayout.component';
import { usePartnerConfig } from '@/hooks/partners';

const disconnectedAccountingPlatformStatuses: AccountingPlatformConnectionStatus[] = [
  AccountingPlatformConnectionStatus.Unlinked,
  AccountingPlatformConnectionStatus.Disconnected,
];

export const OnlyHeaderLayout = () => {
  const { partnerConfig } = usePartnerConfig();
  const [isSyncNowEnabled] = useFeature<boolean>(FeatureFlags.SyncNow, false);

  const { data: accountingPlatforms } = useAccountingPlatforms();
  const accountingPlatform = accountingPlatforms?.find(
    (accountingPlatform) => !disconnectedAccountingPlatformStatuses.includes(accountingPlatform.connectionStatus),
  );

  const { can } = usePermissions();
  const syncNowIcon =
    isSyncNowEnabled && accountingPlatform && can({ subject: 'accountingPlatform:sync', action: 'create' })
      ? () => <SyncNowWidget accountingPlatform={accountingPlatform} />
      : undefined;

  return (
    <AppLayout
      topNav={{
        TopNavIcon: partnerConfig.icons.LogoIcon.small,
        TopNavSmallIcon: partnerConfig.icons.LogoIcon.extraSmall,
        SyncNowIcon: syncNowIcon,
      }}
    >
      <Outlet />
    </AppLayout>
  );
};
