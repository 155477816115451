import { getAccountingPlatformBrandSymbolType } from '@melio/ap-domain';
import { BrandSymbolProps } from '@melio/penny';
import {
  isQuickBooksOnlineAccountingPlatform,
  isXeroAccountingPlatform,
  QuickBooksOnlineAccountingPlatform,
  useAccountingPlatforms,
  XeroAccountingPlatform,
} from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSubscriptionFeature, useSyncedPaymentsCount } from '@melio/subscriptions';
import { isNil } from 'lodash';

export const usePlanRemainingPaymentsSync = () => {
  const { formatMessage } = useMelioIntl();

  const [isLimitPaymentsSyncBasedOnPlanQuotaEnabled] = useFeature(
    FeatureFlags.LimitPaymentsSyncBasedOnPlanQuota,
    false
  );

  const { data: syncedPaymentsCountData, isLoading: isLoadingSyncedPaymentsNumber } = useSyncedPaymentsCount();

  const {
    quota: { freeUnitsLimit: paymentsSyncLimit },
    showPaywallForIneligibleFeature: showPaywallForSyncedPaymentsFeature,
  } = useSubscriptionFeature({
    featureName: 'syncedPayments',
    requirements: { totalUnits: Infinity },
  });

  const hasNonZeroLimitedSyncNumber = !!(paymentsSyncLimit && paymentsSyncLimit !== Infinity);

  const { data: accountingPlatformsData, isLoading: isLoadingAccountingPlatforms } = useAccountingPlatforms({
    enabled: hasNonZeroLimitedSyncNumber && isLimitPaymentsSyncBasedOnPlanQuotaEnabled,
  });

  const isLoading = isLoadingSyncedPaymentsNumber || isLoadingAccountingPlatforms;

  type RelevantAccountingPlatform = XeroAccountingPlatform | QuickBooksOnlineAccountingPlatform;

  const relevantAccountingPlatform = accountingPlatformsData?.find(
    (accountingPlatform): accountingPlatform is RelevantAccountingPlatform => {
      const hasEverSyncedWithThePlatform = !!accountingPlatform.lastCompletionTime;
      const isIncludedInSyncedPaymentsFeature =
        isQuickBooksOnlineAccountingPlatform(accountingPlatform) || isXeroAccountingPlatform(accountingPlatform);

      return isIncludedInSyncedPaymentsFeature && hasEverSyncedWithThePlatform;
    }
  );

  const numberOfSyncedPayments = syncedPaymentsCountData?.count;

  const shouldShowRemainingPaymentsSync =
    !isLoading &&
    !isNil(numberOfSyncedPayments) &&
    isLimitPaymentsSyncBasedOnPlanQuotaEnabled &&
    relevantAccountingPlatform &&
    hasNonZeroLimitedSyncNumber;

  if (shouldShowRemainingPaymentsSync) {
    const remainingPlanPaymentSync = Math.max(0, paymentsSyncLimit - numberOfSyncedPayments);

    const noRemainingSync = formatMessage(
      'activities.paymentScheduled.screens.paymentScheduled.planRemainingPaymentsSync.description.noRemainingPayments'
    );

    const hasRemainingSync = formatMessage(
      'activities.paymentScheduled.screens.paymentScheduled.planRemainingPaymentsSync.description.remainingPayments',
      { remainingPlanPaymentSync }
    );

    const description = remainingPlanPaymentSync === 0 ? noRemainingSync : hasRemainingSync;

    const accountingPlatformBrandSymbol: BrandSymbolProps = {
      type: getAccountingPlatformBrandSymbolType(relevantAccountingPlatform.accountingSlug),
      isDisabled: !remainingPlanPaymentSync,
    };

    return { description, accountingPlatformBrandSymbol, showPaywallForSyncedPaymentsFeature };
  }

  return;
};
