import { FormattedMessage, InvoiceExpandedSummary, InvoiceTimeline } from '@melio/ar-domain';
import { Divider, Group, Icon, Pill, SectionBanner, Text } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { forwardRef } from '@melio/platform-utils';

import { DisplayStatusLabel, DisplayStatusLabelMap, InvoiceDrawerActionsHandlers } from '../../../utils';
import { useDrawerSectionBanner } from '../hooks';
import { InvoiceAttachments } from './InvoiceAttachments';
import { InvoiceBasicDetails } from './InvoiceBasicDetails';
import { InvoicePaymentSummeryDetails } from './InvoicePaymentSummeryDetails';
import { InvoiceSummaryTitle } from './InvoiceSummaryTitle';

type DrawerBodyProps = {
  expandedInvoice: InvoiceExpandedSummary;
  invoiceTimeline?: InvoiceTimeline;
  companyName: string;
  invoiceActions: InvoiceDrawerActionsHandlers;
};

export const InvoiceSummary = forwardRef<DrawerBodyProps>(
  ({ companyName, expandedInvoice, invoiceTimeline, invoiceActions }, ref) => {
    const { title, description, variant, icon } = useDrawerSectionBanner(
      expandedInvoice,
      invoiceActions,
      invoiceTimeline
    );
    const [isInvoiceAttachmentsEnabled] = useFeature(FeatureFlags.ArInvoiceAttachments, false);

    const hideSectionBanner =
      expandedInvoice.displayStatus == 'open' && !expandedInvoice.isAchAllowed && !expandedInvoice.isCardAllowed;

    return (
      <Group variant="vertical" spacing="l" ref={ref}>
        <Text as="h3" ref={ref} textStyle="heading3Semi">
          <FormattedMessage id="ar.dashboard.activities.InvoiceDrawer.body.header.label" />
        </Text>
        <Pill
          type="secondary"
          status={DisplayStatusLabelMap[expandedInvoice.displayStatus]}
          label={DisplayStatusLabel[expandedInvoice.displayStatus]}
          data-testid="invoice-drawer-badge"
        />
        {!hideSectionBanner && (
          <SectionBanner
            description={description}
            title={title}
            variant={variant}
            leftElement={icon ? <Icon type={icon} aria-hidden /> : undefined}
          />
        )}
        <Group variant="vertical" spacing="m">
          <InvoiceSummaryTitle expandedInvoice={expandedInvoice} />
          <InvoiceBasicDetails expandedInvoice={expandedInvoice} companyName={companyName} />
          {isInvoiceAttachmentsEnabled ? <InvoiceAttachments attachments={expandedInvoice.attachments} /> : null}
          <Divider />
          <InvoicePaymentSummeryDetails expandedInvoice={expandedInvoice} />
        </Group>
      </Group>
    );
  }
);
