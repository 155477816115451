import { BrandSymbol } from '@melio/penny';
import { AccountingPlatformSlug } from '@melio/platform-api';

export const SyncNowPlatformIcon = ({ accountingPlatformSlug }: { accountingPlatformSlug: AccountingPlatformSlug }) => (
  <div
    style={{
      position: 'absolute',
      margin: '-1rem 0px 0px 1.5rem',
      cursor: 'pointer',
      border: '0.2rem solid #fff',
      borderRadius: '100%',
      display: 'flex',
      pointerEvents: 'none',
    }}
    data-testid="sync-now-platform-icon"
    data-tourid="sync-now-platform-icon"
  >
    {
      {
        ['xero']: <BrandSymbol type="xero-logo" size="small" />,
        ['quickBooksOnline']: <BrandSymbol type="quickbooks" size="small" />,
        ['quickBooksDesktop']: <BrandSymbol type="quickbooks" size="small" />,
        ['quickBooksDesktopInApp']: <BrandSymbol type="quickbooks" size="small" />,
        ['dynamicsBusinessCentral']: <BrandSymbol type="dynamics365bc" size="small" />,
        ['freshBooks']: <BrandSymbol type="freshbooks-logo" size="small" />,
      }[accountingPlatformSlug]
    }
  </div>
);
