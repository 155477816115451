import { ARFeeDetails, ConversionUtils, PayorPaymentRequestDetails, useMelioIntl } from '@melio/ar-domain';
import { Group, Pill, SectionBanner, Text } from '@melio/penny';
import { useConfig } from '@melio/platform-provider';

import { FundingSourceSelection } from '../components';
import { useFormattedFee } from '../hooks';
import { GuestPayorFundingSourceTypes } from '../types';

const PaymentMethodTitle = ({ title, feeDetails }: { title: string; feeDetails?: ARFeeDetails }) => {
  const { getFeeLabel } = useFormattedFee();

  return (
    <Group alignItems="center" spacing="xxs">
      <Text textStyle="body1Semi">{title}</Text>
      {feeDetails && <Pill status="neutral" type="secondary" label={getFeeLabel(feeDetails)} />}
    </Group>
  );
};

export const PaymentRequestTitle = ({
  paymentRequestDetails,
  selectedFundingSource,
  handleFundingSourceSelection,
}: {
  paymentRequestDetails: PayorPaymentRequestDetails;
  selectedFundingSource?: GuestPayorFundingSourceTypes;
  handleFundingSourceSelection: (fundingSource: GuestPayorFundingSourceTypes) => void;
}) => {
  const config = useConfig();
  const { formatMessage } = useMelioIntl();
  const goodsReceivedAmountThreshold = ConversionUtils.fromDollarsToCents(config.settings.goodsReceivedAmountThreshold);
  const shouldShowGoodsReceivedBanner = paymentRequestDetails.invoice.totalAmount > goodsReceivedAmountThreshold;
  const { isAchAllowed, isCardAllowed } = paymentRequestDetails.invoice.paymentOptions;

  if (isCardAllowed && isAchAllowed) {
    return (
      <>
        {shouldShowGoodsReceivedBanner && selectedFundingSource === 'card' && (
          <SectionBanner
            data-testid="guest-payment-goods-received-banner-card-and-bank"
            description={formatMessage(
              'ar.guestPayment.paymentMethods.goodsReceivedBanner.cardAndBank.description.text'
            )}
          />
        )}
        <FundingSourceSelection
          fees={paymentRequestDetails.fees}
          selectedFundingSource={selectedFundingSource}
          onCardSelected={() => handleFundingSourceSelection('card')}
          onBankSelected={() => handleFundingSourceSelection('bank')}
        />
      </>
    );
  }

  if (isCardAllowed) {
    return (
      <>
        {shouldShowGoodsReceivedBanner && (
          <SectionBanner
            data-testid="guest-payment-goods-received-banner-card"
            description={formatMessage('ar.guestPayment.paymentMethods.goodsReceivedBanner.card.description.text')}
          />
        )}
        <PaymentMethodTitle
          title={formatMessage('ar.guestPayment.paymentMethods.payByCard.title.text')}
          feeDetails={paymentRequestDetails.fees?.card}
        />
      </>
    );
  }

  if (isAchAllowed) {
    return (
      <PaymentMethodTitle
        title={formatMessage('ar.guestPayment.paymentMethods.payByBank.title.text')}
        feeDetails={paymentRequestDetails.fees?.ach}
      />
    );
  }

  return null;
};
