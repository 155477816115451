import { addWildcardToRoutes, useFlowRouting, withOutlet } from '@melio/ar-domain';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { Route, Routes } from 'react-router-dom';

import { CreateInvoiceActivity as _CreateInvoiceActivity } from '../activities';
import { useInvoiceModals } from './useInvoiceModals';

const CreateInvoiceActivity = withOutlet(_CreateInvoiceActivity);

export type CreateInvoiceFlowProps = {
  onClose: VoidFunction;
  onDone: (id?: string) => void;
  onIssueInvoice: (id: string) => void;
  onError?: ARErrorFunction;
};

export const CreateInvoiceFlow = withAnalyticsContext<CreateInvoiceFlowProps>(
  forwardRef(({ setAnalyticsProperties, onClose, onDone, onIssueInvoice, ...props }, ref) => {
    const { Paths, goToCreateInvoice } = useCreateInvoiceFlowNavigation();

    const {
      renderModals,
      openTaxRateModal,
      openDiscountModal,
      openServiceChargeModal,
      removeServiceCharge,
      discount,
      serviceCharges,
      removeDiscount,
      taxRate,
      onCreateCustomer,
      onUpdateCustomer,
      customerId,
    } = useInvoiceModals({ onClose: goToCreateInvoice });

    setAnalyticsProperties({
      ProductName: 'ar',
      PageName: 'create-invoice',
      Flow: 'create-invoice',
      Intent: 'create-invoice',
    });

    const onCreateDone = (id: string, shouldIssue?: boolean) => {
      if (!shouldIssue) {
        onDone(id);
      } else {
        onIssueInvoice(id);
      }
    };

    return (
      <Routes>
        <Route
          path={Paths.Home}
          element={
            <CreateInvoiceActivity
              discount={discount}
              onSetDiscount={openDiscountModal}
              onRemoveDiscount={removeDiscount}
              onClose={onClose}
              onDone={onCreateDone}
              taxRate={taxRate}
              onSetTaxRate={openTaxRateModal}
              serviceCharges={serviceCharges}
              onAddServiceCharge={openServiceChargeModal}
              onRemoveServiceCharge={removeServiceCharge}
              onCreateCustomer={onCreateCustomer}
              onUpdateCustomer={onUpdateCustomer}
              customerId={customerId}
              {...props}
              ref={ref}
            />
          }
        >
          {renderModals()}
        </Route>
      </Routes>
    );
  })
);

CreateInvoiceFlow.displayName = 'CreateInvoiceFlow';

const useCreateInvoiceFlowNavigation = () => {
  enum Paths {
    Home = '',
  }

  const { createCallback } = useFlowRouting<Paths>({ withSearchparams: true });
  const goToCreateInvoice = createCallback(Paths.Home);

  return {
    Paths: addWildcardToRoutes(Paths),
    goToCreateInvoice,
  };
};
