import { getFundingSourceBalance } from '@melio/ap-widgets';
import { PillProps } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useFormatExpirationDate } from '@melio/platform-utils';

export const useFundingSourceCardProps = ({
  fundingSource,
}: {
  fundingSource: FundingSource;
}): {
  title: string;
  line1?: string;
  line2?: string;
  badgeProps?: Array<PillProps & { 'data-testid': string }>;
} => {
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();
  const { formatExpirationDate } = useFormatExpirationDate();

  if (fundingSource.type === 'bank-account') {
    const balance = getFundingSourceBalance(fundingSource);

    return {
      title: fundingSource.displayName,
      line1: fundingSource.nickname,
      line2: balance
        ? formatMessage('activities.paymentFlow.form.content.fundingSourceCard.bank-account.line2', {
            availableBalance: formatCurrency(balance.availableBalance),
            availableBalanceUpdatedAt: formatDate(balance.availableBalanceUpdatedAt, {
              month: 'short',
              day: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
              timeZone: 'America/New_York',
              timeZoneName: 'short',
            }),
          })
        : undefined,
    };
  } else if (fundingSource.type === 'card') {
    return {
      title: fundingSource.displayName,
      line1: fundingSource.nickname,
      line2: formatMessage('activities.paymentFlow.form.content.fundingSourceCard.card.line2', {
        expirationDate: formatExpirationDate({
          month: fundingSource.details.expirationMonth,
          year: fundingSource.details.expirationYear,
        }),
      }),
    };
  } else {
    return {
      title: 'not implemented',
    };
  }
};
