import { useAccount } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { Logger } from '@melio/platform-logger';
import { useConfig } from '@melio/platform-provider';
import { differenceInCalendarDays, isStringMatchDateFormat } from '@melio/platform-utils';
import { addDays } from 'date-fns';

import { useIsSubscriptionsEnabled } from '../hooks/useIsSubscriptionsEnabled';

const GRACE_PERIOD_DATE_FORMAT = 'yyyy-MM-dd';

type SubscriptionGracePeriod = {
  isEligibleForGracePeriod: boolean;
  isTodayInGracePeriod: boolean;
  isFetchingGracePeriod: boolean;
  isOrgCreatedBeforeGracePeriodStart: boolean;
  isUserCreatedBeforeGracePeriodStart: boolean;
  isGracePeriodEnded: boolean;
  gracePeriodStartDate?: Date;
  gracePeriodEndDate?: Date;
  gracePeriodEndDateToDisplay?: Date;
};

const DEFAULT_RESPONSE = {
  isEligibleForGracePeriod: false,
  isTodayInGracePeriod: false,
  isFetchingGracePeriod: false,
  isOrgCreatedBeforeGracePeriodStart: false,
  isUserCreatedBeforeGracePeriodStart: false,
  isGracePeriodEnded: false,
};

/**
 * Hook to check if the user is eligible for the subscription grace period.
 * The grace period is defined by the feature flags `FeatureFlags.SubscriptionsGracePeriodStart` and `FeatureFlags.SubscriptionsGracePeriodEnd`.
 * Both feature flags should be in the format 'yyyy-MM-dd'.
 * The user is eligible for the grace period if the organization creation date is before the grace period start date.
 * The user is in the grace period if the current date is before the grace period end and the platform-subscription feature flag is enabled.
 */
export const useSubscriptionGracePeriod = ({
  hasSubscription,
}: {
  hasSubscription: boolean;
}): SubscriptionGracePeriod => {
  const {
    settings: { subscriptions: subscriptionsConfig },
  } = useConfig();

  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const { data: account, isFetching: isFetchingAccount } = useAccount({ id: 'me', enabled: isSubscriptionsEnabled });

  const [subscriptionsGracePeriodStartStr] = useFeature(FeatureFlags.SubscriptionsGracePeriodStart, '');
  const [subscriptionsGracePeriodEndStr] = useFeature(FeatureFlags.SubscriptionsGracePeriodEnd, '');

  if (!isSubscriptionsEnabled || !subscriptionsConfig?.isGracePeriodSupported) {
    return DEFAULT_RESPONSE;
  }

  const isStartDateValid = isStringMatchDateFormat(subscriptionsGracePeriodStartStr, GRACE_PERIOD_DATE_FORMAT);
  const isEndDateValid = isStringMatchDateFormat(subscriptionsGracePeriodEndStr, GRACE_PERIOD_DATE_FORMAT);

  if (!isStartDateValid || !isEndDateValid) {
    Logger.log(
      `useSubscriptionGracePeriod - grace period start end dates should be in '${GRACE_PERIOD_DATE_FORMAT}' format. input: startDate:${subscriptionsGracePeriodStartStr}, endDate:${subscriptionsGracePeriodEndStr}`,
      'error'
    );

    return DEFAULT_RESPONSE;
  }

  if (isFetchingAccount || !account) {
    return {
      isEligibleForGracePeriod: false,
      isTodayInGracePeriod: false,
      isFetchingGracePeriod: true,
      isOrgCreatedBeforeGracePeriodStart: false,
      isUserCreatedBeforeGracePeriodStart: false,
      isGracePeriodEnded: false,
    };
  }

  // eslint-disable-next-line no-restricted-syntax
  const subscriptionsGracePeriodStart = new Date(`${subscriptionsGracePeriodStartStr}T00:00:00`);
  // eslint-disable-next-line no-restricted-syntax
  const subscriptionsGracePeriodEnd = new Date(`${subscriptionsGracePeriodEndStr}T00:00:00`);
  // eslint-disable-next-line no-restricted-syntax
  const today = new Date();
  const isTodayInGracePeriod =
    differenceInCalendarDays(today, subscriptionsGracePeriodEnd) <= 0 &&
    differenceInCalendarDays(today, subscriptionsGracePeriodStart) >= 0;

  const isGracePeriodEnded = differenceInCalendarDays(today, subscriptionsGracePeriodEnd) > 0;

  const {
    organizationCreatedAt,
    history: { createdAt: userCreatedAt },
  } = account;

  const isOrgCreatedBeforeGracePeriodStart =
    differenceInCalendarDays(organizationCreatedAt, subscriptionsGracePeriodStart) < 0;

  const isUserCreatedBeforeGracePeriodStart =
    differenceInCalendarDays(userCreatedAt, subscriptionsGracePeriodStart) < 0;

  const isEligibleForGracePeriod = !hasSubscription && isOrgCreatedBeforeGracePeriodStart;
  const gracePeriodEndDateToDisplay = addDays(subscriptionsGracePeriodEnd, 1);

  return {
    isEligibleForGracePeriod,
    isTodayInGracePeriod,
    isGracePeriodEnded,
    isFetchingGracePeriod: false,
    isOrgCreatedBeforeGracePeriodStart,
    isUserCreatedBeforeGracePeriodStart,
    gracePeriodStartDate: subscriptionsGracePeriodStart,
    gracePeriodEndDate: subscriptionsGracePeriodEnd,
    gracePeriodEndDateToDisplay,
  };
};
