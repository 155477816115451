import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { omit } from 'lodash';
import { useEffect, useMemo } from 'react';

import { usePlansMe, useSubscriptionMe } from '../api';
import { useAccountingClientSubscriptionSelectedPlan } from '../hooks/useAccountingClientSubscriptionSelectedPlan';
import { useIsSubscriptionsEnabled } from '../hooks/useIsSubscriptionsEnabled';

export type UseSubscriptionDataProps = {
  retryOnMount?: boolean;
};

export const useSubscriptionData = ({ retryOnMount }: UseSubscriptionDataProps = {}) => {
  const isSubscriptionsEnabled = useIsSubscriptionsEnabled();
  const [, isSubscriptionFeatureFlagLoading] = useFeature(FeatureFlags.PlatformSubscriptions, false);
  const {
    settings: { subscriptions: subscriptionsConfig },
  } = useConfig();
  const accountingClientSelectedSubscriptionPlan = useAccountingClientSubscriptionSelectedPlan();
  const {
    data: subscription,
    isFetching: isFetchingMySubscription,
    error: subscriptionError,
  } = useSubscriptionMe({
    enabled: isSubscriptionsEnabled,
    retryOnMount,
  });
  const {
    data: plansData,
    isFetching: isFetchingPlans,
    error: plansError,
  } = usePlansMe({
    enabled: isSubscriptionsEnabled,
  });

  const isFetching = isFetchingMySubscription || isFetchingPlans || isSubscriptionFeatureFlagLoading;

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.info('useSubscriptionData Loading', {
      isFetchingMySubscription,
      isFetchingPlans,
      isSubscriptionFeatureFlagLoading,
    });
  }, [isFetchingMySubscription, isFetchingPlans, isSubscriptionFeatureFlagLoading]);

  const isError =
    !!plansError ||
    !!(subscriptionError && (subscriptionError.code !== '404' || subscriptionsConfig?.expectOrgsToBeSubscribed));

  const [isLimitPaymentsSyncBasedOnPlanQuotaEnabled] = useFeature(
    FeatureFlags.LimitPaymentsSyncBasedOnPlanQuota,
    false
  );

  // TODO: delete once the 'limit-payments-sync-based-on-plan-quota' flag and 'accountingPlatformQbo', 'accountingPlatformXero' features are deleted  https://meliorisk.atlassian.net/browse/ME-74177, ME-74074
  const plans = useMemo(
    () =>
      isLimitPaymentsSyncBasedOnPlanQuotaEnabled
        ? plansData?.map((plan) => ({
            ...plan,
            features: omit(plan.features, ['accountingPlatformQbo', 'accountingPlatformXero']),
          }))
        : plansData,
    [plansData, isLimitPaymentsSyncBasedOnPlanQuotaEnabled]
  );

  return { isFetching, isError, subscription, plans, accountingClientSelectedSubscriptionPlan };
};
