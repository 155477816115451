import { isEbill, isFXCurrency, RateSection, useInternationalFxFeature } from '@melio/ap-domain';
import { FundingSourceCardListOfLists, PCIComplienceInfo, useFundingSourceCardDisplayText } from '@melio/ap-widgets';
import { Group, SectionBanner } from '@melio/penny';
import { useAnalyticsContext, useAnalyticsView } from '@melio/platform-analytics';
import { FundingSource, useInternationalRate } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { forwardRef, SystemMessageDisplay, useBoolean } from '@melio/platform-utils';
import React, { useEffect, useState } from 'react';

import { MonitoredAction } from '../../../../../monitoring';
import { getFundingSourceById } from '../../../../BatchPayments/screens/PaymentIntentsTable/PaymentIntentsTable.screen.analytics-utils';
import { NewSinglePaymentStepLayout } from '../../../../NewSinglePaymentStepLayout';
import { FundingSourceSelectionScreenProps } from '../../types';
import { getAdditionalAnalytics } from './getAdditionalAnalytics';
import { FundingSourceSelectionScreenTitle } from './ScreenTitle/FundingSourceSelectionScreenTitle';
import { useScreenDescription } from './useScreenDescription';

export const FundingSourceSelectionScreen = forwardRef<FundingSourceSelectionScreenProps>(
  (
    {
      vendor,
      paymentAmount,
      recurringPaymentDetails,
      isLoading,
      isLoadingRepaymentTerms,
      fundingSources,
      step,
      totalSteps,
      selectedId,
      onVerify,
      onAdd,
      onClose,
      onDone,
      goodsReceived = true,
      shouldShowMccErrorBanner,
      fundingSourceTypesOptions,
      failedDate,
      originalFundingSourceId,
      onChangePaymentAmount,
      bill,
      shouldAllowEditAmount,
      shouldAllowFinancingOption,
      repaymentTerms,
      userId,
      onSelectFinancingOption: setSelectFinancingOption,
      isFinancingSelected,
      isRepaymentTermsError,
      ...props
    },
    ref
  ) => {
    const [selectedFundingSourceId, setSelectedFundingSourceId] = useState<FundingSource['id'] | undefined>(selectedId);
    const [paymentAmountHasError, paymentAmountError] = useBoolean();
    const { formatMessage, formatDate } = useMelioIntl();
    const { getDisplayText } = useFundingSourceCardDisplayText();
    let failedFundingSource: FundingSource | undefined;
    if (originalFundingSourceId) {
      failedFundingSource = getFundingSourceById(fundingSources, originalFundingSourceId);
    }
    const [isCloverFreeAchPromotion] = useFeature<boolean>(FeatureFlags.CloverFreeAchPromotion, false);
    const isFxPaymentEnabled = useInternationalFxFeature();

    const { startAction } = useMonitoring<MonitoredAction>();
    const isFxPayment = isFxPaymentEnabled && isFXCurrency(vendor.currency);
    const isRetryFlow = !!originalFundingSourceId;

    const ctaLabel = formatMessage('activities.fundingSourceSelection.screens.fundingSourceSelection.continue');

    const { data: fxRate, isLoading: isLoadingFxRate } = useInternationalRate({
      enabled: isFxPayment,
      foreignCurrency: vendor.currency ?? undefined,
      foreignAmount: paymentAmount,
    });

    useEffect(() => {
      setSelectedFundingSourceId(selectedId);
    }, [selectedId]);

    useEffect(() => {
      const shouldSkipContinueButton = isFinancingSelected && fundingSources.length === 0;
      if (shouldSkipContinueButton) {
        onNext();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFinancingSelected, fundingSources]);

    const isBillEbill = !!bill && isEbill(bill);
    const billBalance = bill?.balance;
    const minimumAmount = bill?.minimumAmount;

    useAnalyticsContext({
      globalProperties: getAdditionalAnalytics({
        paymentAmount,
        bill,
        fundingSourceTypesOptions,
        repaymentTerms,
        isFxPayment,
        fxRate,
      }),
    });

    useAnalyticsView('PaymentMethod', !isLoading, true, {
      Intent: 'choose-payment-method',
    });

    const onSelect = (fundingSourceId: FundingSource['id']) => {
      setSelectedFundingSourceId(fundingSourceId);
      setSelectFinancingOption?.(false);
    };

    const onNext = () => {
      startAction('funding_source_selection');
      onDone(selectedFundingSourceId, paymentAmount);
    };

    const screenDescription = useScreenDescription({
      paymentAmount,
      currency: bill?.currency,
      vendor,
      recurringPaymentDetails,
    });

    const onSelectFinancingOption = () => {
      setSelectFinancingOption?.(true);
    };

    const showNoGoodsReceivedSectionBanner = !goodsReceived;
    const showFreeAchPromotionSectionBanner =
      isCloverFreeAchPromotion && goodsReceived && !shouldShowMccErrorBanner && !(failedDate && failedFundingSource);
    const showSameFundingSourceSectionBanner = failedDate && failedFundingSource;

    const isRecurringPayment = !!recurringPaymentDetails;
    const isEditingSingleRecurringPayment = isRecurringPayment && recurringPaymentDetails.occurrenceNumber;

    const disableUpdatingFundingSource = !!(
      isEditingSingleRecurringPayment &&
      recurringPaymentDetails.managedBy &&
      vendor.isManaged
    );

    const shouldShowProgressBar = step !== undefined && totalSteps !== undefined;
    return (
      <NewSinglePaymentStepLayout
        ref={ref}
        data-component="FundingSourceSelectionActivity.FundingSourceSelectionScreen"
        data-testid="funding-source-selection-activity-funding-source-selection-screen"
        isLoading={isLoading}
        {...props}
        headerContent={
          <NewSinglePaymentStepLayout.Header>
            {shouldShowProgressBar && (
              <NewSinglePaymentStepLayout.ProgressBar currentStep={step} totalSteps={totalSteps} />
            )}
            <NewSinglePaymentStepLayout.CloseButton onClick={onClose} />
          </NewSinglePaymentStepLayout.Header>
        }
        footerContent={
          <Group variant="vertical" spacing="xxl">
            {fundingSources.length > 0 && (
              <NewSinglePaymentStepLayout.Actions>
                <NewSinglePaymentStepLayout.NextButton
                  isDisabled={!selectedFundingSourceId || paymentAmountHasError}
                  onClick={onNext}
                  label={ctaLabel}
                />
              </NewSinglePaymentStepLayout.Actions>
            )}
          </Group>
        }
        bottomContent={
          <NewSinglePaymentStepLayout.Content>
            <PCIComplienceInfo />
          </NewSinglePaymentStepLayout.Content>
        }
      >
        <SystemMessageDisplay data-testid="funding-source-selection-message-display" />
        <FundingSourceSelectionScreenTitle
          paymentAmount={paymentAmount}
          isEbill={isBillEbill}
          billBalance={billBalance}
          minimumAmount={minimumAmount}
          vendorName={vendor.name}
          currency={bill?.currency}
          isRecurringPayment={!!recurringPaymentDetails}
          onChangePaymentAmount={(newAmount) => {
            paymentAmountError.off();
            onChangePaymentAmount?.(newAmount);
          }}
          shouldAllowEditAmount={shouldAllowEditAmount}
          onError={() => paymentAmountError.on()}
          fxRate={fxRate}
          isLoadingFxRate={isLoadingFxRate}
        />
        {isFxPayment && !isLoadingFxRate && fxRate && (
          <RateSection
            data-testid="fx-payment-rate-banner"
            quote={fxRate}
            message="activities.fundingSourceSelection.screens.fundingSourceSelection.fxPaymentRateBanner.rate"
            tooltipOverrides={
              isRetryFlow
                ? {
                    label: formatMessage(
                      'activities.fundingSourceSelection.screens.fundingSourceSelection.fxPaymentRateBanner.rate.retry.tooltipText'
                    ),
                  }
                : undefined
            }
          />
        )}
        {screenDescription ? (
          <NewSinglePaymentStepLayout.Description>{screenDescription}</NewSinglePaymentStepLayout.Description>
        ) : null}

        <NewSinglePaymentStepLayout.Content>
          <Group variant="vertical" spacing="m">
            {showNoGoodsReceivedSectionBanner && (
              <SectionBanner
                data-testid="no-goods-section-banner-warning"
                variant="warning"
                title={formatMessage(
                  'activities.fundingSourceSelection.screens.fundingSourceSelection.goodsNotReceived.title'
                )}
                description={formatMessage(
                  'activities.fundingSourceSelection.screens.fundingSourceSelection.goodsNotReceived.description'
                )}
              />
            )}
            {showFreeAchPromotionSectionBanner && (
              <SectionBanner
                data-testid="free-ach-promotion-fs-section-banner-info"
                title={formatMessage(
                  'activities.fundingSourceSelection.screens.fundingSourceSelection.feeAchPromotion.title'
                )}
                description={formatMessage(
                  'activities.fundingSourceSelection.screens.fundingSourceSelection.feeAchPromotion.description'
                )}
                variant="informative"
              />
            )}
            {failedFundingSource && showSameFundingSourceSectionBanner && (
              <SectionBanner
                data-testid="same-funding-source-section-banner-info"
                variant="informative"
                description={formatMessage(
                  'activities.fundingSourceSelection.screens.fundingSourceSelection.failedPayment.description',
                  {
                    date: formatDate(failedDate, { dateStyle: 'medium' }),
                    FS: getDisplayText(failedFundingSource),
                  }
                )}
              />
            )}
            {shouldShowMccErrorBanner && (
              <SectionBanner
                data-testid="mcc-selection-modal-section-banner-error"
                variant="critical"
                title={formatMessage(
                  'activities.fundingSourceSelection.screens.fundingSourceSelection.mccSelectionFailedLoading.title'
                )}
                description={formatMessage(
                  'activities.fundingSourceSelection.screens.fundingSourceSelection.mccSelectionFailedLoading.description'
                )}
              />
            )}
            <FundingSourceCardListOfLists
              isLoadingRepaymentTerms={isLoadingRepaymentTerms}
              isRepaymentTermsError={isRepaymentTermsError}
              data={fundingSources}
              selectedId={selectedFundingSourceId}
              onVerify={onVerify}
              onAdd={onAdd}
              onSelect={onSelect}
              fundingSourceTypesOptions={fundingSourceTypesOptions}
              shouldAllowFinancingOption={shouldAllowFinancingOption}
              repaymentTerms={repaymentTerms}
              userId={userId}
              onSelectFinancingOption={onSelectFinancingOption}
              isFinancingSelected={isFinancingSelected}
              disableUpdatingFundingSource={disableUpdatingFundingSource}
            />
          </Group>
        </NewSinglePaymentStepLayout.Content>
      </NewSinglePaymentStepLayout>
    );
  }
);

FundingSourceSelectionScreen.displayName = 'FundingSourceSelectionActivity.FundingSourceSelectionScreen';
