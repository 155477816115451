import { EditSinglePaymentActivity, PaymentFlowDoneAction } from '@melio/ap-activities';
import { useBlockPaymentsForOrganizations } from '@melio/ap-domain';
import { usePayment } from '@melio/platform-api';
import { useNavigate } from '@melio/platform-utils';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { usePaymentFlowIntuitConnect } from '@/hooks/accountingPlatforms.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { useIsNewDashboardEnabled } from '@/hooks/useIsNewDashboardEnabled';
import { PayDashboardTabs } from '@/types/payDashboard.types';
import { BlockedInternationalPaymentsForState } from '../blocked-international-payments/BlockedInternationalPaymentsForState.screen';

export const EditPaymentScreen = ({ paymentId, returnUrl }: { paymentId: string; returnUrl?: string }) => {
  const navigate = useNavigate();
  const { replaceToPayDashboardTab, generateNPEDashboardLink, goToPayDashboard } = useRouter();
  const { loginToAccountPlatformAuth, isLoginToAccountPlatformAuthLoading } = usePaymentFlowIntuitConnect({
    redirectMethod: 'replace',
  });
  const { shouldBlockInternationalPayments, getBlockReason } = useBlockPaymentsForOrganizations();
  const { data: payment, isLoading: isLoadingPayment } = usePayment({
    id: paymentId,
  });
  const shouldRedirectToNewDashboard = useIsNewDashboardEnabled();

  const handleClose = () => {
    if (returnUrl) {
      return navigate(returnUrl);
    }
    replaceToPayDashboardTab(PayDashboardTabs.Scheduled, paymentId);
  };

  const handleDone = (paymentId: string, action: PaymentFlowDoneAction) => {
    if (action === 'syncQBOAccountingPlatform') {
      return loginToAccountPlatformAuth();
    }
    if (shouldRedirectToNewDashboard) {
      return navigate(generateNPEDashboardLink('payments'));
    }

    replaceToPayDashboardTab(PayDashboardTabs.Scheduled);
  };

  if (isLoadingPayment) {
    return <Loader isAbsoluteCenter />;
  }
  const deliveryMethods = payment?.deliveryMethod ? [payment.deliveryMethod] : undefined;
  if (shouldBlockInternationalPayments(deliveryMethods)) {
    return (
      <BlockedInternationalPaymentsForState
        onClose={() => goToPayDashboard()}
        blockReason={getBlockReason(deliveryMethods)}
      />
    );
  }

  return (
    <EditSinglePaymentActivity
      paymentId={paymentId}
      onBack={handleClose}
      onClose={handleClose}
      onDone={handleDone}
      isLoading={isLoginToAccountPlatformAuthLoading}
    />
  );
};
