import { Box } from '@chakra-ui/react';
import { Text } from '@melio/penny';

import { usePlatformIntl } from '@/translations/Intl';

export const BillPaymentDetailsEmptyState = () => {
  const { formatMessage } = usePlatformIntl();

  return (
    <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
      <Text textStyle="body4" color="global.neutral.800">
        {formatMessage('widgets.billPaymentDetailsEmptyState.label')}
      </Text>
    </Box>
  );
};
