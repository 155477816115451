import { Box } from '@chakra-ui/react';
import { getAccountingPlatformNameForAnalytics } from '@melio/ap-domain';
import { AccountingPlatformCard } from '@melio/ap-widgets';
import { Group, Modal, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { AccountingPlatform, AccountingPlatformSlug } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSubscriptionFeature, useSyncedPaymentsCount } from '@melio/subscriptions';
import { useEffect, useState } from 'react';

export type AccountingSoftwareConnectModalProps = {
  isOpen: boolean;
  isLoading: boolean;
  accountingPlatforms: AccountingPlatform[];
  onClose: VoidFunction;
  onConnect: (accountingSlug: AccountingPlatformSlug) => void;
};

export const AccountingSoftwareConnectModal = ({
  isOpen,
  isLoading,
  accountingPlatforms,
  onClose,
  onConnect,
}: AccountingSoftwareConnectModalProps) => {
  const { track } = useAnalytics();
  const { formatMessage } = useMelioIntl();
  const [selectedPlatformToConnect, setSelectedPlatformToConnect] = useState<AccountingPlatformSlug>();
  const [platformXeroAccountingSoftwareEnabled] = useFeature(
    FeatureFlags.PlatformXeroAccountingSoftwareEnabled,
    false,
    {
      shouldTrack: true,
    }
  );

  const [isLimitPaymentsSyncBasedOnPlanQuotaEnabled] = useFeature(
    FeatureFlags.LimitPaymentsSyncBasedOnPlanQuota,
    false
  );

  // TODO: delete this 2 lines once the 'limit-payments-sync-based-on-plan-quota' flag is deleted  https://meliorisk.atlassian.net/browse/ME-74177
  const { tryUseFeature: tryUseConnectQboFeature } = useSubscriptionFeature({ featureName: 'accountingPlatformQbo' });
  const { tryUseFeature: tryUseConnectXeroFeature } = useSubscriptionFeature({ featureName: 'accountingPlatformXero' });

  const { data: syncedPaymentsCountData, isLoading: isLoadingSyncedPaymentsNumber } = useSyncedPaymentsCount();
  const numberOfSyncedPayments = syncedPaymentsCountData?.count || 0;

  const { tryUseFeature: tryUseSyncedPaymentsFeature } = useSubscriptionFeature({
    featureName: 'syncedPayments',
    requirements: { totalUnits: numberOfSyncedPayments + 1 },
  });

  const quickBooksAccountingPlatform = accountingPlatforms.find(
    (accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.QuickBooksOnline
  );
  const quickBooksDesktopAccountingPlatform = accountingPlatforms.find(
    (accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.QuickBooksDesktop
  );
  const xeroAccountingPlatform = accountingPlatforms.find(
    (accountingPlatform) => accountingPlatform.accountingSlug === AccountingPlatformSlug.Xero
  );

  const accountingPlatformsToShow = [];

  if (quickBooksAccountingPlatform) {
    accountingPlatformsToShow.push(quickBooksAccountingPlatform);
  }

  if (quickBooksDesktopAccountingPlatform) {
    accountingPlatformsToShow.push(quickBooksDesktopAccountingPlatform);
  }

  if (platformXeroAccountingSoftwareEnabled && xeroAccountingPlatform) {
    accountingPlatformsToShow.push(xeroAccountingPlatform);
  }

  useEffect(() => {
    track('Bill', 'View', { PageName: 'sync-with-accounting-software', Flow: 'add-bill', Intent: 'sync' });
  }, [track]);

  useEffect(() => {
    if (!isLoading) {
      setSelectedPlatformToConnect(undefined);
    }
  }, [isLoading]);

  const handleAccountingPlatformConnect = (accountingSlug: AccountingPlatformSlug) => {
    const handle = () => {
      setSelectedPlatformToConnect(accountingSlug);
      track('Bill', 'Click', {
        PageName: 'sync-with-accounting-software',
        Flow: 'add-bill',
        Intent: 'sync',
        Cta: `connect-${getAccountingPlatformNameForAnalytics(accountingSlug)}`,
      });

      onConnect(accountingSlug);
    };

    if (
      isLimitPaymentsSyncBasedOnPlanQuotaEnabled &&
      (accountingSlug === AccountingPlatformSlug.QuickBooksOnline || accountingSlug === AccountingPlatformSlug.Xero)
    ) {
      return tryUseSyncedPaymentsFeature({ onFeatureIsEligible: handle });
    }

    // TODO: delete this 2 conds once the 'limit-payments-sync-based-on-plan-quota' flag is deleted  https://meliorisk.atlassian.net/browse/ME-74177
    if (accountingSlug === AccountingPlatformSlug.QuickBooksOnline) {
      return tryUseConnectQboFeature({ onFeatureIsEligible: handle });
    }
    if (accountingSlug === AccountingPlatformSlug.Xero) {
      return tryUseConnectXeroFeature({ onFeatureIsEligible: handle });
    }
    handle();
  };

  const handleClose = () => {
    track('Bill', 'Click', {
      PageName: 'sync-with-accounting-software',
      Flow: 'add-bill',
      Intent: 'sync',
      Cta: 'exit',
    });

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      isLoading={isLoadingSyncedPaymentsNumber}
      header={formatMessage(`activities.payDashboard.billsTab.accountingSoftwareConnectModal.title`)}
      onClose={handleClose}
      data-testid="accounting-software-connect-modal"
      closeButtonAriaLabel={formatMessage('widgets.vendorDrawer.closeButtonAriaLabel')}
    >
      <Group variant="vertical">
        <Text>{formatMessage(`activities.payDashboard.billsTab.accountingSoftwareConnectModal.description`)}</Text>
        <Box marginY="s">
          <Group variant="vertical" spacing="s">
            {accountingPlatformsToShow.map((accountingPlatform) => (
              <AccountingPlatformCard
                key={accountingPlatform.id}
                accountingPlatform={accountingPlatform}
                isLoading={isLoading && accountingPlatform.accountingSlug === selectedPlatformToConnect}
                isDisabled={isLoading && accountingPlatform.accountingSlug !== selectedPlatformToConnect}
                onConnect={() => handleAccountingPlatformConnect(accountingPlatform.accountingSlug)}
                shouldShowRightArrow
              />
            ))}
          </Group>
        </Box>
      </Group>
    </Modal>
  );
};
