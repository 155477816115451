import { FormInputs, useIsMobile, useMelioIntl } from '@melio/ar-domain';
import { Form, FormSelectNewOption, Group, Icon, Text } from '@melio/penny';
import { forwardRef, useUpdateEffect } from '@melio/platform-utils';
import { useRef } from 'react';

import { DueDatePreset, InvoiceFormMode } from '../../types';
import { useInvoiceFormContext } from '../../utils';
import { FormSection } from '../FormSection';

type InvoiceDetailsFormSectionProps = {
  mode?: InvoiceFormMode;
  onCreateCustomer?: (value: string) => void;
  onUpdateCustomer?: (customerId: string) => void;
  onClearCustomer?: () => void;
};

export const InvoiceDetailsFormSection = forwardRef<InvoiceDetailsFormSectionProps>(
  ({ mode, onCreateCustomer, onUpdateCustomer, onClearCustomer, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const isMobile = useIsMobile();
    const { registerField, watch, setValue } = useInvoiceFormContext();

    const isCustomDate = watch('dueDatePreset') == 'custom';

    const dueDateRef = useRef<HTMLInputElement>(null);
    useUpdateEffect(() => {
      if (isCustomDate) {
        dueDateRef.current?.click();
      } else {
        setValue('dueDate', undefined);
      }
    }, [isCustomDate]);

    return (
      <FormSection
        title={formatMessage('ar.invoiceLifecycle.activities.createInvoice.form.sections.invoiceDetails.label')}
        subTitle={formatMessage(
          'ar.invoiceLifecycle.activities.createInvoice.form.sections.invoiceDetails.validation.label'
        )}
        columns={[1, 1, 4]}
        data-testid="form-section-invoice-details"
        data-component={InvoiceDetailsFormSection.displayName}
        {...props}
        ref={ref}
      >
        <FormInputs.CustomerSelect
          colSpan={2}
          {...registerField('customerId')}
          labelProps={{
            label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.customerName.title.label'),
          }}
          placeholder={formatMessage(
            'ar.invoiceLifecycle.activities.createInvoice.inputs.customerName.placeholder.text'
          )}
          onCreate={onCreateCustomer}
          onMissingEmail={onUpdateCustomer}
          onClearCustomer={onClearCustomer}
        />

        <Form.TextField
          colSpan={2}
          {...registerField('invoiceNumber')}
          labelProps={{
            label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.invoiceNumber.title.label'),
          }}
          isReadOnly={mode === 'edit'}
        />

        <FormInputs.Select
          colSpan={2}
          {...registerField('frequency')}
          shouldHideClearButton
          labelProps={{
            label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.invoiceFrequency.title.label'),
          }}
          emptyState={{
            label: formatMessage(
              'ar.invoiceLifecycle.activities.createInvoice.inputs.invoiceFrequency.emptyState.text'
            ),
          }}
          options={[
            {
              value: 'one-time',
              label: formatMessage(
                'ar.invoiceLifecycle.activities.createInvoice.inputs.invoiceFrequency.options.oneTime.text'
              ),
            },
            {
              value: 'weekly',
              label: formatMessage(
                'ar.invoiceLifecycle.activities.createInvoice.inputs.invoiceFrequency.options.weekly.text'
              ),
            },
            {
              value: 'monthly',
              label: formatMessage(
                'ar.invoiceLifecycle.activities.createInvoice.inputs.invoiceFrequency.options.monthly.text'
              ),
            },
            {
              value: 'annually',
              label: formatMessage(
                'ar.invoiceLifecycle.activities.createInvoice.inputs.invoiceFrequency.options.annually.text'
              ),
            },
          ]}
          valueRenderer={renderInvoiceFrequencyOption}
          optionRenderer={renderInvoiceFrequencyOption}
          isReadOnly
        />

        <FormInputs.Select
          colSpan={isCustomDate ? 1 : 2}
          {...registerField('dueDatePreset')}
          labelProps={{
            label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.invoiceDue.title.label'),
          }}
          emptyState={{ label: '' }}
          shouldHideClearButton
          options={[
            {
              value: DueDatePreset.Today,
              label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.dueDate.preset.today.label'),
            },
            {
              value: DueDatePreset.SevenDays,
              label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.dueDate.preset.7days.label'),
            },
            {
              value: DueDatePreset.FourteenDays,
              label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.dueDate.preset.14days.label'),
            },
            {
              value: DueDatePreset.ThirtyDays,
              label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.dueDate.preset.30days.label'),
            },
            {
              value: DueDatePreset.Custom,
              label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.dueDate.preset.custom.label'),
            },
          ]}
        />

        <Form.DateField
          ref={dueDateRef}
          isHidden={!isCustomDate}
          colSpan={1}
          excludeHolidays={false}
          weekDays={[0, 1, 2, 3, 4, 5, 6]}
          labelProps={{
            label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.dueDate.title.label'),
          }}
          {...registerField('dueDate')}
          isRequired
        />

        <Form.TextArea
          {...registerField('note')}
          colSpan={4}
          data-private
          labelProps={{ label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.note.title.label') }}
          numberOfRows={isMobile ? 2 : 1}
          placeholder={formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.note.placeholder.text')}
          maxChars={500}
        />
      </FormSection>
    );
  }
);

InvoiceDetailsFormSection.displayName = 'InvoiceDetailsFormSection';

const renderInvoiceFrequencyOption = (selectedOption: FormSelectNewOption<string>) => (
  <Group width="full" alignItems="center" justifyContent="space-between" spacing="xs">
    <Text textStyle="inline" shouldSupportEllipsis>
      {selectedOption.label}
    </Text>
    {selectedOption.value !== 'one-time' && <Icon type="repeat" size="small" />}
  </Group>
);
