import { SystemMessage, useSystemMessage } from '@melio/ar-domain';
import { Container } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export const FormPanelHeaderMobile = forwardRef((_, ref) => {
  const { message } = useSystemMessage();

  return message ? (
    <Container ref={ref} paddingX="s" paddingTop="l" paddingBottom="xs">
      <SystemMessage />
    </Container>
  ) : null;
});

FormPanelHeaderMobile.displayName = 'FormPanelHeaderMobile';
