import { useOrganizationPreferences } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

export const useIsArEnabled = (defaultValue = false) => {
  const [arDashboardEnabled] = useFeature(FeatureFlags.ARDashboard, defaultValue);
  const { data: orgPreferences, isLoading: isOrgPreferencesLoading } = useOrganizationPreferences();
  const isArOrgPrefEnabled = orgPreferences?.find((item) => item.key === 'isArEnabled')?.value === 'true';
  const orgPrefValue = isOrgPreferencesLoading ? defaultValue : isArOrgPrefEnabled;

  return arDashboardEnabled || orgPrefValue;
};
