import { Box } from '@chakra-ui/react';
import { throttle } from 'lodash';
import { Button, IconButton, Link, LinkProps, useToast } from '@melio/penny';

import { LabelCopyComponentTheme } from '@/cl/components/LabelCopy/LabelCopy.component.theme';
import { useAppTheme } from '@/cl/theme/theme.hooks';

type Props = {
  testId: string;
  variant?: 'button' | 'icon';
  direction?: 'column' | 'row';
  align?: 'center' | 'left';
  size?: 'small' | 'medium' | 'large';
  label: string;
  link: string;
  linkColor?: LinkProps['color'];
  buttonLabel?: string;
  buttonAriaLabel?: string;
  successMessage?: string;
  errorMessage?: string;
  onCopy?: () => void;
  onLinkClick?: () => void;
};

export const LabelCopy = ({
  testId,
  variant = 'icon',
  direction = 'row',
  align = 'left',
  size = 'small',
  label,
  link,
  linkColor,
  buttonLabel,
  buttonAriaLabel = 'copy',
  successMessage,
  errorMessage,
  onCopy,
  onLinkClick,
}: Props) => {
  const styles = useAppTheme(LabelCopyComponentTheme, { variant, align, direction });
  const { toast } = useToast();

  const copyToClipboard = throttle(async () => {
    return navigator.clipboard
      .writeText(link)
      .then(
        () =>
          successMessage &&
          toast({
            type: 'informative',
            title: successMessage,
          }),
      )
      .catch(
        () =>
          errorMessage &&
          toast({
            type: 'error',
            title: errorMessage,
          }),
      )
      .finally(onCopy);
  }, 2000);

  const onLinkClicked = () => {
    onLinkClick?.();
    return true;
  };

  return (
    <Box sx={styles['container']}>
      <Box sx={styles['link']} onClick={onLinkClicked} onContextMenu={onLinkClicked}>
        <Link href={link} label={label} newTab data-testid={testId} color={linkColor} shouldSupportEllipsis />
      </Box>
      {variant === 'icon' ? (
        <IconButton
          aria-label={buttonAriaLabel}
          size="small"
          variant="naked"
          icon="duplicate"
          onClick={copyToClipboard}
        />
      ) : (
        <Button
          aria-label={buttonAriaLabel}
          size={size}
          variant="tertiary"
          onClick={copyToClipboard}
          label={buttonLabel}
        />
      )}
    </Box>
  );
};
