import { useGuestPayorOnboarding } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useEffect } from 'react';

type OnboardingActivityProps = {
  paymentRequestLink: string;
  onDone: (vendorId: string, isMissingMcc: boolean) => void;
  onError?: ARErrorFunction;
  onClose?: VoidFunction;
};

export const OnboardingActivity = forwardRef<OnboardingActivityProps>(
  ({ paymentRequestLink, onDone, onError, onClose }) => {
    const { onboarding } = useGuestPayorOnboarding();
    useEffect(
      () => {
        onboarding({ paymentRequestLink })
          .then(({ vendorId, isMissingMcc }) => onDone(vendorId, isMissingMcc))
          .catch((error: ARPlatformError) => {
            onClose?.();
            onError?.(error);
          });
      },
      [] // eslint-disable-line react-hooks/exhaustive-deps
    );
    return null;
  }
);
OnboardingActivity.displayName = 'OnboardingActivity';
