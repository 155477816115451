import { useModelApi } from '@melio/api-client';
import { SubscriptionSyncedPaymentsApiClient } from '@melio/platform-api-axios-client';

export const useSyncedPaymentsCount = () =>
  useModelApi({
    id: 'synced-payments-count',
    queryKey: ['SubscriptionSyncedPaymentsApi'],
    queryFn: () => SubscriptionSyncedPaymentsApiClient.getSubscriptionsSyncedPayments(),
    cacheTime: 0,
  });
