import { useBreakpointValue } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useSubscriptionFeature } from '@melio/subscriptions';

export const useIsLineItemsAvailable = () => {
  const [isLineItemsEnabledOnMobile] = useFeature(FeatureFlags.IsLineItemsEnabledOnMobile, true);
  const isMobile = useBreakpointValue({ xs: true, s: false }, { ssr: false } as never);

  const { isEligible: isEligibleForLineItems } = useSubscriptionFeature({ featureName: 'lineItems' });
  return isEligibleForLineItems && (isMobile ? isLineItemsEnabledOnMobile : true);
};
