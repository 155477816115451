/* eslint-disable no-restricted-syntax */
import { getDefaultDeliveryPreferenceByDeliveryMethodType } from '@melio/ap-domain';
import {
  ApiError,
  BillSubscriptionErrorCode,
  DeliveryPreferenceType,
  ManagedAccountDeliveryMethod,
  ModelError,
  PaymentErrorCode,
  Vendor,
} from '@melio/platform-api';
import { useEffect, useMemo, useState } from 'react';

import { PaymentFlowFormFields } from '../../../../types';
import { DeliverMethodChangedError } from '../types';
import { useDescriptionText } from './useDescriptionText';
import { useHeaderText } from './useHeaderText';

export function useDeliveryMethodChangedModal({
  submitError,
  vendor,
  formValues,
  onSubmit,
}: {
  submitError: ModelError | ApiError | undefined | null;
  formValues: PaymentFlowFormFields;
  vendor: Vendor | undefined;
  onSubmit: (fieldValues: PaymentFlowFormFields) => Promise<unknown>;
}) {
  const [deliveryMethodChangedErrorData, setDeliveryMethodChangedErrorData] = useState<{
    dmId: string;
    debitDate?: string;
    deliveryDate?: string;
  }>();
  const today = useMemo(() => new Date(), []);

  const { deliveryDate, deliveryMethodId, scheduleDate, startDate, recurrenceType } = formValues;

  useEffect(() => {
    if (!isDeliveryMethodChangedError(submitError)) {
      return;
    }

    const [errorData] = submitError.data;
    const { dmId, deliveryDate, debitDate } = errorData;
    setDeliveryMethodChangedErrorData({ dmId, deliveryDate, debitDate });
  }, [submitError]);

  const getDeliveryMethodChangeDiff = () => {
    if (!deliveryMethodChangedErrorData) {
      return;
    }

    const newDeliveryMethod = vendor?.deliveryMethods?.find(
      (method) => method.id === deliveryMethodChangedErrorData.dmId
    ) as ManagedAccountDeliveryMethod;

    const oldDeliveryMethod = vendor?.deliveryMethods?.find(
      (method) => method.id === deliveryMethodId
    ) as ManagedAccountDeliveryMethod;

    if (!newDeliveryMethod || !oldDeliveryMethod) {
      return;
    }

    const existingDeliveryDate = (recurrenceType === 'recurring' ? startDate : deliveryDate) || today;
    const existingDebitByDate = scheduleDate || today;

    const { debitDate: newDebitDate, deliveryDate: newDeliveryDate } = deliveryMethodChangedErrorData;

    return {
      deliveryMethod: {
        old: oldDeliveryMethod,
        new: newDeliveryMethod,
      },
      deliveryByDate: {
        old: existingDeliveryDate,
        new: newDeliveryDate ? new Date(newDeliveryDate) : existingDeliveryDate,
      },
      debitByDate: {
        old: scheduleDate || today,
        new: newDebitDate ? new Date(newDebitDate) : existingDebitByDate,
      },
    };
  };

  const deliveryMethodChangeDiff = getDeliveryMethodChangeDiff();

  const close = () => setDeliveryMethodChangedErrorData(undefined);

  const confirm = () => {
    if (!deliveryMethodChangeDiff) {
      return;
    }

    const deliveryPreference =
      deliveryMethodChangeDiff?.deliveryMethod.new.details.deliveryType === 'check'
        ? DeliveryPreferenceType.Check
        : getDefaultDeliveryPreferenceByDeliveryMethodType(deliveryMethodChangeDiff.deliveryMethod.new.type);

    const newValues = {
      ...formValues,
      deliveryMethodId: deliveryMethodChangeDiff.deliveryMethod.new.id,
      deliveryPreference,
      scheduleDate: deliveryMethodChangeDiff.debitByDate.new,
      deliveryDate: deliveryMethodChangeDiff.deliveryByDate.new,
      startDate: deliveryMethodChangeDiff.deliveryByDate.new,
    };

    const submitValues = {
      ...newValues,
      etaDetails: {
        deliveryDate: deliveryMethodChangeDiff.deliveryByDate.new,
        scheduledDate: deliveryMethodChangeDiff.debitByDate.new,
      },
    };

    return onSubmit(submitValues).finally(close);
  };

  return {
    open: !!deliveryMethodChangedErrorData,
    header: useHeaderText(deliveryMethodChangeDiff, vendor?.name),
    description: useDescriptionText(deliveryMethodChangeDiff),
    close,
    confirm,
  };
}

const isDeliveryMethodChangedError = (error?: ModelError | ApiError | null): error is DeliverMethodChangedError => {
  const deliveryMethodChangedErrors: string[] = [
    PaymentErrorCode.DeliveryMethodChanged,
    BillSubscriptionErrorCode.DeliveryMethodChangedForBillSubscription,
  ];

  return Boolean(error?.errorCode && deliveryMethodChangedErrors.includes(error?.errorCode));
};
