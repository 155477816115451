import React, { useCallback, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { MicroDepositModalRefHandlers, MicroDepositModalWrapper } from '@melio/ap-activities';
import { ActionsDropdownMenu, Group, Icon, IconKey, Modal, NakedButton, Typography } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import {
  BankAccount,
  DeliveryMethod,
  useAccountingPlatforms,
  useDeliveryMethod,
  useIsDeliveryMethodPlaidLinkTokenLoading,
} from '@melio/platform-api';

import { AsyncModalDialog } from '@/cl/components/AsyncModalDialog/AsyncModalDialog.component';
import { MethodCard } from '@/cl/components/MethodCard/MethodCard.component';
import { useRouter } from '@/hooks/router.hooks';
import { useDisclosure } from '@/hooks/useDisclosure';
import { usePlatformIntl } from '@/translations/Intl';
import {
  getAccountNumber4digits,
  getDeliveryMethodName,
  isManualBankAccountNotVerified,
  useReceivingMethodActions,
} from '@/utils/receivingMethods.utils';

interface ReceivingMethodWithAction {
  isHideActions?: boolean;
  deliveryMethod: DeliveryMethod;
}

export const ReceivingMethodWithActions = ({ deliveryMethod, isHideActions }: ReceivingMethodWithAction) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { track } = useAnalytics();

  const {
    goViewReceivingMethodBankAccount,
    goToEditPlaidBankAccount,
    goToEditManualBankAccount,
    goToReceivingMethodLinkBankAccount,
  } = useRouter();

  const { activeAccountingPlatform, hasAccountingPlatform } = useAccountingPlatforms();
  const { isOpen: isOpenDeleteDialog, onOpen: onOpenDeleteDialog, onClose: onCloseDeleteDialog } = useDisclosure();
  const { isOpen: isOpenUpdateDialog, onOpen: onOpenUpdateDialog, onClose: onCloseUpdateDialog } = useDisclosure();

  const { delete: deleteDeliveryMethod, isUpdating: isDeliveryMethodUpdating } = useDeliveryMethod({
    id: deliveryMethod?.id,
  });
  const isDeliveryMethodPlaidLinkTokenLoading = useIsDeliveryMethodPlaidLinkTokenLoading();
  const isDeliveryMethodBeingOperatedOn = isDeliveryMethodUpdating || isDeliveryMethodPlaidLinkTokenLoading;

  const microDepositRef = React.useRef<MicroDepositModalRefHandlers>(null);
  const { logo } = deliveryMethod?.details as BankAccount;
  const { formatMessage } = usePlatformIntl();

  const openMicroDepositsModal = React.useCallback(() => {
    microDepositRef.current?.openModal();
  }, [microDepositRef]);

  const handleEditAction = () => {
    //todo redirect to receiving method edit page
    track('UpdatebankReceivingMethod', 'Chose');
    onOpenUpdateDialog();
  };

  const goEditDeliveryMethod = useCallback(() => {
    if (deliveryMethod?.id) {
      const isPlaidAccountDeliveryMethod =
        deliveryMethod.type === 'bank-account' && !!deliveryMethod?.details.plaidAccount?.accountId;

      track('UpdateReceivingMethod', 'Submitted');

      isPlaidAccountDeliveryMethod
        ? goToEditPlaidBankAccount(deliveryMethod.id)
        : goToEditManualBankAccount(deliveryMethod.id);
    }
  }, [deliveryMethod, track, goToEditPlaidBankAccount, goToEditManualBankAccount]);

  const handleOnMicroDepositSuccess = () => {
    if (!hasAccountingPlatform) return;
    goToReceivingMethodLinkBankAccount(deliveryMethod.id);
  };

  const onCloseDeliveryMethodDialog = () => {
    track('CancelUpdatebankReceivingMethod', 'Chose');
    onCloseUpdateDialog();
  };

  const goViewDeliveryMethod = (deliveryMethodId: string) => {
    goViewReceivingMethodBankAccount(deliveryMethodId);
  };

  const onEditSyncDetails = (deliveryMethodId: string) => {
    track('EditSyncDetailsReceivingMethod', 'Chose');
    goToReceivingMethodLinkBankAccount(deliveryMethodId);
  };

  const handleDeleteAction = () => {
    track('DeleteReceivingMethod', 'Chose');
    onOpenDeleteDialog();
  };

  const handleOnDelete = async () => {
    try {
      track('DeleteReceivingMethod', 'Submitted');
      await deleteDeliveryMethod();
      track('DeleteReceivingMethod', 'Succeeded');
      onCloseDeleteDialog();
    } catch (e) {
      onCloseDeleteDialog();
    }
  };

  const onCloseDeleteDeliveryMethodDialog = () => {
    track('CancelReceivingMethodDelete', 'Chose');
    onCloseDeleteDialog();
  };

  const title = formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.deleteModal.title');
  const content = formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.deleteModal.context');
  const primaryBtnTxt = formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.deleteModal.primary');
  const secondaryBtnTxt = formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.deleteModal.secondary');

  const dataTestId = `settings-payment-method-${deliveryMethod.id}-menu`;

  const imageObj = {
    logo,
  };

  const deliveryMethodName = getDeliveryMethodName(deliveryMethod);

  const actions = useReceivingMethodActions({
    activeAccountingPlatform,
    deliveryMethod,
    onDelete: handleDeleteAction,
    onEditLabel: handleEditAction,
    dataTestIdPrefix: dataTestId,
    onEditSyncDetails,
    goViewDeliveryMethod,
  });

  const getItemHelperTextAndDescription = (deliveryMethod: DeliveryMethod) => {
    const displayName = isManualBankAccountNotVerified(deliveryMethod) ? (
      <Typography.MainLabel
        label={formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.displayName')}
        variant="bold"
        pillProps={{
          status: 'neutral',
          type: 'secondary',
          label: formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.unverified.helperText'),
        }}
      />
    ) : (
      `${formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.displayName')}`
    );

    const helperText = (
      <>
        {deliveryMethodName}
        {isManualBankAccountNotVerified(deliveryMethod) && (
          <Box ml={1} as="span">
            <NakedButton
              variant="secondary"
              onClick={openMicroDepositsModal}
              data-testid="open-micro-deposit-modal"
              isDisabled={isDeliveryMethodBeingOperatedOn}
              label={formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.unverified.link')}
            />
          </Box>
        )}
      </>
    );

    return { displayName, helperText };
  };

  return (
    <Box>
      <MethodCard
        isDisabled={false}
        key={deliveryMethod.id}
        actionElement={
          !isHideActions ? (
            <ActionsDropdownMenu
              size="small"
              label="menu action"
              data-testid={dataTestId}
              items={actions}
              isOpen={isMenuOpen}
              onOpenChange={setIsMenuOpen}
            />
          ) : undefined
        }
        imageObj={imageObj}
        icon={<Icon type={'bank' as IconKey} color="inherit" />}
        {...getItemHelperTextAndDescription(deliveryMethod)}
      />
      <AsyncModalDialog
        body={content}
        isOpen={isOpenDeleteDialog}
        cancelText={secondaryBtnTxt}
        confirmText={primaryBtnTxt}
        onClose={onCloseDeleteDeliveryMethodDialog}
        onConfirm={handleOnDelete}
        title={title}
        type="cancel"
      />
      <Modal
        isOpen={isOpenUpdateDialog}
        onClose={onCloseDeliveryMethodDialog}
        header={formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.updateModal.title')}
        secondaryButton={{
          variant: 'secondary',
          label: formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.updateModal.secondary'),
          onClick: onCloseDeliveryMethodDialog,
        }}
        primaryButton={{
          variant: 'primary',
          label: formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.updateModal.primary'),
          onClick: goEditDeliveryMethod,
        }}
      >
        <Group spacing="s" variant="vertical">
          <Box textStyle="body2">
            {formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.updateModal.context', {
              activeBankAccount: getAccountNumber4digits(deliveryMethod?.details as BankAccount),
            })}
          </Box>
          <Box textStyle="body2">
            {formatMessage('widgets.receivingMethodsSettings.receivingMethodItem.updateModal.description')}
          </Box>
        </Group>
      </Modal>
      <MicroDepositModalWrapper
        ref={microDepositRef}
        deliveryMethodId={deliveryMethod.id}
        onSuccess={handleOnMicroDepositSuccess}
      />
    </Box>
  );
};
ReceivingMethodWithActions.displayName = 'ReceivingMethodWithActions';
