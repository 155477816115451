import { Divider, Grid, Group } from '@melio/penny';

import { PlanCardButton } from './PlanCardButton';
import { PlanCardButtonProps } from './PlanCardButton/PlanCardButton';
import { PlanCardFeaturesList, PlanCardFeaturesListProps } from './PlanCardFeaturesList';
import { PlanCardFeeFooter, PlanCardFeeFooterProps } from './PlanCardFeeFooter';
import { PlanCardHeader } from './PlanCardHeader';
import { PlanCardHeaderProps } from './PlanCardHeader/PlanCardHeader';
import { PlanCardPrice, PlanCardPriceProps } from './PlanCardPrice';

export type PlanCardViewProps = {
  id: string;
  headerProps: PlanCardHeaderProps;
  priceProps: PlanCardPriceProps;
  selectPlanButtonProps?: PlanCardButtonProps;
  featureListProps: PlanCardFeaturesListProps;
  planFeeProps?: PlanCardFeeFooterProps | null;
  shouldRenderMostPopularVariation?: boolean;
  shouldRenderCurrentPlanVariation?: boolean;
  onClick?: VoidFunction;
};

export const PlanCardView = ({
  id,
  shouldRenderCurrentPlanVariation,
  shouldRenderMostPopularVariation,
  headerProps,
  priceProps,
  selectPlanButtonProps,
  featureListProps,
  planFeeProps,
  onClick,
}: PlanCardViewProps) => (
  <Grid
    borderRadius="l"
    backgroundColor={shouldRenderMostPopularVariation ? 'global.brand.100' : 'initial'}
    border={shouldRenderCurrentPlanVariation ? 'none' : '1px transparent'}
    outline={shouldRenderCurrentPlanVariation ? '2px solid' : '1px solid'}
    outlineOffset="-2px"
    boxSizing="border-box"
    outlineColor={
      shouldRenderCurrentPlanVariation
        ? 'global.brand.700'
        : shouldRenderMostPopularVariation
        ? 'global.brand.200'
        : 'global.neutral.400'
    }
    gridTemplateRows="subgrid"
    gridRow="auto / span 5"
    p="m"
    gap="l"
    data-testid={`subscription-plan-card-${id}`}
    cursor={onClick ? 'pointer' : 'initial'}
    onClick={onClick}
  >
    <PlanCardHeader {...headerProps} />
    <PlanCardPrice {...priceProps} />

    <Group variant="vertical" spacing="s">
      {selectPlanButtonProps && <PlanCardButton {...selectPlanButtonProps} />}
      <Divider />
    </Group>

    <PlanCardFeaturesList {...featureListProps} />
    {planFeeProps && <PlanCardFeeFooter {...planFeeProps} />}
  </Grid>
);
