/* eslint-disable react-hooks/exhaustive-deps */
import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import {
  AutoPaymentCancellationModalActivity,
  CancelEBillSubscriptionModalActivity,
  MonitoredAction,
} from '@melio/ap-activities';
import { DeleteVendorModal } from '@melio/ap-activities/src/components/PayDashboard/components/VendorsTab/components/DeleteVendor/DeleteVendorModal';
import { useVendorActions } from '@melio/ap-domain';
import {
  ErrorTypeToErrorCodesMap,
  getErrorsByType,
  getVendorNameForNotificationMessage,
  InvalidVendorBankAccountDetails,
  InvalidVendorBankAccountModal,
  useVendorDirectoryMissingFields,
} from '@melio/ap-widgets';
import { Drawer, LoadingContainer, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import {
  isVendorBankAccountNotCreated,
  LinkVendorToDirectoryParams,
  ModelError,
  UpdateVendorParams,
  useMelioQueryClient,
  useVendor,
  Vendor,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import { usePartnerFeature } from '@melio/platform-provider';
import { SystemMessage, useLocation, useSystemMessage, withSystemMessageProvider } from '@melio/platform-utils';

import { usePlatformIntl } from '@/translations/Intl';
import { VendorDetailsFormFields } from '@/types/vendors.types';
import { useAnalyticsTaxIdType } from './hooks/useAnalyticsTaxIdType';
import { useVendorDrawerAnalytics } from './hooks/useVendorDrawerAnalytics';
import { VendorDrawerBody } from './VendorDrawerBody.widget';
import { VendorDrawerFooter } from './VendorDrawerFooter.widget';

type VendorDrawerProps = {
  onClose: VoidFunction;
  vendorId: string;
  onEditBillSubscription: ({ id }: { id: string }) => void;
  onDeleteSuccess: (vendor: Vendor) => void;
};

export const VendorDrawer = withSystemMessageProvider(
  withAnalyticsContext(({ onClose, vendorId, onEditBillSubscription, onDeleteSuccess }: VendorDrawerProps) => {
    const { formatMessage } = useMelioIntl();
    const [isClosing, setIsClosing] = useState(false);

    // The expand here is to avoid a separate react query api call for the analytics
    const { data: vendor } = useVendor({ id: vendorId, params: { expand: ['billSubscriptions.nextOccurrence'] } });
    const analyticsTaxIdType = useAnalyticsTaxIdType(vendor);
    const { isOpen: isDeleteModalOpen, onOpen: onDeleteModalOpen, onClose: onDeleteModalClose } = useDisclosure();
    const {
      isOpen: isCancelEBillSubscriptionModalOpen,
      onOpen: onCancelEBillSubscriptionModalOpen,
      onClose: onCancelEBillSubscriptionModalClose,
    } = useDisclosure();
    const {
      isOpen: isAutoPayCancellationModalOpen,
      onOpen: onAutoPayCancellationModalOpen,
      onClose: onAutoPayCancellationModalClose,
    } = useDisclosure();
    const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
    const queryClient = useMelioQueryClient();
    const { state: locationState } = useLocation();
    const { showMessage } = useSystemMessage();

    const { isEditMode, setIsEditMode } = useEditMode();
    const vendorDirectoryMissingFields = useVendorDirectoryMissingFields(vendor);
    const isVendorDirectoryInfoCompleted = !vendorDirectoryMissingFields.length;
    const {
      submitButtonProps,
      cancelButtonProps,
      onSubmissionStateChange,
      onSubmit,
      handleResetForm,
      apiErrorsCodes,
      closeInvalidVendorBankAccountModal,
      handleUpdateVendorDone,
      invalidBankAccountModalData,
    } = useEditVendor({
      vendorId,
      isVendorDirectoryInfoCompleted,
    });
    const { hideMessage } = useSystemMessage();

    const vendorActions = useVendorActions(vendor);
    const hasActions = Object.values(vendorActions).some(Boolean);

    const { track } = useAnalytics();
    useVendorDrawerAnalytics();

    const handleClose = () => {
      setIsEditMode(false);
      onClose();
    };

    const handleDeleteSuccess = (vendor: Vendor) => {
      void queryClient.invalidateQueries('InboxItemsApi');
      void queryClient.invalidateQueries('PaymentsApi');
      void queryClient.invalidateQueries('delivery-method-type-options');
      onDeleteSuccess(vendor);
      handleClose();
    };

    const shouldReturnFocus = useRef(!!document.activeElement && document.activeElement !== document.body);

    useEffect(() => {
      const locationStateObject = locationState as { message: SystemMessage | undefined } | undefined;
      if (locationStateObject?.message) {
        showMessage({ ...locationStateObject.message });
        window.history.replaceState({ ...locationStateObject, message: undefined }, document.title);
      }
    }, [locationState]);

    useEffect(() => {
      if (!isEditMode) {
        handleResetForm();
      }
      if (vendor) {
        const intent = isEditMode
          ? !isVendorDirectoryInfoCompleted
            ? 'missing-vendor-info-view'
            : 'edit-vendor'
          : 'view-vendor';

        track('Vendor', 'View', {
          Intent: intent,
          PageName: 'vendor-details',
          RecurringPayments: vendor.billSubscriptions?.map((it) => ({ [it.id]: it.intervalType })),
          TaxId: analyticsTaxIdType,
          ...(isVendorDirectoryInfoCompleted
            ? {}
            : {
                UserMessage: 'missing-vendor-details',
              }),
        });

        if (isEditMode && vendorDirectoryMissingFields.length) {
          track('Vendor', 'Status', {
            PageName: 'vendor-details',
            Intent: intent,
            ErrorType: `${vendorDirectoryMissingFields.length}-missing-fields`,
          });
        }
      }
    }, [isEditMode, vendor]);

    const handleCloseClick = () => {
      track('Vendor', 'Click', {
        Intent: isVendorDirectoryInfoCompleted ? 'edit-vendor' : 'missing-vendor-info-view',
        Cta: 'exit',
      });

      setIsClosing(true);
    };

    const handleDeleteVendorClick = (vendor: Vendor) => {
      setSelectedVendor(vendor);
      onDeleteModalOpen();
    };

    const handleDeleteModalClose = () => {
      setSelectedVendor(null);
      onDeleteModalClose();
    };

    const onEditClick = () => {
      track('Vendor', 'Click', {
        Intent: isVendorDirectoryInfoCompleted ? 'edit-vendor' : 'missing-vendor-info-view',
        ...(isVendorDirectoryInfoCompleted
          ? {}
          : {
              UserMessage: 'missing-vendor-details',
            }),
        Cta: 'more-edit-vendor',
      });
      setIsEditMode(true);
    };
    const onCancelEditClick = () => {
      track('Vendor', 'Click', {
        Intent: isVendorDirectoryInfoCompleted ? 'edit-vendor' : 'missing-vendor-info-view',
        Cta: 'cancel',
      });
      hideMessage();
      setIsEditMode(false);
    };

    const onInvalidVendorBankAccountModalClose = () => {
      closeInvalidVendorBankAccountModal();
    };

    const onInvalidVendorBankAccountModalSuccess = () => {
      if (!invalidBankAccountModalData) {
        return;
      }

      handleUpdateVendorDone({
        type: 'success',
        title: formatMessage('widgets.invalidVendorBankAccountModal.success', {
          vendorName: invalidBankAccountModalData.fullVendorName,
        }),
        dataTestId: 'bank-account-update-success',
      });
      closeInvalidVendorBankAccountModal();
    };

    return (
      <>
        <Drawer
          isOpen={!isClosing}
          shouldReturnFocus={shouldReturnFocus.current}
          onClose={handleCloseClick}
          onCloseComplete={handleClose}
          data-testid="pay-dashboard-vendor-drawer"
          closeButtonAriaLabel={formatMessage('widgets.vendorDrawer.closeButtonAriaLabel')}
          header={
            <Text as="h2" textStyle="heading2Semi">
              {formatMessage('widgets.vendorDrawer.header.title')}
            </Text>
          }
          body={
            <Suspense fallback={<LoadingContainer aria-live="polite" isLoading />}>
              <VendorDrawerBody
                vendorId={vendorId}
                onSubmit={onSubmit}
                onSubmissionStateChange={onSubmissionStateChange}
                isEditMode={isEditMode}
                onCancelEBillSubscription={onCancelEBillSubscriptionModalOpen}
                onAutoPaymentCancellation={onAutoPayCancellationModalOpen}
                onEditBillSubscription={onEditBillSubscription}
                inlineApiErrorCodes={apiErrorsCodes?.inline}
                onEditClick={onEditClick}
              />
              {invalidBankAccountModalData ? (
                <InvalidVendorBankAccountModal
                  isOpen
                  onClose={onInvalidVendorBankAccountModalClose}
                  invalidVendorBankAccountDetails={invalidBankAccountModalData}
                  onSuccess={onInvalidVendorBankAccountModalSuccess}
                />
              ) : null}
            </Suspense>
          }
          footer={
            vendor && hasActions ? (
              <Suspense fallback={<LoadingContainer isLoading />}>
                <VendorDrawerFooter
                  vendor={vendor}
                  submitButtonProps={submitButtonProps}
                  cancelButtonProps={cancelButtonProps}
                  onDeleteClick={handleDeleteVendorClick}
                  isEditMode={isEditMode}
                  onEditClick={onEditClick}
                  onCancelClick={onCancelEditClick}
                />
              </Suspense>
            ) : null
          }
        />
        {selectedVendor && (
          <DeleteVendorModal
            vendor={selectedVendor}
            isOpen={selectedVendor && isDeleteModalOpen}
            onClose={handleDeleteModalClose}
            onSuccess={handleDeleteSuccess}
          />
        )}
        {vendor?.id && isAutoPayCancellationModalOpen ? (
          <AutoPaymentCancellationModalActivity
            isOpen={isAutoPayCancellationModalOpen}
            onClose={onAutoPayCancellationModalClose}
            vendorId={vendor.id}
            vendorName={vendor?.name || ''}
          />
        ) : null}
        {vendor?.id && (
          <CancelEBillSubscriptionModalActivity
            isOpen={isCancelEBillSubscriptionModalOpen}
            vendorId={vendor.id}
            onClose={onCancelEBillSubscriptionModalClose}
          />
        )}
      </>
    );
  }),
);

const useEditVendor = ({
  vendorId,
  isVendorDirectoryInfoCompleted,
}: {
  vendorId: string;
  isVendorDirectoryInfoCompleted: boolean;
}) => {
  const { formatMessage } = usePlatformIntl();
  const { showMessage } = useSystemMessage();
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const [isInvalidBankAccountModalEnabled] = usePartnerFeature('InvalidVendorBankAccountModal', false);
  const { track } = useAnalytics();
  const { update: updateVendor, linkVendorToDirectory } = useVendor({
    id: vendorId,
    enabled: false,
    refetchOnMount: 'always',
  });
  const { setIsEditMode } = useEditMode();
  const { onSubmissionStateChange, submitButtonProps, cancelButtonProps, reset } =
    useFormSubmissionController<VendorDetailsFormFields>();
  const queryClient = useMelioQueryClient();
  const [apiErrorsCodes, setApiErrorsCodes] = useState<Pick<ErrorTypeToErrorCodesMap, 'inline' | 'banner'>>();

  const [invalidBankAccountModalData, setInvalidBankAccountModalData] = useState<
    InvalidVendorBankAccountDetails | undefined
  >();

  const handleUpdateVendorDone = (message: SystemMessage) => {
    showMessage(message);

    track('Vendor', 'Status', {
      Intent: isVendorDirectoryInfoCompleted ? 'edit-vendor' : 'missing-vendor-info-view',
      ErrorType: isVendorDirectoryInfoCompleted ? undefined : 'business-details-updated',
      Status: 'success',
    });
    void queryClient.invalidateQueries('InboxItemsApi');
    void queryClient.invalidateQueries('PaymentsApi');
    void queryClient.invalidateQueries('delivery-method-type-options');
    setIsEditMode(false);
    endAction('vendor_edit');
  };

  const closeInvalidVendorBankAccountModal = () => {
    setInvalidBankAccountModalData(undefined);
  };

  const handleResetForm = () => {
    reset?.();
    setApiErrorsCodes(undefined);
  };

  const makeUpdateVendorRequest = async (payload: UpdateVendorParams) => {
    if (isVendorDirectoryInfoCompleted) {
      return await updateVendor(payload);
    }

    return await linkVendorToDirectory(payload as LinkVendorToDirectoryParams);
  };

  const onSubmit = async (payload: UpdateVendorParams, throwErrors = false) => {
    setApiErrorsCodes(undefined);

    try {
      if (!vendorId) {
        return;
      }
      startAction('vendor_edit');
      track('Vendor', 'Click', {
        Intent: isVendorDirectoryInfoCompleted ? 'edit-vendor' : 'missing-vendor-info-view',
        Cta: 'save',
      });
      const payloadWithValidatedEmail = {
        ...payload,
        contact: payload.contact ? { ...payload.contact, email: payload.contact.email || null } : {},
      };

      const updatedVendor = await makeUpdateVendorRequest(payloadWithValidatedEmail);

      const areVendorBankDetailsNotCreated = isVendorBankAccountNotCreated(updatedVendor.warnings);

      if (isInvalidBankAccountModalEnabled && areVendorBankDetailsNotCreated && payload.bankAccount) {
        setInvalidBankAccountModalData({
          vendorId: updatedVendor.id,
          vendorName: updatedVendor.name,
          fullVendorName: getVendorNameForNotificationMessage(updatedVendor),
          bankAccountNumber: payload.bankAccount.accountNumber,
        });
        return;
      }

      handleUpdateVendorDone({
        type: 'success',
        title: formatMessage('widgets.editVendor.toast.success.withName', {
          vendorName: updatedVendor.name,
        }),
        dataTestId: 'edit-vendor-success',
      });
    } catch (e) {
      const { inline, banner } = getErrorsByType(e as ModelError, false);
      setApiErrorsCodes({ inline, banner });

      if (throwErrors) {
        throw e;
      }

      track('Vendor', 'Status', { Intent: 'edit-vendor', Status: 'failure' });

      if (!inline) {
        if (banner) {
          showMessage({
            type: 'error',
            title: formatMessage(`widgets.vendors.apiErrors.${banner[0]}`),
            id: 'edit-vendor-error',
          });
        } else {
          showMessage({
            type: 'error',
            title: formatMessage('widgets.editVendor.toast.error', {
              companyName: payload.name,
            }),
            id: 'edit-vendor-error',
          });
        }
      }
    }
  };

  return {
    onSubmissionStateChange,
    submitButtonProps,
    cancelButtonProps,
    onSubmit,
    handleResetForm,
    apiErrorsCodes,
    invalidBankAccountModalData,
    handleUpdateVendorDone,
    closeInvalidVendorBankAccountModal,
  };
};

const useEditMode = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isEditMode = searchParams.get('edit') === 'true';
  const setIsEditMode = useCallback(
    (isEditable: boolean) =>
      setSearchParams(
        (params) => {
          if (isEditable) {
            params.set('edit', String(isEditable));
          } else {
            params.delete('edit');
          }
          return params;
        },
        { replace: true },
      ),
    [setSearchParams],
  );

  return { isEditMode, setIsEditMode };
};
