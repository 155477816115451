import { getIsVoidAndRefundEnabled } from '@melio/ap-domain';
import { isBillSubscriptionCancelled } from '@melio/ap-domain/src/functions/bill-subscriptions';
import { Group, Icon, Pill, Table, Text, Tooltip } from '@melio/penny';
import { DeliveryMethodType, Payment, PaymentApprovalDecisionStatusEnum, PaymentStatusEnum } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';

import {
  isPaymentCompletedRefund,
  isPaymentCompletedVoidAndRefund,
  isPaymentScheduledRefund,
  isPaymentScheduledVoid,
  isPaymentScheduledVoidAndRefund,
} from '../../../../../utils/pay-flow/Payment.utils';
import { CanceledRecurringPaymentStatusDescription } from '../../../../CanceledRecurringPaymentStatusDescription/CanceledRecurringPaymentStatusDescription';

const PaymentsTabStatusCellDescription = ({ payment }: { payment: Payment }) => {
  const { formatMessage } = useMelioIntl();
  if (isPaymentCompletedRefund(payment)) {
    return (
      <Tooltip
        content={formatMessage('activities.payDashboard.paymentsTab.table.cells.statusDescription.refundedTooltip')}
      >
        <Group spacing="xxs" alignItems="center">
          <Text textStyle="body3" color="global.neutral.800">
            {formatMessage('activities.payDashboard.paymentsTab.table.cells.statusDescription.refunded')}
          </Text>
          <Icon type="info" color="inherit" size="small" />
        </Group>
      </Tooltip>
    );
  }

  if (
    payment.status === PaymentStatusEnum.Canceled &&
    payment.subscriptionOccurrence?.billSubscription &&
    isBillSubscriptionCancelled(payment.subscriptionOccurrence.billSubscription)
  ) {
    return (
      <CanceledRecurringPaymentStatusDescription
        testId={`payments-tab-row-${payment.id}-status-cell-recurring-canceled`}
      />
    );
  }

  return null;
};
export const PaymentsTabStatusCell = ({ payment }: { payment: Payment }) => {
  const calculatePaymentStatus = usePaymentStatus();
  const { label, status } = calculatePaymentStatus(payment);

  return (
    <Table.Cell data-testid={`payments-tab-row-${payment.id}-status-cell`}>
      <Group variant="vertical" spacing="xxs">
        <Pill type="secondary" label={label} status={status} />
        <PaymentsTabStatusCellDescription payment={payment} />
      </Group>
    </Table.Cell>
  );
};

export const usePaymentStatus = () => {
  const { formatMessage } = useMelioIntl();
  const [isSecureFileUploadEnabled] = useFeature<boolean>(FeatureFlags.SecureFileUpload, false);

  return useCallback(
    (payment: Payment) => {
      const isVoidAndRefundEnabled: boolean = getIsVoidAndRefundEnabled(payment);

      const isProcessingVoid = isPaymentScheduledVoid(payment);
      const isProcessingRefund = isPaymentScheduledRefund(payment);
      const isProcessingVoidAndRefund = isPaymentScheduledVoidAndRefund(payment);
      const isCompletedRefund = isPaymentCompletedRefund(payment);
      const isCompletedVoidAndRefund = isPaymentCompletedVoidAndRefund(payment);
      const isPendingFile = isSecureFileUploadEnabled && payment.paymentPending?.uploadDocuments;

      if (isProcessingVoid) {
        return {
          label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.voidInProgress'),
          status: 'neutral' as const,
        };
      }
      if (isProcessingRefund || isProcessingVoidAndRefund) {
        return {
          label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.refundInProgress'),
          status: 'neutral' as const,
        };
      }
      if (isCompletedRefund || isCompletedVoidAndRefund) {
        return {
          label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.refunded'),
          status: 'success' as const,
        };
      }

      switch (payment.status) {
        case 'completed':
          if (payment.markedAsPaid) {
            return {
              label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.markedAsPaid'),
              status: 'success' as const,
            };
          }
          return {
            label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.paid'),
            status: isVoidAndRefundEnabled ? ('warning' as const) : ('success' as const),
          };
        case 'in-progress':
          return {
            label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.inProgress'),
            status: 'success' as const,
          };
        case 'scheduled':
          if (payment.deliveryMethod?.type === DeliveryMethodType.VirtualAccount) {
            return {
              label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.pendingVendor'),
              status: 'warning' as const,
            };
          }
          return {
            label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.scheduled'),
            status: 'informative' as const,
          };
        case 'blocked':
          if (isPendingFile) {
            return {
              label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.pendingFile'),
              status: 'warning' as const,
            };
          }
          if (payment.approvalDecisionStatus === 'pending') {
            return {
              label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.approvalPending'),
              status: 'warning' as const,
            };
          }
          if (payment.deliveryMethod?.type === DeliveryMethodType.VirtualAccount) {
            return {
              label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.pendingVendor'),
              status: 'warning' as const,
            };
          }
          if (payment.fundingSource && !payment.fundingSource.isVerified) {
            return {
              label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.pendingVerification'),
              status: 'warning' as const,
            };
          }
          return {
            label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.scheduled'),
            status: 'informative' as const,
          };
        case 'failed':
          if (payment.approvalDecisionStatus === PaymentApprovalDecisionStatusEnum.Declined) {
            return {
              label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.approvalDeclined'),
              status: 'critical' as const,
            };
          }
          return {
            label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.failed'),
            status: 'critical' as const,
          };
        case 'canceled':
          return {
            label: formatMessage('activities.payDashboard.paymentsTab.table.cells.status.canceled'),
            status: 'critical' as const,
          };
        default: {
          return {
            label: 'unknown', //TODO:  should remove while we add all statuses
            status: 'critical' as const,
          };
        }
      }
    },
    [formatMessage, isSecureFileUploadEnabled]
  );
};
