import { Modal } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { PaymentIntentWithDerivatives, usePaymentIntent } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useSystemMessage } from '@melio/platform-utils';
import { ComponentProps } from 'react';

import { NoteToSelfBaseModal } from './NoteToSelfBaseModal';

export type PaymentIntentNoteToSelfModalProps = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onClose'> & {
  paymentIntentId: string;
  onSuccess?: (data: PaymentIntentWithDerivatives) => void;
};

export const PaymentIntentNoteToSelfModal = ({
  paymentIntentId,
  isOpen,
  onClose,
  onSuccess,
}: PaymentIntentNoteToSelfModalProps) => {
  const { formatMessage } = useMelioIntl();
  const { showMessage } = useSystemMessage();
  const { track } = useAnalytics();

  const onSuccessfulUpdate = (data: PaymentIntentWithDerivatives) => {
    showMessage({
      type: 'informative',
      title: formatMessage('activities.paymentNoteToSelfModal.success.toast'),
    });
    track('Dashboard', 'Click', { Intent: 'payment-updated', Cta: 'confirm' });
    onSuccess?.(data);
    onClose();
  };

  const onUpdateError = () => {
    showMessage({
      type: 'error',
      title: formatMessage('activities.paymentNoteToSelfModal.error.toast'),
    });
    onClose();
  };

  const {
    data: basicPaymentIntent,
    isUpdating: isUpdatingPaymentIntent,
    update: updatePaymentIntent,
  } = usePaymentIntent({
    id: paymentIntentId,
    onUpdate: onSuccessfulUpdate,
    onUpdateError,
  });

  return (
    <NoteToSelfBaseModal
      isOpen={isOpen}
      onClose={onClose}
      note={basicPaymentIntent?.noteToVendor || ''}
      isUpdating={isUpdatingPaymentIntent}
      updateNote={(note) => updatePaymentIntent({ noteToVendor: note })}
      onSuccess={(data) => onSuccess?.(data as PaymentIntentWithDerivatives)}
    />
  );
};
