import { Container, Group, LoadingContainer, Text } from '@melio/penny';
import { useFundingSources } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMemo, useState } from 'react';

import { Plan, SubscriptionBillingCycleEnum } from '../../../../../api';
import { FundingSourceCard } from '../../../../components';
import { SubscriptionBillingCycle } from './components/SubscriptionBillingCycle';
import { SubscriptionPaymentMethod } from './components/SubscriptionPaymentMethod';

type SubscriptionCheckoutSelectionProps = {
  selectedPlan: Plan;
  isSubscribing: boolean;
  fundingSourceId: string | null;
  setFundingSourceId: (id: string) => void;
  onDoneAddingCreditCard: () => void;
  cycle: SubscriptionBillingCycleEnum;
  setCycle: (cycle: SubscriptionBillingCycleEnum) => void;
};

export const SubscriptionCheckoutSelection = ({
  selectedPlan,
  isSubscribing,
  fundingSourceId,
  setFundingSourceId,
  onDoneAddingCreditCard,
  cycle,
  setCycle,
}: SubscriptionCheckoutSelectionProps) => {
  const { formatMessage } = useMelioIntl();
  const { data: fundingSources, isFetching: isFetchingFundingSources } = useFundingSources();
  const fundingSource = useMemo(
    () => fundingSources?.find((x) => x.id === fundingSourceId),
    [fundingSourceId, fundingSources]
  );
  const [isFundingSourceSelectable, setFundingSourceSelectable] = useState(false);

  const selectedPlanHasAnnualBasePrice =
    selectedPlan.cycles.annual?.basePrice || selectedPlan.cycles.annual?.basePrice === 0;

  return (
    <Group variant="vertical" width="full" spacing="l">
      {selectedPlanHasAnnualBasePrice && (
        <SubscriptionBillingCycle
          selectedPlan={selectedPlan}
          isSubscribing={isSubscribing}
          selectedBillingCycle={cycle}
          onBillingCycleChange={setCycle}
        />
      )}
      <Group variant="vertical" spacing="xxxs" data-component="SubscriptionPaymentMethod">
        <Container paddingBottom="s">
          <Text textStyle="body2Semi" color="global.neutral.800">
            {formatMessage('activities.subscription.checkout.paymentMethod.title')}
          </Text>
        </Container>
        <LoadingContainer isLoading={isFetchingFundingSources}>
          {fundingSource && !isFundingSourceSelectable ? (
            <FundingSourceCard fundingSource={fundingSource} onChangeClick={() => setFundingSourceSelectable(true)} />
          ) : (
            <Container border="regular" paddingX="m" paddingY="m">
              <SubscriptionPaymentMethod
                fundingSourceId={fundingSourceId}
                setFundingSourceId={setFundingSourceId}
                onDoneAddingCreditCard={onDoneAddingCreditCard}
                selectedPlan={selectedPlan}
                selectedBillingCycle={cycle}
                onClose={() => setFundingSourceSelectable(false)}
              />
            </Container>
          )}
        </LoadingContainer>
      </Group>
    </Group>
  );
};
