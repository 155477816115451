import { useMtlDateUtils } from '@melio/ap-domain';
import { Account } from '@melio/platform-api';
import { pick } from 'lodash';

import { CompleteRequiredDetailsFormField, CompleteRequiredDetailsFormFields } from './types';
import { filterByFieldsList } from './utils';

/**
 * Populate AND filter default values for the form fields
 *
 * @param fieldsList The list of fields that the form should display.
 * @param account The account data.
 * @returns The default values for the form fields, filtered by the fieldsList.
 */
export function usePopulateDefaultValues(
  fieldsList: Set<CompleteRequiredDetailsFormField>,
  account: Account
): Partial<CompleteRequiredDetailsFormFields> {
  const { convertFromISO, dateToString } = useMtlDateUtils();

  const allFields: Partial<CompleteRequiredDetailsFormFields> = {
    firstName: account.user.firstName ?? undefined,
    lastName: account.user.lastName ?? undefined,
    dateOfBirth: account.user.dateOfBirth ? dateToString(convertFromISO(account.user.dateOfBirth)) : undefined,
    companyName: account.company.name ?? undefined,
    businessType: account.company.businessType ?? undefined,
    taxInfoType: account.company.taxInfo?.type ?? undefined,
    taxInfoIdentifier: account.company.taxInfo?.identifier.replaceAll('X', '•') || '',
    legalCompanyName: account.company.legalName ?? undefined,
    contactFirstName: account.company.contactFirstName ?? undefined,
    contactLastName: account.company.contactLastName ?? undefined,
    phoneNumber: account.company.phoneNumber ?? undefined,
    industry: account.company.industry?.name ? pick(account.company.industry, ['name', 'naicsCode']) : undefined,
  };

  return filterByFieldsList(allFields, fieldsList);
}
