import { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import { Button, Icon, Image, Text } from '@melio/penny';

import { useAppTheme } from '@/cl/theme/theme.hooks';
import { EmptyStateListTheme } from './EmptyStateList.component.theme';

type EmptyStateListProps = {
  icon?: string;
  title?: string;
  description?: string;
  button?: {
    text: string;
    onClick: VoidFunction;
  };
  secondaryButton?: ReactNode;
  testId?: string;
};

export const EmptyStateList = ({ title, description, icon, button, secondaryButton, testId }: EmptyStateListProps) => {
  const styles = useAppTheme(EmptyStateListTheme, {});

  const hasButton = button || secondaryButton;

  return (
    <Box sx={styles['container']} data-testid={testId}>
      <Box sx={styles['iconContainer']}>
        {icon && <Image width="auto" height="auto" src={icon} alt="error" />}
        <Box sx={styles['textContainer']}>
          {title && <Text textStyle="body2Semi">{title}</Text>}
          {description && (
            <Text textStyle="body3" color="global.neutral.900">
              {description}
            </Text>
          )}
        </Box>
      </Box>
      {hasButton && (
        <Box sx={styles['buttonsContainer']}>
          {button && (
            <Button
              leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
              variant="primary"
              size={'large'}
              onClick={button.onClick}
              isFullWidth
              label={button.text}
            />
          )}
          {secondaryButton}
        </Box>
      )}
    </Box>
  );
};
