import { useAllowedMcc, useMelioIntl, useVendor } from '@melio/ar-domain';
// eslint-disable-next-line import/no-deprecated
import { useToast } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { useEffect, useMemo } from 'react';

import { IndustrySelectionAmexModalScreen, IndustrySelectionVisaModalScreen } from './screens';

type IndustrySelectionModalActivityProps = {
  isOpen: boolean;
  isAmex: boolean;
  onDone: VoidFunction;
  onClose: VoidFunction;
  vendorId: string;
  vendorCompanyName: string;
};

export const IndustrySelectionModalActivity = withAnalyticsContext<IndustrySelectionModalActivityProps>(
  forwardRef(({ isOpen, isAmex, onDone, onClose, vendorId, vendorCompanyName, setAnalyticsProperties }, ref) => {
    // eslint-disable-next-line import/no-deprecated
    const { toast } = useToast();
    const { track } = useAnalytics();
    const { formatMessage } = useMelioIntl();

    const cardNetwork = isAmex ? 'amex' : 'visa';

    setAnalyticsProperties({
      PageName: 'add-vendor-details',
      Intent: 'add-vendor-business-details',
      CardNetwork: cardNetwork,
    });

    useEffect(() => {
      if (isOpen) track('PaymentRequest', 'View');
    }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    const { isMutating: isSavingIndustry, update: updateVendor } = useVendor({ id: vendorId, enabled: false });
    const { data: mccIndustryList = [], isLoading: isLoadingMccList } = useAllowedMcc({ cardNetwork, enabled: isOpen });

    const handleSave = async (mcc: string) => {
      try {
        track('PaymentRequest', 'Click', { Cta: 'save' });
        await updateVendor({ traits: { mcc } });
        onDone();
      } catch (error) {
        toast({ type: 'error', title: formatMessage('ar.guestPayment.industrySelectionModal.error.text') });
      }
    };

    const handleClose = () => {
      track('PaymentRequest', 'Click', { Cta: 'close' });
      onClose();
    };

    const ModalComponent = useMemo(
      () => (isAmex ? IndustrySelectionAmexModalScreen : IndustrySelectionVisaModalScreen),
      [isAmex]
    );

    return (
      <ModalComponent
        isOpen={isOpen}
        isSavingIndustry={isSavingIndustry}
        isLoadingMccList={isLoadingMccList}
        onSave={handleSave}
        onClose={handleClose}
        mccIndustryList={mccIndustryList}
        vendorCompanyName={vendorCompanyName}
        ref={ref}
      />
    );
  })
);
IndustrySelectionModalActivity.displayName = 'IndustrySelectionModalActivity';
