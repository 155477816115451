import { VisuallyHidden } from '@chakra-ui/react';
import { Group, Icon, Modal, SectionBanner, SimpleGrid, StatusModal, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView, withAnalyticsContext } from '@melio/platform-analytics';
import { useCollaborators } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { Logger } from '@melio/platform-logger';
import { isCollaboratorBlocked } from '@melio/platform-utils';
import { useSystemMessage } from '@melio/platform-utils/system-message';
import { useState } from 'react';

import { useSubscriptionMe } from '../../../api';
import {
  usePartnerGroups,
  usePlanInfo,
  usePlansFeaturesDifference,
  usePlansTiers,
  useRedirects,
  useSubscription,
} from '../../../hooks';
import { getQuantityBasedIsFeatureInPlan } from '../../../utils';

type SubscriptionDowngradeModalActivityProps = {
  isOpen: boolean;
  newPlanId: string;
  onClose: () => void;
};

export const SubscriptionDowngradeModalActivity = withAnalyticsContext<SubscriptionDowngradeModalActivityProps>(
  ({ isOpen, newPlanId, onClose, setAnalyticsProperties }) => {
    const [accountManagerAccessCollaboratorsEnabled] = useFeature<boolean>(
      FeatureFlags.AccountManagerAccessCollaborators,
      false
    );
    const { showMessage } = useSystemMessage();

    const [downgradeError, setDowngradeError] = useState<boolean>(false);
    const { formatMessage, formatDate } = useMelioIntl();
    const subscription = useSubscription();
    const { getPlan } = usePlansTiers();
    const { getDowngradedPlanFeaturesLost } = usePlansFeaturesDifference();
    const { isFiservPartner } = usePartnerGroups();
    const { goToSettingsCollaboratorsPage } = useRedirects();
    const { planName: newPlanName } = usePlanInfo(newPlanId);
    const { planName: currentPlanName } = usePlanInfo(subscription?.planId);
    const {
      data: collaborators,
      isFetching: isCollaboratorsFetching,
      isLoading: isCollaboratorsLoading,
    } = useCollaborators({
      enabled: accountManagerAccessCollaboratorsEnabled,
    });

    const isFetching = isCollaboratorsFetching || isCollaboratorsLoading;

    const { update: updateSubscription, isMutating: isUpdatingSubscription } = useSubscriptionMe({ enabled: false });
    const { goToReturnUrlOrDashboard, goToSettingsPage } = useRedirects();

    const featuresLostList = getDowngradedPlanFeaturesLost(newPlanId);
    const ModalComponent = isFiservPartner ? StatusModal : Modal;
    const hasPartnerColors = !!isFiservPartner;

    setAnalyticsProperties({
      PageName: 'downgrade-plan',
      Flow: 'settings',
      Intent: 'downgrade',
      CurrentPlan: subscription?.planId,
    });
    const { track } = useAnalytics();

    useAnalyticsView('Organization', isOpen, true);

    if (!subscription) {
      Logger.log('SubscriptionDowngradeModalActivity - subscription is not exists');

      return null;
    }

    const formattedNextBillingDate = formatDate(subscription?.nextBillingDate, {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    const activeCollaborators = collaborators?.filter((c) => !isCollaboratorBlocked(c)) ?? [];

    const newPlan = getPlan(newPlanId);
    const {
      quota: { freeUnitsLimit: newPlanCollaboratorsLimit },
      isEligible: doesNewPlanAllowCurrentCollaboratorsCount,
    } = getQuantityBasedIsFeatureInPlan(newPlan, 'collaborators', activeCollaborators.length);

    const onSuccess = () => {
      const redirectAfterCancel = isFiservPartner ? goToSettingsPage : goToReturnUrlOrDashboard;

      redirectAfterCancel();

      showMessage({
        type: 'success',
        title: formatMessage('activities.subscription.cancelOrDowngradeFlow.systemMessage.success', {
          planName: newPlanName,
        }),
      });

      onClose();
    };

    const switchSubscription = () => {
      setDowngradeError(false);

      void updateSubscription({ planId: newPlanId, planCyclePeriod: subscription.planCyclePeriod })
        .then(() => {
          track('Organization', 'Status', {
            StatusType: 'success',
          });
          onSuccess?.();
        })
        .catch(() => {
          setDowngradeError(true);
          track('Organization', 'Status', {
            StatusType: 'failure',
            ErrorType: 'submit-error',
          });
        });
    };

    const onCloseHandler = (closeType: string) => {
      track('Organization', 'Click', {
        Intent: closeType,
        Cta: closeType,
      });
      onClose();
    };

    const shouldRenderTooManySeatsUx = isFiservPartner && !doesNewPlanAllowCurrentCollaboratorsCount;

    const content = shouldRenderTooManySeatsUx
      ? ({
          primaryButton: {
            textKey: 'activities.subscription.cancelModal.cancel.tooManyUsers.primaryButton',
            variant: hasPartnerColors ? 'primary' : 'critical',
            onClick: onClose,
          },
          secondaryButton: {
            textKey: 'activities.subscription.cancelModal.cancel.tooManyUsers.secondaryButton',
            onClick: () => {
              goToSettingsCollaboratorsPage();
              track('Organization', 'Click', {
                Intent: 'users-and-role-settings',
                Cta: 'users-and-role-settings',
              });
            },
            variant: 'tertiary',
          },
          descriptionKey: 'activities.subscription.cancelModal.cancel.tooManyUsers.description',
        } as const)
      : ({
          primaryButton: {
            textKey: 'activities.subscription.cancelModal.downgrade.primaryButton',
            variant: hasPartnerColors ? 'primary' : 'critical',
            onClick: switchSubscription,
          },
          secondaryButton: {
            textKey: 'activities.subscription.cancelModal.secondaryButton',
            onClick: () => {
              onCloseHandler('keep-my-plan');
            },
            variant: hasPartnerColors ? 'secondary' : 'tertiary',
          },
          descriptionKey: subscription?.isFreeTrial
            ? 'activities.subscription.cancelModal.descriptionWithFreeTrial'
            : 'activities.subscription.cancelModal.description',
        } as const);

    const { primaryButton, secondaryButton, descriptionKey } = content;

    const modalTitle = formatMessage('activities.subscription.cancelModal.downgrade.title', {
      newPlanName,
    });

    return (
      <>
        <VisuallyHidden role="status" aria-live="polite">
          {(isFetching || isUpdatingSubscription) &&
            formatMessage('activities.subscription.cancelModal.loading', { title: modalTitle })}
        </VisuallyHidden>

        <ModalComponent
          isOpen={isOpen}
          onClose={() => onCloseHandler('exit')}
          header={modalTitle}
          primaryButton={{
            isLoading: isUpdatingSubscription,
            label: formatMessage(primaryButton.textKey),
            onClick: primaryButton.onClick,
            variant: primaryButton.variant,
          }}
          secondaryButton={{
            label: formatMessage(secondaryButton.textKey),
            onClick: secondaryButton.onClick,
            variant: secondaryButton.variant,
            isDisabled: isUpdatingSubscription,
          }}
          data-testid="subscription-downgrade-modal-activity"
          isLoading={isFetching}
          variant="warning"
        >
          <Group variant="vertical" spacing="m">
            {downgradeError && (
              <SectionBanner
                description={formatMessage(`activities.subscription.cancelModal.${'downgrade'}.toast.error`)}
                variant="critical"
                data-testid="subscription-downgrade-failed"
                data-role="alert"
              />
            )}

            <Text>
              {formatMessage(descriptionKey, {
                nextBillingDate: formattedNextBillingDate,
                newPlanName,
                currentPlanName,
                collaboratorsLimit: newPlanCollaboratorsLimit,
              })}
            </Text>

            {!shouldRenderTooManySeatsUx && (
              <Group variant="vertical" spacing="xxxs">
                {featuresLostList.map((feature) => (
                  <SimpleGrid key={feature} alignItems="flex-start" display="flex" gap="xs" paddingBottom="xxs">
                    <Text color={hasPartnerColors ? 'global.neutral.700' : 'global.critical.700'}>
                      <Icon type="cancel-circle" color="inherit" size="small" aria-hidden="true" />
                    </Text>
                    <Text color={hasPartnerColors ? 'global.neutral.700' : undefined} textStyle="body3">
                      {feature}
                    </Text>
                  </SimpleGrid>
                ))}
              </Group>
            )}
          </Group>
        </ModalComponent>
      </>
    );
  }
);
