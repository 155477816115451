import { _TableHeaderCellDescription, Group, Icon, Switch, Text, Tooltip } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';

import { SchedulePaymentIntent } from '../../../../types';
import { useCombinePaymentsToggle } from './CombinedPaymentsToggle.utils';

export type CombinedPaymentsToggleProps = {
  paymentIntentsWithDerivatives: SchedulePaymentIntent[];
  arePaymentsCombined?: boolean;
  onToggleCombinedPayments: (isChecked: boolean) => void;
  isToggling?: boolean;
};
export const CombinedPaymentsToggle = forwardRef<CombinedPaymentsToggleProps, 'div'>(
  ({ isToggling, paymentIntentsWithDerivatives, arePaymentsCombined, onToggleCombinedPayments }, ref) => {
    const { formatMessage } = useMelioIntl();

    const { isDisabled: _isToggleDisabled, getTooltip: getToggleTooltip } =
      useCombinePaymentsToggle(paymentIntentsWithDerivatives);

    const combinedTooltip = getToggleTooltip();
    const label = arePaymentsCombined
      ? formatMessage('activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.switch.on')
      : formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.switch.off'
        );

    const isToggleDisabled = _isToggleDisabled || isToggling;
    return (
      <Group
        data-testid="combine-payment-toggle-wrapper"
        ref={ref}
        justifyContent="space-between"
        alignItems="center"
        width="full"
        spacing="xxs"
      >
        <_TableHeaderCellDescription
          text={formatMessage(
            'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.label'
          )}
        />
        <Group.Item shrink={1}>
          <Group spacing="xxs" alignItems="center" width="auto">
            <Text textStyle="body4Semi" color="global.neutral.1000">
              {label}
            </Text>
            <Switch
              size="large"
              data-testid="combine-payment-toggle"
              defaultIsChecked={arePaymentsCombined}
              onChange={onToggleCombinedPayments}
              isDisabled={isToggleDisabled}
              aria-label={formatMessage(
                'activities.batchPayments.screens.paymentIntentsTable.billDetailsCell.combinePayments.switch.ariaLabel',
                { switchDescription: combinedTooltip }
              )}
            />
            <Tooltip content={combinedTooltip} shouldAddTriggerFocus>
              <Icon type="info" color="inherit" size="small" data-testid="combined-payments-toggle-tooltip-info-icon" />
            </Tooltip>
          </Group>
        </Group.Item>
      </Group>
    );
  }
);

CombinedPaymentsToggle.displayName = 'CombinedPaymentsToggle';
