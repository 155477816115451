import {
  calculateRecurringEndDateByNumOfOccurrences,
  getNumberOfOccurrences,
  getRecurringFieldsToShow,
  RECURRING_NUM_OF_OCCURRENCES_REGEX,
} from '@melio/ap-domain';
import { Form, Group, Text, useBreakpointValue, UseMelioFormResults, useWatch } from '@melio/penny';
import {
  BillSubscriptionEndPolicyEnum,
  BillSubscriptionIntervalTypeEnum,
  PaymentCalendarCalculation,
} from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig } from '@melio/platform-provider';
import { addBusinessDays, useDateUtils } from '@melio/platform-utils';
import { addYears, isBefore } from 'date-fns';
import { first, last } from 'lodash';
import { ComponentProps } from 'react';

import { RESTRICTION_YEARS } from '../../../../../add-bill/AddBillV2Form/utils';
import { PaymentFlowFormFields, PaymentFlowOnChangeHandlers } from '../../../../types';
import { DateField } from '../../../components/DateField';

type Props = {
  form: UseMelioFormResults<PaymentFlowFormFields>;
  paymentCalendarDates: PaymentCalendarCalculation['dates'] | undefined;
  onStartDateChange: PaymentFlowOnChangeHandlers['onStartDateChange'];
  isLoading: boolean;
  isEditFlow: boolean;
};
export function RecurringFields({ form, paymentCalendarDates, onStartDateChange, isLoading, isEditFlow }: Props) {
  const { formatMessage, formatDate, formatCurrency } = useMelioIntl();
  const { createDate } = useDateUtils();
  const { registerField, control } = form;
  const {
    settings: {
      newBillExperience: { isRecurringPaymentImprovementsEnabled },
    },
  } = useConfig();

  const isMobile = useBreakpointValue({ xs: true, s: false });

  const availableFrequencyValues = isRecurringPaymentImprovementsEnabled
    ? Object.values(BillSubscriptionIntervalTypeEnum)
    : [BillSubscriptionIntervalTypeEnum.Weekly, BillSubscriptionIntervalTypeEnum.Monthly];
  const frequencyOptions: ComponentProps<typeof Form.SelectNew>['options'] = availableFrequencyValues.map((type) => ({
    value: type,
    label: formatMessage(`activities.paymentFlow.form.content.recurring.frequency.options.${type}`),
    testId: `frequency-${type}`,
  }));

  const endPolicyAvailableOptions = isRecurringPaymentImprovementsEnabled
    ? [
        BillSubscriptionEndPolicyEnum.NoEndDate,
        BillSubscriptionEndPolicyEnum.EndDate,
        BillSubscriptionEndPolicyEnum.NumOfOccurrences,
      ]
    : [BillSubscriptionEndPolicyEnum.EndDate, BillSubscriptionEndPolicyEnum.NumOfOccurrences];

  const endPolicyOptions: ComponentProps<typeof Form.SelectNew>['options'] = endPolicyAvailableOptions.map((type) => ({
    value: type,
    label: formatMessage(`activities.paymentFlow.form.content.recurring.paymentDuration.options.${type}`),
    testId: `endPolicy-${type}`,
  }));

  const [endPolicy, startDate, scheduleDate, paymentAmount, intervalType, endDate, numOfOccurrences] = useWatch({
    control,
    name: ['endPolicy', 'startDate', 'scheduleDate', 'amountToPay', 'intervalType', 'endDate', 'numOfOccurrences'],
  });

  const recurringFieldsToShow = endPolicy
    ? getRecurringFieldsToShow(endPolicy, isRecurringPaymentImprovementsEnabled)
    : undefined;

  const calculatedNumOfOccurrences =
    intervalType && startDate
      ? getNumberOfOccurrences({
          paymentFrequency: intervalType,
          startDate,
          endDate: endDate || undefined,
        })
      : undefined;

  const calculatedLastPaymentDate =
    intervalType && numOfOccurrences && startDate
      ? calculateRecurringEndDateByNumOfOccurrences({
          paymentFrequency: intervalType,
          startDate,
          numOfOccurrences: Number(numOfOccurrences),
        })
      : undefined;

  const debitText = formatMessage('activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.helperText', {
    debitDate: formatDate(scheduleDate, { dateStyle: 'medium' }),
  });

  return (
    <Group variant="vertical" spacing="m">
      <Form.SelectNew
        {...registerField('intervalType')}
        labelProps={{ label: formatMessage('activities.paymentFlow.form.content.recurring.frequency.label') }}
        options={frequencyOptions}
        isRequired
        shouldHideClearButton
      />
      <Group variant="vertical" spacing={isMobile ? 'm' : 's'}>
        <Group variant={isMobile ? 'vertical' : 'horizontal'} width="full" spacing={isMobile ? 's' : 'm'}>
          <Group.Item grow={1} basis={2}>
            <Group variant="vertical" width="full" spacing="xs-s">
              <DateField
                {...form.registerField('startDate')}
                onChange={(startDate) => {
                  if (startDate && endDate && isBefore(endDate, startDate)) {
                    form.setValue('endDate', addBusinessDays(startDate, 1));
                  }
                  onStartDateChange(startDate);
                }}
                toggleDatePickerAriaLabel={formatMessage(
                  'activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.toggleDatePickerAriaLabel'
                )}
                labelProps={{
                  label: isEditFlow
                    ? formatMessage('activities.paymentFlow.form.content.recurring.nextPaymentDeliverBy.label')
                    : formatMessage('activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.label'),
                }}
                placeholder={formatMessage('activities.paymentFlow.form.content.deliveryDate.placeholder')}
                paymentCalendarDates={paymentCalendarDates}
                minDate={first(paymentCalendarDates)?.minDeliveryDate}
                maxDate={endDate || last(paymentCalendarDates)?.minDeliveryDate}
                isDisabled={!isLoading && !paymentCalendarDates}
                isLoading={isLoading}
                excludeHolidays
                hideClear
                value={startDate}
                aria-label={formatMessage(
                  isEditFlow
                    ? 'activities.paymentFlow.form.content.recurring.nextPaymentDeliverBy.aria'
                    : 'activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.aria',
                  {
                    debitAria: debitText,
                  }
                )}
                secondaryDateAriaLabel={formatMessage(
                  'activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.selectedDebit.aria'
                )}
                currentDateAriaLabel={formatMessage(
                  'activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.today.aria'
                )}
                selectedDateAriaLabel={formatMessage(
                  isEditFlow
                    ? 'activities.paymentFlow.form.content.recurring.nextPaymentDeliverBy.selected.aria'
                    : 'activities.paymentFlow.form.content.recurring.firstPaymentDeliverBy.selected.aria'
                )}
              />
              {scheduleDate && (
                <Text textStyle="body2" color="global.neutral.800" data-testid="startDate-debitDate-text">
                  {debitText}
                </Text>
              )}
            </Group>
          </Group.Item>
          <Group.Item grow={1} basis={2}>
            <Form.SelectNew
              {...form.registerField('endPolicy')}
              options={endPolicyOptions}
              isRequired
              labelProps={{
                label: formatMessage('activities.paymentFlow.form.content.recurring.paymentDuration.label'),
              }}
              shouldHideClearButton
            />
          </Group.Item>
        </Group>
        {(recurringFieldsToShow?.endDate ||
          recurringFieldsToShow?.numberOfOccurrences ||
          recurringFieldsToShow?.lastAmount) && (
          <Group variant={isMobile ? 'vertical' : 'horizontal'} width="full" spacing="m">
            {recurringFieldsToShow.endDate && (
              <Group.Item basis={2} grow={1}>
                <DateField
                  {...form.registerField('endDate')}
                  toggleDatePickerAriaLabel={formatMessage(
                    'activities.paymentFlow.form.content.recurring.endDate.toggleDatePickerAriaLabel'
                  )}
                  onChange={(date) => form.setValue('endDate', date, { shouldValidate: true })}
                  isRequired
                  minDate={startDate ? addBusinessDays(startDate, 1) : createDate()}
                  maxDate={
                    startDate ? addYears(startDate, RESTRICTION_YEARS) : addYears(createDate(), RESTRICTION_YEARS)
                  }
                  excludeHolidays
                  value={endDate}
                  hideClear
                  labelProps={{ label: formatMessage('activities.paymentFlow.form.content.recurring.endDate.label') }}
                  selectedDateAriaLabel={formatMessage(
                    'activities.paymentFlow.form.content.recurring.endDate.selected.aria'
                  )}
                  currentDateAriaLabel={formatMessage(
                    'activities.paymentFlow.form.content.recurring.endDate.today.aria'
                  )}
                  helperTextProps={
                    calculatedNumOfOccurrences
                      ? {
                          label: formatMessage('activities.paymentFlow.form.content.recurring.endDate.helperText', {
                            numOfOccurrences: calculatedNumOfOccurrences,
                          }),
                        }
                      : undefined
                  }
                />
              </Group.Item>
            )}
            {recurringFieldsToShow.numberOfOccurrences && (
              <Group.Item basis={2} grow={1}>
                <Form.TextField
                  {...form.registerField('numOfOccurrences')}
                  isRequired
                  labelProps={{
                    label: formatMessage('activities.paymentFlow.form.content.recurring.occurrences.label'),
                  }}
                  maskProps={{ mask: RECURRING_NUM_OF_OCCURRENCES_REGEX }}
                  helperTextProps={
                    calculatedLastPaymentDate
                      ? {
                          label: formatMessage('activities.paymentFlow.form.content.recurring.occurrences.helperText', {
                            lastPaymentDate: formatDate(calculatedLastPaymentDate, {
                              dateStyle: 'medium',
                            }),
                          }),
                        }
                      : undefined
                  }
                />
              </Group.Item>
            )}
            {recurringFieldsToShow.lastAmount && (
              <Group.Item basis={2} grow={1}>
                <Form.AmountField
                  allowNegativeValue={false}
                  {...form.registerField('lastAmount')}
                  labelProps={{
                    label: formatMessage('activities.paymentFlow.form.content.recurring.lastPaymentAmount.label'),
                  }}
                  placeholder={paymentAmount ? formatCurrency(Number(paymentAmount)) : undefined}
                />
              </Group.Item>
            )}
          </Group>
        )}
      </Group>
    </Group>
  );
}
