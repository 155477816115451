import { Box } from '@chakra-ui/react';
import { Text } from '@melio/penny';
import { AccountingPlatform } from '@melio/platform-api';

import { SyncNowIconButton } from './components/SyncNowIconButton';
import { SyncNowPlatformIcon } from './components/SyncNowPlatformIcon';

type SyncIconProps = {
  ariaLabel: string;
  isError?: boolean;
  isSyncRunning: boolean;
  accountingPlatform: AccountingPlatform;
  isExtraSmallScreen: boolean;
  onClick: VoidFunction;
  tooltipTestId?: string;
  tooltipTitle?: string;
  tooltipLabel?: string;
};

export const SyncIcon = ({
  ariaLabel,
  isError,
  isSyncRunning,
  accountingPlatform,
  isExtraSmallScreen,
  onClick,
  tooltipTestId,
  tooltipTitle,
  tooltipLabel,
}: SyncIconProps) => {
  const shouldShowPlatformIcon = !isError && !isSyncRunning;

  return (
    <Box position="relative" tabIndex={-1}>
      <Text>
        <SyncNowIconButton
          variant={isExtraSmallScreen ? 'naked' : undefined}
          onClick={onClick}
          isDisabled={isSyncRunning}
          ariaLabel={ariaLabel}
          tooltipTestId={tooltipTestId}
          tooltipLabel={tooltipLabel}
          tooltipTitle={tooltipTitle}
          placement="bottom"
        />
        {shouldShowPlatformIcon ? (
          <SyncNowPlatformIcon accountingPlatformSlug={accountingPlatform.accountingSlug} />
        ) : null}
      </Text>
    </Box>
  );
};
