import { Button, Icon, Popover } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useState } from 'react';

type Props = {
  onEditClick: VoidFunction;
  vendorName: string;
};

export const VendorMissingInfoIcon = ({ onEditClick, vendorName }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handlePopoverVisibilityChange = (isOpen: boolean) => {
    setIsPopoverOpen(isOpen);
    if (!isOpen) {
      return;
    }

    track('Dashboard', 'Click', {
      Cta: 'missing-info-icon',
    });
    track('Dashboard', 'View', {
      Intent: 'missing-vendor-info-view',
    });
  };

  const handleEditClick = () => {
    track('Dashboard', 'Click', {
      Intent: 'missing-vendor-info-view',
      Cta: 'edit-vendor-details',
    });
    onEditClick();
  };

  return (
    <Popover
      title={formatMessage('widgets.vendorMissingInfoIcon.tooltip.title')}
      actionRenderer={({ onClose, ...props }) => (
        <Button
          {...props}
          aria-haspopup="dialog"
          tabIndex={0}
          size="small"
          variant="secondary-inverse"
          label={formatMessage('widgets.vendorMissingInfoIcon.tooltip.cta')}
          onClick={() => {
            onClose();
            handleEditClick();
          }}
        />
      )}
      isOpen={isPopoverOpen}
      onOpenChange={handlePopoverVisibilityChange}
      description={formatMessage('widgets.vendorMissingInfoIcon.tooltip.description', { vendorName })}
      data-testid="vendor-missing-info-icon-widget-popover"
    >
      <Icon
        size="small"
        type="info"
        aria-label={formatMessage('widgets.vendorMissingInfoIcon.ariaLabel')}
        data-testid="vendor-missing-info-icon-widget-icon"
        role="button"
      />
    </Popover>
  );
};
