import { Box, Button as ChakraButton } from '@chakra-ui/react';
import { BaseMenu, Button, Divider, Group, Icon, Text, useTheme } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { CompanyType, OrganizationWithLogoUrl } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { partition } from 'lodash';
import { useMemo, useState } from 'react';

import { CompanyAvatarWidget } from '../CompanyAvatar';
import { sortOrganizations } from './OrganizationSwitcher.widgets.utils';

export type OrganizationSwitcherWidgetsProps = {
  organizations: OrganizationWithLogoUrl[];
  selectedId: OrganizationWithLogoUrl['id'];
  onSelect: (organizationId: OrganizationWithLogoUrl['id']) => void;
  defaultIsOpen?: boolean;
  onAddCompany: VoidFunction;
  shouldShowAddCompanyButton: boolean;
};

export const OrganizationSwitcherWidget = withAnalyticsContext<OrganizationSwitcherWidgetsProps>(
  ({
    organizations,
    selectedId,
    onSelect,
    defaultIsOpen,
    onAddCompany,
    shouldShowAddCompanyButton,
    setAnalyticsProperties,
    ...props
  }) => {
    const { formatMessage } = useMelioIntl();
    const { track } = useAnalytics();
    setAnalyticsProperties({
      PageName: 'companies-dropdown',
      Flow: 'companies',
      CountInTab: organizations.length,
      Intent: 'see-companies',
    });

    const [isOpen, setIsOpen] = useState<boolean>(!!defaultIsOpen);
    const shouldShowCaret = shouldShowAddCompanyButton || organizations.length > 1;

    const selectedItem = organizations.find((organization) => organization.id === selectedId);
    const theme = useTheme();

    const { accountingFirm, smbOrganizations } = useMemo(() => {
      const visibleOrganizations = organizations.filter(({ isHidden }) => !isHidden);
      const [accountingFirms, smbOrganizations] = partition(
        visibleOrganizations,
        (o) => o.companyType === CompanyType.AccountingFirm
      );
      const accountingFirm = accountingFirms[0];

      const sortedSmbOrganizations = sortOrganizations(smbOrganizations);

      return {
        accountingFirm,
        smbOrganizations: sortedSmbOrganizations,
      };
    }, [organizations]);

    const handleTriggerClick = () => {
      track('User', 'Click', {
        Cta: 'companies-dropdown',
      });
    };

    const handleOpenChange = (isDropdownOpen: boolean) => {
      if (isDropdownOpen) {
        track('User', 'View');
      }
      setIsOpen(isDropdownOpen);
    };

    const handleAddCompanyClick = () => {
      track('User', 'Click', {
        Cta: 'add-company',
      });
      onAddCompany();
    };

    const handleCompanySelect = (organizationId: string) => () => {
      track('User', 'Click', {
        Cta: 'select-company',
        CompanyOrgId: organizationId,
      });
      onSelect(organizationId);
      setIsOpen(false);
    };

    const trigger = (
      <ChakraButton
        data-testid="select-company-btn"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        sx={{
          ...theme.textStyles.body4Semi,
          maxWidth: 'inherit',
          borderRadius: '8px',
          padding: '12px 16px',
          _hover: { backgroundColor: theme.colors.global.neutral['200'] },
          _expanded: { backgroundColor: theme.colors.global.neutral['300'] },
          _focus: { outlineColor: theme.colors.global.informative['700'], outlineOffset: 0 },
        }}
        onClickCapture={handleTriggerClick}
      >
        <Group alignItems="center" spacing="s">
          {selectedItem?.name && (
            <CompanyAvatarWidget
              name={selectedItem?.name}
              src={selectedItem?.logoUrl}
              isSelected={selectedItem.id === selectedId}
            />
          )}
          {selectedItem?.name && (
            <Text color="inherit" textStyle="inline" shouldSupportEllipsis>
              {selectedItem?.name}
            </Text>
          )}
          {shouldShowCaret && <Icon type={isOpen ? 'caret-up' : 'caret-down'} size="small" color="default" />}
        </Group>
      </ChakraButton>
    );

    const header = accountingFirm ? (
      <>
        <Box
          paddingX={5}
          paddingY={4}
          textStyle="body4SemiUpper"
          color="global.neutral.900"
          data-testid="organization-switcher-accounting-firm-title"
        >
          {formatMessage('widgets.accountSwitcher.menuList.accountingFirmTitle')}
        </Box>
        <BaseMenu.Item
          onClick={handleCompanySelect(accountingFirm.id)}
          data-testid={`organization-switcher-item-${accountingFirm.id}`}
        >
          <Box paddingX={5} paddingY="s" width="full">
            <Group alignItems="center" spacing="s">
              {accountingFirm.name && (
                <CompanyAvatarWidget
                  name={accountingFirm.name}
                  src={accountingFirm.logoUrl}
                  isSelected={accountingFirm.id === selectedId}
                />
              )}
              {accountingFirm.name && (
                <Text color="inherit" textStyle="body3" shouldSupportEllipsis>
                  {accountingFirm.name}
                </Text>
              )}
            </Group>
          </Box>
        </BaseMenu.Item>
        <Divider />
        <Box
          paddingX={5}
          paddingY={4}
          textStyle="body4SemiUpper"
          color="global.neutral.900"
          data-testid="organization-switcher-clients-title"
        >
          {formatMessage('widgets.accountSwitcher.menuList.clientsTitle')}
        </Box>
      </>
    ) : (
      <Box paddingX={5} paddingY={4} textStyle="body4SemiUpper" color="global.neutral.900">
        {formatMessage('widgets.accountSwitcher.menuList.title')}
      </Box>
    );

    const footer = shouldShowAddCompanyButton ? (
      <Box padding={4}>
        <Button
          size="small"
          leftElement={<Icon size="small" type="add" color="inherit" aria-hidden />}
          data-testid="organization-switcher-add-company-btn"
          label={formatMessage('widgets.accountSwitcher.menuList.buttons.addCompany')}
          variant="tertiary"
          isFullWidth
          onClick={handleAddCompanyClick}
        />
      </Box>
    ) : null;

    return (
      <BaseMenu
        data-component="OrganizationSwitcherWidget"
        data-testid="organization-switcher-dropdown"
        {...props}
        trigger={trigger}
        isOpen={isOpen}
        header={header}
        content={smbOrganizations.map((organization, index) => (
          <BaseMenu.Item
            key={index}
            onClick={handleCompanySelect(organization.id)}
            data-testid={`organization-switcher-item-${organization.id}`}
          >
            <Box paddingX={5} paddingY="s" width="full">
              <Group alignItems="center" spacing="s">
                {organization?.name && (
                  <CompanyAvatarWidget
                    name={organization.name}
                    src={organization?.logoUrl}
                    isSelected={organization.id === selectedId}
                  />
                )}
                {organization.name && (
                  <Text
                    color="inherit"
                    textStyle="body3"
                    data-testid="organization-switcher-item-name"
                    shouldSupportEllipsis
                  >
                    {organization.name}
                  </Text>
                )}
              </Group>
            </Box>
          </BaseMenu.Item>
        ))}
        footer={footer}
        onOpenChange={handleOpenChange}
        width="310px"
        maxHeight="500px"
      />
    );
  }
);

OrganizationSwitcherWidget.displayName = 'OrganizationSwitcherWidget';
