/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { object, SchemaOf, string } from 'yup';
import { validateInvalidChars } from '@melio/ap-widgets';
import { Form, useMelioForm, VisuallyHidden } from '@melio/penny';
import { useVendor } from '@melio/platform-api';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { useVendors } from '@/hooks/vendors.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { DataComponentEnum, VendorDetailsFormFields } from '@/types/vendors.types';
import { optionalEmailIsValid } from '@/utils/email.utils';
import { optionalPhoneIsValid } from '@/utils/phone.utils';
import { ConfirmVendorUpdateModal, useConfirmVendorUpdate } from '../../ConfirmVendorUpdateModal.widget';
import { VendorDetailsFormInnerProps } from '../types';
import { preventFormSubmissionOnEnter } from '../utils';
import { getUpdateVendorPayload } from './utils';

type VendorDetailsMinimalUnmanagedFields = Pick<
  VendorDetailsFormFields,
  'companyName' | 'fullName' | 'email' | 'phone' | 'accountNumber'
>;

const maskAccountNumber = (accountNumber?: string | null) =>
  accountNumber ? `****${accountNumber.slice(-4)}` : accountNumber;

export const VendorDetailsUnmanagedForm = forwardRef<VendorDetailsFormInnerProps, 'form'>(
  ({ vendorId, defaultValues, isSaving, onSubmit, onSubmissionStateChange, isEditable, ...props }, ref) => {
    const { formatMessage } = usePlatformIntl();
    const confirmVendorUpdate = useConfirmVendorUpdate({
      vendorId,
      initialFormData: defaultValues,
    });

    const {
      settings: {
        vendor: {
          createVendor: { shouldCollectAccountNumberForUnmanagedVendor },
        },
      },
    } = useConfig();

    const useSchema = (): SchemaOf<VendorDetailsMinimalUnmanagedFields> => {
      const { formatMessage } = usePlatformIntl();
      const { vendors } = useVendors();

      return object().shape({
        companyName: string()
          .trim()
          .required(formatMessage('widgets.vendorDetails.form.companyName.validation.required'))
          .test(
            'is-company-name-not-exists',
            formatMessage('widgets.vendorDetails.form.companyName.validation.alreadyExists'),
            (companyName) => {
              return (
                vendors?.filter((vendor) => vendor?.name === companyName).length === 0 ||
                companyName === defaultValues?.companyName
              );
            },
          ),
        fullName: string().trim().optional().nullable(),
        email: string()
          .trim()
          .optional()
          .nullable()
          .test(
            'validAddress',
            formatMessage('widgets.vendorDetails.form.email.validation.format'),
            optionalEmailIsValid,
          ),
        phone: string()
          .trim()
          .optional()
          .nullable()
          .test(
            'validPhone',
            formatMessage('widgets.vendorDetails.form.phone.validation.format'),
            optionalPhoneIsValid,
          ),
        accountNumber: string()
          .max(32, formatMessage('widgets.vendors.accountNumber.validation.maxLength'))
          .test(
            'validate-invalid-chars',
            '',
            validateInvalidChars(/[!"#$%&\-0-9A-Za-z]*/, (invalidChars) =>
              formatMessage('widgets.vendors.accountNumber.validation.format', {
                invalidChars,
              }),
            ),
          ),
      }) as SchemaOf<VendorDetailsMinimalUnmanagedFields>;
    };

    const handleSubmit = async () => {
      const data = getValues();
      await onSubmit(getUpdateVendorPayload(data));
      confirmVendorUpdate.onClose();
    };

    const { formProps, registerField, reset, formState, getValues } = useMelioForm<VendorDetailsMinimalUnmanagedFields>(
      {
        onSubmit: (data) => {
          if (confirmVendorUpdate.shouldShowConfirm(data)) {
            confirmVendorUpdate.onOpen();
          } else {
            handleSubmit();
          }
        },
        schema: useSchema(),
        isSaving,
        defaultValues,
        onSubmissionStateChange,
      },
    );

    const { getVendorUnmaskedAccountNumber } = useVendor({ id: vendorId });
    const [isLoadingUnmaskedValues, setIsLoadingUnmaskedValues] = useState(false);
    const [unmaskedAccountNumber, setUnmaskedAccountNumber] = useState<string>();

    useEffect(() => {
      if (!formState.isSubmitting) {
        const accountNumber = isEditable ? unmaskedAccountNumber : maskAccountNumber(defaultValues?.accountNumber);

        reset({
          ...defaultValues,
          accountNumber: accountNumber ?? defaultValues?.accountNumber ?? '',
        });
      }
    }, [defaultValues, isEditable, reset]);

    useEffect(() => {
      const unmaskValues = async () => {
        setIsLoadingUnmaskedValues(true);

        try {
          const vendorUnmaskedAccountNumber = await getVendorUnmaskedAccountNumber();

          setUnmaskedAccountNumber(vendorUnmaskedAccountNumber.accountNumber);
        } finally {
          setIsLoadingUnmaskedValues(false);
        }
      };

      if (isEditable && shouldCollectAccountNumberForUnmanagedVendor) {
        unmaskValues();
      }
    }, [isEditable, getVendorUnmaskedAccountNumber, defaultValues?.accountNumber]);

    return (
      <>
        <Form
          data-component={DataComponentEnum.VENDOR_DETAILS_FORM}
          isLoading={isLoadingUnmaskedValues}
          {...props}
          {...formProps}
          ref={ref}
          isViewMode={!isEditable}
          {...preventFormSubmissionOnEnter()}
        >
          <Form.TextField
            {...registerField('companyName')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.companyName.label') }}
            placeholder={formatMessage('widgets.vendorDetails.form.companyName.placeholder')}
            autoFocus={isEditable}
            isTruncated={!isEditable}
          />
          <Form.TextField
            {...registerField('fullName')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.name.label') }}
            viewModePlaceholder={formatMessage('widgets.vendorDetails.form.name.viewModePlaceholder')}
            placeholder={formatMessage('widgets.vendorDetails.form.name.placeholder')}
            isTruncated={!isEditable}
          />
          {shouldCollectAccountNumberForUnmanagedVendor && (
            <Form.TextField
              {...registerField('accountNumber')}
              data-private
              labelProps={{
                label: formatMessage('widgets.vendors.accountNumber.label'),
                tooltipProps: {
                  content: (
                    <>
                      <VisuallyHidden>
                        {formatMessage('widgets.vendors.accountNumber.optional.labelTooltip')} tooltip
                      </VisuallyHidden>
                      {formatMessage('widgets.vendors.accountNumber.optional.labelTooltip')}
                    </>
                  ),
                },
              }}
              isTruncated={!isEditable}
              viewModePlaceholder={formatMessage('widgets.vendors.accountNumber.optional.viewModePlaceholder')}
            />
          )}
          <Form.TextField
            {...registerField('email')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.email.label') }}
            viewModePlaceholder={formatMessage('widgets.vendorDetails.form.email.viewModePlaceholder')}
            placeholder={formatMessage('widgets.vendorDetails.form.email.placeholder')}
            isTruncated={!isEditable}
          />
          <Form.PhoneField
            {...registerField('phone')}
            labelProps={{ label: formatMessage('widgets.vendorDetails.form.phone.label') }}
            viewModePlaceholder={formatMessage('widgets.vendorDetails.form.phone.viewModePlaceholder')}
            placeholder={formatMessage('widgets.vendorDetails.form.phone.placeholder')}
          />
        </Form>
        <ConfirmVendorUpdateModal
          submitForm={handleSubmit}
          isSubmitting={formState.isSubmitting}
          onClose={confirmVendorUpdate.onClose}
          isOpen={confirmVendorUpdate.isOpen}
        />
      </>
    );
  },
);
