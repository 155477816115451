import {
  DeliveryMethod,
  DeliveryMethodType,
  DeliveryMethodTypeOption,
  InternationalAccountIdentifierTypeEnum,
} from '@melio/platform-api';

export const shouldDisplayInternationalAccount = (
  isInternationalEnabled: boolean,
  deliveryMethods: DeliveryMethod[],
  deliveryMethodTypeOptions: DeliveryMethodTypeOption[] | undefined,
) => {
  return (
    isInternationalEnabled ||
    (vendorHasInternationalAccountDM(deliveryMethods) &&
      isDeliveryMethodNotSupportedForState(deliveryMethodTypeOptions))
  );
};

export const shouldDisplayFXInternationalAccount = (
  isInternationalFXEnabled: boolean,
  deliveryMethods: DeliveryMethod[],
  deliveryMethodTypeOptions: DeliveryMethodTypeOption[] | undefined,
) => {
  return (
    isInternationalFXEnabled ||
    (vendorHasFXInternationalAccountDM(deliveryMethods) &&
      isDeliveryMethodNotSupportedForState(deliveryMethodTypeOptions))
  );
};

export const vendorHasInternationalAccountDM = (deliveryMethods: DeliveryMethod[]): boolean => {
  return !!deliveryMethods.find(({ type, details }) => {
    const isInternationalAccount = type === DeliveryMethodType.InternationalAccount;
    if (isInternationalAccount) {
      return details.identifierType !== InternationalAccountIdentifierTypeEnum.V2;
    }
    return isInternationalAccount;
  });
};

export const vendorHasFXInternationalAccountDM = (deliveryMethods: DeliveryMethod[]): boolean => {
  return !!deliveryMethods.find(({ type, details }) => {
    const isInternationalAccount = type === DeliveryMethodType.InternationalAccount;
    if (isInternationalAccount) {
      return details.identifierType === InternationalAccountIdentifierTypeEnum.V2;
    }
    return isInternationalAccount;
  });
};

export const isDeliveryMethodNotSupportedForState = (
  deliveryMethodTypeOptions: DeliveryMethodTypeOption[] | undefined,
): boolean => {
  return !!deliveryMethodTypeOptions?.find(
    ({ type, reason }) =>
      type === DeliveryMethodType.InternationalAccount && reason === 'deliveryMethodNotSupportedForState',
  );
};
