/* eslint-disable max-lines */
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import {
  DEFAULT_CURRENCY,
  isFXCurrency,
  useAccountingPlatformEntities,
  useInternationalFxFeature,
  useIsLineItemsAvailable,
  useSupportedFXData,
} from '@melio/ap-domain';
import { useVendorDirectoryInfoComplete } from '@melio/ap-widgets';
import {
  Container,
  LargeFileUpload,
  OnSubmissionStateChange,
  SplitScreen,
  StatusModal,
  Text,
  useBreakpointValue,
  useMelioForm,
  UseMelioFormResults,
  useTheme,
  useWatch,
} from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { Bill, FileInfo, useAccountsWithLogoUrl, useInternationalRate, useVendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { useConfig, usePartnerFeature } from '@melio/platform-provider';
import { useDateUtils, useSystemMessage } from '@melio/platform-utils';
import { isEmpty, keys } from 'lodash';
import { ReactElement, useEffect, useState } from 'react';

import { useUploadBillFile, UseUploadBillFileProps } from '../../../utils/useUploadBillFile';
import { useVendorSelectSideEffects } from '../../../utils/useVendorSelectSideEffects';
import { AmountsMismatchModal } from '../../ReviewScannedInvoice/components/AmountsMismatchModal';
import { InvoiceNumberSchema, SetInvoiceNumberModal } from '../../set-invoice-number-modal';
import { BillFileOcrData, getInitialValueLineItemWithAmount, useGetBillFileOcrData } from '../bill-utils';
import { Footer } from './components/Footer';
import { AddBillFormPanel } from './components/LineItems/AddBillFormPanel/AddBillFormPanel';
import { NewBillHeader } from './components/NewBillHeader';
import { ReplaceExistingDetailsModal } from './components/ReplaceExistingDetailsModal';
import { useAddBillV2Schema } from './hooks/useAddBillV2Schema';
import { useDefaultFormValues } from './hooks/useDefaultFormValues';
import { useGetAllowedFXBusinessType } from './hooks/useGetAllowedFXBusinessType';
import {
  AddBillV2DFormInitialValues,
  AddBillV2FormFrequency,
  AddBillV2FormValues,
  AddBillV2FormValuesResult,
  AddBillV2OriginEnum,
  AddBillV2RecurringEndBy,
  AddBillV2SubmitType,
  CustomFooterProps,
} from './types';
import {
  calculateTotalLineItemsCount,
  formatFormValues,
  getValidFxAmountLimitForQuery,
  isRecurringFrequency,
  scrollToFirstError,
  useLineItemsController,
  useLocationsPaywall,
} from './utils';

export type AddBillV2FormActivityProps = {
  onClose: VoidFunction;
  onBack: VoidFunction;
  onDone: (values: AddBillV2FormValuesResult, submitType: AddBillV2SubmitType) => Promise<Bill | null | void> | void;
  initialValues?: AddBillV2DFormInitialValues;
  isSaving?: boolean;
  isLoading?: boolean;
  onSubmissionStateChange?: OnSubmissionStateChange<AddBillV2FormValues>;
  renderCustomFooter?: (props: CustomFooterProps) => ReactElement;
  allowModifyingFile?: boolean;
  initialFile?: File;
  fileInfo?: FileInfo;
  origin?: AddBillV2OriginEnum;
  isAdvancedView?: boolean;
  hideSecondaryButton?: boolean;
};

export const AddBillV2FormActivity = withAnalyticsContext<AddBillV2FormActivityProps>(
  ({ setAnalyticsProperties, origin = AddBillV2OriginEnum.New, ...props }) => {
    const { isFetching: isLoadingAccounts } = useAccountsWithLogoUrl({ enabled: false });
    const { track } = useAnalytics();
    const { showMessage, hideMessage } = useSystemMessage();
    const theme = useTheme();
    const {
      settings: {
        newBillExperience: { isRecurringEnabled },
      },
    } = useConfig();
    const [savedSubmitType, setSavedSubmitType] = useState<string | undefined>();
    const [amountFieldBlurTimer, setAmountFieldBlurTimer] = useState<NodeJS.Timeout | null>(null);
    const [amountForQuery, setAmountForQuery] = useState<string | undefined>(undefined);
    const isInternationalFxEnabled = useInternationalFxFeature();
    const [isGetBillFileOcrDataOnBillDetailsScreenEnabled] = useFeature(
      FeatureFlags.GetBillFileOcrDataOnBillDetailsScreen,
      false,
      {
        shouldTrack: true,
      }
    );
    const isEditMode = origin === AddBillV2OriginEnum.Edit;
    const [shouldClearVendorIdField, setShouldClearVendorFieldId] = useState<boolean>(false);
    const [isReplaceExistingDetailsModalOpen, setIsReplaceExistingDetailsModalOpen] = useState(false);
    const [isAmountMismatchModalOpen, setIsAmountMismatchModalOpen] = useState(false);
    const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState(false);
    const {
      isOpen: isShowAddInvoiceNumberModal,
      onOpen: onShowAddInvoiceNumberModal,
      onClose: onCloseAddInvoiceNumberModal,
    } = useDisclosure();
    const { formatMessage } = useMelioIntl();

    const isMobile = useBreakpointValue({ xs: true, s: false });
    const isTablet = useBreakpointValue({ m: true, l: false });
    const isLineItemsAvailable = useIsLineItemsAvailable();

    const { isAllowedBusinessType } = useGetAllowedFXBusinessType();

    const isFxFeatureAvailable = isAllowedBusinessType && isInternationalFxEnabled;
    const { currencies } = useSupportedFXData({});

    const {
      billLabels,
      billLineItemLabels,
      activeAccountingPlatform,
      hasAccountingPlatform,
      isAccountingPlatformEntitiesLoading,
    } = useAccountingPlatformEntities(true);

    const handleUploadFileError = () => {
      track('Bill', 'Status', {
        Intent: 'invoice-upload-ended',
        Status: 'Failure',
      });
    };

    const handleUploadFileStarted = () => {
      track('Bill', 'Status', {
        Intent: 'invoice-upload-started',
        Status: 'Success',
      });
    };

    const handleUploadFileFinished: UseUploadBillFileProps['onUploadFinished'] = async (newFileInfo) => {
      track('Bill', 'Status', {
        Intent: 'invoice-upload-ended',
        Status: 'Success',
      });

      if (isGetBillFileOcrDataOnBillDetailsScreenEnabled && newFileInfo && newFileInfo?.id !== props.fileInfo?.id) {
        const hasInitialValues = Object.values(props.initialValues || {}).some(Boolean);
        const billFileOcrDataResult = await getBillFileOcrData(newFileInfo?.id);
        if (billFileOcrDataResult) {
          if (melioFormProps.formState.isDirty || !isEmpty(melioFormProps.formState.dirtyFields) || hasInitialValues) {
            setIsReplaceExistingDetailsModalOpen(true);
          } else {
            handleReplace(billFileOcrDataResult);
          }
        }
      }
    };

    const {
      file,
      fileValue,
      setFile,
      fileInfo,
      isLoading: isFileLoading,
    } = useUploadBillFile({
      initialFileInfo: props.fileInfo,
      initialFile: props.initialFile,
      onError: handleUploadFileError,
      onUploadStarted: handleUploadFileStarted,
      onUploadFinished: handleUploadFileFinished,
    });

    const { defaultFormValues, getDefaultFormValues } = useDefaultFormValues({
      values: props.initialValues,
      hasAccountingPlatform,
      activeAccountingPlatform,
    });
    const schema = useAddBillV2Schema({
      initialValues: { amount: props.initialValues?.amount, balance: props.initialValues?.balance },
    });
    const { createDate } = useDateUtils();
    const melioFormProps = useMelioForm<AddBillV2FormValues>({
      onSubmit: async (values, _, submitType = AddBillV2SubmitType.SAVE_AND_CLOSE) => {
        if (selectedVendor && !isVendorDirectoryInfoCompleted) {
          return;
        }

        const { lineItems, ...restValues } = formatFormValues({
          values,
          createDate,
          isAdvancedBillFlow: isLineItemsAvailable,
          billLineItemLabels,
          activeAccountingPlatform,
        });
        restValues.invoiceDate = restValues?.invoiceDate || createDate();
        if (props?.initialValues?.balance && values?.amount) {
          const currentBalance = props?.initialValues?.balance;
          const currentAmount = parseFloat(values?.amount);
          const previousAmount = parseFloat(props?.initialValues?.amount || '0');
          restValues.balance = currentBalance + currentAmount - previousAmount;
        }
        const lineItemsCount = calculateTotalLineItemsCount({ values });

        const analyticsProps = {
          Cta: submitType === AddBillV2SubmitType.CONTINUE_TO_PAY ? 'continue-to-pay' : 'save-and-close',
          amount: values?.amount,
          PaymentFrequency: values?.frequency,
          RecurringStartDate: restValues?.recurringStartDate,
          RecurringPaymentEndBy: restValues?.recurringEndBy,
          Currency: values?.currency,
          UsdAmount: amountInUSD,
          AccountingSoftwareType: activeAccountingPlatform?.accountingSlug,
          RecurringPaymentEndValue:
            restValues?.recurringEndBy === AddBillV2RecurringEndBy.DATE
              ? restValues?.recurringEndDate?.toISOString()
              : restValues?.recurringOccurrences,
          LineItemsCount: lineItemsCount,
          IsRecurring: isRecurringFrequency(values.frequency),
          RecurringEndDate: restValues?.recurringEndDate,
          LastPaymentAmount: values.lastAmount,
          NumberOfPayments: values.recurringOccurrences,
        };

        track('Bill', 'Click', analyticsProps);
        const newBill = await props.onDone(
          {
            ...restValues,
            lineItems,
            fileId: fileInfo?.id,
            file: file ?? undefined,
          },
          submitType as AddBillV2SubmitType
        );

        if (newBill && !isEditMode) {
          track('Bill', 'Status', {
            ...analyticsProps,
            PageName: 'bill-details',
            BillId: newBill.id,
            Flow: 'advanced',
            Status: 'success',
            Location: !!newBill?.externalLabelId,
            LineItemsDetails: lineItems.map((lineItem) => ({
              item: !!lineItem.externalItemId,
              class: lineItem.labels?.some((label) => !isEmpty(label.externalLabelId)),
              category: !!lineItem.externalCategoryId,
              description: !!lineItem.description,
            })),
          });
        }
      },
      isSaving: props.isSaving,
      subscribeToDefaultValuesChanges: true,
      defaultValues: defaultFormValues,
      onSubmissionStateChange: props.onSubmissionStateChange,
      schema,
    });
    const { submitButtonProps, handleSubmit, getValues } = melioFormProps;
    const { isSuvcOverStpVendor, isFetching: isFetchingSelectedVendor } = useVendorSelectSideEffects(
      getValues('vendorId')
    );

    const [frequency, vendorId, currency, amountInUSD] = useWatch({
      control: melioFormProps.control,
      name: ['frequency', 'vendorId', 'currency', 'amountInUSD'],
    });

    const { data: selectedVendor, isLoading: isLoadingSelectedVendor } = useVendor({ id: vendorId });
    const selectedCurrency = isInternationalFxEnabled ? selectedVendor?.currency || currency : undefined;

    const isVendorDirectoryInfoCompleted = useVendorDirectoryInfoComplete(selectedVendor);

    const lineItemsController = useLineItemsController(melioFormProps);

    useLocationsPaywall(melioFormProps, defaultFormValues);

    useEffect(() => {
      setAnalyticsProperties({
        PageName: 'bill-details',
        Flow: 'advanced',
        Intent: 'add-bill',
        IsSynced: !!hasAccountingPlatform,
        LineItems: lineItemsController.isEligible && lineItemsController.isExpanded,
        BillId: props.initialValues?.id?.toString(),
        amount: props.initialValues?.amount?.toString(),
        FileAttached: !!file,
        Currency: currency || DEFAULT_CURRENCY,
        UsdAmount: amountInUSD || '0',
        IsFxExposed: isFxFeatureAvailable,
        Location: !!billLabels?.length,
        Classes: !!billLineItemLabels?.length,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      lineItemsController.isEligible,
      lineItemsController.isExpanded,
      currency,
      amountInUSD,
      billLabels,
      billLineItemLabels,
    ]);

    useEffect(() => {
      const lineItemsCount = calculateTotalLineItemsCount({
        values: melioFormProps?.getValues(),
      });

      track('Bill', 'View', {
        LineItemsCount: lineItemsCount,
        ...(isVendorDirectoryInfoCompleted
          ? {}
          : {
              UserMessage: 'missing-vendor-details',
            }),
      });

      return () => {
        amountFieldBlurTimer && clearTimeout(amountFieldBlurTimer);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (shouldClearVendorIdField) {
        setShouldClearVendorFieldId(false);
      }
    }, [shouldClearVendorIdField]);

    useEffect(() => {
      const formErrors = melioFormProps?.formState?.errors;
      if (!isEmpty(formErrors)) {
        const hasValidationErrorFields = scrollToFirstError('input[aria-invalid=true]');
        const hiddenValidationFields = !hasValidationErrorFields ? formErrors : undefined;

        const errorType = keys(formErrors)?.[0];
        if (errorType) {
          track('Bill', 'Status', {
            Status: 'failure',
            ErrorType: `${errorType}-error`,
            HiddenValidationField: hiddenValidationFields,
          });
        }
      }
    }, [melioFormProps?.formState?.errors, track]);

    useEffect(() => {
      if (selectedVendor?.currency && isFxFeatureAvailable) {
        selectedVendor.currency === DEFAULT_CURRENCY && melioFormProps.clearErrors('invoiceDate');
        melioFormProps.setValue('currency', selectedVendor.currency);
        if (isFXCurrency(selectedVendor.currency)) {
          melioFormProps.setValue('frequency', AddBillV2FormFrequency.ONE_TIME);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVendor?.currency, melioFormProps.setValue, props.initialValues, isFxFeatureAvailable]);

    useEffect(() => {
      if (currencies && currency) {
        if (![DEFAULT_CURRENCY, ...currencies].includes(currency)) {
          melioFormProps.setValue('currency', DEFAULT_CURRENCY);
        }
      }
    }, [currency, currencies, melioFormProps]);

    const {
      isLoading: isFileOCRDataLoading,
      getBillFileOcrData,
      billFileOcrData,
    } = useGetBillFileOcrData({
      billFileInfo: fileInfo,
    });

    const isLoading = isAccountingPlatformEntitiesLoading || isLoadingAccounts || props.isLoading;

    const { data: foreignCurrencyRate, isLoading: isFetchingFXRates } = useInternationalRate({
      foreignCurrency: selectedCurrency as string,
      foreignAmount: amountForQuery ? Number(amountForQuery) : undefined,
    });

    const setFormValues = (values?: AddBillV2DFormInitialValues) => {
      // Because of the implementation of VendorSelect widget selected vendor value is not updated
      // when initial value is already set and form state is updated programmatically.
      // Because of it we need explicitly clear VendorSelect select and only after set the value.
      // setTimeout is needed to make sure that we'll set form state when vendor value will be cleared
      setShouldClearVendorFieldId(true);
      setTimeout(() => {
        melioFormProps.reset(values, { keepDefaultValues: true });
      });
    };

    const closeToastWrapper =
      <T extends unknown[], U>(fn: (...args: T) => U) =>
      (...args: T): U => {
        hideMessage();
        return fn(...args);
      };

    const handleSubmitBillForm: UseMelioFormResults<AddBillV2FormValues>['handleSubmit'] = (target?: string) =>
      closeToastWrapper(async () => {
        if (amountFieldBlurTimer) {
          clearTimeout(amountFieldBlurTimer);
        }
        const isFormValid = await melioFormProps.trigger();
        const shouldAskPayorToFillInvoiceNumber =
          isSuvcOverStpVendor && isEmpty(melioFormProps.getValues('invoiceNumber')) && isFormValid;

        if (shouldAskPayorToFillInvoiceNumber) {
          onShowAddInvoiceNumberModal();
          setSavedSubmitType(target);
        } else {
          handleSubmit(target)();
        }
      });

    const handleReplace = (billFileOcrDataResult: BillFileOcrData) => {
      setIsReplaceExistingDetailsModalOpen(false);

      if (billFileOcrDataResult?.mismatchedAmount && isLineItemsAvailable) {
        setIsAmountMismatchModalOpen(true);
      } else {
        setFormValues(getDefaultFormValues(billFileOcrDataResult?.values));
      }
    };

    const handleCancel = () => {
      setIsReplaceExistingDetailsModalOpen(false);
    };

    const handleAutoAddLines = () => {
      if (!billFileOcrData) {
        return;
      }
      setShouldClearVendorFieldId(true);
      const values = { ...billFileOcrData.values } as AddBillV2DFormInitialValues;
      values.lineItems = [
        ...(values.lineItems ?? []),
        getInitialValueLineItemWithAmount(
          billFileOcrData?.mismatchedAmount,
          formatMessage('widgets.ReviewScannedInvoice.amountsMismatch.lineItem.desc')
        ),
      ];

      setFormValues(getDefaultFormValues(values));
      setIsAmountMismatchModalOpen(false);
    };

    const handleRemoveLines = () => {
      if (!billFileOcrData) {
        return;
      }
      const values = { ...billFileOcrData.values } as AddBillV2DFormInitialValues;
      values.lineItems = [
        getInitialValueLineItemWithAmount(
          Number(values.amount ?? '') ?? 0,
          formatMessage('widgets.ReviewScannedInvoice.amountsMismatch.lineItem.desc')
        ),
      ];

      setFormValues(getDefaultFormValues(values));
      setIsAmountMismatchModalOpen(false);
    };

    const handleUpdateInvoiceNumber = ({ invoiceNumber }: InvoiceNumberSchema) => {
      melioFormProps.setValue('invoiceNumber', invoiceNumber);
      onCloseAddInvoiceNumberModal();
      handleSubmit(savedSubmitType)();
    };

    const handleSkipAddingInvoiceNumber = () => {
      onCloseAddInvoiceNumberModal();
      handleSubmit(savedSubmitType)();
    };

    const allowRecurring = isRecurringEnabled && !props.initialValues?.id;
    const isRecurringFrequencyFlow = isRecurringFrequency(frequency);
    const shouldShowAmountInUSDField =
      isInternationalFxEnabled && isFXCurrency(selectedCurrency) && !isRecurringFrequencyFlow;

    const footerIsSticky = isMobile;
    const footerIsDisabled = submitButtonProps.isDisabled || isFetchingSelectedVendor;
    const footerIsLoading = (submitButtonProps.isLoading || isFetchingFXRates || isLoadingSelectedVendor) ?? false;
    const footerWithSecondaryButton = !props.hideSecondaryButton && !isRecurringFrequencyFlow;

    const handleCloseBillForm = () => closeToastWrapper(props.onClose);

    const onAmountFieldBlur = (amount?: string) => {
      if (shouldShowAmountInUSDField) {
        const timer = setTimeout(() => {
          setAmountForQuery(getValidFxAmountLimitForQuery(currency, amount));
        }, 100);
        setAmountFieldBlurTimer(timer);
      }
    };

    const vendorName = selectedVendor?.name;

    const [showScannedInvoiceDisclaimer] = usePartnerFeature('ScannedInvoiceDisclaimer', false);
    const showBillAmountBanner = !!fileInfo && showScannedInvoiceDisclaimer;

    return (
      <>
        <Container id="addBillV2FormActivity" width="full" height="full" backgroundColor="white">
          <SplitScreen
            isLoading={isLoading}
            variant="1:2"
            data-testid="add-bill-manual-activity"
            header={isTablet ? <NewBillHeader onClose={props.onClose} /> : null}
            footer={
              footerIsSticky ? (
                <Footer
                  isDisabled={footerIsDisabled}
                  isLoading={footerIsLoading}
                  withSecondaryButton={footerWithSecondaryButton}
                  onSubmit={handleSubmitBillForm}
                  onClose={handleCloseBillForm()}
                  renderCustomFooter={props.renderCustomFooter}
                />
              ) : undefined
            }
            panelA={{
              maxWidth: 34,
              padding: 'none',
              content: (
                <Flex
                  background={theme.colors.global.neutral['100']}
                  paddingX={{ xs: 's', m: 'xxl' }}
                  paddingY={{ xs: 'm', m: 'xxl' }}
                  boxSize="100%"
                  align="center"
                >
                  <AmountsMismatchModal
                    mismatchedValue={`$${billFileOcrData?.mismatchedAmount ?? 0}`}
                    isOpen={isAmountMismatchModalOpen && isLineItemsAvailable}
                    onAdd={handleAutoAddLines}
                    onRemove={handleRemoveLines}
                    billId={props.initialValues?.id?.toString()}
                  />
                  <ReplaceExistingDetailsModal
                    isOpen={isReplaceExistingDetailsModalOpen}
                    onClose={handleCancel}
                    onReplace={() => handleReplace(billFileOcrData)}
                    onCancel={handleCancel}
                  />
                  <StatusModal
                    variant="alert"
                    isOpen={isDeleteFileModalOpen}
                    header={formatMessage('activities.addBillV2.deleteFile.header')}
                    onClose={() => setIsDeleteFileModalOpen(false)}
                    primaryButton={{
                      label: formatMessage('activities.addBillV2.deleteFile.primaryButton.label'),
                      variant: 'critical',
                      onClick: () => {
                        setFile(null);
                        setIsDeleteFileModalOpen(false);
                        showMessage({
                          type: 'informative',
                          title: vendorName
                            ? formatMessage('activities.addBillV2.deleteFile.toast.title', {
                                vendorName,
                              })
                            : formatMessage('activities.addBillV2.deleteFile.toast.titleWithoutName'),
                          dataTestId: 'delete-file-success',
                        });
                      },
                    }}
                    secondaryButton={{
                      label: formatMessage('activities.addBillV2.deleteFile.secondaryButton.label'),
                      onClick: () => setIsDeleteFileModalOpen(false),
                      variant: 'tertiary',
                    }}
                  >
                    <Text>{formatMessage('activities.addBillV2.deleteFile.description')}</Text>
                  </StatusModal>
                  <LargeFileUpload
                    value={fileValue}
                    onChange={closeToastWrapper(setFile)}
                    acceptTypes={['pdf', 'png', 'jpg']}
                    isLoading={isFileLoading || isFileOCRDataLoading}
                    isReadOnly={props.allowModifyingFile}
                    data-testid="add-bill-v2-file-upload"
                    onDelete={closeToastWrapper(() => setIsDeleteFileModalOpen(true))}
                    previewFileAriaLabel={formatMessage(
                      'activities.addBillV2.billForm.fileUpload.filePreviewAriaLabel'
                    )}
                    uploadFileButtonAriaLabel={formatMessage(
                      'activities.addBillV2.billForm.fileUpload.uploadFileButtonAriaLabel'
                    )}
                    placeholder={formatMessage('activities.addBillV2.billForm.fileUpload.uploadFileButtonAriaLabel')}
                    deleteFileButtonAriaLabel={formatMessage(
                      'activities.addBillV2.billForm.fileUpload.deleteFileButtonAriaLabel'
                    )}
                    replaceFileButtonAriaLabel={formatMessage(
                      'activities.addBillV2.billForm.fileUpload.replaceFileButtonAriaLabel'
                    )}
                  />
                </Flex>
              ),
            }}
            panelB={{
              padding: 'none',
              content: (
                <Box
                  background={theme.colors.global.neutral['100']}
                  pt={{ xs: 's', m: 'xxl' }}
                  px={{ xs: 's', m: 'xxl' }}
                  minH="100%"
                >
                  <AddBillFormPanel
                    melioFormProps={melioFormProps}
                    activeAccountingPlatform={activeAccountingPlatform}
                    shouldClearVendorIdField={shouldClearVendorIdField}
                    isConnectedToAccountingPlatform={hasAccountingPlatform}
                    allowRecurring={allowRecurring}
                    isLineItemsEligible={lineItemsController.isEligible}
                    isLineItemsExpanded={lineItemsController.isExpanded}
                    onExpandLineItems={lineItemsController.toggleExpanded}
                    billLineItemLabels={billLineItemLabels}
                    billLabel={billLabels?.[0]}
                    onClose={closeToastWrapper(props.onClose)}
                    selectedVendor={selectedVendor}
                    shouldShowAmountInUSDField={shouldShowAmountInUSDField}
                    onAmountFieldBlur={onAmountFieldBlur}
                    foreignCurrencyRate={foreignCurrencyRate}
                    isInternationalFxEnabled={isInternationalFxEnabled}
                    setAmountForQuery={setAmountForQuery}
                    amountForQuery={amountForQuery}
                    statusMessageParentSelector="[id=addBillV2FormActivity]"
                    isFileLoading={isFileLoading}
                    showBillAmountBanner={showBillAmountBanner}
                  />
                </Box>
              ),
              footer: footerIsSticky ? undefined : (
                <Footer
                  isDisabled={footerIsDisabled}
                  isLoading={footerIsLoading}
                  withSecondaryButton={footerWithSecondaryButton}
                  onSubmit={handleSubmitBillForm}
                  onClose={handleCloseBillForm()}
                  renderCustomFooter={props.renderCustomFooter}
                />
              ),
            }}
          />
        </Container>
        {selectedVendor ? (
          <SetInvoiceNumberModal
            analytics={{
              contextView: 'Bill',
            }}
            onSubmitInvoiceNumber={handleUpdateInvoiceNumber}
            isOpen={isShowAddInvoiceNumberModal}
            onClose={handleSkipAddingInvoiceNumber}
            onContinueWithoutInvoiceNumber={handleSkipAddingInvoiceNumber}
            vendorName={selectedVendor.name}
          />
        ) : null}
      </>
    );
  }
);
