import { useInvalidFundingSources } from '@melio/ap-domain';
import { BaseMenu, Table } from '@melio/penny';
import { FundingSource } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { useFundingSourcesSearchBar } from '../../../../../payment-flow/PaymentFlowActivity/PaymentFlowForm/components/FundingSourceSection/useFundingSourceSearchBar/useFundingSourceSearchBar';
import { FundingSourceHeaderSelectCellProps } from '../FundingSourceHeaderSelectCell/FundingSourceHeaderSelectCell';
import { useFundingSourceHeaderDescription } from '../FundingSourceHeaderSelectCell/FundingSourceHeaderSelectCell.utils';
import { EmptySearchResults } from './EmptySearchResults';
import { FundingSourceSectionedOptions } from './FundingSourceSectionedOptions';
import { SearchBarHeader } from './SearchBarHeader';
import { useGroupedSources } from './useGroupedSources';

export const FundingSourceHeaderSelectCellV2 = forwardRef<FundingSourceHeaderSelectCellProps, 'div'>(
  ({ fundingSources, onSelect, onAddFundingSource, selectedFundingSourceId, ...props }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { getFundingSourceTooltip } = useInvalidFundingSources();

    const [isCombinedPaymentsSupported] = useFeature(FeatureFlags.CombinedPayments, false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const {
      props: searchProps,
      show: showSearch,
      resetQuery,
      filtered: filteredFundingSources,
    } = useFundingSourcesSearchBar(fundingSources);
    const { getDescription } = useFundingSourceHeaderDescription();
    const { groupedSources } = useGroupedSources(filteredFundingSources);

    const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId);

    const handleSelect = (selectedFundingSourceId: FundingSource['id']) => {
      const selectedFundingSource = fundingSources.find((fs) => fs.id === selectedFundingSourceId);
      selectedFundingSource && onSelect(selectedFundingSource);
      setIsOpen(false);
    };

    const description = isCombinedPaymentsSupported
      ? getDescription(fundingSources, selectedFundingSourceId)
      : undefined;
    const tooltipLabel = selectedFundingSource && getFundingSourceTooltip(selectedFundingSource);
    const hasItems = filteredFundingSources.length > 0;

    const trigger = (
      <Table._MenuTriggerHeaderCell
        {...props}
        data-testid="funding-source-header-select-cell"
        role="button"
        label={formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.fundingSourceHeaderSelectCell.label'
        )}
        description={description}
        tooltipLabel={tooltipLabel}
        onClick={() => setIsOpen(!isOpen)}
        isMenuOpen={isOpen}
        value={selectedFundingSourceId}
        ref={ref}
      />
    );
    return (
      <BaseMenu
        trigger={trigger}
        isOpen={isOpen}
        onOpenChange={(isOpen: boolean) => {
          setIsOpen(isOpen);
          resetQuery();
        }}
        width="297px"
        maxHeight="320px"
        hasItems={hasItems}
        role="listbox"
        disableTypeahead
        header={showSearch && <SearchBarHeader searchProps={searchProps} />}
        content={
          filteredFundingSources.length > 0 ? (
            <FundingSourceSectionedOptions
              selectedFundingSourceId={selectedFundingSourceId}
              onSelect={handleSelect}
              groupedSources={groupedSources}
            />
          ) : (
            <EmptySearchResults />
          )
        }
        hasSections={hasItems}
        ref={ref}
        {...props}
      />
    );
  }
);

FundingSourceHeaderSelectCellV2.displayName = 'FundingSourceHeaderSelectCellV2';
