import { Box, Img } from '@chakra-ui/react';
import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { Container, Group, Link, Logo, Text } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useConfig } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { AmexLogo, DinersClubLogo, DiscoverLogo, MasterCardLogo, VisaLogo } from './assets';

export const GuestPaymentFooter = forwardRef(({ ...props }, ref) => {
  const { formatMessage } = useMelioIntl();
  const { privacyPolicyUrl, termsOfServiceUrl, helpCenterUrl } = useConfig().settings;
  const { track } = useAnalytics();

  return (
    <Container backgroundColor="white" border="regular" paddingX="l" paddingY="l" {...props} ref={ref}>
      <Group variant="vertical" spacing="s">
        <Box width="fit-content" data-testid="logo-wrapper">
          <Logo size="large" type="dark" />
        </Box>
        <Text textStyle="body4Semi">
          <FormattedMessage id="ar.guestPayment.footer.partners.subTitle.text" />
        </Text>
        <Group spacing="m" data-testid="partner-logos-wrapper">
          {[MasterCardLogo, DinersClubLogo, VisaLogo, DiscoverLogo, AmexLogo].map((logo, i) => (
            <Img key={i} src={logo} />
          ))}
        </Group>
        <Group variant="vertical" spacing="xs" data-testid="legal-links-wrapper">
          <Group hasDivider>
            <Box color="global.neutral.800" textStyle="body4">
              <Link
                href={privacyPolicyUrl}
                label={formatMessage('ar.guestPayment.footer.links.privacyPolicy.label')}
                onClick={() => track('PaymentRequest', 'Click', { Cta: 'privacy-policy' })}
                color="inherit"
                newTab
              />
            </Box>
            <Box color="global.neutral.800" textStyle="body4">
              <Link
                href={termsOfServiceUrl}
                label={formatMessage('ar.guestPayment.footer.links.termsOfService.label')}
                color="inherit"
                onClick={() => track('PaymentRequest', 'Click', { Cta: 'terms-of-service' })}
                newTab
              />
            </Box>
          </Group>
          <Group>
            <Text textStyle="body4" color="global.neutral.800">
              <FormattedMessage id="ar.guestPayment.footer.helpCenter.text" />{' '}
              <Link
                href={helpCenterUrl}
                label={formatMessage('ar.guestPayment.footer.links.helpCenter.label')}
                newTab
              />
            </Text>
          </Group>
        </Group>
      </Group>
    </Container>
  );
});
