import { CompanyLegalInfoField, useAccount } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useConfig } from '@melio/platform-provider';
import { identity, pickBy } from 'lodash';

import { useMtlKycUpliftEnabled } from '../mtl';

export const useMissingCompanyLegalInfoFields = ():
  | undefined
  | { isLoading: boolean; missingCompanyLegalInfoFields: CompanyLegalInfoField[] } => {
  const { data: account, isLoading: isAccountMeLoading } = useAccount({ id: 'me' });
  const [shouldIndustryBePartOfLegalInfo] = useFeature(FeatureFlags.ShouldIndustryBePartOfLegalInfo, true);
  const { mtlKycUpliftEnabled, isLoading: mtlKycUpliftEnabledLoading } = useMtlKycUpliftEnabled();

  const {
    settings: { collectMissingCompanyLegalInfoFields },
  } = useConfig();

  if (!account) {
    return;
  }
  const company = account.company;

  const missingTaxInfo = !company.taxInfo?.identifier || !company.taxInfo.type;

  const missingCompanyLegalInfoFields = Object.keys(
    pickBy(
      {
        legalCompanyName: !company.legalName,
        legalAddress:
          !company.legalAddress?.postalCode ||
          !company.legalAddress.state ||
          !company.legalAddress.city ||
          (!company.legalAddress.line1 && !company.legalAddress.line2),
        phoneNumber: !company.phoneNumber,
        industry: shouldIndustryBePartOfLegalInfo && !company.industry?.name,
        businessType: mtlKycUpliftEnabled ? !company.businessType || missingTaxInfo : !company.businessType,
        taxInfoType: missingTaxInfo,
        taxInfoIdentifier: !company.taxInfo?.identifier,
      },
      identity
    )
  ) as CompanyLegalInfoField[];

  return {
    missingCompanyLegalInfoFields: collectMissingCompanyLegalInfoFields ? missingCompanyLegalInfoFields : [],
    isLoading: isAccountMeLoading || mtlKycUpliftEnabledLoading,
  };
};
