import { NakedButton, SectionBanner } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  onEditClick: VoidFunction;
  variant?: 'warning' | 'critical';
  vendorName: string;
};

export const MissingVendorInfoBannerWidget = ({ onEditClick, variant = 'warning', vendorName }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <SectionBanner
      variant={variant}
      title={formatMessage('widgets.vendorDrawer.missingInfoBanner.title')}
      description={formatMessage('widgets.vendorDrawer.missingInfoBanner.description', { vendorName })}
      data-testid="vendor-missing-info-banner"
      action={
        <NakedButton
          variant="secondary"
          aria-haspopup="dialog"
          size="medium"
          label={formatMessage('widgets.vendorDrawer.missingInfoBanner.cta')}
          onClick={onEditClick}
          data-testid="vendor-missing-info-banner-cta"
        />
      }
    />
  );
};
