// TODO: the text should be per plan and not just per feature. waiting for product answer https://www.figma.com/design/iFQTSLomTaSVgSdDxpiOej?node-id=951-86552#800202620
// for example: the "1teamMember" is not correct for all current plan. it may be the the current plan is the paid plan with 3 seats and it doesn't support the feature.
export const currentConfigPerFeatureFiserv = {
  collaborators: {
    capabilities: [
      'activities.subscription.contextualPlans.collaborators.current.capabilities.1teamMember',
      'activities.subscription.contextualPlans.collaborators.current.capabilities.ownerRole',
    ],
  },
  batchPayments: {
    capabilities: [
      'activities.subscription.contextualPlans.batchPayments.current.capabilities.paySingleBills',
      'activities.subscription.contextualPlans.batchPayments.current.capabilities.noCombinedPayments',
    ],
    actionButton: 'activities.subscription.contextualPlans.batchPayments.current.actionButtonLabel',
  },
  accountingPlatformQbo: {
    capabilities: [
      'activities.subscription.contextualPlans.accountingPlatformQbo.current.capabilities.1teamMember',
      'activities.subscription.contextualPlans.accountingPlatformQbo.current.capabilities.basicCapabilities',
    ],
  },
  accountingPlatformXero: {
    capabilities: [
      'activities.subscription.contextualPlans.accountingPlatformXero.current.capabilities.1teamMember',
      'activities.subscription.contextualPlans.accountingPlatformXero.current.capabilities.basicCapabilities',
    ],
  },
  approvalWorkflows: {
    capabilities: [],
  },
  advancedApprovalWorkflows: {
    capabilities: [],
  },
  accountingPlatformQbdt: { capabilities: [] },
  lineItems: {
    capabilities: [
      'activities.subscription.contextualPlans.lineItems.current.capabilities.limitedFreeTransfers',
      'activities.subscription.contextualPlans.lineItems.current.capabilities.limitedFreeSyncs',
    ],
  },
  w9: { capabilities: [] },
  tax1099: { capabilities: [] },
  classesAndLocations: { capabilities: [] },
  ar: { capabilities: [] },
  requestCallbackSupport: { capabilities: [] },
  achTPlusTwo: { capabilities: [] },
  freeAchPayments: { capabilities: [] },
  syncedPayments: { capabilities: [] },
} as const;

export const upgradedPlanConfigPerFeatureFiserv = {
  collaborators: {
    capabilities: [
      'activities.subscription.contextualPlans.collaborators.upgrade.capabilities.unlimitedCollaborators',
      'activities.subscription.contextualPlans.collaborators.upgrade.capabilities.multipleRoles',
    ],
  },
  batchPayments: {
    capabilities: [
      'activities.subscription.contextualPlans.batchPayments.upgrade.capabilities.multipleBills',
      'activities.subscription.contextualPlans.batchPayments.upgrade.capabilities.combinedBillPayments',
    ],
  },
  accountingPlatformQbo: {
    capabilities: [
      'activities.subscription.contextualPlans.accountingPlatformQbo.upgrade.capabilities.advanceAccountingCapabilities',
      'activities.subscription.contextualPlans.accountingPlatformQbo.upgrade.capabilities.directAccountingIntegrations',
      'activities.subscription.contextualPlans.accountingPlatformQbo.upgrade.capabilities.multipleTeamMembers',
      'activities.subscription.contextualPlans.accountingPlatformQbo.upgrade.capabilities.quickbooksAndXero',
    ],
  },
  accountingPlatformXero: {
    capabilities: [
      'activities.subscription.contextualPlans.accountingPlatformXero.upgrade.capabilities.advanceAccountingCapabilities',
      'activities.subscription.contextualPlans.accountingPlatformXero.upgrade.capabilities.directAccountingIntegrations',
      'activities.subscription.contextualPlans.accountingPlatformXero.upgrade.capabilities.multipleTeamMembers',
      'activities.subscription.contextualPlans.accountingPlatformXero.upgrade.capabilities.quickbooksAndXero',
    ],
  },
  approvalWorkflows: {
    capabilities: [],
  },
  advancedApprovalWorkflows: {
    capabilities: [],
  },
  accountingPlatformQbdt: { capabilities: [] },
  lineItems: {
    capabilities: [
      'activities.subscription.contextualPlans.lineItems.upgrade.capabilities.unlimitedSyncs',
      'activities.subscription.contextualPlans.lineItems.upgrade.capabilities.approvalWorkflows',
      'activities.subscription.contextualPlans.lineItems.upgrade.capabilities.lineItems',
      'activities.subscription.contextualPlans.lineItems.upgrade.capabilities.batchPayments',
    ],
  },
  w9: { capabilities: [] },
  tax1099: { capabilities: [] },
  classesAndLocations: { capabilities: [] },
  ar: { capabilities: [] },
  requestCallbackSupport: { capabilities: [] },
  achTPlusTwo: { capabilities: [] },
  freeAchPayments: { capabilities: [] },
  syncedPayments: { capabilities: [] },
} as const;
