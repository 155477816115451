import { useSearchParams, useServiceChargeCatalogs } from '@melio/ar-domain';
import { forwardRef, useDebounceCallback } from '@melio/platform-utils';
import { useMemo } from 'react';

import { ServiceChargesTableScreen } from './screens';
type SearchParams = {
  searchTerm: string;
};

type ServiceChargesTableActivityProps = {
  onError?: ARErrorFunction;
  onEdit: (itemId: string) => unknown;
  onDelete: (itemId: string) => unknown;
  onAdd: VoidFunction;
};

export const ServiceChargesTableActivity = forwardRef<ServiceChargesTableActivityProps>(
  ({ onEdit, onAdd, onError, onDelete, ...props }, ref) => {
    const { data, isLoading, isFetching } = useServiceChargeCatalogs({ onError });
    const [{ searchTerm = '' }, _setParams] = useSearchParams<SearchParams>();
    const setParams = useDebounceCallback(_setParams, 200);

    const filteredData = useMemo(
      () =>
        (data ?? []).filter(
          (serviceCharge) =>
            serviceCharge.name.toLowerCase().includes(searchTerm.toLowerCase()) || serviceCharge.id === searchTerm
        ),
      [data, searchTerm]
    );

    const sortedData = useMemo(() => filteredData.sort((a, b) => a.name.localeCompare(b.name)), [filteredData]);

    return (
      <ServiceChargesTableScreen
        isLoading={isLoading || isFetching}
        searchTerm={searchTerm}
        setSearchTerm={(value) => setParams({ searchTerm: value || undefined })}
        serviceCharges={sortedData}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        {...props}
        ref={ref}
      />
    );
  }
);
