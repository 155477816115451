import { Box } from '@chakra-ui/react';
import { useIsMobile } from '@melio/ar-domain';
import { Container, Group, Layout, SectionBanner, SectionBannerProps } from '@melio/penny';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import React, { useEffect } from 'react';

import { GuestPaymentFooter } from './GuestPaymentFooter';

export type GuestPaymentLayoutProps = {
  isLoading?: boolean;
  onBack?: VoidFunction;
  onClose?: VoidFunction;
  notificationProps?: SectionBannerProps;
};

export const GuestPaymentLayout = forwardRef<GuestPaymentLayoutProps>(
  ({ notificationProps, isLoading, onBack, onClose, children, ...props }, ref) => {
    const isMobile = useIsMobile();
    const notificationRef = React.useRef<HTMLDivElement>(null);

    const [isBannerDismissed, dismissBanner] = useBoolean();

    useEffect(() => {
      dismissBanner.off();
      notificationRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [notificationProps]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Box backgroundColor="global.neutral.200" height="100%">
        <Layout
          backgroundColor="default"
          isLoading={isLoading}
          paddingContent={isMobile ? 'none' : 'xxl'}
          data-layout={GuestPaymentLayout.displayName}
          maxWidth="600px"
          {...props}
          ref={ref}
        >
          <Group variant="vertical" alignItems="center" spacing="l">
            {notificationProps && !isBannerDismissed ? (
              <Box role="status">
                <SectionBanner
                  ref={notificationRef}
                  data-testid="invoice-notification"
                  {...notificationProps}
                  onClose={dismissBanner.on}
                />
              </Box>
            ) : null}
            <Container border="regular" backgroundColor="white" paddingX="l" paddingY="l">
              {children}
            </Container>
            <GuestPaymentFooter />
          </Group>
        </Layout>
      </Box>
    );
  }
);

GuestPaymentLayout.displayName = 'GuestPaymentLayout';
