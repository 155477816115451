import { isEbill } from '@melio/ap-domain';
import { useShowMemoToVendor } from '@melio/ap-widgets';
import { Table } from '@melio/penny';
import { Bill, DeliveryMethod, PaymentIntent } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';
import { forwardRef } from '@melio/platform-utils';

import { SchedulePaymentIntent } from '../../../../types';

export type ActionsCellProps = {
  paymentIntentWithDerivatives: SchedulePaymentIntent;
  selectedDeliveryMethod: DeliveryMethod | undefined;
  isRemovable: boolean;
  onViewBillDetailsClick: (paymentIntentId: PaymentIntent['id'], bill: Bill) => void;
  onAddMemoToVendorClick: (paymentIntentId: PaymentIntent['id']) => void;
  onAddPaymentNoteClick: (paymentIntentId: PaymentIntent['id']) => void;
  onSetInvoiceNumberClick: (paymentIntentId: PaymentIntent['id']) => void;
  onRemoveBillsClick: (paymentIntentId: PaymentIntent['id'], billId?: Bill['id']) => void;
  onEditAmountClick: (paymentIntentId: PaymentIntent['id'], bill: Bill) => void;
};

export const ActionsCell = forwardRef<ActionsCellProps, 'div'>(
  (
    {
      onAddMemoToVendorClick,
      onAddPaymentNoteClick,
      onSetInvoiceNumberClick,
      onRemoveBillsClick,
      onViewBillDetailsClick,
      paymentIntentWithDerivatives,
      selectedDeliveryMethod,
      isRemovable,
      onEditAmountClick,
      ...props
    },
    ref
  ) => {
    const { formatMessage } = useMelioIntl();
    const [isVendorsBatchEnabled] = useFeature(FeatureFlags.PlatformVendorsBatchNewPaymentsEnabled, false, {
      shouldTrack: true,
    });
    const [isEditAmountEnabled] = useFeature(FeatureFlags.BatchPaymentEbillEditAmount, false);
    const [isNoteToSelfEnabled] = usePartnerFeature('PaymentNoteToSelf', false);

    const { paymentIntent } = paymentIntentWithDerivatives;

    const { shouldDisplayMemoToVendor } = useShowMemoToVendor();
    const isSingleBillPaymentIntent = paymentIntentWithDerivatives.bills?.length === 1;
    const isDraftBill = paymentIntent.status === 'draft';

    const showMemoToVendor = !selectedDeliveryMethod || shouldDisplayMemoToVendor(selectedDeliveryMethod);

    const memoToVendorAction = {
      label: formatMessage(
        `activities.batchPayments.screens.paymentIntentsTable.actionsCell.memoToVendor.${
          paymentIntent.noteToVendor ? 'edit' : 'add'
        }`
      ),
      onClick: () => onAddMemoToVendorClick(paymentIntent.id),
      dataTestId: 'add-memo-to-vendor-action',
    };

    const invoiceNumberAction = {
      label: paymentIntent.billInfo.invoice.number
        ? formatMessage('activities.batchPayments.screens.paymentIntentsTable.actionsCell.invoiceNumber.edit')
        : formatMessage('activities.batchPayments.screens.paymentIntentsTable.actionsCell.invoiceNumber.add'),
      onClick: () => onSetInvoiceNumberClick(paymentIntent.id),
      dataTestId: 'invoice-number-action',
    };

    const removeFromListAction = {
      label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.actionsCell.removeFromList'),
      onClick: () => onRemoveBillsClick(paymentIntent.id),
      dataTestId: 'remove-from-list-action',
      variant: 'critical',
    };

    const paymentNoteToSelfAction = {
      label: paymentIntent.noteToVendor
        ? formatMessage('activities.batchPayments.screens.paymentIntentsTable.actionsCell.paymentNote.edit')
        : formatMessage('activities.batchPayments.screens.paymentIntentsTable.actionsCell.paymentNote.add'),
      onClick: () => onAddPaymentNoteClick(paymentIntent.id),
      dataTestId: 'add-payment-note-action',
    };

    const actions = [];

    if (isSingleBillPaymentIntent && !isDraftBill) {
      const bill = paymentIntentWithDerivatives.bills[0];
      if (bill) {
        const viewBillDetailsAction = {
          label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.actionsCell.viewBillDetails'),
          onClick: () => onViewBillDetailsClick(paymentIntent.id, bill),
          dataTestId: 'view-bill-details-action',
        };

        actions.push(viewBillDetailsAction);

        if (isEditAmountEnabled && isEbill(bill)) {
          const editAmountAction = {
            label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.actionsCell.editAmount'),
            onClick: () => onEditAmountClick(paymentIntent.id, bill),
            dataTestId: 'edit-amount-action',
          };
          actions.push(editAmountAction);
        }
      }
    }

    if (showMemoToVendor) {
      actions.push(memoToVendorAction);
    }
    if (isNoteToSelfEnabled) {
      actions.push(paymentNoteToSelfAction);
    }
    if (isVendorsBatchEnabled && isSingleBillPaymentIntent) {
      actions.push(invoiceNumberAction);
    }
    if (isRemovable) {
      actions.push(removeFromListAction);
    }
    if (actions.length === 0) {
      return null;
    }
    return <Table.ActionsCell options={actions} data-testid={`${paymentIntent.id}-actions`} {...props} ref={ref} />;
  }
);

ActionsCell.displayName = 'ActionsCell';
