import { useState } from 'react';
import { useAccount, useAccountMeLimitations } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';

import { parseBoolean } from '@/utils/parsing.utils';

const IS_DISMISSED_SESSION_STORAGE_KEY_PREFIX = 'mandatory-details-blocker-screen-visited';
const getIsVisitedStorageKey = (accountId: string) => {
  return `${IS_DISMISSED_SESSION_STORAGE_KEY_PREFIX}_${accountId}`;
};
const getIsVisitedDefaultValue = (accountId?: string) => {
  return !!accountId && parseBoolean(window.sessionStorage.getItem(getIsVisitedStorageKey(accountId)));
};
const setIsVisitedSessionStorageValue = (accountId: string, value: boolean) => {
  if (!value) {
    window.sessionStorage.removeItem(getIsVisitedStorageKey(accountId));
  } else {
    window.sessionStorage.setItem(getIsVisitedStorageKey(accountId), String(value));
  }
};

export const useIsMissingKycComplianceInformation = (options?: { enabled?: boolean }) => {
  const { enabled = true } = options || {};
  const { data: account } = useAccount({ id: 'me', enabled: false });
  const [isVisitedBlockerScreen, setIsVisitedBlockerScreen] = useState(getIsVisitedDefaultValue(account?.id));

  const [isExistingUserMtlKycEnabled] = useFeature(FeatureFlags.ExistingUserMtlKycUplift, false);
  const {
    data: accountLimitations,
    isFetching,
    isLoading,
    invalidateQuery,
  } = useAccountMeLimitations({ enabled: enabled && isExistingUserMtlKycEnabled, params: { stage: 'dashboard' } });

  const { user, company } = accountLimitations?.entitiesInformation || {};

  const missingFields = [...(company?.missingFields || []), ...(user?.missingFields || [])];
  const missingKycComplianceInformation = isExistingUserMtlKycEnabled && !!missingFields.length;

  const onBlockerScreenUserInteraction = () => {
    account && setIsVisitedSessionStorageValue(account.id, true);
    setIsVisitedBlockerScreen(true);
    void invalidateQuery();
  };

  return {
    isLoading,
    isFetching,
    missingKycComplianceInformation,
    isVisitedBlockerScreen,
    onBlockerScreenUserInteraction,
    ...accountLimitations,
  };
};
