import { Container, Group, Illustration, Layout, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

export const PayDashboardZeroState = () => {
  const { formatMessage } = useMelioIntl();

  const title = formatMessage('activities.payDashboard.billsTab.zeroState.title');
  const description = formatMessage('activities.payDashboard.billsTab.zeroState.description');
  const ariaLabel = formatMessage('activities.payDashboard.billsTab.zeroState.ariaLabel');

  return (
    <Layout
      maxWidth="600px"
      paddingContent="none"
      data-testid="pay-dashboard-zero-state"
      aria-live="assertive"
      aria-label={ariaLabel}
    >
      <Container paddingTop="xxl">
        <Group alignItems="center" variant="vertical">
          <Illustration type="pay-zero" size="medium" />
          <Group spacing="m" variant="vertical" textAlign="center">
            <Text textStyle="heading3Semi">{title}</Text>
            <Text color="global.neutral.800">{description}</Text>
          </Group>
        </Group>
      </Container>
    </Layout>
  );
};
