import { useDisclosure } from '@chakra-ui/react';
import { addWildcardToRoutes, RouteElement, useFlowRouting, withOutlet } from '@melio/ar-domain';
import { PartnerGroupEnum } from '@melio/partner-tools';
import { PartnerName } from '@melio/platform-api';
import { VFC } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  GuestPaymentConfirmationActivity,
  GuestPaymentRequestErrorsActivity,
  InvoiceDrawerActivity,
  InvoicePaidActivity,
  NoPaymentOptionsActivity,
  PaymentRequestTriageActivity,
} from '../activities';
import { FiservPaymentFulfillmentFlow } from './FiservPaymentFulfillment.flow';
import { PaymentFulfillmentFlow } from './PaymentFulfillment.flow';

export type PaymentRequestFlowProps = {
  partnerName?: PartnerName;
  partnerGroup?: PartnerGroupEnum | null;
  isNavigateApUsersToDashboardEnabled?: boolean;
  paymentRequestLink: string;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
  onUpdatedLink: (paymentRequestLink: string) => void;
  onDone?: (...args: string[]) => unknown; // for testing purposes
};

export const PaymentRequestFlow: VFC<PaymentRequestFlowProps> = ({
  onLoggedIn,
  partnerName = 'melio',
  partnerGroup,
  isNavigateApUsersToDashboardEnabled,
  paymentRequestLink,
  onUpdatedLink,
}) => {
  const Router = usePaymentRequestFlow();

  const { isOpen: isDrawerOpened, onOpen: onViewInvoice, onClose: onDrawerClose } = useDisclosure();

  return (
    <Routes>
      <Route
        element={withOutlet(
          <InvoiceDrawerActivity
            paymentRequestLink={paymentRequestLink}
            isOpened={isDrawerOpened}
            onDownloadInvoice={(fileUrl) => (window.location.href = fileUrl)}
            onClose={onDrawerClose}
          />
        )}
      >
        <Route
          path={Router.Paths.Error}
          element={<GuestPaymentRequestErrorsActivity paymentRequestLink={paymentRequestLink} />}
        />
        <Route
          path={Router.Paths.InvoicePaid}
          element={
            <InvoicePaidActivity
              paymentRequestLink={paymentRequestLink}
              onViewInvoice={onViewInvoice}
              onError={() => Router.goToError()}
            />
          }
        />
        <Route
          path={Router.Paths.NoPaymentOptions}
          element={
            <NoPaymentOptionsActivity
              paymentRequestLink={paymentRequestLink}
              onViewInvoice={onViewInvoice}
              onError={() => Router.goToError()}
            />
          }
        />
        <Route
          path={Router.Paths.PaymentConfirmation}
          element={
            <RouteElement
              component={GuestPaymentConfirmationActivity}
              pathToProps={{ paymentId: 'paymentId' }}
              paymentRequestLink={paymentRequestLink}
              onViewInvoice={onViewInvoice}
              onError={() => Router.goToError()}
            />
          }
        />
        <Route
          path={Router.Paths.Pay}
          element={
            PartnerGroupEnum.FISERV === partnerGroup ? (
              <FiservPaymentFulfillmentFlow
                paymentRequestLink={paymentRequestLink}
                onViewInvoice={onViewInvoice}
                onDone={(id) => Router.goToPaymentConfirmation(id, { replace: true })}
              />
            ) : (
              <PaymentFulfillmentFlow
                paymentRequestLink={paymentRequestLink}
                onViewInvoice={onViewInvoice}
                onDone={(id) => Router.goToPaymentConfirmation(id, { replace: true })}
                isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
                partnerName={partnerName}
                onLoggedIn={onLoggedIn}
              />
            )
          }
        />
        <Route
          path={Router.Paths.InitialRouting}
          element={
            <PaymentRequestTriageActivity
              paymentRequestLink={paymentRequestLink}
              onDone={(status, link) => {
                switch (status) {
                  case 'updated':
                    return onUpdatedLink(link);
                  case 'paid':
                    return Router.goToInvoicePaid({ replace: true });
                  case 'error':
                  case 'cancelled':
                    return Router.goToError({ replace: true });
                  case 'no-payment-option':
                    return Router.goToNoPaymentOptions({ replace: true });
                  default:
                    return Router.goToFulfillmentFlow({ replace: true });
                }
              }}
            />
          }
        />
      </Route>
    </Routes>
  );
};

PaymentRequestFlow.displayName = 'PaymentRequestFlow';

const usePaymentRequestFlow = () => {
  enum Paths {
    InitialRouting = '',
    Pay = 'pay-by',
    Error = 'error',
    InvoicePaid = 'invoice-paid',
    NoPaymentOptions = 'no-payment-options',
    PaymentConfirmation = 'payment-confirmation/:paymentId',
  }
  const { createCallback, createCallbackWithParam } = useFlowRouting<Paths>();

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    goToError: createCallback(Paths.Error),
    goToInvoicePaid: createCallback(Paths.InvoicePaid),
    goToFulfillmentFlow: createCallback(Paths.Pay),
    goToNoPaymentOptions: createCallback(Paths.NoPaymentOptions),
    goToPaymentConfirmation: createCallbackWithParam(Paths.PaymentConfirmation, 'paymentId'),
  };
};
