import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { useIsFeatureInSubscription } from '../../../../hooks';

export const useAccountingSoftwareSyncDescription = () => {
  const [isLimitPaymentsSyncBasedOnPlanQuotaEnabled] = useFeature(
    FeatureFlags.LimitPaymentsSyncBasedOnPlanQuota,
    false
  );

  const hasSyncedPaymentsFeature = useIsFeatureInSubscription({
    featureName: 'syncedPayments',
    requirements: { totalUnits: Infinity },
  }).isEligible;

  // TODO: delete this 2 lines once the 'limit-payments-sync-based-on-plan-quota' flag is deleted  https://meliorisk.atlassian.net/browse/ME-74177
  const isSyncWithXero = useIsFeatureInSubscription({ featureName: 'accountingPlatformXero' }).isEligible;
  const isSyncWithQuickBooks = useIsFeatureInSubscription({ featureName: 'accountingPlatformQbo' }).isEligible;

  const { formatMessage } = useMelioIntl();

  const getAccountingSoftwareSyncDescription = () => {
    if (isLimitPaymentsSyncBasedOnPlanQuotaEnabled) {
      if (hasSyncedPaymentsFeature) {
        return formatMessage(
          'activities.settings.editablePlanCardFiserv.description.accountingSoftwareSync.quickBooksOrXero'
        );
      }
    } else {
      if (isSyncWithXero && isSyncWithQuickBooks) {
        return formatMessage(
          'activities.settings.editablePlanCardFiserv.description.accountingSoftwareSync.quickBooksOrXero'
        );
      }
      if (isSyncWithXero) {
        return formatMessage('activities.settings.editablePlanCardFiserv.description.accountingSoftwareSync.xero');
      }
      if (isSyncWithQuickBooks) {
        return formatMessage(
          'activities.settings.editablePlanCardFiserv.description.accountingSoftwareSync.quickBooks'
        );
      }
    }

    return;
  };

  return getAccountingSoftwareSyncDescription();
};
