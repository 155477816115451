import { isEinOnlyBusinessType, masks, useMtlFormValues, useMtlMessages } from '@melio/ap-domain';
import { AddressSearchWidget, IndustryTypeSelectWidget } from '@melio/ap-widgets';
import { Button, CollapsibleCard, Form, useWatch } from '@melio/penny';
import { useAnalytics, useAnalyticsContext, useAnalyticsView } from '@melio/platform-analytics';
import { Industry, OrganizationBusinessType, TaxIdTypeEnum } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { isNil } from 'lodash';
import { useState } from 'react';

import { isDirty, isFieldReadonly as _isFieldReadonly } from '../../../../../../utils';
import { useTrackFormError } from '../hooks/useTrackFormError';
import { BusinessDetailsForm, FXFormSectionCardBaseProps } from '../types';
import { setValueAndMakeDirty } from '../utils/formUtils';

type FXBusinessDetailsCardProps = FXFormSectionCardBaseProps<BusinessDetailsForm> & {
  businessName: string;
  isBlockedFromSubmitting: boolean;
};
export const FXBusinessDetailsCard = ({
  form,
  isExpanded,
  onExpandChange,
  isCompleted,
  businessName,
  isLoading,
  isBlockedFromSubmitting,
}: FXBusinessDetailsCardProps) => {
  const { formatMessage } = useMelioIntl();
  const [isBusinessTypeChanged, setIsBusinessTypeChanged] = useState(false);
  const [isIndustryTypeChanged, setIsIndustryTypeChanged] = useState(false);
  useTrackFormError<BusinessDetailsForm>({
    form,
    pageName: 'business-details',
    intent: 'set-business-details',
  });
  const setBusinessIndustry = (industryName: BusinessDetailsForm['businessIndustry']) => {
    setIsIndustryTypeChanged(true);
    setValueAndMakeDirty({
      form,
      fieldKey: 'businessIndustry',
      value: industryName,
    });
  };

  const setBusinessType = (businessType: OrganizationBusinessType) => {
    setIsBusinessTypeChanged(true);
    setValueAndMakeDirty({
      form,
      fieldKey: 'businessType',
      value: businessType,
    });
  };

  const { businessTypeOptionsDeprecatedSelect, taxIdTypeOptions } = useMtlFormValues();
  const { track } = useAnalytics();
  const {
    labels: { company: companyLabels },
    placeholders,
  } = useMtlMessages();
  const [selectedBusinessType, selectedTaxIdType, businessIndustry] = useWatch({
    control: form.control,
    name: ['businessType', 'taxIdType', 'businessIndustry'],
  });

  useAnalyticsView('Organization', !isLoading && isExpanded, true, {
    PageName: 'business-details',
    Intent: 'set-business-details',
    Flow: 'UBO',
    CompanyName: form.getValues('companyName'),
    LegalBusinessName: form.getValues('companyLegalName'),
  });

  const isFieldReadonly = (fieldKey: keyof BusinessDetailsForm) =>
    _isFieldReadonly({
      fieldKey,
      form,
    });
  const [forceTaxIdTypeEditable, setForceTaxIdTypeEditable] = useState(false);

  const onBusinessTypeChanged: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const businessType = e.target.value as OrganizationBusinessType;
    setBusinessType(businessType);

    if (isEinOnlyBusinessType(businessType)) {
      if (selectedTaxIdType !== TaxIdTypeEnum.Ein) {
        onTaxIdTypeChanged(TaxIdTypeEnum.Ein);
      }
    } else {
      setForceTaxIdTypeEditable(true);
    }
  };

  const onTaxIdTypeChanged = (taxIdType: TaxIdTypeEnum) => {
    setValueAndMakeDirty({
      form,
      fieldKey: 'businessTaxId',
      value: '',
    });
    setValueAndMakeDirty({
      form,
      fieldKey: 'taxIdType',
      value: taxIdType,
    });
  };

  useAnalyticsContext({
    globalProperties: {
      CompanyName: form.getValues('companyName'),
      LegalBusinessName: form.getValues('companyLegalName'),
      BusinessIndustry: form.getValues('businessIndustry')?.name,
      BusinessType: form.getValues('businessType'),
      TaxIdType: form.getValues('taxIdType'),
    },
  });

  const onClickSaveAndContinue = () => {
    track('Organization', 'Click', {
      Cta: 'save-and-continue',
      Intent: 'set-business-details',
      PageName: 'business-details',
      Flow: 'UBO',
    });

    form.submitButtonProps.onClick();
  };

  const isBusinessIndustryReadOnly =
    !isDirty({ form, fieldKey: 'businessIndustry' }) && !isNil(businessIndustry?.naicsCode);
  const isBusinessIndustryError =
    form.formState?.errors.businessIndustry || !!form.formState?.errors?.businessIndustry?.naicsCode;
  return (
    <CollapsibleCard
      data-testid="fx-business-details-form-card"
      isExpanded={isExpanded}
      onExpandChange={onExpandChange}
      status={isCompleted ? 'success' : undefined}
      title={formatMessage('activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.title')}
      description={
        isExpanded
          ? formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.subtitle',
              { businessName }
            )
          : undefined
      }
    >
      <Form {...form.formProps}>
        <Form.TextField
          {...form.registerField('companyName')}
          isViewMode={isFieldReadonly('companyName')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.companyName.label'
            ),
          }}
        />
        <Form.TextField
          {...form.registerField('companyLegalName')}
          isViewMode={isFieldReadonly('companyLegalName')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.companyLegalName.label'
            ),
          }}
        />
        <AddressSearchWidget
          {...form.registerField('legalBusinessAddress')}
          isViewMode={isFieldReadonly('legalBusinessAddress')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.legalBusinessAddress.label'
            ),
          }}
          error={
            form.formState?.errors.legalBusinessAddress?.line1 || !!form.formState.errors.legalBusinessAddress
              ? {
                  message: formatMessage(
                    'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.legalBusinessAddress.required'
                  ),
                }
              : undefined
          }
        />
        <Form.PhoneField
          {...form.registerField('phone')}
          isViewMode={isFieldReadonly('phone')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.phone.label'
            ),
          }}
        />
        <IndustryTypeSelectWidget
          {...form.registerField('businessIndustry')}
          error={
            isBusinessIndustryError
              ? {
                  message: formatMessage(
                    'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.validations.industry.required'
                  ),
                }
              : undefined
          }
          sixDigitNaicsOnly
          creatableOption={undefined}
          isViewMode={isBusinessIndustryReadOnly && !isIndustryTypeChanged}
          onCreateOption={({ value }) => {
            setBusinessIndustry(value as unknown as Industry);
          }}
          onChange={({ target }) => {
            setBusinessIndustry(target.value as unknown as Industry);
          }}
          placeholder={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.businessIndustry.placeholder'
          )}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.businessIndustry.label'
            ),
          }}
          isRequired
          isDisabled={isBlockedFromSubmitting && isIndustryTypeChanged}
        />
        <Form.Select
          {...form.registerField('businessType')}
          isDisabled={isBlockedFromSubmitting && isBusinessTypeChanged}
          isViewMode={!isBusinessTypeChanged && !!selectedBusinessType}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.businessType.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.businessType.placeholder'
          )}
          options={businessTypeOptionsDeprecatedSelect}
          emptyState={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.businessType.emptyState'
            ),
          }}
          onChange={onBusinessTypeChanged}
        />
        <Form.RadioGroup
          {...form.registerField('taxIdType')}
          isViewMode={!forceTaxIdTypeEditable && isFieldReadonly('taxIdType')}
          labelProps={{
            label: formatMessage(
              'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.taxIdType.label'
            ),
          }}
          options={taxIdTypeOptions(selectedBusinessType)}
          onChange={(e) => onTaxIdTypeChanged(e.target.value as TaxIdTypeEnum)}
        />
        <Form.TextField
          {...form.registerField('businessTaxId')}
          isViewMode={isFieldReadonly('businessTaxId')}
          labelProps={{
            label: companyLabels.taxId(),
          }}
          type="text"
          maskProps={{
            mask: !isFieldReadonly('businessTaxId')
              ? masks.taxId[selectedTaxIdType || TaxIdTypeEnum.Ein]
              : masks.taxId.any,
          }}
          placeholder={placeholders.taxId(selectedTaxIdType)}
          onFocus={() => form.setValue('businessTaxId', '')}
          isRequired
          data-private
        />
        <Button
          {...form.submitButtonProps}
          isLoading={isLoading && !isBlockedFromSubmitting}
          isDisabled={isBlockedFromSubmitting}
          data-testid="business-details-submit"
          label={formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.businessDetailsCard.submitButton.label'
          )}
          onClick={onClickSaveAndContinue}
        />
      </Form>
    </CollapsibleCard>
  );
};
