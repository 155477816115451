import { SyncNowIconButton } from '@melio/ap-widgets';
import { useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { AccountingPlatform } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  accountingPlatform?: AccountingPlatform;
  analyticsProperties: Record<string, string>;
  onAccountingPlatformConnect: VoidFunction;
};

export const AccountingPlatformConnectButton = ({ analyticsProperties, onAccountingPlatformConnect }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { isExtraSmallScreen } = useBreakpoint();
  const tooltipTitle = formatMessage('activities.payDashboard.accountingPlatformConnect.tooltip.title');
  return (
    <SyncNowIconButton
      tooltipTestId="accounting-platform-connect-button-tooltip"
      tooltipTitle={tooltipTitle}
      tooltipLabel={formatMessage('activities.payDashboard.accountingPlatformConnect.tooltip.label')}
      placement="bottom"
      onClick={() => {
        track('SyncAccountingSoftware', 'Click', {
          ...analyticsProperties,
          Cta: 'sync',
        });
        onAccountingPlatformConnect();
      }}
      isDisabled={false}
      variant={isExtraSmallScreen ? 'naked' : undefined}
      ariaLabel={tooltipTitle}
    />
  );
};
