import { isEinOnlyBusinessType, useMtlMessages, useMtlSchemaValidations } from '@melio/ap-domain';
import { OrganizationBusinessType } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { AnySchema, object, SchemaOf, string } from 'yup';

import { CompleteRequiredDetailsFormField, CompleteRequiredDetailsFormFields, MissingField } from './types';
import { filterByFieldsList } from './utils';

export type DynamicSchemaFields = {
  [K in CompleteRequiredDetailsFormField]: SchemaOf<CompleteRequiredDetailsFormFields[K]>;
};

type Options = {
  isSkippable: boolean;
  taxIdNumberValidate: boolean;
  missingFields: MissingField[];
};

const alwaysRequiredFields = ['dateOfBirth', 'taxInfoType', 'taxInfoIdentifier'];

/**
 * Returns a validation schema for the complete required details form
 *
 * By default, the schema is generated as a mix of required and not required fields (specifically date of birth).
 * If the form is not dismissible, all fields are marked as required.
 *
 * @param fieldsList
 * @param options
 */
export const useCompleteRequiredDetailsValidationSchema = (
  fieldsList: Set<CompleteRequiredDetailsFormField>,
  options: Options
) => {
  const [legalDateOfBirthEnabled] = useFeature(FeatureFlags.RiskMtlKycLegalDOB, false);
  const { formatMessage } = useMelioIntl();
  const {
    labels: { company: companyLabels },
  } = useMtlMessages();
  const { name, companyName, dateOfBirth, address, phoneNumber, industry, businessType, taxIdType, taxId } =
    useMtlSchemaValidations();

  let allFields: Record<string, AnySchema> = {
    firstName: name('firstName'),
    lastName: name('lastName'),
    companyName: companyName(),
    dateOfBirth: dateOfBirth(), // dateOfBirth is always required
    address: address('operating-address'),
    legalDateOfBirth: dateOfBirth(),
    legalCompanyName: companyName(),
    phoneNumber: phoneNumber(),
    legalAddress: address('legal-address'),
    industry: industry(),
    businessType: businessType(),
    taxInfoType: taxIdType().test('ein-only-allowed', function (value) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { businessType }: { businessType: string | null } = this.parent;
      if (businessType) {
        if (!value) {
          return this.createError({
            message: formatMessage('app.mtl.validations.taxInfo.type.required'),
          });
        } else if (isEinOnlyBusinessType(businessType as OrganizationBusinessType) && value !== 'EIN') {
          return this.createError({
            message: companyLabels.einOnlyHelperText(businessType as OrganizationBusinessType),
          });
        }
      }
      return true;
    }),
    taxInfoIdentifier: options.taxIdNumberValidate ? taxId('taxInfoType') : string().notRequired(),
    contactFirstName: name('firstName'),
    contactLastName: name('lastName'),
  };

  // Written like this so that if FF is removed, code can be deleted easily
  if (!legalDateOfBirthEnabled) {
    delete allFields['legalDateOfBirth'];
  }

  // If the form is marked isSkippable, we mark all the fields as notRequired
  if (options.isSkippable) {
    allFields = Object.fromEntries(
      Object.entries(allFields).map(([key, value]) => {
        if (!alwaysRequiredFields.includes(key)) {
          return [key, value.notRequired()];
        } else {
          return [key, value];
        }
      })
    );
  } else {
    // We only mark those that are marked as required in the missing fields
    allFields = Object.fromEntries(
      Object.entries(allFields).map(([key, value]) => {
        const missingField = options.missingFields.find((field) => field.name === key);
        if (!missingField?.isRequired) {
          return [key, value.notRequired()];
        } else {
          return [key, value];
        }
      })
    );
  }

  return object().shape(filterByFieldsList(allFields, fieldsList) as DynamicSchemaFields);
};
