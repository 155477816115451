import { ARFeeDetails } from '@melio/ar-domain';
import { Group, Pill, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useFormattedFee } from '../../../hooks';

type PaymentMethodTitleProps = {
  title: string;
  feeDetails?: ARFeeDetails;
};

export const PaymentMethodTitle = forwardRef<PaymentMethodTitleProps>(({ title, feeDetails, ...props }, ref) => {
  const feeLabel = useFormattedFee().getFeeLabel(feeDetails);

  return (
    <Group alignItems="center" spacing="xxs" {...props} ref={ref}>
      <Text textStyle="body1Semi">{title}</Text>
      {feeLabel && <Pill status="neutral" type="secondary" label={feeLabel} />}
    </Group>
  );
});
