// eslint-disable-next-line import/no-deprecated
import { useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';
import { useState } from 'react';

import { useUserState } from '../../../hooks';
import { AddBankAccountDetailsScreen, AddBankAccountDetailsScreenProps } from './screens';

export type AddBankAccountDetailsActivityProps = Pick<
  AddBankAccountDetailsScreenProps,
  'onViewInvoice' | 'onSelectFundingSource' | 'isDisabled'
> & {
  paymentRequestLink: string;
  isNavigateApUsersToDashboardEnabled?: boolean;
  onDone: (email: string) => void;
  onError?: ARErrorFunction;
};

export const AddBankAccountDetailsActivity = forwardRef<AddBankAccountDetailsActivityProps>(
  ({ onError, paymentRequestLink, isNavigateApUsersToDashboardEnabled, isDisabled, onDone, ...props }, ref) => {
    const [currentUserEmail, setCurrentUserEmail] = useState<string>();
    const userState = useUserState({ email: currentUserEmail, paymentRequestLink });
    const { data: paymentRequestDetails } = useGuestPayorPaymentRequestDetails({ paymentRequestLink, onError });

    return (
      <AddBankAccountDetailsScreen
        paymentRequestDetails={paymentRequestDetails}
        onSubmit={(data) => onDone(data.email)}
        isSaving={isDisabled}
        onEmailChanged={setCurrentUserEmail}
        isLoading={userState.isLoading}
        isCheckingEmail={userState.isCheckingEmail}
        signInUrl={(isNavigateApUsersToDashboardEnabled && userState.isApUser && userState.signInUrl) || undefined}
        {...props}
        ref={ref}
      />
    );
  }
);
AddBankAccountDetailsActivity.displayName = 'AddBankAccountDetailsActivity';
