/* eslint-disable react-hooks/exhaustive-deps */
import {
  PaymentApprovalDecisionStatusEnum,
  PaymentProcessStatusEnum,
  PaymentQueryParameters,
  PaymentStatusEnum,
} from '@melio/platform-api';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { usePayDashboardSorting } from '../../PayDashboardSortingProvider';
import { PayDashboardSortingType } from '../../types';
import { CELLS_IDS, PaymentsTabFilter } from './types';
import { convertPaymentsTabSortToApiSort } from './utils';

export const failedPaymentsSearchParams = {
  'payment.status': [PaymentStatusEnum.Failed],
  'payment.collectStatus': { $ne: PaymentProcessStatusEnum.CancelCleared },
  'payment.approvalDecisionStatus': { $ne: PaymentApprovalDecisionStatusEnum.Declined },
};

const searchQueryParamByTabFilter: Record<PaymentsTabFilter, PaymentQueryParameters['search']> = {
  [PaymentsTabFilter.All]: undefined,
  [PaymentsTabFilter.Scheduled]: { 'payment.status': [PaymentStatusEnum.Scheduled, PaymentStatusEnum.Blocked] },
  [PaymentsTabFilter.InProgress]: { 'payment.status': [PaymentStatusEnum.InProgress] },
  [PaymentsTabFilter.Paid]: { 'payment.status': [PaymentStatusEnum.Completed] },
  [PaymentsTabFilter.Failed]: failedPaymentsSearchParams,
};

export const DEFAULT_SORT_BY_TAB_FILTER: Record<PaymentsTabFilter, PayDashboardSortingType['sortingState']> = {
  [PaymentsTabFilter.All]: { id: CELLS_IDS.UPDATED_AT, sortDirection: 'desc' },
  [PaymentsTabFilter.Scheduled]: { id: CELLS_IDS.DEBIT_DATE, sortDirection: 'asc' },
  [PaymentsTabFilter.InProgress]: { id: CELLS_IDS.DEBIT_DATE, sortDirection: 'asc' },
  [PaymentsTabFilter.Paid]: { id: CELLS_IDS.DELIVERY_DATE, sortDirection: 'desc' },
  [PaymentsTabFilter.Failed]: { id: CELLS_IDS.DEBIT_DATE, sortDirection: 'asc' },
};

export const usePaymentsTabQueryParams = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const sorting = usePayDashboardSorting();

  const searchStatusParam = searchParams.get('status') ?? '';
  const activeFilter = Object.values(PaymentsTabFilter).includes(searchStatusParam as PaymentsTabFilter)
    ? (searchStatusParam as PaymentsTabFilter)
    : PaymentsTabFilter.All;
  const activeSort: PayDashboardSortingType['sortingState'] = DEFAULT_SORT_BY_TAB_FILTER[activeFilter];

  useEffect(() => {
    searchParams.set('status', activeFilter);
    applyDefaultSort(activeSort);
  }, []);

  const applyFilter = (filter: PaymentsTabFilter) => {
    searchParams.set('status', filter);
    const defaultSort = DEFAULT_SORT_BY_TAB_FILTER[filter];
    applyDefaultSort(defaultSort);
  };

  const applyDefaultSort = (sort: PayDashboardSortingType['sortingState']) => {
    sort && sorting?.setSortState({ id: sort.id, sortDirection: sort.sortDirection, disableSaveforNextTime: true });
    navigate({ search: searchParams.toString() }, { replace: true, state: { keepToast: true } });
  };

  const queryParams = {
    search: searchQueryParamByTabFilter[activeFilter],
  };

  const sort = sorting?.sortingState ? convertPaymentsTabSortToApiSort(sorting.sortingState) : undefined;

  return { activeFilter, applyFilter, queryParams, sort };
};
