import { Money } from '@melio/money';
import { Form, Group, Text } from '@melio/penny';
import { Payment } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useDebounceCallback } from '@melio/platform-utils';

import { getAvailableBalance, isJustPayPayment } from '../../../../util/amount';
import { AmountInputProps } from '../types';

type BasicAmountInputProps = AmountInputProps & {
  hideLabel?: boolean;
  hideHelperText?: boolean;
};

export const BasicAmountInput = ({ form, onChange, bill, payment, hideHelperText = false }: BasicAmountInputProps) => {
  const { formatMessage, formatCurrency } = useMelioIntl();
  const { registerField, watch, setValue } = form;
  const [amountToPay] = watch(['amountToPay']);
  const isDisabled = Boolean((payment?.bills?.length || 0) > 1);

  const shouldDisplayPartialPaymentHelperText = (payment?: Payment) => {
    const isRecurringPayment =
      !!payment?.subscriptionOccurrence && payment?.subscriptionOccurrence?.billSubscription?.status !== 'canceled';

    return !isRecurringPayment && !isJustPayPayment(payment?.createMethod);
  };

  const debouncedOnChange = useDebounceCallback(onChange, 500);

  let partialAmountText;
  if (bill && amountToPay) {
    const current = Money.fromNaturalUnit(amountToPay, bill.currency);
    const availableBalance = getAvailableBalance(bill, payment);
    const expectedBalance = availableBalance.minus(current).toNaturalUnit();
    const displayPartialPaymentHelperText = shouldDisplayPartialPaymentHelperText(payment);

    if (expectedBalance > 0 && expectedBalance < availableBalance.toNaturalUnit() && displayPartialPaymentHelperText) {
      partialAmountText = formatMessage('activities.paymentFlow.form.content.amountToPay.helperText.partialPayment', {
        remainingAmount: formatCurrency(expectedBalance),
        invoiceNumber: bill?.invoice.number || undefined,
      });
    }
  }

  return (
    <Group width="full" variant="vertical" spacing="s">
      <Form.AmountField
        {...registerField('amountToPay')}
        labelProps={{ label: formatMessage('activities.paymentFlow.form.content.amountToPay.label') }}
        integerLimit={10}
        decimalLimit={2}
        decimalScale={2}
        allowNegativeValue={false}
        isDisabled={isDisabled}
        onChange={(event) => {
          setValue('amountToPay', event.target.value);
          debouncedOnChange(event.target.value);
        }}
      />
      {!hideHelperText && partialAmountText && (
        <Text textStyle="body3" color="global.neutral.800" data-testid="amountToPay-partial-helperText">
          {partialAmountText}
        </Text>
      )}
    </Group>
  );
};
