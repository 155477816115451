import { Table } from '@melio/penny';
import { Vendor } from '@melio/platform-api';

import { MAX_PAYMENT_AMOUNT_DIGITS } from '../../../../consts';
import { useFormContext } from '../../FormContext';

type Props = { vendor: Vendor; onChange: (vendor: Vendor, value?: string) => void };
export const AmountCell = ({ vendor, onChange }: Props) => {
  const { setValue, formState } = useFormContext();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(`amounts.${vendor.id}`, e.target.value);
    onChange(vendor, e.target.value);
  };

  return (
    <Table.Cell data-testid={`vendors-batch-payments-modal-body-row-${vendor.id}-amount-cell`}>
      <Table.AmountInput
        data-testid={`vendors-batch-payments-amounts-modal-input-${vendor.id}`}
        onChange={handleInputChange}
        allowNegativeValue={false}
        errorMessage={formState.errors.amounts?.[vendor.id]?.message}
        integerLimit={MAX_PAYMENT_AMOUNT_DIGITS}
      />
    </Table.Cell>
  );
};
