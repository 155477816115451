import { withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { usePaymentRequestStatus } from '../../hooks';
import { ErrorType } from '../../types';
import { GuestPaymentRequestErrorScreen } from './screens';

type GuestPaymentRequestErrorsActivityProps = {
  paymentRequestLink: string;
};

export const GuestPaymentRequestErrorsActivity = withAnalyticsContext<GuestPaymentRequestErrorsActivityProps>(
  forwardRef(({ setAnalyticsProperties, paymentRequestLink, ...props }, ref) => {
    setAnalyticsProperties({
      ProductName: 'ar',
      Flow: 'payment-request',
      PaymentRequestLink: paymentRequestLink,
    });

    const { status, isLoading } = usePaymentRequestStatus(paymentRequestLink);
    const errorType = status == 'cancelled' ? ErrorType.INVOICE_CANCELLED : ErrorType.GENERAL_ERROR;

    return <GuestPaymentRequestErrorScreen isLoading={isLoading} errorType={errorType} {...props} ref={ref} />;
  })
);

GuestPaymentRequestErrorsActivity.displayName = 'GuestPaymentRequestErrorsActivity';
