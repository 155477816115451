import { useMelioIntl } from '@melio/platform-i18n';
import { isAfter, isBefore, isSameDay, startOfDay } from 'date-fns';

import { formatDate } from '../DeliveryMethodChangedModal';
import { DeliveryMethodDetailsDiff } from '../types';

export const useDescriptionText = (diff: DeliveryMethodDetailsDiff | undefined) => {
  const { formatMessage } = useMelioIntl();

  if (!diff) {
    return '';
  }

  const { debitByDate, deliveryByDate } = diff;

  if (isAfter(deliveryByDate.new, deliveryByDate.old) && isSameDay(debitByDate.new, debitByDate.old)) {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.sameDebitLaterDelivery', {
      fromDeliveryDate: formatDate(deliveryByDate.old),
      toDeliveryDate: formatDate(deliveryByDate.new),
      debitDate: formatDate(debitByDate.old),
    });
  }

  if (
    isBefore(startOfDay(debitByDate.new), startOfDay(debitByDate.old)) &&
    isSameDay(deliveryByDate.new, deliveryByDate.old)
  ) {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.earlyDebitSameDelivery', {
      fromDebitDate: formatDate(debitByDate.old),
      toDebitDate: formatDate(debitByDate.new),
      deliveryDate: formatDate(deliveryByDate.old),
    });
  }

  if (
    isBefore(startOfDay(debitByDate.new), startOfDay(debitByDate.old)) &&
    !isSameDay(deliveryByDate.new, deliveryByDate.old)
  ) {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.earlyDebitDifferentDelivery', {
      fromDebitDate: formatDate(debitByDate.old),
      toDebitDate: formatDate(debitByDate.new),
      fromDeliveryDate: formatDate(deliveryByDate.old),
      toDeliveryDate: formatDate(deliveryByDate.new),
    });
  }

  if (
    isAfter(startOfDay(debitByDate.new), startOfDay(debitByDate.old)) &&
    isSameDay(deliveryByDate.new, deliveryByDate.old)
  ) {
    return formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.laterDebitSameDelivery', {
      fromDebitDate: formatDate(debitByDate.old),
      toDebitDate: formatDate(debitByDate.new),
      deliveryDate: formatDate(deliveryByDate.old),
    });
  }

  return '';
};
