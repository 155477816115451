import { SystemMessage, useIsMobile } from '@melio/ar-domain';
import { Group, Layout, LayoutProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type InvoiceFormLayoutProps = {
  onClose?: VoidFunction;
  isDisabled?: boolean;
  isLoading?: boolean;
  footer?: NonNullable<LayoutProps['footer']>['content'];
  header?: NonNullable<LayoutProps['header']>['content'];
  backgroundColor?: LayoutProps['backgroundColor'];
  maxWidth: LayoutProps['maxWidth'];
};

export const InvoiceFormLayout = forwardRef<InvoiceFormLayoutProps>(
  ({ isLoading, isDisabled, onClose, footer, header, children, ...props }, ref) => {
    const isMobile = useIsMobile();
    return (
      <Layout
        isLoading={isLoading}
        footer={footer && { content: footer, isSticky: true }}
        header={isMobile && header ? { content: header, isSticky: true } : undefined}
        data-component={InvoiceFormLayout.displayName}
        {...props}
        ref={ref}
      >
        <Group variant="vertical" spacing="xl" data-testid="invoice-screen-content">
          <SystemMessage />
          {!isMobile && header}
          {children}
        </Group>
      </Layout>
    );
  }
);
InvoiceFormLayout.displayName = 'InvoiceFormLayout';
