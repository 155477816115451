import { useEngagementMessaging } from '@melio/in-app-marketing';
import React, { useEffect, useMemo, useState } from 'react';

import { SubscriptionProvider } from './SubscriptionContext';
import { SubscriptionContextualPaywallProps, SubscriptionProviderProps } from './types';
import { useSubscriptionData } from './useSubscriptionData';
import { useSubscriptionGracePeriod } from './useSubscriptionGracePeriod';

export const SubscriptionWrapper: React.FC<SubscriptionProviderProps> = ({
  LoaderComponent,
  ErrorComponent,
  partnerGroup,
  children,
}) => {
  const engagementMessaging = useEngagementMessaging();
  const {
    isFetching: isSubscriptionDataFetching,
    isError: isSubscriptionDataError,
    subscription,
    plans,
    accountingClientSelectedSubscriptionPlan,
  } = useSubscriptionData({
    // parent comp may useSubscriptionData() to prefetch subscription data. when org has no subscription, we return a 404 error.
    // retrying on mount will invalidate the parent's query. since parent may unmount the provider while data is loading we can end up with an infinite remount loop.
    retryOnMount: false,
  });

  const [paywallProps, setPaywallProps] = useState<SubscriptionContextualPaywallProps | null>(null);
  const hasSubscription = !!subscription;
  const {
    isEligibleForGracePeriod,
    isTodayInGracePeriod,
    isFetchingGracePeriod,
    isOrgCreatedBeforeGracePeriodStart,
    isUserCreatedBeforeGracePeriodStart,
    isGracePeriodEnded,
    gracePeriodStartDate,
    gracePeriodEndDate,
    gracePeriodEndDateToDisplay,
  } = useSubscriptionGracePeriod({
    hasSubscription,
  });

  const value = useMemo(
    () => ({
      partnerGroup,
      plans: plans || [],
      subscription,
      accountingClientSelectedSubscriptionPlan,
      contextualPaywall: {
        paywallProps,
        showPaywall: setPaywallProps,
        hidePaywall: () => setPaywallProps(null),
      },
      gracePeriod: {
        isEligibleForGracePeriod,
        isTodayInGracePeriod,
        isOrgCreatedBeforeGracePeriodStart,
        isUserCreatedBeforeGracePeriodStart,
        isGracePeriodEnded,
        isEligibleAndGracePeriodEnded: isEligibleForGracePeriod && isGracePeriodEnded,
        gracePeriodStartDate,
        gracePeriodEndDate,
        gracePeriodEndDateToDisplay,
      },
      clientPlanDiscountPercent: 40,
    }),
    [
      partnerGroup,
      plans,
      subscription,
      paywallProps,
      isEligibleForGracePeriod,
      isTodayInGracePeriod,
      accountingClientSelectedSubscriptionPlan,
      isOrgCreatedBeforeGracePeriodStart,
      isUserCreatedBeforeGracePeriodStart,
      isGracePeriodEnded,
      gracePeriodStartDate,
      gracePeriodEndDate,
      gracePeriodEndDateToDisplay,
    ]
  );

  useEffect(() => {
    if (hasSubscription) {
      engagementMessaging.setUserAttribute('hasSubscription', true);
    }
  }, [engagementMessaging, hasSubscription, isSubscriptionDataFetching]);

  if (isSubscriptionDataFetching || isFetchingGracePeriod) {
    return <LoaderComponent />;
  }

  if (isSubscriptionDataError) {
    return <ErrorComponent />;
  }

  return <SubscriptionProvider value={value}>{children}</SubscriptionProvider>;
};
