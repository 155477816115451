/* eslint-disable max-len */
import { Link } from '@melio/penny';
import { DeliveryMethod, DeliveryMethodType } from '@melio/platform-api';
import { useMelioIntl, usePartnerFeature } from '@melio/platform-provider';
export function useBlockPaymentsForOrganizations() {
  const { formatMessage } = useMelioIntl();
  const [isBlockRPPSPaymentsForOrganizations] = usePartnerFeature('BlockRPPSPaymentsForOrganizations', false);
  const [isBlockInternationalPaymentsForOrganizations] = usePartnerFeature(
    'BlockInternationalPaymentsForOrganizations',
    false
  );

  const isRPPS = (deliveryMethods: DeliveryMethod[] | undefined): boolean =>
    isBlockRPPSPaymentsForOrganizations && hasBlockedDeliveryMethod(deliveryMethods, DeliveryMethodType.BillerAccount);

  const isInternationalAccount = (deliveryMethods: DeliveryMethod[] | undefined): boolean =>
    isBlockInternationalPaymentsForOrganizations &&
    hasBlockedDeliveryMethod(deliveryMethods, DeliveryMethodType.InternationalAccount);

  const hasBlockedDeliveryMethod = (
    deliveryMethods: DeliveryMethod[] | undefined,
    deliveryMethodType: DeliveryMethodType
  ): boolean => !!deliveryMethods?.some(({ type }) => type === deliveryMethodType);

  const shouldBlockInternationalPayments = (deliveryMethods: DeliveryMethod[] | undefined): boolean =>
    isRPPS(deliveryMethods) || isInternationalAccount(deliveryMethods);

  const getTooltipContent = (deliveryMethods: DeliveryMethod[] | undefined): string => {
    if (isRPPS(deliveryMethods)) {
      return formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approve.rpps.disabled.tooltip');
    }
    if (isInternationalAccount(deliveryMethods)) {
      return formatMessage('activities.payDashboard.paymentsTab.table.cells.actions.approve.disabled.tooltip');
    }
    return '';
  };

  const getBlockReason = (deliveryMethods: DeliveryMethod[] | undefined): string => {
    if (isRPPS(deliveryMethods)) {
      return formatMessage('app.blockedInternationalPayments.rpps.error.description', {
        link: (
          <Link
            color="secondary"
            newTab
            href="https://melio.zendesk.com/hc/en-us/articles/6010956720028-Paying-vendors-in-Melio-s-network"
            label={formatMessage('app.blockedInternationalPayments.rpps.link.error.description')}
          />
        ),
      });
    }
    if (isInternationalAccount(deliveryMethods)) {
      return formatMessage('app.blockedInternationalPayments.error.description');
    }
    return '';
  };

  return {
    shouldBlockInternationalPayments,
    getTooltipContent,
    getBlockReason,
  };
}
