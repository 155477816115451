import { useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { PayDashboardEmptyState } from '../../PayDashboardEmptyState';
import { PayDashboardClearedState } from '../../PayDashboardEmptyState/PayDashboardClearedState';
import { ButtonPropsWithTestId } from '../../PayDashboardEmptyState/PayDashboardEmptyState';
import { COLLABORATORS_SETTINGS_RELPATH } from '../consts';
import { useManageApprovalsButtonLabel } from './useManageApprovalsButtonLabel';

export const ApprovalsTabEmptyStateV2 = ({ onLinkClick }: { onLinkClick: VoidFunction }) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  const navigate = useNavigate();
  const { track } = useAnalytics();
  const { buttonLabel } = useManageApprovalsButtonLabel();

  const generateButtons = (): ButtonPropsWithTestId[] => [
    {
      label: buttonLabel,
      variant: 'secondary',
      onClick: onLinkClick,
      'data-testid': 'approvals-tab-empty-state-manage-button',
    },
    {
      label: formatMessage('activities.payDashboard.approvalsTab.emptyState.addCollaborator'),
      onClick: handleAddCollaboratorClick,
      'data-testid': 'approvals-tab-empty-state-collaborator-button',
    },
  ];

  const handleAddCollaboratorClick = useCallback(() => {
    track('Dashboard', 'Click', { Intent: 'approval-workflow', Cta: 'add-collaborator' });
    navigate(COLLABORATORS_SETTINGS_RELPATH);
  }, [track, navigate]);

  if (isExtraSmallScreen) {
    return (
      <PayDashboardClearedState
        description={formatMessage('activities.payDashboard.approvalsTab.emptyState.description')}
        aria-label={formatMessage('activities.payDashboard.approvalsTab.emptyState.ariaLabel')}
        buttonProps={generateButtons()}
        illustrationSrc="pay-zero"
      />
    );
  }

  return (
    <PayDashboardEmptyState
      description={formatMessage('activities.payDashboard.approvalsTab.emptyState.description')}
      aria-label={formatMessage('activities.payDashboard.approvalsTab.emptyState.ariaLabel')}
      buttonProps={generateButtons()}
      animationSrc="https://platform-static.meliopayments.com/assets/melio/approval.lottie.json"
    />
  );
};
