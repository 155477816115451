import { useMelioForm, useToast } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-i18n';
import { useMonitoring } from '@melio/platform-monitoring';
import {
  converDateToStringRepresentation,
  formatDomesticPhoneNumberToIntl,
  getNationalNumberFromNumber,
  getRegionCodeFromPhoneNumber,
  useDateUtils,
} from '@melio/platform-utils';

import { MonitoredAction } from '../../../../../../monitoring';
import { AccountHolderForm } from '../types';
import { getDirtyValuesFromForm } from '../utils/formUtils';
import { useAccountHolderFormSchema } from './useAccountHolderFormSchema';

export const useAccountHolderForm = ({ onDone }: { onDone: (values: AccountHolderForm) => void }) => {
  const { startAction, endAction } = useMonitoring<MonitoredAction>();
  const { createDate } = useDateUtils();
  const { formatMessage } = useMelioIntl();
  const { track } = useAnalytics();
  const { toast } = useToast();
  const {
    data: account,
    update: updateAccount,
    isMutating,
    isLoading,
  } = useAccount({
    id: 'me',
    onUpdate: () => {
      endAction('fx_account_holder_submit');
      onDone(form.getValues());
    },
    onUpdateError: () => {
      track('Organization', 'Status', {
        Status: 'failure',
        PageName: 'account-holder',
        Intent: 'set-account-holder-details',
        ErrorType: `some-thing-went-wrong`,
      });
      toast({
        // @ts-expect-error Missing type on DS side
        'data-testid': 'fx-update-account-holder-failed-toast',
        type: 'error',
        title: formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.commons.errors.general-error'
        ),
      });
    },
  });
  const accountHolderFormSchema = useAccountHolderFormSchema();
  const form = useMelioForm<AccountHolderForm>({
    onSubmit: () => {
      startAction('fx_account_holder_submit');
      const dirtyAccountHolderFields = getDirtyValuesFromForm({ form });

      const shouldUpdateUserPhone =
        !!dirtyAccountHolderFields.phoneNumber &&
        getRegionCodeFromPhoneNumber(dirtyAccountHolderFields.phoneNumber) === 'US';
      updateAccount({
        user: {
          dateOfBirth: converDateToStringRepresentation(dirtyAccountHolderFields.dayOfBirth),
          ...(shouldUpdateUserPhone
            ? { phoneNumber: getNationalNumberFromNumber(dirtyAccountHolderFields.phoneNumber as string) }
            : {}),
        },
      });
    },
    schema: accountHolderFormSchema,
    defaultValues: {
      firstName: account?.user.firstName ?? '',
      lastName: account?.user.lastName ?? '',
      email: account?.user.email ?? '',
      phoneNumber: account?.user.phoneNumber ? formatDomesticPhoneNumberToIntl(account.user.phoneNumber) : '',
      taxId: '',
      address: {
        line1: '',
        city: '',
        state: '',
        countryCode: '',
        postalCode: '',
      },
      dayOfBirth: account?.user.dateOfBirth ? createDate(account?.user.dateOfBirth) : undefined,
      isUbo: false,
    },
    subscribeToDefaultValuesChanges: true,
    subscribeToDefaultValuesChangesOptions: {
      keepDirtyValues: true,
    },
  });

  return { form, isMutating, isLoading };
};
