import { FormInputs, useMelioIntl } from '@melio/ar-domain';
import { Container } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { useInvoiceFormContext } from '../../../utils';

type InvoiceItemFormProps = {
  index: number;
  onCreate: VoidFunction;
  onDelete: VoidFunction;
  onError?: (type: 'general' | 'sizeExceeded') => void;
  isSaving: boolean;
};

const MAX_ATTACHMENT_FILE_SIZE_10MB = 10 * 1024 * 1024;

export const InvoiceFileAttachmentsForm = forwardRef<InvoiceItemFormProps>(
  ({ isSaving, onError, onCreate, onDelete, index }, ref) => {
    const { formatMessage } = useMelioIntl();
    const { filesFieldArray, registerField, getValues } = useInvoiceFormContext();
    const field = filesFieldArray.fields[index];
    const { attachments } = getValues();
    const currentAttachmentsFilesSize =
      attachments?.reduce((total, attachment) => total + (attachment.size || 0), 0) || 0;

    return (
      <Container ref={ref}>
        <FormInputs.MultipleAttachmentSelect
          maxFilesSize={MAX_ATTACHMENT_FILE_SIZE_10MB}
          currentFilesSize={currentAttachmentsFilesSize}
          data-testid={field?.id}
          {...(!attachments?.[index]?.fileName && {
            helperTextProps: {
              label: formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.helperText'),
            },
          })}
          onError={onError}
          isReadOnly={isSaving}
          labelProps={{
            label:
              index === 0
                ? formatMessage(
                    'ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.withoutNumberSign.label'
                  )
                : formatMessage(
                    'ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.withNumberSign.label',
                    {
                      index: index + 1,
                    }
                  ),
          }}
          onChangeState={(data) => {
            if (data) {
              onCreate();
            } else {
              onDelete();
            }
          }}
          acceptTypes={['pdf']}
          placeholder={formatMessage('ar.invoiceLifecycle.activities.createInvoice.inputs.attachments.placeholder')}
          {...registerField(`attachments.${index}`)}
        />
      </Container>
    );
  }
);
InvoiceFileAttachmentsForm.displayName = 'InvoiceAttachmentForm';
