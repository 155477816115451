import { Route, Routes } from 'react-router-dom';
import { GuestPaymentRouter, InvoiceDashboardRouter, OnboardingRouter } from '@melio/ar-app';
import { addWildcardToRoutes, useNavigate } from '@melio/ar-domain';
import { FeatureFlags, useAnonymousFeature, useFeature } from '@melio/platform-feature-flags';
import { SubscriptionWrapper } from '@melio/subscriptions';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { usePartnerConfig } from '@/hooks/partners';
import { useResetAccessToken } from '@/hooks/session.hooks';
import { useActiveScreen } from '@/hooks/useActiveScreen';
import { useIsArEnabled } from '@/hooks/useIsArEnabled';
import { NotFoundScreen } from '@/screens/error-screens/NotFound.screen';
import { ServerErrorScreen } from '@/screens/error-screens/ServerError.screen';
import { ScreensEnum } from '@/store/app/app.types';
import { DashboardLayout } from '../../layouts/Dashboard.layout';
import { WithAnonymousInitialData, WithInitialData } from '../../utils';

export const ARRouter = () => {
  useActiveScreen(ScreensEnum.ARDashboard);
  const arDashboardEnabled = useIsArEnabled();
  const [arOnboardingEnabled] = useFeature(FeatureFlags.AROnboarding, false);
  const { partnerName, partnerGroup } = usePartnerConfig();
  const { goToSupportSettings } = useSettingsRouterNavigation();
  // TODO: remove this flag once the feature is released to all users (https://meliorisk.atlassian.net/browse/ME-68159)
  const [isNavigateApUsersToDashboardFFEnabled] = useAnonymousFeature(
    FeatureFlags.GuestPayorFlowNavigateApUsersToPayDashboard,
    false,
  );

  return (
    <Routes>
      <Route
        path="meliome/*"
        element={
          <GuestPaymentRouter
            partnerName={partnerName}
            partnerGroup={partnerGroup}
            WithAnonymousInitialData={WithAnonymousInitialData}
            useResetAccessToken={useResetAccessToken}
            isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardFFEnabled}
          />
        }
      />
      <Route
        path="*"
        element={
          arDashboardEnabled ? (
            <InvoiceDashboardRouter
              onVisitSupportSettingsPage={goToSupportSettings}
              DashboardLayout={DashboardLayout}
              WithInitialData={WithInitialData}
            />
          ) : (
            <NotFoundScreen />
          )
        }
      />

      <Route
        path="onboarding/*"
        element={
          arOnboardingEnabled ? (
            <SubscriptionWrapper
              LoaderComponent={() => <Loader isAbsoluteCenter />}
              ErrorComponent={() => <ServerErrorScreen />}
              partnerGroup={partnerGroup}
            >
              <OnboardingRouter />
            </SubscriptionWrapper>
          ) : (
            <NotFoundScreen />
          )
        }
      />
    </Routes>
  );
};

const useSettingsRouterNavigation = () => {
  enum Paths {
    SupportSetting = '/settings/support',
  }
  const navigate = useNavigate<Paths>();

  const goToSupportSettings = () => {
    navigate(Paths.SupportSetting);
  };

  return {
    RedirectPaths: Paths,
    Paths: addWildcardToRoutes(Paths),
    goToSupportSettings,
  };
};
