import { useAnalyticsContext, withAnalyticsContext } from '@melio/platform-analytics';
import { useScannedInvoice } from '@melio/platform-api';

import { Loader } from '@/cl/components/Loader/Loader.component';
import { BillPaymentDetailsEmptyState } from '@/widgets/billPaymentDetailsEmptyState/BillPaymentDetailsEmptyState.widget';
import { ScannedInvoiceDetails } from '@/widgets/pay-dashboard/scanned-invoice-details/ScannedInvoiceDetails.widget';

export const ScannedInvoiceDetailsScreen = withAnalyticsContext(
  ({ scannedInvoiceId }: { scannedInvoiceId: string }) => {
    const { data: scannedInvoice, isLoading: scannedInvoicesIsLoading } = useScannedInvoice({
      id: scannedInvoiceId,
    });

    useAnalyticsContext({
      globalProperties: {
        ScannedInvoiceId: scannedInvoiceId,
      },
    });

    if (scannedInvoicesIsLoading) {
      return <Loader isAbsoluteCenter />;
    }

    if (!scannedInvoice) {
      return <BillPaymentDetailsEmptyState />;
    }

    return <ScannedInvoiceDetails scannedInvoice={scannedInvoice} />;
  },
);
