import { Button, createNumberMask, Form, FormField, UseMelioFormResults } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';
import { Fragment } from 'react';
import type { Path } from 'react-hook-form';

import { isFieldReadonly as _isFieldReadonly } from '../../../../../../utils';
import { InternationalAddressInput } from '../../../../../international-address';
import { useTrackFormError } from '../hooks/useTrackFormError';
import { OwnershipDetailsForm } from '../types';
import { BirthdateField } from './BirthdateField';
import { VerifiedPersonaTaxIdField } from './VerifiedPersonaTaxIdField';

export const OwnerDetailsForm = ({
  form,
  index,
  onRemove,
}: {
  form: UseMelioFormResults<OwnershipDetailsForm>;
  index: number;
  onRemove?: VoidFunction;
}) => {
  const { formatMessage } = useMelioIntl();
  const isFieldReadonly = (fieldKey: Path<OwnershipDetailsForm>) =>
    _isFieldReadonly({
      form,
      fieldKey,
    });
  useTrackFormError<OwnershipDetailsForm>({
    form,
    pageName: 'ownership-details',
    intent: 'set-ownership-details',
  });

  return (
    <Fragment>
      <Form.TextField
        isViewMode={isFieldReadonly(`ubos.${index}.ownerFirstName`)}
        labelProps={{
          label: formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.firstNameField.label'
          ),
        }}
        {...form.registerField(`ubos.${index}.ownerFirstName`)}
        colSpan={3}
      />
      <Form.TextField
        isViewMode={isFieldReadonly(`ubos.${index}.ownerLastName`)}
        labelProps={{
          label: formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.lastNameField.label'
          ),
        }}
        {...form.registerField(`ubos.${index}.ownerLastName`)}
        colSpan={3}
      />
      <BirthdateField
        isViewMode={isFieldReadonly(`ubos.${index}.dateOfBirth`)}
        labelProps={{
          label: formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.dateOfBirthField.label'
          ),
        }}
        placeholder={formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.dateOfBirthField.placeholder'
        )}
        {...form.registerField(`ubos.${index}.dateOfBirth`)}
        colSpan={3}
      />
      <Form.TextField
        labelProps={{
          label: formatMessage(
            'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.ownershipPercentageField.label'
          ),
        }}
        placeholder={formatMessage(
          'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.ownershipPercentageField.placeholder'
        )}
        {...form.registerField(`ubos.${index}.ownershipPercentage`)}
        colSpan={3}
        isViewMode={isFieldReadonly(`ubos.${index}.ownershipPercentage`)}
        maskProps={{
          mask: createNumberMask({
            suffix: '%',
            prefix: '',
            thousandsSeparatorSymbol: '',
          }),
        }}
      />
      <VerifiedPersonaTaxIdField
        {...form.registerField(`ubos.${index}.ssn`)}
        isViewMode={isFieldReadonly(`ubos.${index}.ssn`)}
        colSpan={6}
      />
      <InternationalAddressInput
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error*
        form={form}
        addressLine1FieldName={`ubos.${index}.address.line1`}
        cityFieldName={`ubos.${index}.address.city`}
        countryCodeFieldName={`ubos.${index}.address.countryCode`}
        stateFieldName={`ubos.${index}.address.state`}
        postalCodeFieldName={`ubos.${index}.address.postalCode`}
      />
      {onRemove && (
        <FormField
          labelProps={{ label: '', isHidden: true }}
          size="small"
          colSpan={6}
          render={() => (
            <Button
              label={formatMessage(
                'activities.fxDeliveryMethodActivity.screens.fxBusinessDetails.ownershipDetailsCard.removeOwnerButton'
              )}
              onClick={onRemove}
              variant="tertiary"
              isFullWidth
              data-testid={`remove-additional-owner-${index}`}
            />
          )}
        />
      )}
    </Fragment>
  );
};
