import { useBreakpoint } from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';

import { PayDashboardEmptyState } from '../../PayDashboardEmptyState';
import { PayDashboardClearedState } from '../../PayDashboardEmptyState/PayDashboardClearedState';
import { useBillsTabEmptyState } from '../useBillsTabEmptyState';
import { AddBillDropdownMenu } from './AddBillDropdownMenu';

type Props = {
  onAddNewBillUpload: (files: File[]) => void;
  connectGmailCta: string;
  onAddNewBill: VoidFunction;
  onAccountingPlatformConnect: VoidFunction;
  shouldShowConnectAccountingSoftwareOption: boolean;
  shouldShouldGmailOption: boolean;
  onGmailConnect: VoidFunction;
  manuallyAddNewBill: VoidFunction;
  onEmailCopy: (value: string) => void;
};

export const BillsTabEmptyState = ({ manuallyAddNewBill, onAddNewBill, onEmailCopy, ...props }: Props) => {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen } = useBreakpoint();

  const [isNewBillEntryPointEnabled] = useFeature(FeatureFlags.NewBillEntryPoint, false);

  const { emptyStateText, emptyStateAriaLabel, descriptionCTA } = useBillsTabEmptyState(
    props.shouldShouldGmailOption,
    onEmailCopy
  );

  return isExtraSmallScreen ? (
    <PayDashboardClearedState
      title={formatMessage('activities.payDashboard.billsTab.emptyState.mobile.title')}
      description={emptyStateText}
      descriptionCTA={descriptionCTA}
      illustrationSrc="pay-zero"
      aria-label={emptyStateAriaLabel}
    />
  ) : (
    <PayDashboardEmptyState
      description={emptyStateText}
      descriptionCTA={descriptionCTA}
      aria-label={emptyStateAriaLabel}
      customCTAComponent={
        isNewBillEntryPointEnabled && (
          <AddBillDropdownMenu {...props} label={formatMessage('activities.payDashboard.billsTab.importButton')} />
        )
      }
      buttonProps={[
        {
          label: formatMessage('activities.payDashboard.billsTab.emptyState.primaryButton'),
          onClick: isNewBillEntryPointEnabled ? manuallyAddNewBill : onAddNewBill,
          'data-testid': 'bills-tab-empty-state-primary-button',
        },
      ]}
      animationSrc="https://platform-static.meliopayments.com/assets/melio/add-bill.lottie.json"
    />
  );
};
