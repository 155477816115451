import { useDisclosure } from '@chakra-ui/react';
import {
  InvoiceDiscount,
  InvoiceTaxRate,
  NavigateOptions,
  useInvoiceExpanded,
  useNavigate,
  useSearchParams,
  useTaxRates,
  withRouteId,
} from '@melio/ar-domain';
import {
  CreateCustomerActivity,
  CreateDiscountActivity as _CreateDiscountActivity,
  CreateServiceChargeActivity,
  TaxRateModalActivity as _TaxRateManagementActivity,
  UpdateCustomerActivity as _UpdateCustomerActivity,
} from '@melio/ar-modals';
import { useCallback, useEffect, useState } from 'react';
import { Route } from 'react-router-dom';

import { ServiceChargeParams } from '../types';

const CreateDiscountActivity = withRouteId(_CreateDiscountActivity, 'discountId', 'discountId');
const TaxRateModalActivity = withRouteId(_TaxRateManagementActivity, 'taxRateId', 'taxRateId');
const UpdateCustomerActivity = withRouteId(_UpdateCustomerActivity, 'customerId', 'customerId');

enum FlowRoutes {
  Discount = 'set-discount/:discountId?',
  TaxRate = 'set-tax-rate/:taxRateId?',
  ServiceCharge = 'add-service-charge/',
  EditCustomer = 'edit-customer/:customerId',
  AddCustomer = 'add-customer',
}

type UseModalsRouterProps<T extends string> = {
  onClose: (options?: NavigateOptions<T>) => unknown;
  onError?: ARErrorFunction;
  invoiceId?: string;
};

export const useInvoiceModals = <T extends string>({
  onClose: _onClose,
  onError,
  invoiceId,
}: UseModalsRouterProps<T>) => {
  const navigate = useNavigate<FlowRoutes>({ withSearchparams: true });
  const { data: invoice } = useInvoiceExpanded({ id: invoiceId, onError });

  const onClose = (options: NavigateOptions<T> = {}) => _onClose({ ...options, replace: true });

  // Tax Rate
  const { taxRate: invoiceTaxRate = null } = invoice ?? {};
  const { isLoading: isLoadingTaxRate, defaultTaxRate } = useTaxRates({ onError });
  const [taxRate, setTaxRate] = useState<InvoiceTaxRate>();

  useEffect(() => {
    if (!taxRate && defaultTaxRate) setTaxRate(defaultTaxRate);
  }, [defaultTaxRate, taxRate]);

  useEffect(() => void (invoiceTaxRate && setTaxRate(invoiceTaxRate)), [invoiceTaxRate]);

  const taxRateDialog = useDisclosure({
    onClose,
    onOpen: () => navigate(FlowRoutes.TaxRate, { replace: true, pathParams: { taxRateId: taxRate?.id } }),
  });

  // Discounts
  const { discount: invoiceDiscount = null } = invoice ?? {};
  const [discount, setDiscount] = useState<InvoiceDiscount>();
  const removeDiscount = useCallback(() => setDiscount(undefined), []);
  useEffect(() => void (invoiceDiscount && setDiscount(invoiceDiscount)), [invoiceDiscount]);
  const discountDialog = useDisclosure({
    onClose,
    onOpen: () => navigate(FlowRoutes.Discount, { replace: true, pathParams: { discountId: discount?.id } }),
  });

  // Service Charges
  const [serviceCharges, setServiceCharges] = useState<ServiceChargeParams[]>();
  const removeServiceCharge = useCallback(
    (index: number) => setServiceCharges((prev) => prev?.filter((_, i) => i !== index)),
    []
  );
  const addServiceCharge = useCallback(
    (sc: ServiceChargeParams) => setServiceCharges((prev = []) => [...prev, sc]),
    []
  );
  useEffect(
    () => void (invoice?.invoiceServiceCharges.length && setServiceCharges([...invoice.invoiceServiceCharges])),
    [JSON.stringify(invoice?.invoiceServiceCharges)] // eslint-disable-line react-hooks/exhaustive-deps
  );
  const serviceChargesDialog = useDisclosure({
    onClose,
    onOpen: () => navigate(FlowRoutes.ServiceCharge, { replace: true }),
  });

  const [searchParams] = useSearchParams<{ invoiceCustomerName: string; invoiceCustomerId: string }>();

  const isLoading = isLoadingTaxRate;

  const renderModals = () => (
    <>
      <Route
        path={FlowRoutes.TaxRate}
        element={
          <TaxRateModalActivity
            isOpen
            onClose={taxRateDialog.onClose}
            onDone={(taxRate) => {
              taxRateDialog.onClose();
              setTaxRate(taxRate);
            }}
          />
        }
      />
      <Route
        path={FlowRoutes.Discount}
        element={
          <CreateDiscountActivity
            isOpen
            onClose={discountDialog.onClose}
            onDone={(discount) => {
              discountDialog.onClose();
              setDiscount(discount);
            }}
          />
        }
      />
      <Route
        path={FlowRoutes.ServiceCharge}
        element={
          <CreateServiceChargeActivity
            isOpen
            onClose={serviceChargesDialog.onClose}
            onDone={(serviceCharge) => {
              serviceChargesDialog.onClose();
              addServiceCharge(serviceCharge);
            }}
          />
        }
      />
      <Route
        path={FlowRoutes.AddCustomer}
        element={
          <CreateCustomerActivity
            isOpen
            onDone={(invoiceCustomerId) => onClose({ queryParams: { invoiceCustomerId } })}
            onClose={() => onClose({ queryParams: { invoiceCustomerName: '', invoiceCustomerId: '' } })}
            customerName={searchParams.invoiceCustomerName}
          />
        }
      />
      <Route
        path={FlowRoutes.EditCustomer}
        element={
          <UpdateCustomerActivity
            isOpen
            onDone={(invoiceCustomerId) => onClose({ queryParams: { invoiceCustomerId } })}
            onClose={() => onClose({ queryParams: { invoiceCustomerName: '', invoiceCustomerId: '' } })}
          />
        }
      />
    </>
  );

  return {
    onCreateCustomer: (invoiceCustomerName: string) =>
      navigate(FlowRoutes.AddCustomer, { queryParams: { invoiceCustomerName } }),
    onUpdateCustomer: (customerId: string) => navigate(FlowRoutes.EditCustomer, { pathParams: { customerId } }),
    customerId: searchParams.invoiceCustomerId,
    renderModals,
    openDiscountModal: discountDialog.onOpen,
    openTaxRateModal: taxRateDialog.onOpen,
    serviceCharges: isLoading ? undefined : serviceCharges,
    removeServiceCharge,
    openServiceChargeModal: serviceChargesDialog.onOpen,
    discount: isLoading ? undefined : discount,
    removeDiscount,
    taxRate: isLoading ? undefined : taxRate,
  };
};
