import {
  _TableDateCellProps,
  ActionsDropdownMenu,
  ActionsDropdownMenuItemProps,
  DatePicker,
  Table,
} from '@melio/penny';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { noop } from 'lodash';
import { useState } from 'react';

import { useDeductionDateDescription } from './DeductionDateHeaderCell.utils';

type DeductionDateHeaderCellProps = {
  onSelectDate: _TableDateCellProps['onSelect'];
  onSelectByDueDate: VoidFunction;
  selectedDate?: Date;
  minDate: Date;
  isByDueDate?: boolean;
};

export const DeductionDateHeaderCell = forwardRef<DeductionDateHeaderCellProps, 'div'>(
  ({ onSelectDate, onSelectByDueDate, selectedDate, minDate, isByDueDate, ...props }, ref) => {
    const { formatMessage, formatDate } = useMelioIntl();

    const [isCalendarOpen, openCalendar] = useBoolean();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isCombinedPaymentsSupported] = useFeature(FeatureFlags.CombinedPayments, false);

    const { getDescription } = useDeductionDateDescription();

    const onClose = () => setTimeout(openCalendar.off, 500); // timeout so the user doesnt experience a flickering of the dropdown actions during close

    const handleMenuOpenChange = (isOpen: boolean) => {
      if (isMenuOpen && !isOpen) {
        onClose();
      }
      setIsMenuOpen(isOpen);
    };

    const handleCalenderSelect = (date: Date) => {
      onSelectDate(date);
      onClose();
      setIsMenuOpen(false);
    };

    const handleSelectByDueDate = () => {
      setIsMenuOpen(false);
      onSelectByDueDate();
    };

    const options: ActionsDropdownMenuItemProps[] = [
      {
        onClick: openCalendar.on,
        label: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deductionDateHeaderCell.sameDate.label'
        ),
        dataTestId: 'same-date',
      },
      {
        onClick: handleSelectByDueDate,
        label: formatMessage(
          'activities.batchPayments.screens.paymentIntentsTable.deductionDateHeaderCell.byDueDate.label'
        ),
        dataTestId: 'by-due-date',
      },
    ];

    const description = isCombinedPaymentsSupported ? getDescription(isByDueDate, selectedDate) : undefined;

    const triggerComponent = (
      <Table._MenuTriggerHeaderCell
        label={formatMessage('activities.batchPayments.screens.paymentIntentsTable.deductionDateHeaderCell.label')}
        onClick={noop}
        description={description}
        data-testid="deduction-date-header-cell"
      />
    );

    const backButton = {
      onBack: openCalendar.off,
      label: formatMessage('activities.batchPayments.screens.paymentIntentsTable.deductionDateHeaderCell.back.label'),
    };

    return isCalendarOpen ? (
      <DatePicker
        trigger={triggerComponent}
        isOpen={isMenuOpen}
        onOpenChange={handleMenuOpenChange}
        data-testid="deduction-date-header-cell-dropdown-date-picker"
        size={isCalendarOpen ? 'fit-content' : 'small'}
        {...props}
        ref={ref}
        excludeHolidays
        weekDays={[1, 2, 3, 4, 5]}
        onSelect={handleCalenderSelect}
        minDate={minDate}
        value={selectedDate}
        backButton={backButton}
        legendItems={
          selectedDate && [
            {
              label: formatMessage(
                'activities.batchPayments.screens.paymentIntentsTable.deductionDateHeaderCell.calendar.scheduledDate'
              ),
              date: formatDate(selectedDate, { dateStyle: 'medium' }),
            },
          ]
        }
      />
    ) : (
      <ActionsDropdownMenu
        trigger={triggerComponent}
        shouldCloseMenuOnItemClick={false}
        isOpen={isMenuOpen}
        onOpenChange={handleMenuOpenChange}
        size="large"
        {...props}
        items={options}
        ref={ref}
      />
    );
  }
);

DeductionDateHeaderCell.displayName = 'DeductionDateHeaderCell';
