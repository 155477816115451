import { useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { forwardRef } from '@melio/platform-utils';

import { useUpdatedInvoiceSystemMessage } from '../../hooks';
import { NoPaymentOptionsScreen } from './screens';

type NoPaymentOptionsActivityProps = {
  onViewInvoice: VoidFunction;
  paymentRequestLink: string;
  onError?: ARErrorFunction;
};

export const NoPaymentOptionsActivity = forwardRef<NoPaymentOptionsActivityProps>(
  ({ onError, onViewInvoice, paymentRequestLink, ...props }, ref) => {
    const { data: paymentRequestDetails, isLoading } = useGuestPayorPaymentRequestDetails({
      onError,
      paymentRequestLink,
    });

    useUpdatedInvoiceSystemMessage(paymentRequestLink);

    return (
      <NoPaymentOptionsScreen
        onViewInvoice={onViewInvoice}
        paymentRequestDetails={paymentRequestDetails}
        isLoading={isLoading}
        {...props}
        ref={ref}
      />
    );
  }
);
NoPaymentOptionsActivity.displayName = 'NoPaymentOptionsActivity';
