import { useIsMobile, useMelioIntl, useMonitoring } from '@melio/ar-domain';
import { TBTFormWidget, TBTFormWidgetFields } from '@melio/form-controls';
import { Button, Group, Text, useFormSubmissionController } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useEffect, useState } from 'react';

import {
  CardHolderAddressDetailsAndScheduledDate,
  CardHolderDetailsFormFields,
  CardHolderEmailDetails,
  CardHolderNameDetails,
} from '../../types';
import {
  CardHolderAddressDetailsAndScheduledDateForm,
  CardHolderEmailDetailsForm,
  CardHolderNameDetailsForm,
} from '../components';

export type AddCardFormScreenProps = {
  onSubmit: (data: CardHolderDetailsFormFields) => void;
  isSaving: boolean;
  amount: number;
  onEmailFieldBlur: (email: string) => void;
  EmailBanner: React.ReactNode;
  isEmailFieldLoading: boolean;
};

export const AddCardFormScreen = forwardRef<AddCardFormScreenProps>(
  ({ onSubmit, isSaving, amount, EmailBanner, onEmailFieldBlur, isEmailFieldLoading }, ref) => {
    const isMobile = useIsMobile();
    const { track } = useAnalytics();
    const { routeReady } = useMonitoring();
    const formSize = isMobile ? 'small' : 'large';
    const { formatCurrency, formatMessage } = useMelioIntl();
    const {
      onSubmissionStateChange: onCardHolderEmailDetailsFormStateChange,
      submitButtonProps: cardHolderEmailDetailsSubmitButtonProps,
      formState: cardHolderEmailDetailsFormState,
    } = useFormSubmissionController<CardHolderEmailDetails>();
    const {
      onSubmissionStateChange: onCardHolderNameDetailsFormStateChange,
      submitButtonProps: cardHolderNameDetailsSubmitButtonProps,
      formState: cardHolderNameDetailsFormState,
    } = useFormSubmissionController<CardHolderNameDetails>();
    const {
      onSubmissionStateChange: onCardDetailsFormStateChange,
      submitButtonProps: cardDetailsSubmitButtonProps,
      formState: tbtFormState,
    } = useFormSubmissionController<TBTFormWidgetFields>();
    const {
      onSubmissionStateChange: onCardHolderAddressDetailsAndScheduledDateFormStateChange,
      submitButtonProps: cardHolderAddressDetailsAndScheduledDateSubmitButtonProps,
      formState: cardHolderAddressDetailsAndScheduledDateFormState,
    } = useFormSubmissionController<CardHolderAddressDetailsAndScheduledDate>();

    useAnalyticsView('PaymentRequest', true, true, {
      PaymentMethodType: 'card',
    });

    const [isReady, ready] = useBoolean(false);
    const [cardDetails, setCardDetails] = useState<TBTFormWidgetFields>();
    const [cardHolderEmailDetails, setCardHolderEmailDetails] = useState<CardHolderEmailDetails>();
    const [cardHolderNameDetails, setCardHolderNameDetails] = useState<CardHolderNameDetails>();
    const [cardHolderAddressDetailsAndScheduledDate, setCardHolderAddressDetailsAndScheduledDate] =
      useState<CardHolderAddressDetailsAndScheduledDate>();

    const isFormValid =
      cardHolderEmailDetails &&
      cardHolderEmailDetailsFormState?.isValid &&
      cardHolderNameDetails &&
      cardHolderNameDetailsFormState?.isValid &&
      cardDetails &&
      cardHolderAddressDetailsAndScheduledDate &&
      cardHolderAddressDetailsAndScheduledDateFormState?.isValid &&
      tbtFormState?.isValid;

    const onClick = () => {
      cardHolderEmailDetailsSubmitButtonProps?.onClick();
      cardHolderNameDetailsSubmitButtonProps?.onClick();
      cardDetailsSubmitButtonProps?.onClick();
      cardHolderAddressDetailsAndScheduledDateSubmitButtonProps?.onClick();

      if (!isFormValid) {
        track('PaymentRequest', 'Status', {
          ErrorType: 'invalid-card-details',
        });
      }
    };

    useEffect(() => {
      if (isFormValid) {
        onSubmit({
          ...cardDetails,
          ...cardHolderAddressDetailsAndScheduledDate,
          ...cardHolderEmailDetails,
          ...cardHolderNameDetails,
          ...cardHolderEmailDetails,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      isFormValid,
      cardDetails,
      cardHolderAddressDetailsAndScheduledDate,
      cardHolderEmailDetails,
      cardHolderNameDetails,
    ]);

    const isSubmitButtonDisabled =
      cardDetailsSubmitButtonProps?.isDisabled ||
      cardHolderAddressDetailsAndScheduledDateSubmitButtonProps?.isDisabled ||
      cardHolderEmailDetailsSubmitButtonProps?.isDisabled ||
      cardHolderNameDetailsSubmitButtonProps?.isDisabled;

    const isSubmitButtonLoading =
      cardDetailsSubmitButtonProps?.isLoading ||
      cardHolderAddressDetailsAndScheduledDateSubmitButtonProps?.isLoading ||
      cardHolderEmailDetailsSubmitButtonProps?.isLoading ||
      cardHolderNameDetailsSubmitButtonProps?.isLoading ||
      isSaving;

    return (
      <Group variant="vertical" ref={ref} spacing="l">
        <Group variant="vertical" spacing={isMobile ? 'm' : 'l'}>
          <Text textStyle="body2Semi">{formatMessage('ar.guestPayment.activities.cardHolder.form.title')}</Text>
          {isReady && (
            <>
              <Group variant="vertical" spacing="xs">
                {EmailBanner}
                <CardHolderEmailDetailsForm
                  onSubmit={setCardHolderEmailDetails}
                  onSubmissionStateChange={onCardHolderEmailDetailsFormStateChange}
                  isSaving={isSaving}
                  size={formSize}
                  onEmailFieldBlur={onEmailFieldBlur}
                  isLoading={isEmailFieldLoading}
                />
              </Group>
              <CardHolderNameDetailsForm
                onSubmit={setCardHolderNameDetails}
                onSubmissionStateChange={onCardHolderNameDetailsFormStateChange}
                isSaving={isSaving}
                size={formSize}
              />
            </>
          )}
          <TBTFormWidget
            onSubmissionStateChange={onCardDetailsFormStateChange}
            onSubmit={setCardDetails}
            isSaving={isSaving}
            onReady={ready.on}
            size={formSize}
            hideCardLogos
          />
          {isReady && (
            <CardHolderAddressDetailsAndScheduledDateForm
              onSubmit={setCardHolderAddressDetailsAndScheduledDate}
              onSubmissionStateChange={onCardHolderAddressDetailsAndScheduledDateFormStateChange}
              isSaving={isSaving}
              size={formSize}
            />
          )}
        </Group>
        {isReady && (
          <Button
            ref={routeReady}
            data-testid="add-card-submit-button"
            isDisabled={isSubmitButtonDisabled}
            isLoading={isSubmitButtonLoading}
            onClick={onClick}
            size="large"
            label={formatMessage('ar.guestPayment.activities.cardHolder.form.buttons.submit.text', {
              amount: formatCurrency(amount),
            })}
          />
        )}
      </Group>
    );
  }
);
AddCardFormScreen.displayName = 'AddCardFormScreen';
