import { useGuestPayorPaymentRequestDetails } from '@melio/ar-domain';
import { SectionBannerProps } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { PaymentLayout } from '../layout';
import { GuestPayorFundingSourceTypes, GuestPayorPaymentOptions } from '../types';

type PaymentsLayoutWrapperProps = {
  paymentRequestLink: string;
  onViewInvoice: VoidFunction;
  goToPayByBank?: (id: string) => void;
  goToBank: VoidFunction;
  goToCard: VoidFunction;
  notificationInfo?: SectionBannerProps & { type: 'error' | 'invoice-updated' };
  isPaymentFormLoading: boolean;
  isPaymentProcessing: boolean;
};

export const PaymentsLayoutWrapper = forwardRef<PaymentsLayoutWrapperProps>(
  (
    {
      notificationInfo,
      goToCard,
      goToBank,
      goToPayByBank,
      paymentRequestLink,
      onViewInvoice,
      isPaymentFormLoading,
      isPaymentProcessing,
    },
    ref
  ) => {
    const { data, isLoading } = useGuestPayorPaymentRequestDetails({ paymentRequestLink });
    const params = useParams();

    const [selectedFundingSource, setSelectedFundingSource] = useState<GuestPayorFundingSourceTypes>();
    const handleFundingSourceSelection = useCallback(
      (type?: GuestPayorPaymentOptions, fundingSourceId?: string) => {
        if (type == 'bank') {
          setSelectedFundingSource(type);
          fundingSourceId ? goToPayByBank?.(fundingSourceId) : goToBank();
        } else if (type == 'card') {
          setSelectedFundingSource(type);
          goToCard();
        }
      },
      [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
      if (!data) return;

      if (data.invoice.paymentOptions.isAchAllowed && params['fundingSourceId'])
        handleFundingSourceSelection('bank', params['fundingSourceId']);
      else if (data.invoice.paymentOptions.isCardAllowed) handleFundingSourceSelection('card');
      else if (data.invoice.paymentOptions.isAchAllowed) handleFundingSourceSelection('bank');
      else handleFundingSourceSelection('custom');
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <PaymentLayout
        notificationProps={notificationInfo}
        onViewInvoice={onViewInvoice}
        paymentRequestDetails={data}
        isLoading={isLoading}
        isPaymentFormLoading={isPaymentFormLoading}
        isPaymentProcessing={isPaymentProcessing}
        onSelectFundingSource={handleFundingSourceSelection}
        selectedFundingSource={selectedFundingSource}
        ref={ref}
      />
    );
  }
);

PaymentsLayoutWrapper.displayName = 'PaymentsLayoutWrapper';

// create invoice without card and navigate to card -> it should re-navigate to the right place
// create outlet
