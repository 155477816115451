import React from 'react';
import { CompleteRequiredCompanyLegalInfo } from '@melio/ap-activities';
import { useMissingCompanyLegalInfoFields } from '@melio/ap-domain';
import { useUserHasPayments } from '@melio/platform-api';

import { Loader } from '@/cl/components/Loader/Loader.component';

export const WithCompleteRequiredCompanyLegalInfoGuard: React.FC<{ enabled: boolean }> = ({ children, enabled }) => {
  const { userHasPayments, isLoading: isLoadingUserHasPayments } = useUserHasPayments({ enabled });
  const { missingCompanyLegalInfoFields = [], isLoading: isLoadingMissingCompanyLegalInfo } =
    useMissingCompanyLegalInfoFields() || {};

  if (!enabled) {
    return <>{children}</>;
  }

  if (isLoadingMissingCompanyLegalInfo) {
    return <Loader isAbsoluteCenter />;
  }

  if (missingCompanyLegalInfoFields.length) {
    if (isLoadingUserHasPayments) {
      return <Loader isAbsoluteCenter />;
    }
    if (userHasPayments) {
      return <CompleteRequiredCompanyLegalInfo missingCompanyLegalInfoFields={missingCompanyLegalInfoFields} />;
    }
  }

  return <>{children}</>;
};
