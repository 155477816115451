import type { PillProps } from '@melio/penny';
import type { FundingSource } from '@melio/platform-api';
import { useMelioIntl } from '@melio/platform-provider';

export const useGetFundingSourceLabel = (fundingSource: FundingSource) => {
  const { formatMessage } = useMelioIntl();

  if (fundingSource.type === 'bank-account') {
    return formatMessage('widgets.reviewAndConfirm.payFromSection.fundingSource.bankAccount.label');
  } else if (fundingSource.type == 'flex-account') {
    return formatMessage('widgets.reviewAndConfirm.payFromSection.fundingSource.flexAccount.label');
  } else if (fundingSource.type == 'card') {
    return formatMessage(`widgets.reviewAndConfirm.payFromSection.fundingSource.${fundingSource.details.type}.label`);
  }
  return '';
};

export const useGetFundingSourceHelperText = (fundingSource: FundingSource) => {
  const { formatMessage } = useMelioIntl();

  if (fundingSource.type === 'bank-account' && !fundingSource.isVerified) {
    return formatMessage('widgets.reviewAndConfirm.payFromSection.fundingSource.bankAccount.helperText.unverified');
  }
  return '';
};

export const useGetFundingSourceContent = (fundingSource: FundingSource) => {
  const { formatMessage } = useMelioIntl();

  if (fundingSource.type === 'bank-account') {
    return formatMessage('widgets.reviewAndConfirm.payFromSection.fundingSource.bankAccount.content', {
      accountNumber: fundingSource.details.accountNumber.slice(-4),
    });
  } else if (fundingSource.type === 'flex-account') {
    return formatMessage('widgets.reviewAndConfirm.payFromSection.fundingSource.flexAccount.content');
  } else if (fundingSource.type === 'card') {
    return formatMessage(
      `widgets.reviewAndConfirm.payFromSection.fundingSource.${fundingSource.details.type}.content`,
      { accountNumber: fundingSource.details.lastFourDigits }
    );
  }
  return '';
};

export type UseUpdatedBadgeProps = {
  isShowUpdatedBadge: boolean;
  isDueDatePast?: boolean;
};

type BadgeProps = Pick<PillProps, 'label' | 'status'>;

export const useSectionBadge = ({
  isShowUpdatedBadge,
  isDueDatePast,
}: UseUpdatedBadgeProps): BadgeProps | undefined => {
  const { formatMessage } = useMelioIntl();
  if (isDueDatePast) {
    return {
      label: formatMessage('widgets.reviewAndConfirm.lateBadge'),
      status: 'warning',
    };
  }
  if (isShowUpdatedBadge) {
    return {
      label: formatMessage('widgets.reviewAndConfirm.updatedBadge'),
      status: 'success',
    };
  }
  return void 0;
};

export { useCardExpired } from './useCardExpired';
export { getFundingSourceIconType, getFundingSourceImageProps } from './useFundingSourceIconOrImage';
export { useFundingSourcePermissions } from './useFundingSourcePermissions';
export { useInternationalPaymentCardRestrictionsText } from './useInternationalPaymentCardRestrictionsText';
export { useInvalidFundingSources } from './useInvalidFundingSources';
