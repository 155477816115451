import { PayorPaymentRequestDetails } from '@melio/ar-domain';
import { Group, SectionBannerProps } from '@melio/penny';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import { Outlet } from 'react-router-dom';

import { PaymentProcessingIndicator, PaymentRequestDetailsHeader } from '../components';
import { GuestPayorFundingSourceTypes } from '../types';
import { GuestPaymentLayout } from './GuestPayment.layout';
import { LegalDisclaimer } from './LegalDisclaimer';
import { PaymentRequestTitle } from './PaymentRequestTitle';

export type PaymentLayoutProps = {
  isLoading: boolean;
  paymentRequestDetails?: PayorPaymentRequestDetails;
  onViewInvoice: VoidFunction;
  onSelectFundingSource: (fundingSource: GuestPayorFundingSourceTypes) => void;
  selectedFundingSource?: GuestPayorFundingSourceTypes;
  notificationProps?: SectionBannerProps;
  isPaymentFormLoading: boolean;
  isPaymentProcessing: boolean;
};

export const PaymentLayout = withAnalyticsContext<PaymentLayoutProps>(
  forwardRef(
    (
      {
        setAnalyticsProperties,
        notificationProps,
        onViewInvoice,
        selectedFundingSource,
        onSelectFundingSource,
        paymentRequestDetails,
        isLoading,
        isPaymentFormLoading,
        isPaymentProcessing,
      },
      ref
    ) => {
      const { track } = useAnalytics();
      const { isAchAllowed, isCardAllowed, customPayInstructions } =
        paymentRequestDetails?.invoice.paymentOptions || {};

      const getAnalyticsPaymentMethodType = (fundingSource: GuestPayorFundingSourceTypes | undefined) =>
        fundingSource === 'card' ? 'card' : 'ach';
      const handleFundingSourceSelection = (fundingSource: GuestPayorFundingSourceTypes) => {
        track('PaymentRequest', 'Click', {
          Intent: 'choose-payment-method',
          PaymentMethodType: getAnalyticsPaymentMethodType(fundingSource),
          Cta: fundingSource === 'card' ? 'card' : 'ach',
        });
        onSelectFundingSource(fundingSource);
      };

      const getAvailablePaymentOptionsString = () => {
        if (!paymentRequestDetails) return null;

        const options = [];

        if (isAchAllowed) options.push('ach');
        if (isCardAllowed) options.push('card');
        if (customPayInstructions) options.push('custom');

        return options.join('_');
      };

      const handleOnViewInvoice = () => {
        track('PaymentRequest', 'Click', {
          PageName: 'payment-request',
          Intent: 'view-invoice-file',
          Cta: 'view',
        });
        onViewInvoice();
      };

      setAnalyticsProperties({
        PageName: 'payment-request',
        PaymentMethodShown: getAvailablePaymentOptionsString(),
        PaymentMethodType: getAnalyticsPaymentMethodType(selectedFundingSource),
        Intent: 'pay-invoice',
      });

      return (
        <GuestPaymentLayout
          isLoading={isLoading}
          data-component={PaymentLayout.displayName}
          ref={ref}
          notificationProps={notificationProps}
        >
          {isPaymentProcessing && <PaymentProcessingIndicator />}
          <div {...(isPaymentProcessing && { style: { display: 'none' } })}>
            <Group variant="vertical">
              {!!paymentRequestDetails && (
                <Group variant="vertical" spacing="l" hasDivider>
                  <PaymentRequestDetailsHeader
                    payeePaymentRequestDetails={paymentRequestDetails.payeeDetails}
                    invoicePaymentRequestDetails={paymentRequestDetails.invoice}
                    onViewInvoice={handleOnViewInvoice}
                    isViewInvoiceEnabled={isPaymentFormLoading}
                  />
                  <Group variant="vertical" spacing="l">
                    <PaymentRequestTitle
                      paymentRequestDetails={paymentRequestDetails}
                      selectedFundingSource={selectedFundingSource}
                      handleFundingSourceSelection={handleFundingSourceSelection}
                    />
                    <Outlet />
                  </Group>
                </Group>
              )}
              {(isCardAllowed || isAchAllowed) && <LegalDisclaimer />}
            </Group>
          </div>
        </GuestPaymentLayout>
      );
    }
  )
);

PaymentLayout.displayName = 'PaymentLayout';
