import { Container, Grid, Group, IconButton, Text, useBreakpoint } from '@melio/penny';
import { useAnalytics } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';
import { SystemMessageDisplay } from '@melio/platform-utils/system-message';

import { usePlanInfo, usePlansTiers, useSubscription, useSubscriptionPlan } from '../../../../hooks';

type PlansSelectionHeaderProps = {
  isEligibleForFreeTrial: boolean;
  onBack?: () => void;
  gridTemplateCol?: string;
};

export const PlansSelectionHeader = ({
  isEligibleForFreeTrial,
  gridTemplateCol,
  onBack,
}: PlansSelectionHeaderProps) => {
  const { formatMessage } = useMelioIntl();
  const { getIsFreePlan } = usePlansTiers();
  const { currentPlan } = useSubscriptionPlan();
  const { planName } = usePlanInfo(currentPlan?.id);
  const { isExtraSmallScreen } = useBreakpoint();
  const { track } = useAnalytics();
  const subscription = useSubscription();

  const title = formatMessage(
    isEligibleForFreeTrial && (!currentPlan || getIsFreePlan(currentPlan.id))
      ? 'activities.subscription.plans.titleWhenEligibleForFreeTrial'
      : 'activities.subscription.plans.title'
  );

  const handleBack = () => {
    track('Organization', 'Click', {
      PageName: 'plan-selection',
      Flow: 'subscription',
      Intent: 'exit',
      Cta: 'exit',
      PlanChosen: subscription ? planName.toLowerCase() : 'free',
    });

    if (!subscription) {
      track('Organization', 'Status', {
        Intent: 'free-tier',
        Flow: 'exit-subscription',
        Cta: 'exit',
        Plan: 'free-tier',
      });
    }

    onBack?.();
  };

  return (
    <Container justifyContent="center" alignItems="center" aria-live="assertive">
      <Group variant="vertical" spacing="m">
        <SystemMessageDisplay />
        {isExtraSmallScreen && (
          <Group
            variant="horizontal"
            alignItems="flex-start"
            width="full"
            justifyContent="space-between"
            textAlign="start"
          >
            <Text as="h1" textStyle="heading1Semi">
              {title}
            </Text>
            {onBack && (
              <IconButton
                variant="primary"
                aria-label={formatMessage('activities.subscription.plans.closeButtonAriaLabel')}
                data-testid="back-button"
                icon="close"
                onClick={handleBack}
              />
            )}
          </Group>
        )}

        {!isExtraSmallScreen && (
          <Grid justifyContent="start" gap="m" pb="xxxs" gridTemplateColumns={gridTemplateCol} alignItems="center">
            <Text as="h1" textStyle="heading1Semi">
              {title}
            </Text>
            {onBack && (
              <Container justifyContent="flex-end" alignItems="flex-start" paddingY="xxs">
                <IconButton
                  variant="primary"
                  size="large"
                  aria-label={formatMessage('activities.subscription.plans.closeButtonAriaLabel')}
                  data-testid="back-button"
                  icon="close"
                  onClick={handleBack}
                />
              </Container>
            )}
          </Grid>
        )}
      </Group>
    </Container>
  );
};
