import { memo, useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
import { Box, Flex } from '@chakra-ui/react';
import { Card } from '@melio/penny';

import { GroupItemTheme } from '@/cl/components/PayCard/PayCard.component.theme';
import { useAppTheme } from '@/cl/theme/theme.hooks';
import { useScrollIntoView } from '@/hooks/useScrollIntoView.hooks';
import { GroupItem } from '@/types/payDashboard.types';
import { getPayDashboardItemId, getPayDashboardItemType } from '@/utils/payDashboard.utils';
import { BaseCardProps } from '@/widgets/pay-dashboard/cards/types/PaymentCard.types';
import { ItemCard } from './ItemCard';

export const GroupItemCard = memo(({ item, isSelected, onClick, isInactive }: { item: GroupItem } & BaseCardProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { inViewport } = useInViewport(ref);
  const styles = useAppTheme(GroupItemTheme, { itemType: item.type });

  useScrollIntoView({ ref, isSelected });

  const groupItemId = getPayDashboardItemId(item);
  const groupItemType = getPayDashboardItemType(item);

  return (
    <Box data-testid={`group-item-card-${groupItemType}-${groupItemId}`} ref={ref} sx={styles['cardContainer']}>
      {inViewport ? (
        <Card isSelected={isSelected} isInactive={isInactive} onClick={() => onClick(item)}>
          <Flex sx={styles['container']}>
            <ItemCard item={item} />
          </Flex>
        </Card>
      ) : null}
    </Box>
  );
});

GroupItemCard.displayName = 'GroupItemCard';
