/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { NakedButton } from '@melio/penny';
import { OriginFlow, useAnalytics } from '@melio/platform-analytics';
import { useAccount } from '@melio/platform-api';

import { EmptyStateList } from '@/cl/components/EmptyStateList/EmptyStateList.component';
import { BillsInboxEmptyStatePromo } from '@/components/bills-inbox/BillsInboxEmptyStatePromo';
import { usePartnerConfig } from '@/hooks/partners';
import { usePaydashboardEmptyState } from '@/hooks/payDashboard.hooks';
import { useRouter } from '@/hooks/router.hooks';
import { usePlatformIntl } from '@/translations/Intl';
import { MelioTutorialVideoModal } from '../melioTutorileVideo/MelioTutorialVideoModal.widget';

export const EmptyPayList = () => {
  const { isOpen: isTutorialModalOpen, onOpen: onOpenTutorialModal, onClose: onCloseTutorialModal } = useDisclosure();
  const { formatMessage } = usePlatformIntl();
  const { goToAddNewBill } = useRouter();
  const { partnerConfig } = usePartnerConfig();
  const { EmptyBillsIcon } = partnerConfig.icons;
  const { data: account } = useAccount({ id: 'me' });
  const { inboxEmailAddress } = account?.company || {};
  const isDashboardEmptyStateEnabled = usePaydashboardEmptyState();

  const showRegularEmptyState = isDashboardEmptyStateEnabled;

  const { track } = useAnalytics();
  const onAddABillClicked = React.useCallback(() => {
    track('PayDashboardInboxAddABill', 'Chose');
    goToAddNewBill({ originFlow: OriginFlow.BillPage });
  }, [track]);

  if (!showRegularEmptyState && inboxEmailAddress) {
    return <BillsInboxEmptyStatePromo billsInboxEmail={inboxEmailAddress} />;
  }

  return (
    <>
      <EmptyStateList
        testId="pay-dashboard-empty-state"
        title={formatMessage('widgets.emptyPayList.title')}
        description={formatMessage('widgets.emptyPayList.description')}
        icon={EmptyBillsIcon.small}
        button={{
          text: formatMessage('widgets.emptyPayList.addBillButton'),
          onClick: onAddABillClicked,
        }}
        secondaryButton={
          <NakedButton
            variant="secondary"
            onClick={onOpenTutorialModal}
            label={formatMessage('widgets.emptyPayList.secondaryButton')}
          />
        }
      />
      <MelioTutorialVideoModal
        isOpen={isTutorialModalOpen}
        origin={OriginFlow.PayEmptyInbox}
        onModalClose={onCloseTutorialModal}
      />
    </>
  );
};
