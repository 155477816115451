import { AddressSearchWidget, AddressSearchWidgetProps, CheckAccountFormModel } from '@melio/ap-widgets';
import {
  Container,
  Form,
  Group,
  Modal,
  NakedButton,
  SectionBanner,
  Text,
  useBreakpoint,
  UseMelioFormResults,
} from '@melio/penny';
import { Address, PaperCheckDeliveryMethod, US_STATES, Vendor } from '@melio/platform-api';
import { MessageKey, useMelioIntl } from '@melio/platform-i18n';
import { ComponentProps } from 'react';

import { MandatoryFieldDisclaimer } from '../../MandatoryFieldDisclaimer';
import { AriaLive } from '../../util/AriaLive';

type Props = Pick<ComponentProps<typeof Modal>, 'isOpen' | 'onClose'> &
  UseMelioFormResults<CheckAccountFormModel> & {
    isSaving: boolean;
    isError: boolean;
  } & {
    editing: boolean;
    paperCheck?: PaperCheckDeliveryMethod;
    onEdit: VoidFunction;
    vendor?: Vendor;
  };

export function PaperCheckFormModal({ isOpen, isError, editing, onEdit, paperCheck, onClose, vendor, ...form }: Props) {
  const { formatMessage } = useMelioIntl();
  const { isExtraSmallScreen: isMobile } = useBreakpoint();

  const handleAddressChange: AddressSearchWidgetProps['onChange'] = (event) => {
    const address = event.target.value as unknown as Address;
    const isFormValueCleared = address === null && form.getValues('line1');

    if (isFormValueCleared) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - if we pass '' search works properly only after 2nd search input
      form.setValue('line1', null);
    }

    const setFormField = (field: keyof Omit<CheckAccountFormModel, 'printName'>) => {
      if (address?.[field]) {
        form.setValue(field, address[field], {
          shouldValidate: true,
        });
      }
    };

    setFormField('line1');
    setFormField('line2');
    setFormField('state');
    setFormField('city');
    setFormField('postalCode');
  };

  const headerKey: MessageKey = editing
    ? 'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.title'
    : 'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.title.confirm';

  const primaryButtonLabelKey: MessageKey = editing
    ? 'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.actions.save'
    : 'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.actions.confirm';

  const modalContent = editing ? (
    <Group variant="vertical" width="full" spacing="xs">
      <MandatoryFieldDisclaimer />
      <Group variant="vertical" width="full" spacing="m">
        <Form.TextField
          {...form.registerField('printName')}
          labelProps={{
            label: formatMessage(
              'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.printName.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.printName.placeholder'
          )}
          helperTextProps={{
            label: formatMessage(
              'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.printName.helperText'
            ),
          }}
        />
        <AddressSearchWidget
          {...form.registerField('line1')}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - Type of formatSelectedValue will be fixed & data can be string | undefiend | Address
          formatSelectedValue={(data): Address | string => (data.value as unknown as Address)?.line1 ?? data.value}
          labelProps={{
            label: formatMessage(
              'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.line1.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.line1.placeholder'
          )}
          onChange={handleAddressChange}
        />
        <Form.TextField
          {...form.registerField('line2')}
          labelProps={{
            label: formatMessage(
              'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.line2.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.line2.placeholder'
          )}
        />
        <Form.TextField
          {...form.registerField('city')}
          labelProps={{
            label: formatMessage(
              'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.city.label'
            ),
          }}
          placeholder={formatMessage(
            'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.city.placeholder'
          )}
        />
        <Group width="full" variant={isMobile ? 'vertical' : 'horizontal'}>
          <Group.Item basis="50%">
            <Form.TextField
              {...form.registerField('postalCode')}
              labelProps={{
                label: formatMessage(
                  'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.postalCode.label'
                ),
              }}
              placeholder={formatMessage(
                'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.postalCode.label'
              )}
              helperTextProps={{
                label: formatMessage(
                  'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.postalCode.helperText'
                ),
              }}
            />
          </Group.Item>
          <Group.Item basis="50%">
            <Form.SelectNew
              {...form.registerField('state')}
              labelProps={{
                label: formatMessage(
                  'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.state.label'
                ),
              }}
              placeholder={formatMessage(
                'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.fields.state.placeholder'
              )}
              options={US_STATES.map((state) => ({
                value: state,
                label: formatMessage(`local.USA.states.${state}`),
                testId: state,
              }))}
              emptyState={{ label: formatMessage('form.select.default.emptyState.label') }}
            />
          </Group.Item>
        </Group>
      </Group>
    </Group>
  ) : paperCheck && vendor ? (
    <PaperCheckCard paperCheck={paperCheck} onEdit={onEdit} />
  ) : null;

  /**
   * Guygo: This is a hack suggested by penny in the meantime to force the bottom drawer (mobile) to open with max content https://meliorisk.atlassian.net/browse/ME-66846?focusedCommentId=114308
   * until penny will support mobile SafeAreas natively
   */
  const style = isMobile
    ? {
        style: {
          overflow: 'scroll',
          maxHeight: '50vh',
          paddingLeft: 10,
          paddingRight: 10,
        },
      }
    : undefined;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header={formatMessage(headerKey)}
      primaryButton={{
        ...form.submitButtonProps,
        label: formatMessage(primaryButtonLabelKey),
        variant: 'primary',
        isLoading: form.isSaving,
      }}
      secondaryButton={{
        ...form.cancelButtonProps,
        onClick: onClose,
        isDisabled: form.isSaving,
        label: formatMessage('activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.actions.cancel'),
        variant: 'tertiary',
      }}
    >
      <Group {...style}>
        <Form {...form.formProps}>
          <Group variant="vertical" width="full" spacing="m">
            {!editing ? (
              <Text textStyle="body2">
                {formatMessage(
                  'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.confirm-disclaimer',
                  {
                    vendorName: vendor?.name,
                  }
                )}
              </Text>
            ) : null}
            <AriaLive shown={isError}>
              <SectionBanner
                data-testid="paper-check-form-modal-error-section"
                variant="critical"
                title={formatMessage('activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.error.title')}
                description={formatMessage(
                  'activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.error.description'
                )}
              />
            </AriaLive>
            {modalContent}
          </Group>
        </Form>
      </Group>
    </Modal>
  );
}

function PaperCheckCard({ paperCheck, onEdit }: { paperCheck: PaperCheckDeliveryMethod; onEdit: VoidFunction }) {
  const { formatMessage } = useMelioIntl();
  return (
    <Container paddingX="s" paddingY="s" border="regular">
      <Group justifyContent="space-between" alignItems="flex-start">
        <Group.Item grow={4} basis={0} shrink={1}>
          <Group variant="vertical" spacing="xxxs">
            <Text as="span" textStyle="body2Semi">
              {paperCheck.details.printName}
            </Text>
            <Text as="span" textStyle="body2" shouldSupportEllipsis>
              {[paperCheck.details.address.line1, paperCheck.details.address.line2, paperCheck.details.address.city]
                .filter(Boolean)
                .join(', ')}
            </Text>
            <Text as="span" textStyle="body2">
              {paperCheck.details.address.state} {paperCheck.details.address.postalCode}
            </Text>
          </Group>
        </Group.Item>

        <NakedButton
          variant="secondary"
          data-testid="edit-button"
          onClick={onEdit}
          label={formatMessage('activities.paymentFlow.form.content.deliveryMethod.paperCheckModal.actions.edit')}
        />
      </Group>
    </Container>
  );
}
