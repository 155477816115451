import React from 'react';
import { CompleteRequiredDetailsActivity } from '@melio/ap-activities/src/components/complete-required-details';
import { withAnalyticsContext } from '@melio/platform-analytics';
import { useMelioIntl } from '@melio/platform-i18n';

export const CompleteMtlDetailsScreen = withAnalyticsContext<{
  isTriggerManually?: boolean;
  onDone: VoidFunction;
}>(({ isTriggerManually, setAnalyticsProperties, onDone }) => {
  const { formatMessage } = useMelioIntl();

  setAnalyticsProperties({
    Flow: isTriggerManually ? 'banner' : 'sign-in',
    Intent: isTriggerManually ? 'complete-details' : 'sign-in',
  });

  return (
    <CompleteRequiredDetailsActivity
      onClose={onDone}
      onDone={onDone}
      onSkip={onDone}
      allowSkip={isTriggerManually}
      submitButtonLabel={formatMessage('widgets.completeRequiredDetailsModal.button.primary.label')}
      placement={'complete-required-details'}
    />
  );
});
