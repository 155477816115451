import { SystemMessage, useIsMobile } from '@melio/ar-domain';
import { Container, Group, Layout } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

import { GuestPaymentFooter } from '../GuestPaymentFooter';

export type BaseLayoutProps = {
  isLoading?: boolean;
  onBack?: VoidFunction;
  onClose?: VoidFunction;
};

export const BaseLayout = forwardRef<BaseLayoutProps>(({ isLoading, onBack, onClose, children, ...props }, ref) => {
  const isMobile = useIsMobile();

  return (
    <Container
      backgroundColor="light"
      width="full"
      height="full"
      data-layout={BaseLayout.displayName}
      {...props}
      ref={ref}
    >
      <Layout
        backgroundColor="default"
        isLoading={isLoading}
        paddingContent={isMobile ? 'none' : 'xxl'}
        maxWidth="600px"
      >
        <Group variant="vertical" alignItems="center" spacing="l">
          <SystemMessage />
          <Container border="regular" backgroundColor="white" paddingX="l" paddingY="l">
            {children}
          </Container>
          <GuestPaymentFooter />
        </Group>
      </Layout>
    </Container>
  );
});

BaseLayout.displayName = 'BaseLayout';
