import { FormattedMessage, useMelioIntl } from '@melio/ar-domain';
import { FileAttachment, Group, StatusIconSolid, Text } from '@melio/penny';
import { useAnalytics, useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';

import { GuestPaymentLayout } from '../../../layout';

export type InvoicePaidScreenProps = {
  amount: number;
  invoicePreviewUrl?: string;
  isMarkedAsPaid: boolean;
  onViewInvoice: VoidFunction;
};

const getAnalyticsCommonProperties = (isMarkedAsPaid: boolean) => ({
  PageName: isMarkedAsPaid ? 'payment-request-marked-paid' : 'payment-request-already-paid',
});

export const InvoicePaidScreen = forwardRef<InvoicePaidScreenProps>(
  ({ amount, isMarkedAsPaid, invoicePreviewUrl, onViewInvoice }, ref) => {
    const { formatCurrency } = useMelioIntl();

    useAnalyticsView('PaymentRequest', true, true, getAnalyticsCommonProperties(isMarkedAsPaid));

    const { track } = useAnalytics();

    const handleOnViewInvoice = () => {
      track('PaymentRequest', 'Click', {
        ...getAnalyticsCommonProperties(isMarkedAsPaid),
        Intent: 'view-invoice-file',
        Cta: 'view',
      });
      onViewInvoice();
    };

    const title = (
      <FormattedMessage
        id={
          isMarkedAsPaid
            ? 'ar.guestPayment.invoicePaid.markedAsPaid.title'
            : 'ar.guestPayment.invoicePaid.alreadyScheduled.title'
        }
      />
    );

    return (
      <GuestPaymentLayout data-component={InvoicePaidScreen.displayName} ref={ref}>
        <Group variant="vertical" spacing="l">
          <Group justifyContent="space-between">
            <Group variant="vertical" justifyContent="space-between">
              <StatusIconSolid variant="success" size="extra-large" />
              <Text textStyle="heading3Semi" color="global.neutral.1000">
                {title}
              </Text>
              <Group variant="vertical" spacing="xxxs">
                <Text data-testid="payment-confirmation-amount" textStyle="heading1Semi">
                  {formatCurrency(amount)}
                </Text>
              </Group>
            </Group>
            {invoicePreviewUrl && (
              <FileAttachment onViewModeClick={handleOnViewInvoice} value={invoicePreviewUrl} isViewMode width={90} />
            )}
          </Group>
        </Group>
      </GuestPaymentLayout>
    );
  }
);

InvoicePaidScreen.displayName = 'InvoicePaidScreen';
