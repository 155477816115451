import { CurrencyTooltip } from '@melio/ap-widgets';
import { Group, Table, Text } from '@melio/penny';
import { Bill, Vendor } from '@melio/platform-api';
import { FeatureFlags, useFeature } from '@melio/platform-feature-flags';
import { useMelioIntl } from '@melio/platform-i18n';
import { usePartnerFeature } from '@melio/platform-provider';

import { getTotalBillsBalance, getVendorUnpaidEbill } from '../utils';
import { VendorsTabAmountInputCell } from './VendorsTabAmountInputCell';

export type VendorsTabOpenBalanceCellProps = {
  vendor: Vendor;
  unpaidBills: Bill[];
  value?: string;
  onAmountChange: (vendor: Vendor, value: string) => void;
};
export const VendorsTabOpenBalanceCell = ({
  vendor,
  unpaidBills,
  value,
  onAmountChange,
}: VendorsTabOpenBalanceCellProps) => {
  const { formatCurrency, formatMessage } = useMelioIntl();
  const [isEBillLegalDisclaimer] = usePartnerFeature('EBillLegalDisclaimer', false);
  const [isVendorsBatchPaymentsEnabled] = useFeature<boolean>(
    FeatureFlags.PlatformVendorsBatchNewPaymentsEnabled,
    false,
    {
      shouldTrack: true,
    }
  );
  const unpaidEbill = getVendorUnpaidEbill(unpaidBills);
  const billerAccountBalance =
    unpaidEbill?.billerAccountBalance !== undefined ? unpaidEbill.billerAccountBalance : null;

  const openBalance = getTotalBillsBalance(unpaidBills);
  return unpaidBills.length === 0 && isVendorsBatchPaymentsEnabled ? (
    <VendorsTabAmountInputCell value={value} vendor={vendor} onAmountChange={onAmountChange} />
  ) : (
    <Table.Cell data-testid={`vendors-tab-row-${vendor.id}-open-balance-cell`} textAlign="end">
      <CurrencyTooltip currency={vendor.currency}>
        <Group variant="vertical" spacing="xxs" alignItems="flex-end">
          <Text
            textStyle="body3Semi"
            color={openBalance === 0 ? 'global.neutral.800' : 'global.neutral.1000'}
            data-testid={`vendors-tab-row-${vendor.id}-open-balance-cell-text`}
          >
            {formatCurrency(openBalance, vendor.currency ?? undefined)}
          </Text>
          {isEBillLegalDisclaimer && billerAccountBalance != null ? (
            <Text
              textStyle="body4"
              color="global.neutral.900"
              data-testid={`vendors-tab-row-${vendor.id}-statement-balance-text`}
            >
              {formatMessage('activities.payDashboard.eBills.disclaimers.statementBalance', {
                eBillTotalBalance: formatCurrency(billerAccountBalance),
              })}
            </Text>
          ) : null}
        </Group>
      </CurrencyTooltip>
    </Table.Cell>
  );
};
