import { identity, pickBy } from 'lodash';
import { UpdateVendorParams } from '@melio/platform-api';

import { VendorDetailsExtendedUnmanagedFields } from './types';

export const getUpdateVendorPayload = (
  data: VendorDetailsExtendedUnmanagedFields,
  defaultValues?: Partial<VendorDetailsExtendedUnmanagedFields>,
): UpdateVendorParams => {
  const {
    companyName,
    email,
    phone,
    accountNumber,
    nickname,
    line1,
    line2,
    city,
    state,
    postalCode,
    bankAccountNumber,
    bankRoutingNumber,
    uniqueName,
  } = data;

  const getBankAccount = () => {
    if (bankAccountNumber && bankRoutingNumber) {
      return {
        bankAccount: {
          accountNumber: bankAccountNumber,
          routingNumber: bankRoutingNumber,
        },
      };
    }

    if (defaultValues?.bankAccountNumber && defaultValues?.bankRoutingNumber) {
      return { bankAccount: null };
    }

    return {};
  };

  const bankAccount = getBankAccount();

  const contact = pickBy(
    {
      email,
      phoneNumber: phone,
      ...(line1 ? { address: { line1, line2, city, state, postalCode } } : null),
    },
    identity,
  );

  return {
    name: companyName ?? undefined,
    uniqueName: uniqueName ?? undefined,
    contact,
    accountNumber: accountNumber ?? undefined,
    ...(nickname ? { nickname } : {}),
    ...bankAccount,
  };
};
