import { FormattedMessage } from '@melio/ar-domain';
import { Group, Illustration, Text, ThemeIllustrationType } from '@melio/penny';
import { useAnalyticsView } from '@melio/platform-analytics';
import { forwardRef } from '@melio/platform-utils';
import React from 'react';

import { GuestPaymentLayout } from '../../../layout';
import { ErrorType } from '../../../types';

const getErrorLabelsAndIllustration = ({
  errorType,
  vendorEmail,
}: {
  errorType: ErrorType | undefined;
  vendorEmail?: string;
}): {
  title: React.ReactNode;
  description: React.ReactNode;
  illustrationType: ThemeIllustrationType;
} => {
  if (errorType === ErrorType.EXPIRED) {
    return {
      title: <FormattedMessage id="ar.guestPayment.errors.paymentLinkExipred.title.text" />,
      description: (
        <FormattedMessage id="ar.guestPayment.errors.paymentLinkExipred.description.text" values={{ vendorEmail }} />
      ),
      illustrationType: 'expired',
    };
  }

  if (errorType === ErrorType.INVOICE_CANCELLED) {
    return {
      title: <FormattedMessage id="ar.guestPayment.errors.invoiceCancelled.title.text" />,
      description: <FormattedMessage id="ar.guestPayment.errors.invoiceCancelled.description.text" />,
      illustrationType: 'declined',
    };
  }

  return {
    title: <FormattedMessage id="ar.guestPayment.errors.generalMessage.title.text" />,
    description: <FormattedMessage id="ar.guestPayment.errors.generalMessage.title.description" />,
    illustrationType: 'network-error',
  };
};

export type GuestPaymentRequestErrorScreenProps = {
  errorType: ErrorType | undefined;
  vendorEmail?: string;
  isLoading?: boolean;
};

export const GuestPaymentRequestErrorScreen = forwardRef<GuestPaymentRequestErrorScreenProps>(
  ({ errorType, vendorEmail, isLoading }, ref) => {
    const { title, description, illustrationType } = getErrorLabelsAndIllustration({
      errorType,
      vendorEmail,
    });

    const isCanceledInvoice = errorType === ErrorType.INVOICE_CANCELLED;

    useAnalyticsView(
      'PaymentRequest',
      !isLoading,
      true,
      isCanceledInvoice
        ? {
            PageName: 'payment-request-cancelled',
            Intent: 'pay-invoice',
          }
        : {
            PageName: 'network-error',
            Intent: 'pay-invoice',
            ErrorType: errorType,
            Status: 'failure',
          }
    );

    return (
      <GuestPaymentLayout data-component={GuestPaymentRequestErrorScreen.displayName} isLoading={isLoading} ref={ref}>
        <Group variant="vertical" spacing="s" alignItems="flex-start">
          <Illustration type={illustrationType} />
          <Group variant="vertical" spacing="none">
            <Text textStyle="heading3Semi">{title}</Text>
            <Text textStyle="body3">{description}</Text>
          </Group>
        </Group>
      </GuestPaymentLayout>
    );
  }
);

GuestPaymentRequestErrorScreen.displayName = 'GuestPaymentRequestErrorScreen';
