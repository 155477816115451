// eslint-disable-next-line import/no-deprecated
import {
  addWildcardToRoutes,
  RouteElement,
  useCurrentRoute,
  useFlowRouting,
  useSystemMessage,
  withMemoryRouter,
  withOutlet,
} from '@melio/ar-domain';
import { useAnalytics, withAnalyticsContext } from '@melio/platform-analytics';
import { PartnerName } from '@melio/platform-api';
import { forwardRef, useBoolean } from '@melio/platform-utils';
import { useCallback, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  AddBankAccountDetailsActivity,
  AddBankAccountDetailsActivityProps,
  AddPlaidAccountModalActivity,
  AuthenticationModalActivity,
  OnboardingActivity,
  PayByBankReviewAndConfirmActivity,
} from '../activities';
import { useGetPaymentErrorNotification, useUpdatedInvoiceSystemMessage, useUserState } from '../hooks';

type BankPaymentFulfillmentFlowProps = Pick<
  AddBankAccountDetailsActivityProps,
  'onSelectFundingSource' | 'onViewInvoice'
> & {
  paymentRequestLink: string;
  isNavigateApUsersToDashboardEnabled?: boolean;
  onDone: (paymentId: string) => unknown;
  onError?: ARErrorFunction;
  partnerName: PartnerName;
  onLoggedIn?: (accessToken: string, refreshToken?: string | null) => Promise<unknown>;
};

export const BankPaymentFulfillmentFlow = withMemoryRouter(
  withAnalyticsContext<BankPaymentFulfillmentFlowProps>(
    forwardRef(
      (
        {
          onError,
          paymentRequestLink,
          isNavigateApUsersToDashboardEnabled,
          onLoggedIn,
          onDone,
          partnerName,
          setAnalyticsProperties,
          onViewInvoice,
          onSelectFundingSource,
          ...props
        },
        ref
      ) => {
        const navigation = useFlowNavigation();

        const [email, setEmail] = useState<string>('');
        const [isPlaidLoading, plaidLoading] = useBoolean();

        const { userType } = useUserState({ email, paymentRequestLink });

        setAnalyticsProperties({
          UserType: userType,
          Email: email || undefined,
        });

        useUpdatedInvoiceSystemMessage(paymentRequestLink);

        const { track } = useAnalytics();
        const getPaymentErrorNotification = useGetPaymentErrorNotification();
        const { triggerMessage } = useSystemMessage();
        const errorHandler = (error: ARPlatformError) => {
          track('PaymentRequest', 'Status', { ErrorType: 'payment-failed' });
          triggerMessage({ type: 'critical', title: getPaymentErrorNotification('bank').description });
          onError?.(error);
        };

        const authModalCloseHandler = useCallback(
          () => navigation.goToForm({ replace: true, keepSystemMessage: true }),
          [] // eslint-disable-line react-hooks/exhaustive-deps
        );

        return (
          <Routes>
            <Route
              path={navigation.Paths.Form}
              element={withOutlet(
                <AddBankAccountDetailsActivity
                  onViewInvoice={onViewInvoice}
                  onSelectFundingSource={onSelectFundingSource}
                  isNavigateApUsersToDashboardEnabled={isNavigateApUsersToDashboardEnabled}
                  paymentRequestLink={paymentRequestLink}
                  onDone={(email) => {
                    setEmail(email);
                    navigation.goToAuth({ replace: false });
                  }}
                  onError={onError}
                  isDisabled={!navigation.isOnRoute('Form')}
                  {...props}
                  ref={ref}
                />
              )}
            >
              <Route
                path={navigation.Paths.Auth}
                element={withOutlet(
                  <AuthenticationModalActivity
                    isOpen={!navigation.isOnRoute('Plaid') || isPlaidLoading}
                    email={email}
                    onLoggedIn={onLoggedIn}
                    onDone={() => navigation.goToOnboarding({ replace: true })}
                    partnerName={partnerName}
                    isLoading={!navigation.isOnRoute('Auth')}
                    onClose={authModalCloseHandler}
                    onError={errorHandler}
                  />
                )}
              >
                <Route
                  path={navigation.Paths.Onboarding}
                  element={
                    <OnboardingActivity
                      paymentRequestLink={paymentRequestLink}
                      onDone={() => navigation.goToPlaid({ replace: true })}
                      onError={errorHandler}
                      onClose={authModalCloseHandler}
                    />
                  }
                />
                <Route
                  path={navigation.Paths.Plaid}
                  element={
                    <AddPlaidAccountModalActivity
                      isOpen
                      onDone={(fundingSourceId) => navigation.goToReviewAndConfirm(fundingSourceId, { replace: true })}
                      onLoadToken={(isLoading) => (isLoading ? plaidLoading.on() : plaidLoading.off())}
                      onClose={authModalCloseHandler}
                      onError={errorHandler}
                    />
                  }
                />
              </Route>
            </Route>
            <Route
              path={navigation.Paths.ReviewAndConfirm}
              element={
                <RouteElement
                  component={PayByBankReviewAndConfirmActivity}
                  pathToProps={{ fundingSourceId: 'fundingSourceId' }}
                  paymentRequestLink={paymentRequestLink}
                  onViewInvoice={onViewInvoice}
                  onDone={onDone}
                  onError={errorHandler}
                  onClose={() => navigation.goToForm({ replace: true })}
                />
              }
            />
          </Routes>
        );
      }
    )
  )
);

BankPaymentFulfillmentFlow.displayName = 'BankPaymentFulfillmentFlow';

const useFlowNavigation = () => {
  enum Paths {
    Form = '',
    Auth = 'setup',
    Onboarding = 'onboarding',
    Plaid = 'account',
    ReviewAndConfirm = 'review-and-confirm/:fundingSourceId',
  }

  const { createCallback, createCallbackWithParam } = useFlowRouting<Paths>();

  const isOnRoute = useCurrentRoute(Paths).isOnRoute;

  return {
    isOnRoute,
    Paths: addWildcardToRoutes(Paths),
    goToForm: createCallback(Paths.Form),
    goToAuth: createCallback(Paths.Auth),
    goToOnboarding: createCallback(Paths.Onboarding, { pathPrefix: Paths.Auth }),
    goToPlaid: createCallback(Paths.Plaid, { pathPrefix: Paths.Auth }),
    goToReviewAndConfirm: createCallbackWithParam(Paths.ReviewAndConfirm, 'fundingSourceId'),
  };
};
