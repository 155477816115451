import { ComponentProps } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AccountingSoftwareRedirectActivity } from '../activities/AccountingSoftwareRedirect/AccountingSoftwareRedirectActivity';
import { AssignFirmClientPlan, ClientPlanAssignedSuccessfully } from '../activities/AssignFirmClientPlan';
import { ClientCreatedSuccessfully } from '../activities/ClientCreatedSuccessfully/ClientCreatedSuccessfully';
import { ClientCreationOptions } from '../activities/ClientCreationOptions/ClientCreationOptions.activity';
import { EmptyNewFirmClient } from '../activities/EmptyNewFirmClient/EmptyNewFirmClient.activity';
import { FirmDashboardActivity } from '../activities/FirmDashboard';
import { InvitationSent } from '../activities/InvitationSent/InvitationSent.activity';
import { InviteFirmClient } from '../activities/InviteFirmClient/InviteFirmClient.activity';
import { NewFirmClient } from '../activities/NewFirmClient/NewFirmClient.activity';
import { RedirectTarget } from '../consts';

const DashboardRoutes = ({
  goToSettingsSubscriptionPlans,
  onSwitchOrganization,
}: {
  goToSettingsSubscriptionPlans: ({ refresh }: { refresh?: boolean }) => void;
  onSwitchOrganization: ComponentProps<typeof FirmDashboardActivity>['onSwitchOrganization'];
}) => (
  <Routes>
    <Route
      index
      element={
        <FirmDashboardActivity
          onSwitchOrganization={onSwitchOrganization}
          goToSettingsSubscriptionPlansAndRefresh={() => goToSettingsSubscriptionPlans({ refresh: true })}
        />
      }
    />
  </Routes>
);

const StandaloneRoutes = ({
  goToMelioClientsDashboard,
  switchOrganization,
  intuitConnect,
}: {
  goToMelioClientsDashboard: VoidFunction;
  switchOrganization(orgId: string): Promise<void>;
  intuitConnect: {
    loginToQuickBooks: () => void;
    isLoading: boolean;
  };
}) => {
  const handleRedirect = async ({
    firmClientOrgId,
    returnTo = RedirectTarget.ClientDashboard,
  }: {
    firmClientOrgId: string;
    returnTo?: RedirectTarget;
  }) => {
    const handlers: Record<RedirectTarget, () => void | Promise<void>> = {
      [RedirectTarget.ClientDashboard]: goToMelioClientsDashboard,
      [RedirectTarget.SetupClient]: () => switchOrganization(firmClientOrgId),
    };

    return await handlers[returnTo]();
  };

  return (
    <Routes>
      <Route
        path="/client/creation-options"
        element={<ClientCreationOptions onClose={goToMelioClientsDashboard} intuitConnect={intuitConnect} />}
      />
      <Route path="/client/invite" element={<InviteFirmClient onClose={goToMelioClientsDashboard} />} />
      <Route path="/client/new/success" element={<ClientCreatedSuccessfully onDone={handleRedirect} />} />
      <Route path="/client/new/empty" element={<EmptyNewFirmClient />} />
      <Route path="/client/new" element={<NewFirmClient onClose={goToMelioClientsDashboard} />} />
      <Route path="/client/:id/assign-plan" element={<AssignFirmClientPlan onClose={goToMelioClientsDashboard} />} />
      <Route
        path="/client/:id/assign-plan/success"
        element={<ClientPlanAssignedSuccessfully onDone={handleRedirect} />}
      />
      <Route path="/client/invitation-sent" element={<InvitationSent onDone={goToMelioClientsDashboard} />} />
      <Route
        path="/client/accounting-software-redirect"
        element={<AccountingSoftwareRedirectActivity onClose={goToMelioClientsDashboard} />}
      />
    </Routes>
  );
};

export const AccountantsRoutes = {
  DashboardRoutes,
  StandaloneRoutes,
};
