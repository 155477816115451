import { EmptyState, FormattedMessage } from '@melio/ar-domain';
import { Container, NakedButton, Text } from '@melio/penny';
import { forwardRef } from '@melio/platform-utils';

export type ServiceChargesEmptyStateProps = {
  setSearchTerm: (value: string) => unknown;
};

export const ServiceChargesEmptyState = forwardRef<ServiceChargesEmptyStateProps>(({ setSearchTerm }, ref) => (
  <EmptyState
    ref={ref}
    illustrationType="no-items"
    content={
      <Container textAlign="center">
        <Text textStyle="body4">
          <FormattedMessage id="ar.settings.activities.serviceChagresTable.components.serviceChagresTable.emptyState.text" />
        </Text>
      </Container>
    }
    footer={<NakedButton data-testid="clean-search-button" label="Clear all" onClick={() => setSearchTerm('')} />}
  />
));

EmptyState.displayName = 'EmptyState';
