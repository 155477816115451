import { Group, SectionBanner, StatusModal, Text } from '@melio/penny';
import { useMelioIntl } from '@melio/platform-i18n';

type Props = {
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: VoidFunction;
  onSubmit: VoidFunction;
  header: string;
  description: string;
};

export const formatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    timeZone: 'utc',
  };

  return date.toLocaleDateString('en-US', options);
};

export const DeliveryMethodChangedModal = ({ isOpen, onClose, onSubmit, header, description, isSubmitting }: Props) => {
  const { formatMessage } = useMelioIntl();

  return (
    <StatusModal
      data-testid="delivery-method-changed-modal"
      header={formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.header')}
      variant="warning"
      isOpen={isOpen}
      onClose={onClose}
      primaryButton={{
        isLoading: isSubmitting,
        label: formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.confirmChange'),
        onClick: onSubmit,
        variant: 'primary',
      }}
      secondaryButton={{
        isDisabled: isSubmitting,
        label: formatMessage('activities.paymentFlow.form.content.deliveryMethodChangedModal.editPayment'),
        onClick: onClose,
        variant: 'tertiary',
      }}
    >
      <Group variant="vertical">
        <Text>{header}</Text>
        <SectionBanner hideIcon description={description} variant="warning" />
      </Group>
    </StatusModal>
  );
};
